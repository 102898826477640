import React, { useState, useEffect, useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from "react-i18next"
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux';
import { decrementInvitations, setInvitations } from '../../store/reducers/invitations';
import { addTeam } from '../../store/reducers/user'
import CButton from "../../components/Button"
import CModal from "../../components/Modal"
import { CText } from "../../components/Text"
import ProfilePicture from "../../components/ProfilePicture/index"
import CSelect from "../../components/Select"
import TextInput from '../../components/TextInput'
import { ClubPhoto } from "../../components/Photos/ClubPhoto"
import { SearchBar } from '../../components/TextInput'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import NoInvitationsCard from "../../components/Cards/NoInvitationsCard"
import { useStylesAddModal, useStylesInvitations } from './Invitations.styles.js';
import Invitation from './Invitation';

const Invitations = () => {
  const { t } = useTranslation();
  const classes = useStylesInvitations();
  const dispatch = useDispatch();
  const userStored = useSelector(state => state?.user);
  const invitations = useSelector(state => state?.invitations?.invitations || []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = userStored.info.id;
        const filter = { user_id: { eq: userId } };
        const listInvitationsResp = await API.graphql({
          query: queries.listInvitations,
          variables: { filter },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        const listInvitations = listInvitationsResp.data?.listInvitations?.items || [];
        listInvitations.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        dispatch(setInvitations({ invitations: listInvitations }));
      } catch (error) {
        console.error("Error fetching invitations:", error);
      }
    };

    fetchData();
  }, [userStored.info.id, dispatch]);

  return (
    <div className={classes.container}>
      <CText className={classes.title} level="h1" color="light" label={`${t('invitation.title')}`} />
      <Grid container spacing={1}>
        {invitations.length > 0 ? (
          invitations.map((invitation, index) => (
            <Invitation
              key={index}
              invitation={invitation}
              index={index}
              invitations={invitations}
            />
          ))
        ) : (
          <div className={classes.noInvitations}>
            <NoInvitationsCard />
          </div>
        )}
      </Grid>
    </div>
  );
};

export default Invitations;