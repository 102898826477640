import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { ReactComponent as CardIcon } from '../../../assets/icons/card.svg';
import CButton from "../../../components/Button";
import { CText } from "../../../components/Text";
import { SearchBar } from '../../../components/TextInput';
import ProfilePicture from "../../../components/ProfilePicture/index";
import CSelect from "../../../components/Select";
import { useStyles } from './Highlights.styles.js';
import { matchTimes, searchPlayers, getTeamPlayers } from './utils.js';

const CardModal = ({cardDescription}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const userStored = useSelector(state => state?.user);

  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [selectedGameTime, setSelectedGameTime] = useState(null);

  const [card, setCard] = useState('Y');
  const [allPlayers, setAllPlayers] = useState([]);
  const [listPlayers, setListPlayers] = useState([]);

  useEffect(() => {  
    const fetchData = async () => {
      const players = await getTeamPlayers(userStored);
      setAllPlayers(players)
      setListPlayers(players.slice(0, 6))
    }
  
    fetchData()
  }, [])

  const onChangeSearchPlayer = async(data) => {
    const players = await searchPlayers(listPlayers, data)
    setListPlayers([]) // Don't remove
    setListPlayers(players)
  }
  
  const selectedTimeCb = (data) => {
    setSelectedGameTime(data)
  }

  const submit = () => {
    const cardInfo = {
      player: {
        id: selectedPlayer.user.id,
        first_name: selectedPlayer.user.first_name,
        last_name: selectedPlayer.user.last_name
      },
      type: card,
      time: selectedGameTime.code
    }

    cardDescription(cardInfo)
  }

  let CONTENT;
  if(selectedPlayer === null) {
    CONTENT =
    <div className={classes.modalSearch}>
      <div className={classes.modalSearchBarWrapper}>
        <SearchBar placeholder={`${t('search_bar.player.label')}`} inputCallback={onChangeSearchPlayer} />
      </div>
      <ul className={classes.modalSearchResults}>
        {listPlayers?.map((player, index) => (
          <li key={index}>
            <div className={classes.modalSearchResult} onClick={() => setSelectedPlayer(player)}>
              <ProfilePicture className={classes.modalPlayerPhoto} src={player.user.photo_path} />
              <CText level="p2" fontWeight={500} color="light">{player.user.first_name} {player.user.last_name}</CText>
            </div>
          </li>
        ))}
      </ul>
    </div>
  } else {
    CONTENT =
    <div className={classes.modalSelectedPlayer}>
      <CText level="h2" fontWeight={700} color="primary">{t('after_game.2.modals.3.title')}</CText>
      <div className={classes.modalInfo}>
        <ProfilePicture className={classes.modalSelectedPlayerPhoto} src={selectedPlayer.user.photo_path} />
        <CText level="h3" fontWeight={700} color="light">{selectedPlayer.user.first_name} {selectedPlayer.user.last_name}</CText>
        <div style= {{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
          <div className={classes.modalCardsZone}>
            <div
              id="yellow-card"
              className={[classes.modalCardZone, card === "Y" ? classes.modalCardZoneSelected : null].join(" ")}
              onClick={() => setCard('Y')}
            >
              <CardIcon className={[classes.cardIcon, classes.yellowCard].join(" ")} />
            </div>
            <div
              id="red-card"
              className={[classes.modalCardZone, card === "R" ? classes.modalCardZoneSelected : null].join(" ")}
              onClick={() => setCard('R')}
            >
              <CardIcon className={[classes.cardIcon, classes.redCard].join(" ")} />
            </div>
          </div>
          <CSelect
            className={classes.selector}
            label={t('common.game_time')}
            selectCallback={selectedTimeCb}
            data={matchTimes}
            value={selectedGameTime}
          />
        </div>
      </div>

      <CButton
        className={classes.modalValidationBtn}
        label={t('button.confirm')}
        height={38}
        type="contained"
        onClick={() => submit()}
      />
    </div>
  }

  return (
    <div className={classes.modalContainer}>
      {CONTENT}
    </div>
  );
}

export default CardModal;