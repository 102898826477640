import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useStyles } from './Messages.styles.js';
import MessageInput from '../../components/Input/MessageInput.js';
import axios from 'axios';
import { Auth, API } from 'aws-amplify';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import * as subscriptions from '../../graphql/subscriptions';
import CButton from "../../components/Button.js";
import { updateTeamNameInPath } from "../../utils/urlRedirections";
import { resetConversationCount } from '../../store/reducers/conversations';
import Lottie from 'lottie-react';
import * as animationData from '../../assets/images/messages-animation.json';
import ProfilePicture from "../../components/ProfilePicture/index";
import Tooltip from '@mui/material/Tooltip';
import { formatMessageDate } from "../../utils/dates";
import NoTeamsCard from "../../components/Cards/NoTeamsCard";
import { getUserGroups } from "../../utils/auth";

const skeletons = [
  { width: '55%', height: 80, alignment: 'right' },
  { width: '60%', height: 60, alignment: 'right' },
  { width: '50%', height: 50, alignment: 'left' },
  { width: '50%', height: 50, alignment: 'left' },
  { width: '60%', height: 70, alignment: 'left' }
];

const getAuthHeaders = async () => {
  try {
    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();
    return {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    };
  } catch (error) {
    return {};
  }
};

const markMessageAsRead = async (teamId, conversationId, messageId) => {
  try {
    const headers = await getAuthHeaders();
    await axios.patch(
      `${process.env.REACT_APP_REST_API_BASE_URL}/conversations/v1/teams/${teamId}/conversations/${conversationId}/messages/${messageId}`,
      { read: true },
      { headers: headers }
    );
  } catch (error) {
    console.error('Error marking message as read:', error);
  }
};

const Messages = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [messageQueue, setMessageQueue] = useState([]);
  const userStored = useSelector((state) => state?.user);
  const userId = userStored?.info?.id;
  const isAuthenticated = useSelector((state) => state?.user.isAuthenticated);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [conversation, setConversation] = useState(null);
  const [conversationId, setConversationId] = useState(null);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [noConversation, setNoConversation] = useState(false);
  const [userGroups, setUserGroups] = useState([]);

  // Références
  const messagesEndRef = useRef(null);
  const containerRef = useRef(null);

  const teamId = userStored?.club?.team?.id;

  const fetchConversation = async () => {
    try {
      const headers = await getAuthHeaders();
      const response = await axios.get(
        `${process.env.REACT_APP_REST_API_BASE_URL}/conversations/v1/teams/${teamId}/conversations`,
        { headers }
      );

      if (response.data.length === 0) {
        setNoConversation(true);
        setLoading(false);
        return false;
      }

      setConversation(response.data[0]);
      setConversationId(response.data[0].id);
      return true;
    } catch (error) {
      console.error('Erreur lors de la récupération des détails de la conversation:', error.response ? error.response.data : error.message);
      setNoConversation(true);
      setLoading(false);
      return false;
    }
  };

  const fetchMessages = async (key = null) => {
    try {
      setLoadingMore(true);

      const headers = await getAuthHeaders();
      const params = key ? `&last_evaluated_key=${encodeURIComponent(JSON.stringify(key))}` : '';

      const container = containerRef.current;
      const prevScrollTop = container.scrollTop;
      const prevScrollHeight = container.scrollHeight;

      const response = await axios.get(
        `${process.env.REACT_APP_REST_API_BASE_URL}/conversations/v1/teams/${teamId}/conversations/${conversationId}/messages?sort=asc${params}`,
        { headers }
      );

      const newMessages = response.data.messages.filter(msg => msg && msg.content);

      if (key) {
        setMessages(prevMessages => [...newMessages, ...prevMessages]);

        setTimeout(() => {
          const newScrollHeight = container.scrollHeight;
          container.scrollTop = prevScrollTop + (newScrollHeight - prevScrollHeight);
        }, 0);
      } else {
        setMessages(prevMessages => [...prevMessages, ...newMessages]);
      }

      if (newMessages.length > 0) {
        const lastMessage = newMessages[newMessages.length - 1];
        if (lastMessage && lastMessage.sender_id !== userId && !lastMessage.read_by.includes(userId)) {
          await markMessageAsRead(teamId, conversationId, lastMessage.id);
        }
      }

      setLastEvaluatedKey(response.data.last_evaluated_key ? JSON.parse(decodeURIComponent(response.data.last_evaluated_key)) : null);
    } catch (error) {
      console.error('Erreur lors de la récupération des messages:', error.response ? error.response.data : error.message);
    } finally {
      setLoadingMore(false);
      setLoading(false);
      setInitialLoad(false);
    }
  };

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const urlTeamName = decodeURIComponent(pathSegments[2]);
    
    if(userStored?.club?.team?.name) {
      if (urlTeamName && urlTeamName !== userStored?.club?.team?.name) {
        const encodedTeamName = encodeURIComponent(userStored?.club?.team?.name);
        navigate(`/teams/${encodedTeamName}/messages`);
      }
    } else {
      updateTeamNameInPath(location.pathname, userStored?.club?.team?.id, navigate);
    }
  }, [location.pathname, userStored?.club?.team?.name, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {
        const userGroups = await getUserGroups();
        setUserGroups(userGroups);

        const conversationFetched = await fetchConversation();
        if (conversationFetched && conversationId) {
          await fetchMessages();
          dispatch(resetConversationCount({ id: conversationId }));
        }
      }
    };

    fetchData();
  }, [isAuthenticated, teamId, conversationId]);

  useEffect(() => {
    if (!initialLoad && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, initialLoad]);

  useEffect(() => {
    let subscription;
    if (conversationId && userId) {
      subscription = API.graphql({
        query: subscriptions.onCreateMessage,
        variables: {
          conversation_id: conversationId
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      }).subscribe({
        next: async ({ value: { data } }) => {
          const newMessage = data.onCreateMessage;

          if (newMessage.conversation_id === conversationId) {
            if (newMessage.sender.id !== userId) {
              setMessages(prevMessages => {
                const messageExists = prevMessages.some(msg => msg.id === newMessage.id);

                if (!messageExists) {
                  // Transform createdAt to created_at
                  const transformedMessage = {
                    ...newMessage,
                    created_at: newMessage.createdAt
                  };
  
                  // Mark the last message as read
                  markMessageAsRead(teamId, conversationId, transformedMessage.id);
                  
                  return [...prevMessages, transformedMessage];
                }

                return prevMessages;
              });
            }
          }
        },
        error: (error) => {
          console.error("Subscription error:", error);
        }
      });
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [conversationId, userId]);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        if (scrollTop === 0 && !loading && lastEvaluatedKey) {
          fetchMessages(lastEvaluatedKey);
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loading, lastEvaluatedKey]);

  useEffect(() => {
    const sendMessages = async () => {
      if (sending || messageQueue.length === 0) return;
      setSending(true);
      const nextMessage = messageQueue[0];

      try {
        const headers = await getAuthHeaders();
        const response = await axios.post(
          `${process.env.REACT_APP_REST_API_BASE_URL}/conversations/v1/teams/${teamId}/conversations/${conversationId}/messages`,
          {
            content: nextMessage.content,
            type: nextMessage.type
          },
          { headers }
        );

        setMessages(prevMessages =>
          prevMessages.map(msg =>
            msg.id === nextMessage.id ? { ...msg, id: response.data.id } : msg
          )
        );

        setMessageQueue(prevQueue => prevQueue.slice(1));
      } catch (error) {
        console.error('Erreur lors de l\'envoi du message:', error.response ? error.response.data : error.message);
        setMessageQueue(prevQueue => prevQueue.slice(1));
      } finally {
        setSending(false);
      }
    };

    sendMessages();
  }, [sending, messageQueue, conversationId]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSend = async () => {
    if (input.trim()) {
      let newMessage = {
        content: input,
        type: "TEXT",
        id: Date.now(),
        sender: { id: userId }
      };

      setMessageQueue(prevQueue => [...prevQueue, newMessage]);

      newMessage.created_at = new Date().toISOString();
      setMessages(prevMessages => [...prevMessages, newMessage]);
      setInput('');
    }
  };

  const createConversation = async () => {
    if (!conversationId) {
      const headers = await getAuthHeaders();


      const createResponse = await axios.post(
        `${process.env.REACT_APP_REST_API_BASE_URL}/conversations/v1/teams/${teamId}/conversations`,
        {},
        { headers }
      );

      setConversationId(createResponse.data.id);
      setNoConversation(false);
    }
  }

  const actionTriggeredRef = useRef(false);

  useEffect(() => {
    const handleUserAction = () => {
      if (conversationId) {
        if (!actionTriggeredRef.current) {
          dispatch(resetConversationCount({ id: conversationId }));
          actionTriggeredRef.current = true;

          setTimeout(() => {
            actionTriggeredRef.current = false;
          }, 2000);
        }
      }
    };

    document.addEventListener('mousemove', handleUserAction);
    document.addEventListener('click', handleUserAction);
    document.addEventListener('keydown', handleUserAction);
    document.addEventListener('touchstart', handleUserAction);

    return () => {
      document.removeEventListener('mousemove', handleUserAction);
      document.removeEventListener('click', handleUserAction);
      document.removeEventListener('keydown', handleUserAction);
      document.removeEventListener('touchstart', handleUserAction);
    };
  }, [conversationId, dispatch]);

  let CONTENT;

  if(loading) {
    CONTENT = <div className={classes.skeletonWrapper}>
      {skeletons.map((skeleton, index) => (
        <div
          key={index}
          style={{
            width: skeleton.width,
            height: skeleton.height,
            alignSelf: skeleton.alignment === 'right' ? 'flex-end' : 'flex-start',
          }}
        >
          <Skeleton variant="rounded" width={'100%'} height={'100%'} />
        </div>
      ))}
    </div>
  } else if(!teamId) {
    CONTENT = <div className={classes.noTeamsInfo}>
      <NoTeamsCard redirectBtn={userGroups.includes("ClubAdmins") ? true : false} />
    </div>
  } else if(noConversation) {
    CONTENT = <div className={classes.noConversation}>
      <div style={{maxWidth: 400, maxHeight: 400}}>
        <Lottie
          animationData={animationData}
          loop={true}
          autoplay={true}
        />
      </div>

      <CButton
        className={classes.noConversationBtn}
        label={t('no_conversation.buttons.0.label')}
        height={38}
        type="contained"
        loader={true}
        onClick={async () => await createConversation()}
      />
    </div>
  } else {
    CONTENT = <>
      {loadingMore && <CircularProgress size={25} className={classes.spinner} />}
      {messages.map((msg, msgIndex) => {
        if (!msg || !msg.content) {
          return null;
        }

        return (
          <div className={classes.message} key={msgIndex}>
            {msg.sender && msg.sender.id === userId ?
              null :
              <Tooltip title={`${msg?.sender?.first_name} ${msg?.sender?.last_name}`}>
                <div>
                  <ProfilePicture
                    className={classes.userPhoto}
                    src={msg?.sender?.photo_path}
                  />
                </div>
              </Tooltip>
            }
            <Tooltip title={`${formatMessageDate(msg?.created_at, i18n.language)}`}>
              <div
                key={msg.id}
                className={`${classes.messageBubble} ${msg.sender && msg.sender.id === userId ? classes.myMessage : classes.otherMessage}`}
              >
                <span className={classes.messageText}>{msg.content}</span>
              </div>
            </Tooltip>
          </div>
        );
      })}
      <div ref={messagesEndRef} />
    </>
  }

  return (
    <div className={classes.container}>
      <div className={classes.messagesContainer} ref={containerRef}>
        {CONTENT}
      </div>

      {conversation && (
        <MessageInput
          value={input}
          onChange={handleInputChange}
          onSend={handleSend}
          loading={loading}
        />
      )}
    </div>
  );
}

export default Messages;