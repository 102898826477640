import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { CText } from "../../components/Text";
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as DotsIcon } from '../../assets/icons/dots-vertical.svg';
import { getSession } from "../../utils/auth";
import { formatTime, getTimeFormat } from '../../utils/dates';
import EventMenu from '../Menu/EventMenu';
import { ReactComponent as TrainingIcon } from '../../assets/icons/training.svg';

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = '#242834';
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;

const TrainingCard = ({ data, className, index, withMenu, update, remove }) => {
  const useStyles = makeStyles((theme) => ({
    dataZone: {
      display: 'block',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      borderRadius: 25,
      listStyleType: 'none',
      width: '100%',
      boxSizing: 'border-box',
      [theme.breakpoints.up('xs')]: {
        padding: 20,
        maxWidth: 500
      },
      [theme.breakpoints.up('sm')]: {
        padding: 20,
        maxWidth: 500
      },
      [theme.breakpoints.up('md')]: {
        padding: 20,
        maxWidth: 550,
      },
      [theme.breakpoints.up('lg')]: {
        padding: 20,
        maxWidth: 600
      },
      "&&:hover": {
        filter: 'brightness(95%)',
        boxShadow: 'none'
      }
    },
    icon: {
      width: 35,
      height: 35,
      fill: COLOR_LIGHT
    },
    label: {
      marginLeft: 15
    },
    trainingInfo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    trainingType: {
      fontWeight: '700',
      marginLeft: 8
    },
    moreBtn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 20,
      height: 20,
      fill: COLOR_LIGHT,
      cursor: 'pointer',
      borderRadius: 25,
      marginRight: 10,
      "&:hover": {
        backgroundColor: '#232e2f',
        filter: 'brightness(90%)',
        boxShadow: 'none'
      },
      marginTop: -10,
      marginRight: -10,
      marginLeft: 15,
      padding: 3
    }
  }))

  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const timeFormat = getTimeFormat(i18n.language);
  const [userGroups, setUserGroups] = useState([]);

  const TRAINING_TYPES = [
    { "code": "PHYSICAL", "label": t('calendar.labels.8.label') },
    { "code": "TECHNICAL", "label": t('calendar.labels.9.label') },
    { "code": "TACTICAL", "label": t('calendar.labels.10.label') }
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (data) {
        const session = await getSession();
        const groups = session.idToken.payload['cognito:groups'];
        setUserGroups(groups);
      }
    }

    fetchData();
  }, [data]);

  let dateFormat = i18n.language === "fr" ? "DD-MM-YYYY" : "MM-DD-YYYY";
  let formattedDate = moment(data.date).format(dateFormat);

  /******* Menu - Start *******/
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickMore = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  /******* Menu - End *******/

  const handleClickUpdateEvent = () => {
    update();
    setAnchorEl(null);
  }

  const handleRemoveEvent = async () => {
    try {
      handleClose();
      await remove(data.id);
    } catch (error) {
      //console.error('Error removing training:', error);
    }
  };

  return (
    <div className={[classes.dataZone, className].join(" ")}>
      <Grid container>
        <Grid container item xs={4}>
        </Grid>
        <Grid container item xs={8} justifyContent="flex-end">
          <CText level="p3" fontWeight={700} color="light" label={`${formattedDate} • ${formatTime(moment(data.start_time, "HH:mm", true), timeFormat)} - ${formatTime(moment(data.end_time, "HH:mm", true), timeFormat)}`} />
          {withMenu && (userGroups?.includes("Coaches") || userGroups?.includes("ClubAdmins")) ?
            <Tooltip title={t('training_card.contents.0.labels.0')}>
              <DotsIcon
                className={`${classes.moreBtn}`}
                onClick={handleClickMore}
              />
            </Tooltip>
            : null
          }

          <EventMenu
            anchorEl={anchorEl}
            handleClose={handleClose}
            handleClickUpdateEvent={handleClickUpdateEvent}
            handleRemoveEvent={handleRemoveEvent}
            data={data}
            index={index}
          />
        </Grid>
      </Grid>
      <div className={classes.trainingInfo}>
        <TrainingIcon className={classes.icon} />
        <CText className={classes.label} level="p2" color="light">
        {t('common.training')}: 
        <span className={classes.trainingType}>
          {TRAINING_TYPES.find(type => type.code === data.type)?.label || data.type}
        </span>
        </CText>
      </div>
    </div>
  );
}

export default TrainingCard;