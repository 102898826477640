import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { API, Storage, graphqlOperation } from 'aws-amplify';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import * as mutations from '../../graphql/mutations';
import CButton, { PickMediaBtn } from "../../components/Button";
import CModal from "../../components/Modal";
import { CText } from "../../components/Text";
import { getSession } from "../../utils/auth";
import { useDispatch } from 'react-redux';
import { updateTeam } from '../../store/reducers/user';
import { setPhotoPath } from '../../store/reducers/user';
import { ClubPhoto } from "../../components/Photos/ClubPhoto";
import TextInput from '../../components/TextInput';
import CAutocomplete from "../../components/Autocomplete";
import countriesReferential from '../../assets/referentials/countries.json';

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_RED = "#fc3c2e";
const COLOR_BLUE = "#25a5e6";
const COLOR_PURPLE = "#9d44fd";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const ZONE_BACKGROUND_COLOR_2 = "#242834";
const BUTTON_WIDTH = 100;

const ProfilePhotoModal = ({ modalOpenCallback, photoCallback, photoDataCallback }) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    title: {
      marginBottom: 30
    },
    selectPhoto: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      width: '100%'
    },
    btnZone: {
      marginTop: 'auto',
      alignItems: 'baseline',
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    inlineBtn: {
      width: '100%'
    },
    btn: {
      width: '50%'
    },
    profilePicture: {
      marginBottom: 30,
      width: 'auto',
      [theme.breakpoints.up('xs')]: {
        maxWidth: 150,
        height: 150
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 180,
        height: 180
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 200,
        height: 200
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 220,
        height: 220
      }
    },
    menuPaper: {
      maxHeight: '150 !important' 
    }
  }))


  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const userStored = useSelector((state) => state?.user)

  const [previewFile, setPreviewFile] = useState();
  const [fileData, setFileData] = useState();
  const [fileStatus, setFileStatus] = useState(false);


  const pickFile = (data) => {
    setPreviewFile(URL.createObjectURL(data))
    setFileData(data)
  }

  const removeFile = (data) => {
    setPreviewFile()
    setFileData()
  }

  const uploadFile = async () => {
    // [EVOL] Le nom du fichier ainsi que la création/update de l'url en bdd doit etre fait dans une lambda
    try {
      photoCallback(previewFile)
      photoDataCallback(fileData)
      modalOpenCallback({type:"", value: false})
    } catch(err) {
      console.log(err)
    }
  }

  let CONTENT;
  if(previewFile) {
    CONTENT =
    <div className={classes.selectPhoto}>
      <img className={classes.profilePicture} src={previewFile} />
      <Grid container spacing={1} className={classes.btnZone}>
        <Grid item xs={6}>
          <CButton
            onClick={() => removeFile()}
            label={t('new_club.modals.0.buttons.1.label')}
            height={38} color={COLOR_PINK}
            type="contained"
            className={classes.inlineBtn}
          />
        </Grid>
        <Grid item xs={6}>
          <CButton
            label={t('new_club.modals.0.buttons.2.label')}
            height={38}
            color={COLOR_PRIMARY}
            type="contained"
            className={classes.inlineBtn}
            loader={true}
            onClick={async () => await uploadFile()}
          />
        </Grid>
      </Grid>
    </div>
  } else {
    CONTENT =
    <div className={classes.selectPhoto}>
      <div className={classes.btnZone}>
        <PickMediaBtn
          label={t('club_profile.modals.0.buttons.0.label')}
          fileCb={(data) => pickFile(data)}
          height={38}
          color={COLOR_PRIMARY}
          type="contained"
          className={classes.btn}
        />
      </div>
    </div>
  }

  return (
    <div className={classes.container}>
      <CText level="h2" fontWeight={700} className={classes.title} color="primary" label={`${t('new_club.modals.0.title')}`} />
      {CONTENT}
    </div>
  )
}

const NewClub = (props) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up('xs')]: {
        paddingLeft: '3%',
        paddingRight: '3%'
      },
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 30,
        paddingRight: 30
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: 45,
        paddingRight: 45
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 60,
        paddingRight: 60
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 80,
        paddingRight: 80
      }
    },
    clubLogoZone: {
      textAlign: 'center',
      marginBottom: 15,
      [theme.breakpoints.up('xs')]: {
        width: 120,
        height: 120
      },
      [theme.breakpoints.up('sm')]: {
        width: 150,
        height: 150
      }
    },
    teamLogo: {
      height: '100%',
      width: 'auto',
      cursor: 'pointer',
      "&:hover": {
        opacity: '0.8'
      }
    },
    teamIcon: {
      fill: COLOR_LIGHT
    },
    formBtn: {
      width: '100%',
      maxWidth: 200,
      marginTop: 35
    },
    title: {
      marginBottom: 30,
      [theme.breakpoints.up('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        width: 'calc(400px + 2*30px)'
      },
      [theme.breakpoints.up('sm')]: {
        width: 'calc(400px + 2*30px)'
      },
      [theme.breakpoints.up('md')]: {
        width: 'calc(400px + 2*30px)'
      },
      [theme.breakpoints.up('lg')]: {
        width: 'calc(450px + 2*30px)'
      }
    },
    formInput: {
      marginBottom: '15px !important'
    },
    formLastInput: {
      marginBottom: 0
    },
    formZone: {
      backgroundColor: '#1A1919',
      borderRadius: 10,
      padding: 30,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.up('xs')]: {
        width: 'calc(100% - 2*30px)'
      },
      [theme.breakpoints.up('sm')]: {
        width: 400
      },
      [theme.breakpoints.up('sm')]: {
        width: 400
      },
      [theme.breakpoints.up('md')]: {
        width: 400
      },
      [theme.breakpoints.up('lg')]: {
        width: 450
      }
    }
  }));

  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      const session = await getSession()
      const userId = session.idToken.payload.sub;
    }
  
    fetchData()
  }, [])

  const [modalOpen, setModalOpen] = useState({type: "", value: false})
  const [clubName, setClubName] = useState("")
  const [country, setCountry] = useState(null)
  const [city, setCity] = useState("")
  const [photo, setPhoto] = useState()
  const [photoData, setPhotoData] = useState(null)

  //Handle Modal Content
  let MODAL_CONTENT;

  if(modalOpen.type === "photo") {
    MODAL_CONTENT =
    <ProfilePhotoModal
      modalOpenCallback={(data) => setModalOpen(data)}
      photoCallback={(data) => setPhoto(data)}
      photoDataCallback={(data) => setPhotoData(data)}
    />
  }

  // Form Callbacks
  const clubNameCb = (data) =>{ setClubName(data) }
  const selectCountry = (data) => { setCountry(data) };
  const cityCb = (data) => { setCity(data) };

  const submit = async() => {
    const session = await getSession();
    const userId = session.idToken.payload.sub;
    try {
      if(clubName && country.code && city) {
        const data = {
          names: [`{\"name\":\"${clubName}\"}`],
          cities: [`{\"name\":\"${city}\"}`],
          country_code: country?.code,
          admins: [ userId ]
        }

        let club = await API.graphql(graphqlOperation(
          mutations.createClub,
          { input: data }
        ))
        club = club.data.createClub

        const clubAdminData = {
          id: userId,
          club_id: club.id
        }

        await API.graphql(
          graphqlOperation(mutations.updateClubAdmin, { input: clubAdminData })
        );

        //dispatch(signIn());
        //maj le clubId autrement
        await new Promise((resolve, reject) => {
          dispatch(updateTeam({
            club: { id: club.id }
          }))
          .then(resolve)
          .catch(reject);
        });

        if(photoData) {
          const photoType = photoData.type
          let fileExtension
    
          switch (photoType) {
            case 'image/png':
              fileExtension = "png"
              break;
            case 'image/jpeg':
              fileExtension = "jpeg"
              break;
            case 'image/svg+xml':
              fileExtension = "svg"
              break;
            case 'image/webp':
              fileExtension = "webp"
              break;
            default:
              throw new Error("Invalid file format.");
          }
    
          const filePath = `clubs/${club.id}/${Date.now()}.${fileExtension}`
    
          await Storage.put(filePath, photoData, {
            contentType: photoData.type
          })
          const clubData = {
            id: club.id,
            logo: `${filePath}`
          }

          await API.graphql({
            query: mutations.updateClub,
            variables: {input: clubData},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          })
          dispatch(setPhotoPath({ "photoPath": filePath }))
        }
      }
    } catch (err) {
      console.log('error fetching todos', err)
    } finally {
      setTimeout(() => {
        navigate('/subscriptions');
      }, 100);
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <CText level="h1" color="primary">{t('new_club.title')}</CText>
      </div>
      <div className={classes.formZone}>
        <div className={classes.clubLogoZone}>
          {photo ?
            <img
              className={classes.teamLogo}
              onClick={() => setModalOpen({type: "photo", value: true})}
              src={photo}
            />
            :
            <ClubPhoto
              className={classes.teamLogo} 
              onClick={() => setModalOpen({type: "photo", value: true})}
              owner={true}
            />
          }
        </div>
        <TextInput
          className={classes.formInput}
          label={`${t('new_club.inputs.0.label')} *`}
          inputCallback={clubNameCb}
        />
        <CAutocomplete
          className={classes.formInput}
          label={`${t('new_club.inputs.1.label')} *`}
          data={countriesReferential}
          selectCallback={selectCountry}
          value={country}
        />
        <TextInput
          className={classes.formLastInput}
          label={`${t('new_club.inputs.2.label')} *`}
          inputCallback={cityCb}
        />
        <CButton
          className={classes.formBtn}
          label={t('new_club.buttons.0.label')}
          height={38}
          type="contained"
          loader={true}
          onClick={async () => await submit()}
        />
      </div>
      <CModal
      open={modalOpen.value}
      onClose={() => setModalOpen({type: "", value: false })}
      content={MODAL_CONTENT}
      >
      </CModal>
    </div>
  )
}

export { NewClub }