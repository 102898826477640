import { Checkbox as MuiCheckbox } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

const COLOR_PRIMARY = '#45d69f';
const COLOR_DARK = '#242834';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    color: `${COLOR_PRIMARY} !important`,
    width: `32px !important`,
    height: `32px !important`,
    '&.Mui-checked': {
      color: `${COLOR_PRIMARY} !important`
    },
    "&&:hover": {
      backgroundColor: '#232e2f',
      filter: 'brightness(90%)',
      boxShadow: 'none'
    }
  }
}));

const Checkbox = ({ className, defaultValue, label, onChange  }) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue ? defaultValue : false);

  const handleChange = (data) => {
    const newValue = !value
    setValue(newValue)
    onChange(newValue);
  }

  return (
    <MuiCheckbox
      {...label}
      checked={value}
      onChange={() => handleChange()}
      className={`${classes.checkbox}`}
    />
  );
};

export default Checkbox;