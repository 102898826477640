import axios from 'axios';
import * as subscriptions from '../graphql/subscriptions';
import { API, Auth } from 'aws-amplify';
import { incrementConversationCount, updateConversationCount } from '../store/reducers/conversations';

const countUnreadMessages = async (messages, userId) => {
  let unreadCount = 0;

  for (let i = messages.length - 1; i >= 0; i--) {
    const message = messages[i];
    if (message.sender.id !== userId && !message.read_by.includes(userId)) {
      unreadCount += 1;
    } else {
      break;
    }
  }

  return unreadCount;
};


export const fetchConversation = async (teamId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_REST_API_BASE_URL}/conversations/v1/teams/${teamId}/conversations`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
      }
    );

    if (response.data.length === 0) {
      return null;
    }

    return response?.data[0];
  } catch (error) {}
};

const fetchMessages = async (teamId, conversationId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_REST_API_BASE_URL}/conversations/v1/teams/${teamId}/conversations/${conversationId}/messages?sort=asc`, { 
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    }
  );

  return response?.data;
}

export const messagesCounter = async (userId, teamId, conversationId, isMounted, dispatch) => {
  if (isMounted) {
    let messages = await fetchMessages(teamId, conversationId);
    messages = messages.messages;
    const unreadCount = await countUnreadMessages(messages, userId);
    dispatch(updateConversationCount({ id: conversationId, count: unreadCount }));
  }

  const subscription = API.graphql({
    query: subscriptions.onCreateMessage,
    variables: {
      conversation_id: conversationId
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS'
  }).subscribe({
    next: async ({ value: { data } }) => {
      const newMessage = data.onCreateMessage;
      if (newMessage.sender.id !== userId) {
        console.log("subscription");
        if (newMessage.conversation_id === conversationId) {
          dispatch(incrementConversationCount({ id: conversationId }));
        }
      }
    },
    error: (error) => {
      console.error("Subscription error:", error);
    }
  });

  return subscription;
};