export const formatMessageDate = (date, locale = 'fr') => {
  // Vérifie si la date est valide
  if (!date || isNaN(new Date(date).getTime())) {
    return ''; // Retourne une chaîne vide ou un message par défaut si la date est invalide
  }

  const now = new Date();
  const messageDate = new Date(date);

  // Vérifie si la date du message est aujourd'hui
  const isToday =
    now.getDate() === messageDate.getDate() &&
    now.getMonth() === messageDate.getMonth() &&
    now.getFullYear() === messageDate.getFullYear();

  if (isToday) {
    // Si la date est aujourd'hui, afficher seulement l'heure dans le format approprié
    return new Intl.DateTimeFormat(locale, {
      hour: '2-digit',
      minute: '2-digit'
    }).format(messageDate);
  }

  // Calculer la différence en jours
  const differenceInDays = Math.floor(
    (now - messageDate) / (1000 * 60 * 60 * 24)
  );

  if (differenceInDays < 7) {
    // Si la date est dans les 7 jours précédents, afficher le jour et l'heure
    return new Intl.DateTimeFormat(locale, {
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit'
    }).format(messageDate);
  }

  // Sinon, afficher la date complète et l'heure
  return new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }).format(messageDate);
};

export const formatTime = (date, format) => {
  if (!date) return '';

  const time = new Date(date);
  const hours = time.getHours();
  const minutes = time.getMinutes();
  
  if (format === 'HH:mm') {
    // Format 24 heures
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  } else if (format === 'hh:mm a') {
    // Format 12 heures avec AM/PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const hour12 = hours % 12 || 12;
    return `${hour12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  }
  
  return '';
};

export const getTimeFormat = (language) => {
  switch (language) {
    case 'fr':
    case 'es':
      return 'HH:mm';
    case 'en':
      return 'hh:mm a';
    default:
      return 'HH:mm';
  }
};