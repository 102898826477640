import React, { useState, useEffect } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from '@mui/material/Grid2';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { Auth, API } from 'aws-amplify';
import CountUp from 'react-countup';
import * as queries from '../../../graphql/queries';
import { getUserGroups } from "../../../utils/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { CText } from "../../../components/Text";
import NoTeamsCard from "../../../components/Cards/NoTeamsCard";
import Skeleton from '@mui/material/Skeleton';
import TopScorers from './TopScorers';
import TopAssisters from './TopAssisters';
import MinutesRanking from './MinutesRanking';
import MVPRanking from './MVPRanking';
import BestSeries from './BestSeries';
import LastGames from './LastGames';
import NbVictoriesDrawsLosses from './NbVictoriesDrawsLosses';
import GoalsPerGame from './GoalsPerGame';
import GoalsEvol from './GoalsEvol';
import PerformanceEvol from './PerformanceEvol';
import { updateTeamNameInPath } from "../../../utils/urlRedirections";
import { getCurrentSeason } from "../../../utils/season";
import './stats.styles.css';

import {
  ArcElement,
  BarElement,
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const COLOR_PRIMARY = '#45d69f';
const COLOR_PRIMARY_TRANSPARENT = 'rgba(69, 214, 159, 0.4)';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_PINK_TRANSPARENT = 'rgba(233, 33, 99, 0.4)'
const COLOR_BLUE = "#25a5e6";
const COLOR_BLUE_TRANSPARENT = 'rgba(37, 165, 230, 0.4)';
const COLOR_PURPLE = "#9d44fd";
const COLOR_PURPLE_TRANSPARENT = 'rgba(157, 68, 253, 0.4)';
const COLOR_ORANGE = "#f2a624";
const COLOR_DARK = "#242834";
const COLOR_ORANGE_TRANSPARENT = 'rgba(242, 166, 36, 0.4)'
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;
const MENU_WIDTH = 250;
const BORDER_RADIUS = 18;

const Stats = (props) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      height: '100%',
      [theme.breakpoints.up('md')]: {
        marginLeft: MENU_WIDTH
      }
    },
    noTeamsInfo: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    topContainer: {
      display: 'inline-block',
      width: '100%',
      marginTop: 15,
      marginBottom: 30
    },
    topContainerLeft: {
      display: 'flex',
      flexDirection: 'row',
      float: 'left'
    }
  }));

  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const userStored = useSelector(state => state?.user);
  const teamId = userStored?.club?.team?.id;

  const [isLoaded, setIsLoaded] = useState(false);
  const [season, setSeason] = useState();
  const [matches, setMatches] = useState();
  const [userGroups, setUserGroups] = useState([]);
  const [teams, setTeams] = useState([]);

  const getTeams = async() => {
    try {
      const { groups, club } = userStored;
      let teams = [];
    
      if (groups.includes("ClubAdmins")) {
        const clubId = club?.id

        const teamsFilter = {
          club_id: { eq: clubId },
          deleted: { eq: false }
        }
  
        let teamsList = await API.graphql({ query: queries.listClubTeams, variables: { filter: teamsFilter }, authMode: 'AMAZON_COGNITO_USER_POOLS'})
        teamsList = teamsList.data.listTeams.items

        const teamsListOrdered = teamsList.sort((a, b) => {
          const numberA = parseInt(a.name.replace(/\D/g, ''), 10) || 0;
          const numberB = parseInt(b.name.replace(/\D/g, ''), 10) || 0;
          return numberA - numberB;
        });
  
        teams = teamsListOrdered;

      } else {
        const team = club?.team;
        if(team?.id) { teams.push(team) };

      }

      setTeams(teams)

      return teams;
    }
    catch(err) { console.log(err) }
  }

  const getMatches = async (teamId, season) => {
    try {
      let resp = await fetch(`${process.env.REACT_APP_REST_API_BASE_URL}/teams/v1/teams/${teamId}/results?season=${season}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
      });
      const respBody = await resp.json()

      return respBody
    } catch (error) {
      console.error("Erreur :", error);
    }
  };

  useEffect(() => {
    updateTeamNameInPath(location.pathname, userStored?.club?.team?.id, navigate);
  }, [location.pathname, userStored?.club?.team?.name, navigate]);

  useEffect(() => {  
    const fetchData = async () => {
      if (!isLoaded) {
        const userGroups = await getUserGroups();
        setUserGroups(userGroups);
        const currentSeason = await getCurrentSeason();
        setSeason(currentSeason);
        const teams = await getTeams();

        if (teams.length > 0) {
          const teamMatches = await getMatches(teamId, currentSeason);
          setMatches(teamMatches);
          setTeams(teams);
        }

        setIsLoaded(true);
      }
    }

    fetchData();
  }, [isLoaded, teamId]);

  // Handle CONTENT
  let CONTENT;
  if(isLoaded) {
    if ((userGroups.includes("ClubAdmins") || userGroups.includes("Coaches")) && teams.length < 1) {
      CONTENT =
      <div className={classes.noTeamsInfo}>
        <NoTeamsCard redirectBtn={userGroups.includes("ClubAdmins") ? true : false} />
      </div>
    } else {
      CONTENT =
        <div>
          <Grid container spacing={1} alignItems="flex-start">
            <Grid container size={{ xs: 12, sm: 12, md: 6 }} alignItems="center">
              <TopScorers season={season} teamId={teamId} />
            </Grid>
            <Grid container size={{ xs: 12, sm: 12, md: 6 }} alignItems="center">
              <TopAssisters season={season} teamId={teamId} />
            </Grid>
            <Grid container size={{ xs: 12, sm: 12, md: 6 }} alignItems="center">
              <MinutesRanking season={season} teamId={teamId} />
            </Grid>
            <Grid container size={{ xs: 12, sm: 12, md: 6 }} alignItems="center">
              <MVPRanking season={season} teamId={teamId} />
            </Grid>
            <Grid container size={{ xs: 12, sm: 12, md: 6 }} alignItems="center">
              <LastGames teamId={teamId} matches={matches} numberMatches={7} />
            </Grid>
            <Grid container size={{ xs: 12, sm: 12, md: 6 }} alignItems="center">
              <BestSeries season={season} teamId={teamId} />
            </Grid>
            <Grid container size={{ xs: 12, sm: 12, md: 6 }} alignItems="center">
              <NbVictoriesDrawsLosses matches={matches} teamId={teamId} />
            </Grid>
            <Grid container size={{ xs: 12, sm: 12, md: 6 }} alignItems="center">
              <GoalsPerGame matches={matches} teamId={teamId} />
            </Grid>
            <Grid container size={{ xs: 12 }} alignItems="center">
              <GoalsEvol matches={matches} teamId={teamId} />
            </Grid>
            <Grid container size={{ xs: 12 }} alignItems="center">
              <PerformanceEvol matches={matches} teamId={teamId}  />
            </Grid>
          </Grid>
        </div>
    }
  } else {
    CONTENT = <Grid container spacing={1} alignItems="flex-start">
      <Grid container size={{ xs: 12, sm: 12, md: 6 }} alignItems="center">
        <Skeleton variant="rounded" width="100%" height={118} sx={{ borderRadius: BORDER_RADIUS - 13 }} />
      </Grid>
      <Grid container size={{ xs: 12, sm: 12, md: 6 }} alignItems="center">
        <Skeleton variant="rounded" width="100%" height={118} sx={{ borderRadius: BORDER_RADIUS - 13 }} />
      </Grid>
      <Grid container size={{ xs: 12, sm: 12, md: 6 }} alignItems="center">
        <Skeleton variant="rounded" width="100%" height={118} sx={{ borderRadius: BORDER_RADIUS - 13 }} />
      </Grid>
      <Grid container size={{ xs: 12, sm: 12, md: 6 }} alignItems="center">
        <Skeleton variant="rounded" width="100%" height={118} sx={{ borderRadius: BORDER_RADIUS - 13 }} />
      </Grid>
      <Grid container size={{ xs: 12, sm: 12, md: 6 }} alignItems="center">
        <Skeleton variant="rounded" width="100%" height={118} sx={{ borderRadius: BORDER_RADIUS - 13 }} />
      </Grid>
      <Grid container size={{ xs: 12, sm: 12, md: 6 }} alignItems="center">
        <Skeleton variant="rounded" width="100%" height={118} sx={{ borderRadius: BORDER_RADIUS - 13 }} />
      </Grid>
      <Grid container size={{ xs: 12 }} alignItems="center">
        <Skeleton variant="rounded" width="100%" height={118} sx={{ borderRadius: BORDER_RADIUS - 13 }} />
      </Grid>
      <Grid container size={{ xs: 12 }} alignItems="center">
        <Skeleton variant="rounded" width="100%" height={118} sx={{ borderRadius: BORDER_RADIUS - 13 }} />
      </Grid>
    </Grid>
  }

  return(
    <div className={classes.container}>
      <CText className={classes.title} level="h1" color="light">{t('common.team_stats')}</CText>
      <div className={classes.topContainer}>
      </div>
      {CONTENT}
    </div>
  )
}
  
export { Stats };