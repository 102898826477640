import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { CText } from "../../../components/Text";
import { Doughnut } from 'react-chartjs-2';
import './stats.styles.css';

const COLOR_PRIMARY = '#45d69f';
const COLOR_PRIMARY_TRANSPARENT = 'rgba(69, 214, 159, 0.4)';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_PINK_TRANSPARENT = 'rgba(233, 33, 99, 0.4)'
const COLOR_BLUE = "#25a5e6";
const COLOR_BLUE_TRANSPARENT = 'rgba(37, 165, 230, 0.4)';
const COLOR_PURPLE = "#9d44fd";
const COLOR_PURPLE_TRANSPARENT = 'rgba(157, 68, 253, 0.4)';
const COLOR_ORANGE = "#f2a624";
const COLOR_DARK = "#242834";
const COLOR_ORANGE_TRANSPARENT = 'rgba(242, 166, 36, 0.4)'
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;
const MENU_WIDTH = 250;
const BORDER_RADIUS = 18;

const NbVictoriesDrawsLosses = ({ matches, teamId }) => {
  const { t } = useTranslation();

  const [winCount, setWinCount] = useState(0);
  const [drawCount, setDrawCount] = useState(0);
  const [lossCount, setLossCount] = useState(0);

  useEffect(() => {
    const { winCount, drawCount, lossCount } = countMatchResults(matches, teamId);
    setWinCount(winCount);
    setDrawCount(drawCount);
    setLossCount(lossCount);
  }, [matches, teamId]);

  const countMatchResults = (matches, teamId) => {
    const sortedMatches = matches.sort((a, b) => new Date(b.date) - new Date(a.date));
  
    let winCount = 0;
    let drawCount = 0;
    let lossCount = 0;
  
    sortedMatches.forEach((match) => {
      if (match.home_team_id === teamId && match.home_team_goals > match.away_team_goals) {
        winCount++;
      } else if (match.away_team_id === teamId && match.away_team_goals > match.home_team_goals) {
        winCount++;
      } else if (match.home_team_goals === match.away_team_goals) {
        drawCount++;
      } else {
        lossCount++;
      }
    });
  
    return { winCount, drawCount, lossCount };
  };

  const data = {
    labels: [ t('team_stats.3.labels.0'), t('team_stats.3.labels.1'), t('team_stats.3.labels.2') ],
    datasets: [
      {
        label: '',
        data: [winCount, drawCount, lossCount],
        backgroundColor: [
          COLOR_PRIMARY,
          COLOR_ORANGE,
          COLOR_PINK,
        ],
        borderColor: [
          COLOR_PRIMARY,
          COLOR_ORANGE,
          COLOR_PINK
        ],
        borderWidth: 1,
        offset: 20
      },
    ]
  };

  let options = {
    responsive: true
  };

  return(
    <div className="stats-zone">
      <CText className="stats-title" level="h3" fontWeight="700" color="light">
        {t('team_stats.3.title')}
      </CText>
      <Doughnut data={data} options={options} className="stats-elmt-1" />
    </div>
  )
}

export default NbVictoriesDrawsLosses;