import React, { useState } from 'react'
import { CText } from '../Text'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { ReactComponent as CaretIcon } from '../../assets/icons/caret.svg'
import { useStylesSelector } from './selector.styles.js'
import { useDispatch } from 'react-redux'
import { updateTeam } from '../../store/reducers/user'

const TeamsSelector = ({ teams, selectedTeamIndex, onSelect, className }) => {
  const classes = useStylesSelector();
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  
  const isDisabled = teams.length <= 1;

  const openSelection = (e) => {
    if (!isDisabled) {
      setAnchorEl(e.currentTarget);
      setOpen(true);
    }
  };
  
  const closeSelection = () => {
    setAnchorEl(null);
    setOpen(false)
  };

  const handleSelect = (index) => {
    onSelect(index);
    dispatch(updateTeam({ id: teams[index].id, name: teams[index].name }));
    closeSelection();
  };

  const combinedClass = `${classes.selectorBtn} ${isDisabled ? classes.disabledSelectorBtn : ''}`;

  return (
    <>
      <div className={combinedClass} onClick={openSelection}>
        <CText level="p2" color="light" className={classes.label}>{teams[selectedTeamIndex]?.name}</CText>
        {!isDisabled && <CaretIcon className={classes.selectorIcon} />}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeSelection}
        PaperProps={{
          style: {
            minWidth: '20ch',
            maxHeight: 160
          }
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        autoFocus={false}
      >
        <div>
          {teams.map((team, index) => (
            index !== selectedTeamIndex ?
              <MenuItem onClick={() => handleSelect(index)} key={index}>
                {team?.name}
              </MenuItem> : null
          ))}
        </div>
      </Menu>
    </>
  );
};

export default TeamsSelector;