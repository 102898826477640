import React from 'react';
import { TextField, IconButton, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useStyles } from './MessageInput.styles.js';

const MessageInput = ({ value, onChange, onSend, loading }) => {
  const classes = useStyles();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onSend();
    }
  };

  return (
    <div className={classes.container}>
      <TextField
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        placeholder="Type a message..."
        variant="outlined"
        className={classes.textField}
        multiline
        maxRows={6}
        fullWidth
        disabled={loading}
      />
      <IconButton 
        onClick={onSend} 
        color="primary" 
        className={classes.sendButton} 
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : <SendIcon />}
      </IconButton>
    </div>
  );
};

export default MessageInput;