import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import i18next from 'i18next'
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { API, Storage } from 'aws-amplify';
import { useSelector } from 'react-redux'
import { useStyles } from './styles';

import * as mutations from '../../graphql/mutations';

import CButton, { PickMediaBtn } from "../../components/Button";
import { CText } from "../../components/Text";
import { getSession } from "../../utils/auth"
import { useDispatch } from 'react-redux'
import { setPhotoPath } from '../../store/reducers/user'

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_RED = "#fc3c2e";
const COLOR_BLUE = "#25a5e6";
const COLOR_PURPLE = "#9d44fd";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const ZONE_BACKGROUND_COLOR_2 = "#242834";
const BUTTON_WIDTH = 100;

const ClubPhotoModal = ({ modalOpenCallback }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const userStored = useSelector((state) => state?.user)

  const [previewFile, setPreviewFile] = useState();
  const [fileData, setFileData] = useState();
  const [fileStatus, setFileStatus] = useState(false);


  const pickFile = (data) => {
    setPreviewFile(URL.createObjectURL(data))
    setFileData(data)
  }

  const removeFile = (data) => {
    setPreviewFile()
    setFileData()
  }

  const uploadFile = async () => {
    // [EVOL] Le nom du fichier ainsi que la création/update de l'url en bdd doit etre fait dans une lambda
    try {
      const clubId = userStored?.club?.id;

      const fileType = fileData.type
      let fileExtension

      switch (fileType) {
        case 'image/png':
          fileExtension = "png"
          break;
        case 'image/jpeg':
          fileExtension = "jpeg"
          break;
        case 'image/svg+xml':
          fileExtension = "svg"
          break;
        case 'image/webp':
          fileExtension = "webp"
          break;
        default:
          throw new Error("Invalid file format.");
      }

      const filePath = `clubs/${clubId}/${Date.now()}.${fileExtension}`

      await getSession()
      await Storage.put(filePath, fileData, {
        contentType: fileData.type
      })
      const data = {
        id: clubId,
        logo: `${filePath}`
      }
      await API.graphql({ query: mutations.updateClub, variables: {input: data}, authMode: 'AMAZON_COGNITO_USER_POOLS'})

      dispatch(setPhotoPath({ "photoPath": filePath }))
      modalOpenCallback({type:"", value: false})
    } catch(err) {
      console.log(err)
    }
  }

  let CONTENT;
  if(previewFile) {
    CONTENT =
    <div className={classes.selectPhoto}>
      <img className={classes.clubPicture} src={previewFile} />
      <div className={classes.btnZone}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <CButton
              className={classes.inlineBtn}
              onClick={() => removeFile()}
              label={t('club_profile.modals.0.buttons.1.label')}
              height={38} color={COLOR_PINK}
              type="contained"
            />
          </Grid>
          <Grid item xs={6}>
            <CButton
              className={classes.inlineBtn}
              label={t('club_profile.modals.0.buttons.2.label')}
              height={38}
              color={COLOR_PRIMARY}
              type="contained"
              loader={true}
              onClick={async () => await uploadFile()}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  } else {
    CONTENT =
    <div className={classes.selectPhoto}>
      <div className={classes.btnZone}>
        <PickMediaBtn
          className={classes.btn}
          label={t('club_profile.modals.0.buttons.0.label')}
          fileCb={(data) => pickFile(data)}
          height={38}
          color={COLOR_PRIMARY}
          type="contained"
        />
      </div>
    </div>
  }

  return (
    <div className={classes.container}>
      <CText level="h2" fontWeight={700} className={classes.title} color="primary" label={`${t('club_profile.modals.0.title')}`} />
      {CONTENT}
    </div>
  )
}

export default ClubPhotoModal;