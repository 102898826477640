export const sortASC = (property) => {
  let sortOrder = 1
  if(property[0] === "-") {
    sortOrder = -1
    property = property.substr(1)
  }
  return (a,b) => {
    let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
    return result * sortOrder
  }
}