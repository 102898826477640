import {makeStyles} from "@material-ui/core/styles"

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_RED = "#fc3c2e"
const COLOR_YELLOW = "#EDA247"
const COLOR_BLUE = "#25a5e6";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const ZONE_BACKGROUND_COLOR_2 = "#242834";
const BUTTON_WIDTH = 100;
const COLOR_DARK = "#242834";

export const useStylesPlayers = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%'
  },
  topButtonsContainer: {
    display: 'inline-block',
    width: '100%'
  },
  addButton: {
    float: 'right'
  },
  leftButton: {
    [theme.breakpoints.up('xs')]: {
      marginRight: 5
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 10
    },
    [theme.breakpoints.up('xl')]: {
      marginRight: 12
    }
  },
  inviteListTitle: {
    textAlign: 'center',
    marginBottom: 20
  },
  inviteList: {
    marginTop: 30
  },
  noTeamsInfo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  topContainer: {
    display: 'inline-block',
    width: '100%',
    marginTop: 15,
    marginBottom: 30
  },
  topContainerLeft: {
    display: 'flex',
    flexDirection: 'row',
    float: 'left'
  }
}));

export const useStylesPlayer = makeStyles((theme) => ({
  dataZone: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: ZONE_BACKGROUND_COLOR,
    borderRadius: 25,
    listStyleType: 'none',
    cursor: 'pointer',
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 12,
    paddingTop: 12,
    width: '100%',
    "&&:hover": {
      filter: 'brightness(95%)',
      boxShadow: 'none'
    }
  },
  playerPhoto: {
    width: 35,
    height: 35,
    borderRadius: '50%',
    border: '1px solid'
  },
  playerName: {
    marginLeft: 15
  },
  iconBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    fill: COLOR_LIGHT,
    cursor: 'pointer',
    borderRadius: 25,
    marginRight: 7,
    padding: 3,
    "&:hover": {
      backgroundColor: '#232e2f',
      filter: 'brightness(90%)',
      boxShadow: 'none'
    }
  },
  icon: {
    width: 20,
    height: 20,
    fill: COLOR_LIGHT
  },
  listIcon: {
    width: 17,
    height: 17,
    fill: COLOR_DARK,
    stroke: COLOR_DARK
  },
  listIconZone: {
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center'
  }
}));

export const useStylesModal = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
      padding: 20
    },
    [theme.breakpoints.up('sm')]: {
      padding: 20
    },
    [theme.breakpoints.up('md')]: {
      padding: 30
    },
    [theme.breakpoints.up('lg')]: {
      padding: 30
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  input: {
    width: '100%',
    marginBottom: 15
  },
  formTitle: {
    marginBottom: 30,
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  formBtnZone: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30
  },
  formBtn: {
    marginTop: 'auto',
    width: 200
  }
}));