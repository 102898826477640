import React, { useState, useEffect, useRef } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { API, graphqlOperation } from 'aws-amplify';

import { ClubPhoto } from "../../components/Photos/ClubPhoto"
import { ReactComponent as AssistIcon } from '../../assets/icons/assist.svg';
import { ReactComponent as BallIcon } from '../../assets/icons/ball.svg';
import { ReactComponent as CardIcon } from '../../assets/icons/card.svg';
import { ReactComponent as OpponentIcon } from '../../assets/icons/opponent.svg'

import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';

import CButton from "../../components/Button";
import InputCode from '../../components/Input/InputCode'
import CModal from "../../components/Modal";
import { CText } from "../../components/Text";
import { SearchBar } from '../../components/TextInput';
import { Menu, TabPanel } from '../../components/Menu/index';
import CSelect from "../../components/Select";
import TextInput from '../../components/TextInput.js';

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_RED = "#fc3c2e";
const COLOR_YELLOW = "#EDA247";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const COLOR_DARK = "#242834";
const BUTTON_WIDTH = 100;
const PLAYER_SUBSITUTES_CARD_HEIGHT = 120;

const Score = ({game, onChangeScore, score}) => {
  const useStyles = makeStyles((theme) => ({
    scoreContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    scoreInfo: {
      padding: 30,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      borderRadius: 10,
      listStyleType: 'none',
      [theme.breakpoints.up('xs')]: {
        width: 350
      },
      [theme.breakpoints.up('sm')]: {
        width: 450
      },
      [theme.breakpoints.up('md')]: {
        width: 550
      },
      [theme.breakpoints.up('lg')]: {
        width: 600
      }
    },
    homeTeam: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    awayTeam: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'right'
    },
    scoreZoneWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
    scoreZone: {
      backgroundColor: COLOR_LIGHT,
      width: 26,
      height: 33,
      marginLeft: 2,
      marginRight: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '10%',
      fontSize: 20,
      border: 'none',
      outline: 'none',
      textAlign: 'center',
      caretColor: 'transparent',
      cursor: 'pointer',
      'border': `2px solid ${COLOR_LIGHT}`,
      '&:focus': {
        'border': `2px solid ${COLOR_PRIMARY}`,
        outline: 'none !important'
      },
      '::-moz-selection': {
        background: '#AFAFAF'
      }
    },
    awayTeamName: {
      marginRight: 15
    },
    homeTeamName: {
      marginLeft: 15
    },
    awayTeamIcon: {
      height: '100%',
      width: 45,
      fill: COLOR_LIGHT,
      marginLeft: 10,
      display: 'flex',
      alignItems: 'center'
    },
    homeTeamIcon: {
      height: '100%',
      width: 45,
      fill: COLOR_LIGHT,
      marginRight: 10,
      display: 'flex',
      alignItems: 'center'
    }
  }));

  const { t } = useTranslation();
  const classes = useStyles();

  const [newScore, setNewScore] = useState({ home_team: null, away_team: null });

  const getScore = (data) => {
    setNewScore({...newScore, home_team: parseInt(data[0]), away_team: parseInt(data[1])})
    onChangeScore({...newScore, home_team: parseInt(data[0]), away_team: parseInt(data[1])})
  }

  return (
    <div className={classes.scoreContainer}>
      <div className={classes.scoreInfo}>
        <Grid item xs={4} className={classes.homeTeam}>
          <CText level="p2" fontWeight={700} color="light" style={{display: 'flex', justifyContent: 'center'}}>
            {game.home_team_name ? <OpponentIcon className={classes.homeTeamIcon}/> : <ClubPhoto className={classes.homeTeamIcon} src={game?.home_team?.club?.logo}/>}
            {game.home_team_name ? game.home_team_name : JSON.parse(game?.home_team?.club?.names[0])?.name}
          </CText>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.scoreZoneWrapper}>
            <InputCode
              className={classes.scoreZone}
              length={2}
              onComplete={getScore}
              initialValue={score}
            />
          </div>
        </Grid>
        <Grid item xs={4} className={classes.awayTeam}>
          <CText level="p2" fontWeight={700} color="light">
            {game.away_team_name ? game.away_team_name : JSON.parse(game.away_team.club.names[0]).name}
            {game.away_team_name ? <OpponentIcon className={classes.awayTeamIcon}/> : <ClubPhoto className={classes.awayTeamIcon} src={game?.away_team?.club?.logo} />}
          </CText>
        </Grid>
      </div>
    </div>
  );
}

export default Score;