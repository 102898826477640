import { API, Storage  } from 'aws-amplify';
import * as mutations from '../graphql/mutations'
import { getSession } from "./auth"

class UploadFileError extends Error {
  constructor(code, message) {
    super(message);
    this.code = code;
  }
}

export const uploadUserClubDocuments = async (event, clubId, user, fileType) => {
  try {
    const fileData = event?.target?.files[0];
    const fileExtensions = {
      'image/png': 'png',
      'image/jpeg': 'jpeg',
      'image/webp': 'webp',
      'application/pdf': 'pdf',
      'text/plain': 'txt',
      'application/msword': 'doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      'text/csv': 'csv',
      'application/vnd.ms-excel': 'xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
      'application/vnd.ms-powerpoint': 'ppt',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
      'application/vnd.oasis.opendocument.text': 'odt',
    };

    const fileExtension = fileExtensions[fileData.type];

    if (!fileExtension) {
      throw new UploadFileError(1, 'Invalid file format.');
    }

    const updateData = { id: user.documents_id };
    const timestamp = Date.now();
    let filePath;

    switch (fileType) {
      case 'license':
        filePath = `clubs/${clubId}/users/${user.id}/licenses/${timestamp}.${fileExtension}`;
        updateData.license_path = filePath;
        user.license_path = filePath;
        break;
      case 'membership_form':
        filePath = `clubs/${clubId}/users/${user.id}/membership_forms/${timestamp}.${fileExtension}`;
        updateData.membership_form_path = filePath;
        user.membership_form_path = filePath;
        break;
      case 'id_card':
        filePath = `clubs/${clubId}/users/${user.id}/id_cards/${timestamp}.${fileExtension}`;
        updateData.id_card_path = filePath;
        user.id_card_path = filePath;
        break;
      case 'medical_certificate':
        filePath = `clubs/${clubId}/users/${user.id}/medical_certificates/${timestamp}.${fileExtension}`;
        updateData.medical_certificate_path = filePath;
        user.medical_certificate_path = filePath;
        break;
      case 'health_questionnaire':
        filePath = `clubs/${clubId}/users/${user.id}/health_questionnaires/${timestamp}.${fileExtension}`;
        updateData.health_questionnaire_path = filePath;
        user.health_questionnaire_path = filePath;
        break;
      case 'club_rules':
        filePath = `clubs/${clubId}/users/${user.id}/club_rules/${timestamp}.${fileExtension}`;
        updateData.club_rules_path = filePath;
        user.club_rules_path = filePath;
        break;
      case 'image_rights':
        filePath = `clubs/${clubId}/users/${user.id}/image_rights/${timestamp}.${fileExtension}`;
        updateData.image_rights_form_path = filePath;
        user.image_rights_form_path = filePath;
        break;
      default:
        throw new Error('Invalid file type.');
        throw new UploadFileError(2, 'Invalid file type.');
    }

    await getSession();

    await Storage.put(filePath, fileData, { contentType: fileData.type });

    await API.graphql({
      query: mutations.updateUserClubDocument,
      variables: { input: updateData },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });

    return user;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteUserClubDocuments = async (user, fileType) => {
  try {
    const updateData = { id: user.documents_id };
    let filePath;

    switch (fileType) {
      case 'license':
        filePath = user.license_path;
        updateData.license_path = "";
        user.license_path = "";
        break;
      case 'membership_form':
        filePath = user.membership_form_path;
        updateData.membership_form_path = "";
        user.membership_form_path = "";
        break;
      case 'id_card':
        filePath = user.id_card_path;
        updateData.id_card_path = "";
        user.id_card_path = "";
        break;
      case 'medical_certificate':
        filePath = user.medical_certificate_path;
        updateData.medical_certificate_path = "";
        user.medical_certificate_path = "";
        break;
      case 'health_questionnaire':
        filePath = user.health_questionnaire_path;
        updateData.health_questionnaire_path = "";
        user.health_questionnaire_path = "";
        break;
      case 'club_rules':
        filePath = user.club_rules_path;
        updateData.club_rules_path = "";
        user.club_rules_path = "";
        break;
      case 'image_rights':
        filePath = user.image_rights_form_path;
        updateData.image_rights_form_path = "";
        user.image_rights_form_path = "";
        break;
      default:
        throw new UploadFileError(2, 'Invalid file type.');
    }

    await getSession();

    await API.graphql({
      query: mutations.updateUserClubDocument,
      variables: { input: updateData },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });

    await Storage.remove(filePath);

    return user;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const downloadFile = async (path, fullName, fileType) => {
  try {
    const fileData = await Storage.get(path, { download: true });

    const blob = new Blob([fileData.Body], { type: fileData.ContentType });

    const sanitizedFullName = fullName.replace(/\s/g, '_');
    const sanitizedFileType = fileType.replace(/\s/g, '_');

    const link = document.createElement('a');
    link.download = `${sanitizedFullName}_${sanitizedFileType}.${path.split('.').pop()}`;
    link.href = URL.createObjectURL(blob);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  } catch (err) {
    console.error(err);
  }
};
