import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  count: 0,
  invitations: []
};

const invitationsSlice = createSlice({
  name: 'invitations',
  initialState,
  reducers: {
    decrementInvitations: (state) => {
      state.count -= 1;
    },
    setInvitations: (state, action) => {
      if (action.payload.count !== null && action.payload.count !== undefined) {
        state.count = action.payload.count;
      }
      if (action.payload.invitations !== null && action.payload.invitations !== undefined) {
        state.invitations = action.payload.invitations;
      }
    },    
    addInvitation: (state, action) => {
      state.invitations.push(action.payload);
    },
    removeInvitation: (state, action) => {
      state.invitations = state.invitations.filter(invitation => invitation.id !== action.payload.id);
    },
  },
});

export const { decrementInvitations, setInvitations, addInvitation, removeInvitation } = invitationsSlice.actions;
export default invitationsSlice.reducer;