import React, { useState } from 'react';
import GoalDescription from './GoalDescription';

// Couleurs et styles constants
const COLOR_LIGHT = "#f4f4f4";
const COLOR_DARK = "#242834";
const COLOR_PINK = "#e92163";
const PLAYER_RADIUS = 10;
const PLAYER_STROKE_WIDTH = 3;
const PLAYER_RADIUS_FRONT = PLAYER_RADIUS * 2.5;
const PLAYER_STROKE_WIDTH_FRONT = PLAYER_STROKE_WIDTH * 2.5;

const GoalDescriptionCreator = ({ players, setPlayers, startPreview }) => {
  // Fonction pour gérer le clic sur le terrain
  const handleFieldClick = (event) => {
    const svg = event.target.closest('svg');
    const rect = svg.getBoundingClientRect();

    // Taille réelle du terrain dans le viewBox
    const originalWidth = 1650; // Largeur définie dans le viewBox
    const originalHeight = 1000; // Hauteur définie dans le viewBox

    // Échelle du terrain (comparaison entre sa taille actuelle et sa taille originale)
    const scaleX = originalWidth / rect.width;
    const scaleY = originalHeight / rect.height;

    // Calcul des coordonnées ajustées pour l'affichage (responsive)
    const cxFront = (event.clientX - rect.left) * scaleX;
    const cyFront = (event.clientY - rect.top) * scaleY;

    // Calcul des coordonnées originales pour le backend ou GoalDescription
    const cxOriginal = (event.clientX - rect.left) + PLAYER_RADIUS + (PLAYER_STROKE_WIDTH / 2);
    const cyOriginal = (event.clientY - rect.top) + (2 * PLAYER_RADIUS);

    // Ajouter le nouveau joueur à la liste (avec les coordonnées originales)
    setPlayers((prevPlayers) => [...prevPlayers, { cx: cxOriginal, cy: cyOriginal, cxFront, cyFront }]);
  };

  // Fonction pour réinitialiser les positions des joueurs
  const resetPlayers = () => {
    setPlayers([]);
  };

  // Fonction pour supprimer le dernier joueur (revenir en arrière)
  const undoLastPlayer = () => {
    setPlayers((prevPlayers) => prevPlayers.slice(0, -1));
  };

  return (
    <div style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ maxWidth: '600px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <svg
          id="svg-container"
          viewBox="0 0 1650 1000"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid meet"
          style={{ width: '100%', height: '100%', cursor: 'pointer' }}
          onClick={handleFieldClick} // Gestionnaire de clic pour le positionnement des joueurs
        >
          {/* Terrain */}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1650 1000" preserveAspectRatio="xMidYMid meet">
            <defs>
              <style>
                {`
                  .cls-1 { fill: #1f2126; }
                  .cls-1, .cls-2, .cls-3 { fill-rule: evenodd; }
                  .cls-2 { fill: #45d69f; }
                  .cls-3 { fill: none; stroke: #45d69f; stroke-width: 4px; }
                `}
              </style>
            </defs>
            <path
              id="pitch"
              className="cls-1"
              d="M326.159,0H1323.84c21.61,0,44.2,17.682,50.6,39.959l249.97,870.882c13.93,48.545-1.27,89.159-34.41,89.159H60c-33.137,0-48.342-40.614-34.408-89.159L275.564,39.959C281.958,17.682,304.551,0,326.159,0Z"
            />
            <path id="center-line" className="cls-2" d="M165.406,423.739H1484.59l0.85,2.944H164.561Z" />
            <path
              id="center-circle"
              className="cls-3"
              d="M825,294.236c86.572,0,162.439,54.81,167.9,129.5,5.66,77.475-69,145.954-167.9,145.954S651.443,501.214,657.1,423.739C662.561,349.046,738.428,294.236,825,294.236Z"
            />
            <path id="area-attack" className="cls-3" d="M945.635,0l4.982,77.407a6.451,6.451,0,0,1-6.358,7.1H705.741a6.451,6.451,0,0,1-6.358-7.1L704.365,0" />
            <path id="area-defense" className="cls-3" d="M630,1000l7.021-103.484c0.532-7.848,5.256-14.178,10.552-14.178H1002.43c5.29,0,10.02,6.33,10.55,14.178L1020,1000" />
          </svg>

          {/* Afficher les joueurs (coordonnées adaptées pour l'affichage) */}
          {players.map((player, index) => (
            <circle
              key={index}
              cx={player.cxFront} // Utilisation des coordonnées ajustées pour l'affichage front
              cy={player.cyFront}
              r={PLAYER_RADIUS_FRONT}
              fill={COLOR_DARK}
              stroke={COLOR_PINK}
              strokeWidth={PLAYER_STROKE_WIDTH_FRONT}
            />
          ))}
        </svg>
      </div>
      <div style={{ marginTop: '20px' }}>
        <button onClick={resetPlayers} style={{ marginRight: '10px', padding: '10px 20px', cursor: 'pointer' }}>
          Réinitialiser les joueurs
        </button>
        <button onClick={undoLastPlayer} style={{ marginRight: '10px', padding: '10px 20px', cursor: 'pointer' }}>
          Revenir en arrière
        </button>
        <button onClick={startPreview} style={{ padding: '10px 20px', cursor: 'pointer' }}>
          Prévisualiser
        </button>
      </div>
    </div>
  );
};

const GoalDescriptionApp = () => {
  const [players, setPlayers] = useState([]);
  const [isPreviewing, setIsPreviewing] = useState(false);

  const startPreview = () => {
    setIsPreviewing(true);
    setPlayers(prevPlayers => [...prevPlayers, { cx: 300, cy: 20 }]);
  };

  const goBackToEdit = () => {
    setIsPreviewing(false);
  };

  return (
    <div>
      {!isPreviewing ? (
        <GoalDescriptionCreator players={players} setPlayers={setPlayers} startPreview={startPreview} />
      ) : (
        <GoalDescription data={players.map(player => ({ cx: player.cx, cy: player.cy }))} />
      )}
    </div>
  );
};

export default GoalDescriptionApp;