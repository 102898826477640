import React, { useState, useEffect } from 'react'
import {makeStyles} from "@material-ui/core/styles"
import Grid from '@material-ui/core/Grid'
import { useTranslation } from "react-i18next"
import { API } from 'aws-amplify'
import { useSelector } from 'react-redux'
import * as queries from '../../graphql/queries'
import CButton from "../../components/Button"
import { CText } from "../../components/Text"
import { ReactComponent as RocketIcon } from '../../assets/icons/rocket.svg'
import { ReactComponent as InfinityIcon } from '../../assets/icons/infinity.svg'
import { ReactComponent as PremiumIcon } from '../../assets/icons/premium.svg'

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PURPLE = "#9d44fd";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = '#242834';
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;

const Plan = ({ data }) => {
  const useStyles = makeStyles((theme) => ({
    starterZone: {
      border: `2px solid ${COLOR_PINK}`,
    },
    starterColor: {
      color: COLOR_PINK,
      fill: COLOR_PINK,
    },
    premiumZone: {
      border: `2px solid ${COLOR_PURPLE}`,
    },
    premiumColor: {
      color: COLOR_PURPLE,
      fill: COLOR_PURPLE,
    },
    ultimateZone: {
      border: `2px solid ${COLOR_PRIMARY}`,
    },
    ultimateColor: {
      color: COLOR_PRIMARY,
      fill: COLOR_PRIMARY,
    },
    dataZone: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      borderRadius: 10,
      listStyleType: 'none',
      width: '100%',
      [theme.breakpoints.up('xs')]: {
        maxWidth: 320
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 320
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 'none'
      },
      position: 'relative',
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
      paddingTop: 20
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 30,
    },
    icon: {
      width: 40,
      height: 40,
      marginRight: 10,
    },
    info: {
      marginBottom: 20,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start !important',
      whiteSpace: 'nowrap',
    },
    infoValue: {
      marginLeft: 10,
      fontWeight: 500,
      display: 'flex',
      flexDirection: 'column'
    },
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    btnZone: {
      flexGrow: 1,
      marginTop: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end'
    },
    btn: {
      width: '100%',
      maxWidth: 200
    }
  }))

  const { t } = useTranslation()
  const classes = useStyles()

  // Handle Icon
  let ICON;
  let COLOR;
  switch (data.name) {
    case 'Starter':
      ICON = <RocketIcon className={classes.icon} />
      COLOR = COLOR_PINK
      break
    case 'Premium':
      ICON = <PremiumIcon className={classes.icon} />
      COLOR = COLOR_PURPLE
      break
    case 'Ultimate':
      ICON = <InfinityIcon className={classes.icon} />
      COLOR = COLOR_PRIMARY
      break
  }

  return (
    <li className={[
      classes.dataZone,
      data.name === 'Starter' ? classes.starterZone: null,
      data.name === 'Premium' ? classes.premiumZone: null,
      data.name === 'Ultimate' ? classes.ultimateZone: null
    ].join(' ')}
    >
      <div className={classes.title}>
        <CText
          level="p1"
          fontWeight={700}
          className={[
            data.name === 'Starter' ? classes.starterColor: null,
            data.name === 'Premium' ? classes.premiumColor: null,
            data.name === 'Ultimate' ? classes.ultimateColor: null
          ].join(' ')}
        >
          {ICON}
          {data.name}
        </CText>
      </div>
      <div className={classes.content}>
        <CText level="p2" fontWeight={700} color="light" className={classes.info}>
          {t('subscription.labels.0')}:
          {data.max_teams === 500 ?
            <span className={classes.infoValue}>{t('subscription.labels.5')}</span>
            :
            <span className={classes.infoValue}> {data?.max_teams > 1 ? t('subscription.labels.3') : null} {data.max_teams}</span>
          }
        </CText>
        <CText level="p2" fontWeight={700} color="light" className={classes.info}>
          {t('subscription.labels.1')}:
          <span className={classes.infoValue}>
            {parseFloat(data.price_euro).toFixed(2)}€ {t('subscription.labels.4')}
            {data.extra_team_price_euro ? <span>{t('subscription.labels.6')} {data?.extra_team_price_euro} € {t('subscription.labels.7')}</span> : null}
          </span>
        </CText>

        {data.first_month_free ?
          <CText level="p2" fontWeight={700} color="light">
            {t('subscription.labels.2')}
          </CText>
          :
          null
        }

        {data.is_limited ?
          <CText level="p2" fontWeight={700} color="light">
            {t('subscription.labels.8')}
          </CText>
          :
          null
        }
      </div>
    </li>
  );
}

const Plans = (props) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      height: '100%',
      paddingLeft: '3%',
      paddingRight: '3%'
    },
    title: {
      marginBottom: 20
    },
    topContainer: {
      display: 'inline-block',
      width: '100%',
      marginBottom: 30
    },
    topContainerLeft: {
      display: 'flex',
      flexDirection: 'row',
      float: 'left'
    },
    selector: {
      backgroundColor: ZONE_BACKGROUND_COLOR
    },
    addButton: {
      float: 'right'
    },
    dataZone: {
      width: '100%',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      borderRadius: 18,
      listStyleType: 'none',
      [theme.breakpoints.up('xs')]: {
        padding: 25
      },
      [theme.breakpoints.up('sm')]: {
        padding: 25
      },
      [theme.breakpoints.up('md')]: {
        padding: 25
      },
      [theme.breakpoints.up('lg')]: {
        padding: 30
      }
    },
    championshipsContainer: {
      display: 'inline-block',
      width: '100%',
      marginBottom: 35
    },
    championshipContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '5px 10px',
      cursor: 'pointer',
      "&:hover": {
        opacity: '0.8'
      }
    },
    championshipIcon: {
      width: 30,
      height: 30,
      fill: COLOR_LIGHT,
      marginRight: 10
    },
    championshipCaretIcon: {
      width: 20,
      height: 20,
      fill: COLOR_LIGHT,
      marginLeft: 20   
    },
    championshipButtonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'right'
    },
    leftButton: {
      [theme.breakpoints.up('xs')]: {
        marginRight: 5
      },
      [theme.breakpoints.up('lg')]: {
        marginRight: 10
      },
      [theme.breakpoints.up('xl')]: {
        marginRight: 12
      }
    },
    listIcon: {
      width: 25,
      height: 25,
      fill: COLOR_DARK
    }
  }));

  const { t } = useTranslation();
  const classes = useStyles();
  const userStored = useSelector(state => state?.user);

  const [loaded, setLoaded] = useState(false)
  const [plans, setPlans] = useState([])

  useEffect(() => {  
    const fetchData = async () => {
      await getPlans();
      setLoaded(true)
    }
  
    fetchData();
  }, []);

  const getPlans = async() => {
    try {
      let listPlans = await API.graphql({ query: queries.listPlans, authMode: 'API_KEY' });
      console.log(listPlans)
      listPlans = listPlans.data?.listPlans?.items.sort((a, b) => a.id.localeCompare(b.id))
      setPlans(listPlans)

      console.log("Get plans with success.");
    }
    catch(err) { console.log(err) }
  }
  
  // Content
  let CONTENT;
  if(loaded === true) {
    CONTENT = <div>
      <CText className={classes.title} level="h1" color="light">{t('subscription.titles.1')}</CText>
      <ul style={{padding: 0}}>
        <Grid container spacing={2}>
          {plans.map((plan, index) => (
            <Grid container item key={index} alignItems="stretch" justifyContent="center" xs={12} sm={12} md={6} lg={4}>
              <Plan data={plan} />
            </Grid>
          ))}
        </Grid>
      </ul>
    </div>
  } else {
    CONTENT = <div></div>
  }

  return(
    <div className={classes.container}>
      {CONTENT}
    </div>
  )
}
  
export default Plans;
