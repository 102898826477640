import React, { useState, useEffect } from 'react';
import TextInput from '../../components/TextInput';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import FormControl from '@mui/material/FormControl';
import { API, graphqlOperation } from 'aws-amplify';
import { CFormControlLabel, CFormLabel, CRadioGroup } from '../../components/Radio/Radio';
import championshipCategories from '../../assets/referentials/championship_categories.json';
import CSelect from "../../components/Select";
import CButton from "../../components/Button";
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { useStyles, useStylesModal } from './teamEdition.styles';
import { useSelector } from 'react-redux';
import { CText } from "../../components/Text";
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as CupIcon } from '../../assets/icons/cup.svg';
import { ReactComponent as ChampionshipIcon } from '../../assets/icons/championship.svg';
import { ReactComponent as DotsVerticalIcon } from '../../assets/icons/dots-vertical.svg';
import CModal from "../../components/Modal";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';


const COLOR_PRIMARY = '#45d69f';
const COLOR_PINK = "#e92163";

const AddCompetitionModal = (props) => {
  const { t } = useTranslation()
  const classes = useStylesModal()

  /********* Handle Name *********/
  const [name, setName] = useState("")
  const nameCb = (data) => setName(data)

  /********* Handle Category *********/
  const [category, setCategory] = useState({code: "", label: ""})
  const categoryCb = (data) => { setCategory(data) };

  /********* Handle Gender *********/
  const [gender, setGender] = useState({code: "", label: ""})
  const genderCb = (data) => setGender(data)

  /********* Handle Type *********/
  const [type, setType] = useState({code: "", label: ""})
  const typeCb = (data) => setType(data)

  /********* Send Invite *********/
  const onClickSave = async() => {
    if(category && gender && name && type) {
      try {
        const teamId = props.team.id

        // Current Season
       const searchSeasonsResp = await API.graphql({ query: queries.searchSeasons, authMode: 'AMAZON_COGNITO_USER_POOLS'})
        const currentSeason = searchSeasonsResp.data.searchSeasons.items[0].name

        // Create Competition
        const competitionInputs = {
          category: category.code,
          gender: gender.code,
          name: name,
          type: type.code
        }
        const competition = await API.graphql({
          query: mutations.createCompetition,
          variables: { input: competitionInputs },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        console.log("competition: ",competition)
        
        // Create Competition Team
        const competitionTeamInputs = {
          competition_id: competition.data.createCompetition.id,
          competitionTeamsId: competition.data.createCompetition.id,
          team_id: teamId,
          teamCompetitionsId: teamId,
          season: currentSeason
        }
        let competitionTeam = await API.graphql({
          query: mutations.createCompetitionTeam,
          variables: { input: competitionTeamInputs },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        props.competitionCallback(competition.data.createCompetition, competitionTeam.data.createCompetitionTeam);
        props.modalOpenCallback(false);

      } catch (err) { console.log('error fetching:', err) }
    } else {
      let errorElmts = []
      if(!name) {
        errorElmts.push(t('snack_bars.0.elements.3.label'))
      }
      if(!type) {
        errorElmts.push(t('snack_bars.0.elements.4.label'))
      }
      if(!category) {
        errorElmts.push(t('snack_bars.0.elements.0.label'))
      }
      if(!gender) {
        errorElmts.push(t('snack_bars.0.elements.1.label'))
      }
      props.errorCallback(errorElmts);
    }
  }

  const genders = [
    { "label": t('genders.0.longLabel'), "code": "M" },
    { "label": t('genders.1.longLabel'), "code": "F" }
  ]

  const competitionTypes = [
    { "label": t('competition_types.0.label'), "code": "CHP", "icon": <ChampionshipIcon /> },
    { "label": t('competition_types.1.label'), "code": "CUP", "icon": <CupIcon /> }
  ];

  return (
    <div className={classes.modalContainer}>
      <CText className={classes.formTitle} level="h2" fontWeight={700} color="primary" label={`${t('teams.modals.2.title')}`} />
      <div className={classes.form}>
        <TextInput className={classes.input} label={`${t('teams.modals.2.inputs.0.label')} *`} inputCallback={nameCb} value={name} />
        <div className={[classes.input, classes.inputInline].join(" ")}>
          <div className={classes.inputLeft}>
            <CSelect
              label={`${t('teams.modals.2.inputs.1.label')} *`}
              data={competitionTypes}
              selectCallback={typeCb}
              value={type}
            />
          </div>
          <div className={classes.inputRight}>
            <CSelect
              label={`${t('teams.modals.2.inputs.2.label')} *`}
              data={championshipCategories}
              selectCallback={categoryCb}
              value={category}
            />
          </div>
        </div>
        <div className={[classes.input, classes.halfInput].join(" ")}>
          <CSelect
            label={`${t('teams.modals.2.inputs.3.label')} *`}
            data={genders}
            selectCallback={genderCb}
            value={gender}
          />
        </div>
        <div className={classes.formBtnZone}>
          <CButton
            className={classes.formBtn}
            label={t('teams.modals.2.buttons.0.label')}
            height={38}
            type="contained"
            loader={true}
            onClick={async () => await onClickSave()}
          />
        </div>
      </div>
    </div>
  );
}

const TeamEdition = ({ updateInfo }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const userStored = useSelector((state) => state?.user);
  const { teamId } = useParams();

  const [team, setTeam] = useState({});
  const [name, setName] = useState("");
  const [category, setCategory] = useState({ code: "", label: "" });
  const [competitions, setCompetitions] = useState([{ name: "", type: "CHP" }]);
  const [modifiedCompetitions, setModifiedCompetitions] = useState(false);
  const [gender, setGender] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState({ value: false, type: null });
  const [openSnack, setOpenSnack] = useState(false);
  const [snackBarErrorElmts, setSnackBarErrorElmts] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      await getTeam();
      setIsLoaded(true);
    }
    fetchData();
  }, [userStored]);

  const genders = [
    { "label": t('genders.0.longLabel'), "code": "M" },
    { "label": t('genders.1.longLabel'), "code": "F" }
  ];

  //Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickMore = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getTeam = async () => {
    try {
      let competitionsInfo = [];

      let team = await API.graphql({
        query: queries.getTeamWithCompetitions,
        variables: { 
          id: teamId,
          competitionFilter: {
            deleted: { ne: true }
          }
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      team = team?.data?.getTeam;

      if (team?.competitions?.items) {
        competitionsInfo = team?.competitions?.items.map(data => ({
          ...data.competition,
          team_competition_id: data.id
        }));
        setCompetitions(competitionsInfo);
      }
      
      const teamInfo = {
        id: team?.id,
        name: team?.name,
        category: team?.category,
        gender: team?.gender,
        competitions: competitionsInfo
      }

      const teamCategory = championshipCategories.find(category => category.code === team.category);
      setCategory(teamCategory);

      setTeam(teamInfo);
      return teamInfo;
    }
    catch (err) { console.log(err) }
  }

  const removeCompetition = (index) => {
    setAnchorEl(null);
    const updateData = {
      id: competitions[index].team_competition_id,
      deleted: true,
      deletion_date: moment().format("YYYY-MM-DD")
    };

    API.graphql({
      query: mutations.updateCompetitionTeam,
      variables: { input: updateData },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });

    let newCompetitions = [...competitions];
    newCompetitions.splice(index, 1);
    setCompetitions(newCompetitions);
  };

  const nameCb = (data) => { setName(data) };
  const categoryCb = (data) => { setCategory(data) };
  const genderCb = (data) => { setGender(data) };

  const onClickSave = async () => {
    const updatedTeam = {
      id: team.id
    };

    if (name && name !== team.name) {
      updatedTeam.name = name;
    }
    if (category?.code && category?.code !== team.category) {
      updatedTeam.category = category?.code;
    }
    if (gender && gender !== team.gender) {
      updatedTeam.gender = gender;
    }

    if (Object.keys(updatedTeam).length > 1) {
      await API.graphql(graphqlOperation(mutations.updateTeam, {
        input: updatedTeam
      }));

      setModifiedCompetitions(false);
    }
  };

  //Modal
  const onClickModalAdd = () => {
    setModalOpen({ value: true, type: null });
  }

  // Competition
  const competitionCB = (competitionData, competitionTeamData) => {
    let newCompetitions = [...competitions];

    newCompetitions.push({
      id: competitionData.id,
      name: competitionData.name,
      category: competitionData.category,
      gender: competitionData.gender,
      team_competition_id: competitionTeamData.id
    });
  
    newCompetitions.sort((a, b) => a.name.localeCompare(b.name, undefined, {
      numeric: true,
      sensitivity: 'base'
    }));
  
    setCompetitions(newCompetitions);
  }

  /********* Handle Error *********/


  const errorCB = (errorElmts) => {
    setOpenSnack(true)
    setSnackBarErrorElmts(errorElmts)
  }

  return (
    <>
      <CText level="h1" color="light">{`${t('edit_team.title')}`}</CText>
      <div className={classes.topButtonsContainer}>
        <CButton
          className={classes.topButton}
          label={t('edit_team.buttons.0.label')}
          type="contained"
          color={COLOR_PRIMARY}
          size={14}
          loader={true}
          onClick={async () => await onClickSave()}
        />
      </div>
    
      <div className={classes.content}>
        {isLoaded === true ?
          <div className={classes.formZone}>
            <TextInput
              className={classes.input}
              label={`${t('edit_team.inputs.0.label')} *`}
              inputCallback={nameCb}
              defaultValue={team.name}
            />
            <CSelect
              className={classes.input}
              label={`${t('edit_team.inputs.1.label')} *`}
              data={championshipCategories}
              selectCallback={categoryCb}
              value={category}
            />
            <div className={[classes.input, classes.radioInput].join(" ")}>
              <FormControl>
                <CFormLabel id="radio-buttons-group" label={`${t('edit_team.inputs.2.label')} *`} />
                <CRadioGroup
                  defaultValue={team.gender}
                  onChange={genderCb}
                >
                  {genders.map((gender, index) => (
                    <CFormControlLabel
                      key={index}
                      label={gender.label}
                      value={gender.code}
                    />
                  ))}
                </CRadioGroup>
              </FormControl>
            </div>
            <div className={[classes.input, classes.competitionsInput].join(" ")}>
              <CText color="light" className={[classes.competitionsInputLabel].join(" ")}>
                {t('edit_team.inputs.3.label')}
                <CButton
                  className={classes.competitionAddBtn}
                  label={t('edit_team.buttons.2.label')}
                  type="contained"
                  color={COLOR_PRIMARY}
                  size={12}
                  loader={true}
                  onClick={onClickModalAdd}
              />
              </CText>

              {competitions.map((elmt, index) => (
                <div key={index}>
                  <CText className={[classes.competitionLabel].join(" ")} color="light" key={index}>
                    {elmt?.type === "CHP" ?
                      <ChampionshipIcon className={classes.competitionIcon} /> : <CupIcon className={classes.competitionIcon} />
                    }
                    {elmt.name}
                    <span className={classes.moreBtn} onClick={handleClickMore}>
                      <DotsVerticalIcon />
                    </span>
                  </CText>

                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        minWidth: '10ch'
                      },
                    }}
                    autoFocus={false}
                    >
                    <MenuItem
                      className={classes.listRemove}
                      onClick={() => removeCompetition(index)}
                    >
                      <span className={classes.listIconZone}>
                        <TrashIcon className={classes.listIcon} />
                      </span>
                      {t('edit_team.buttons.1.label')}
                    </MenuItem>
                  </Menu>
                </div>
              ))}
            </div>
          </div>
          : null
        }
      </div>


      <CModal
        open={modalOpen.value} // Correct usage of modalOpen
        onClose={() => setModalOpen({ value: false, type: null })}
        content={<AddCompetitionModal team={team} modalOpenCallback={(data) => setModalOpen({value: data})} competitionCallback={competitionCB} errorCallback={errorCB} />}
      >
      </CModal>
    </>
  );
};

export default TeamEdition;