import {makeStyles} from "@material-ui/core/styles"

const COLOR_PRIMARY = '#45d69f';
const COLOR_PRIMARY_TRANSPARENT = 'rgba(69, 214, 159, 0.4)';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_RED = "#fc3c2e";
const COLOR_YELLOW = "#EDA247";
const COLOR_DARK = '#242834';
const ZONE_BACKGROUND_COLOR = "#1f2126";
const MENU_LABEL_ACTIVE_BACKGROUND_COLOR = "rgba(69, 214, 159, 0.07)";
const FONT_FAMILY='Montserrat';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  tacticalSchemaTitle: {
    maxWidth: 200
  },
  tacticalSchemaContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  pitch: {
    position: 'relative',
    width: '100%',
    height: '500px',
    border: '1px solid black',
    transform: "scale(0.95)",
    [theme.breakpoints.up('xs')]: {
      transform: "scale(0.8)",
      width: '500px',
      marginTop: -50
    },
    [theme.breakpoints.up('sm')]: {
      transform: "scale(0.9)",
      width: '500px',
      marginTop: -20
    },
    [theme.breakpoints.up('md')]: {
      transform: "scale(0.95)",
      width: '500px',
      marginTop: 0
    },
    [theme.breakpoints.up('lg')]: {
      transform: "scale(1)",
      width: '500px',
      marginTop: 0
    }
  },
  lateralMenu: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 60,
    height: 500,
    backgroundColor: ZONE_BACKGROUND_COLOR,
    padding: 10,
    borderRadius: 25,
    marginRight: 15
  },
  player: {
    position: 'absolute',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    color: COLOR_LIGHT,
    fontWeight: '700',
    [theme.breakpoints.up('xs')]: {
      fontSize: 14
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 18
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 18
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 18
    },
    fontFamily: FONT_FAMILY
  },
  playerTitleZone: {
    textAlign: 'center'
  },
  canvasMenuBtn: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 30,
    width: '100%',
    textDecoration: 'none',
    borderRadius: 10,
    cursor: 'pointer',
    "-moz-user-select": "none",
    "-khtml-user-select": "none",
    "-webkit-user-select": "none",
    "&:hover, &:visited": {
      backgroundColor: MENU_LABEL_ACTIVE_BACKGROUND_COLOR
    }
  },
  modalContainer: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  input: {
    marginBottom: 15
  },
  fabIcon: {
    width: 22,
    height: 22
  },
  fabEdit: {
    marginBottom: '5px !important'
  },
  fabContainer: {
    position: 'fixed',
    bottom: 15,
    right: 15,
    display: 'flex',
    flexDirection: 'column'
  }
}));