import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { API } from 'aws-amplify'
import { useLocation, useNavigate } from "react-router-dom";
import { sortASC } from "../../utils/sort"
import { getUserGroups } from "../../utils/auth"
import { useSelector } from 'react-redux'
import * as queries from '../../graphql/queries'
import { CText } from "../../components/Text"
import NoTeamsCard from "../../components/Cards/NoTeamsCard"
import PlayersDocumentsTable from '../../components/Table/PlayersDocumentsTable'
import { positions } from '../../assets/referentials/positions'
import { useStylesTeamDocuments } from './teamDocuments.styles.js'
import { getCurrentSeason } from "../../utils/season"
import { updateTeamNameInPath } from "../../utils/urlRedirections";

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = '#242834';
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;
const MENU_WIDTH = 250;

export const getPositionOrder = (t) => {
  // Create a lookup object to map position short_label to their respective order
  return positions(t).reduce((order, position, index) => {
    order[position.short_label] = index;
    return order;
  }, {});
};

const TeamDocuments = (props) => {
  const classes = useStylesTeamDocuments();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const userStored = useSelector(state => state?.user);
  const teamId = userStored?.club?.team?.id;

  const [loaded, setLoaded] = useState(false);
  const [players, setPlayers] = useState([]);
  const [editable, setEditable] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const [season, setSeason] = useState("");
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    updateTeamNameInPath(location.pathname, userStored?.club?.team?.id, navigate);
  }, [location.pathname, userStored?.club?.team?.name, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      await getUserType()
      const userGroups = await getUserGroups()
      setUserGroups(userGroups)

      const currentSeason = await getCurrentSeason()
      setSeason(currentSeason)
      const teams = await getTeams();
      if (teams.length > 0) {    
        getTeamPlayers(teams, currentSeason);
      }

      setLoaded(true)
    }
  
    fetchData()
  }, [])

  const getUserType = async() => {
    try {
      const groups = userStored?.groups
      if(groups.includes("Coaches") || groups.includes("ClubAdmins")) {
        setEditable(true)
      }
    }
    catch(err) { console.log(err) }
  }

  const getTeams = async() => {
    try {
      const { groups, club } = userStored;
      let teams = [];
    
      if (groups.includes("ClubAdmins")) {
        const clubId = club?.id
  
        const teamsFilter = {
          club_id: { eq: clubId },
          deleted: { eq: false }
        }
  
        let teamsList = await API.graphql({ query: queries.listClubTeams, variables: { filter: teamsFilter }, authMode: 'AMAZON_COGNITO_USER_POOLS'})
        teamsList = teamsList.data.listTeams.items;
  
        const teamsListOrdered = teamsList.sort((a, b) => {
          const numberA = parseInt(a.name.replace(/\D/g, ''), 10) || 0;
          const numberB = parseInt(b.name.replace(/\D/g, ''), 10) || 0;
          return numberA - numberB;
        });
  
        teams = teamsListOrdered;
      } else {
        const team = club?.team
        teams.push(team)
      }
  
      setTeams(teams)
  
      return teams;
    }
    catch(err) { console.log(err) }
  }

  const getTeamPlayers = async(teams, season) => {
    try {
      let [goalkeepersArray, defendersArray, midfieldersArray, strickersArray, players] = [[], [], [], [], []];

      const userFilter = {
        invite_status: { eq: 1 },
        user_type: { eq: "P" },
        end_date: { attributeExists: false },
        season: { eq: season }
      }

      const userClubContributionFilter = {
        season: { eq: season }
      }

      let teamPlayers = await API.graphql({
        query: queries.getTeamPlayersMembership,
        variables: {
          id: teamId,
          userFilter: userFilter,
          userClubContributionFilter: userClubContributionFilter
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })

      teamPlayers = teamPlayers.data.getTeam.users.items

      if(teamPlayers.length > 0) {
        let teamPlayersOrdered = teamPlayers.sort(sortASC("first_name"))

        await teamPlayersOrdered.map( (player, index) => {
          switch (player.position) {
            case 'GK':
              goalkeepersArray.push(player)
              break
            case 'DF':
              defendersArray.push(player)
              break
            case 'MF':
              midfieldersArray.push(player)
              break
            case 'ST':
              strickersArray.push(player)
              break
          }
        })

        teamPlayersOrdered = [...goalkeepersArray, ...defendersArray, ...midfieldersArray, ...strickersArray]

        await teamPlayersOrdered.map((player, index) => {
          let position;
          let playerInfo = player?.user;

          switch (player.position) {
            case 'GK':
              position = t('positions.0.shortLabel')
              break
            case 'DF':
              position = t('positions.1.shortLabel')
              break
            case 'MF':
              position = t('positions.2.shortLabel')
              break
            case 'ST':
              position = t('positions.3.shortLabel')
              break
          }

          players.push({
            id: playerInfo?.id,
            first_name: playerInfo?.first_name,
            last_name: playerInfo?.last_name,
            photo_path: playerInfo?.photo_path,
            position: position,
            documents: {
              id: playerInfo?.documents?.items[0]?.id,
              id_card_path: playerInfo?.documents?.items[0]?.id_card_path,
              license_path: playerInfo?.documents?.items[0]?.license_path,
              club_rules_path: playerInfo?.documents?.items[0]?.club_rules_path,
              health_questionnaire_path: playerInfo?.documents?.items[0]?.health_questionnaire_path,
              medical_certificate_path: playerInfo?.documents?.items[0]?.medical_certificate_path,
              image_rights_form_path: playerInfo?.documents?.items[0]?.image_rights_form_path
            },
            contribution: {
              id: playerInfo?.contributions?.items[0]?.id,
              paid: playerInfo?.contributions?.items?.length > 0 ? playerInfo?.contributions?.items[0]?.paid : false
            }
          })
        })
      }

      setPlayers(players)
    }
    
    catch(err) { console.log(err) }
  }

  return(
    <div className={classes.container}>
      <CText level="h1" color="light">{t('team_documents.contents.0.title')}</CText>
      <div className={classes.topContainer}>
      </div>

      {loaded === true && userStored?.groups?.includes("ClubAdmins") && teams.length < 1 ?
        <div className={classes.noTeamsInfo}>
          <NoTeamsCard redirectBtn={true} />
        </div>
        :
        null
      }

      {loaded === true && teams.length > 0 ?
        <div style={{backgroundColor: ZONE_BACKGROUND_COLOR, borderRadius: 12, paddingBottom: 25}}>
          <PlayersDocumentsTable data={players} clubId={userStored.club.id} season={season} />
        </div>
        :
        null
      }
    </div>
  )
}
  
export default TeamDocuments;