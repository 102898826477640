import React, { useState } from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';

import countriesReferential from '../../assets/referentials/countries.json'

const LABEL_COLOR = '#f4f4f4';
const COLOR_PRIMARY = '#45d69f';
const TEXTFIELD_COLOR = '#242834';
const iconSize = 28;

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    borderRadius: 8,
    fontSize: "16px !important",
    backgroundColor: TEXTFIELD_COLOR,
    fontFamily: [ 
      '"Helvetica Neue"'
    ].join(',')
  },
  root: {
    color: LABEL_COLOR,
    userSelect: 'none',
    "&$focusedLabel": {
      color: COLOR_PRIMARY
    }
  },
  inputRoot: {
    color: LABEL_COLOR,
    borderRadius: 8,
    background: 'transparent',
    "&&&:before": {
      borderBottom: "none"
    },
    "&:after": {
      borderRadius: 8,
      borderBottom: `2px solid #45d69f`
    },
    '& :-webkit-autofill': {
      fontSize: "16px !important",
      borderRadius: 8,
      "transition-delay": "99999999s"
    },
    '& :-webkit-autofill::first-line': {
      fontSize: "16px !important",
      borderRadius: 8
    }
  },
  focusedLabel: { color: COLOR_PRIMARY },
  dialCodeSelector: {
    color: 'rgba(0, 0, 0, 1)',
    borderRadius: 1,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0.05
  },
  dialCodeSelectorLabel: {
    color: '#f2f2f2',
    fontSize: "16px",
    fontWeight: 700,
    marginLeft: 5,
    fontFamily: [ 
      '"Helvetica Neue"'
    ].join(',')
  },
  dialCodeSelectorIcon: {
    marginTop: 2.5
  },
  dropdown: {
    maxHeight: 180
  }
}));

const MobilePhoneInput = ({ initialCountryCode, initialPhoneNumber, className, onChange }) => {
  const countriesWithDialCode = countriesReferential
  .filter(country => country.dial_code)
  .sort((a, b) => {
    const dialCodeA = parseInt(a.dial_code.replace('+', ''), 10);
    const dialCodeB = parseInt(b.dial_code.replace('+', ''), 10);
    
    return dialCodeA - dialCodeB;
  });

  const initialCountry = countriesWithDialCode.find(country => country.code === initialCountryCode) || countriesWithDialCode[0];

  const [phone, setPhone] = useState(initialPhoneNumber || '');
  const [selectedCountry, setSelectedCountry] = useState(initialCountry);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleCountryChange = (country) => {
    setSelectedCountry(country);

    const phoneDetails = {
      phoneNumber: phone,
      dialCode: country.dial_code,
      countryCode: country.code
    };

    if (onChange) {
      onChange(phoneDetails);
    }

    handleClosePopover();
  };

  const handlePhoneChange = (event) => {
    let value = event.target.value;
    
    if (/^[0-9\b]+$/.test(value) || value === '') {
      if (value.startsWith('0') && value.length > 1) {
          value = value.substring(1);
      }
      setPhone(value);

      const phoneDetails = {
        phoneNumber: value,
        dialCode: selectedCountry.dial_code,
        countryCode: selectedCountry.code
      };

      if (onChange) {
        onChange(phoneDetails);
      }
    }
};


  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'country-popover' : undefined;

  return (
    <>
      <TextField
        label={"Phone Number"}
        variant="filled"
        size="small"
        className={[classes.input, className].join(' ')}
        value={phone}
        onChange={handlePhoneChange}
        InputLabelProps={{
          classes: {
            root: classes.root,
            focused: classes.focusedLabel
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                aria-label="select country"
                size="small"
                className={classes.dialCodeSelector}
                aria-describedby={id}
                onClick={handleOpenPopover}
              >
                <span className={classes.dialCodeSelectorIcon}>{selectedCountry.icon}</span> <span className={classes.dialCodeSelectorLabel}>{selectedCountry.dial_code}</span>
              </IconButton>
            </InputAdornment>
          ),
          classes: {
            root: classes.inputRoot,
          }
        }} 
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        className={classes.dropdown}
      >
        {countriesWithDialCode.map((country) => (
          <MenuItem key={country.code} onClick={() => handleCountryChange(country)}>
            {country.icon} {country.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default MobilePhoneInput;