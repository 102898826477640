import React from 'react';
import Grid from '@mui/material/Grid2';
import { useTranslation } from "react-i18next";
import { CText } from "../../components/Text";
import { useNavigate } from "react-router-dom";
import './footer.styles.css';
import appLogo from "../../assets/images/app_logo.png"

const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="footer">
      <Grid container spacing={4}>
        <Grid size={{ xs: 12, sm: 3 }}>
          <CText className="footer-title" color="light" level="p1">
            <strong>{t('footer.0.title')}</strong>
          </CText>
          <CText className="footer-text" color="light" level="p2">
            <a href="mailto:contact@footballconnexion.com">{t('footer.0.labels.0')}</a>
          </CText>
        </Grid>

        <Grid size={{ xs: 12, sm: 3 }}>
          <CText className="footer-title" color="light" level="p1">
            <strong>{t('footer.1.title')}</strong>
          </CText>

          <div
            className="footer-text"
            onClick={() => navigate('/terms_and_conditions')}
          >
            <CText
              color="light"
              level="p2"
            >
              {t('footer.1.labels.0')}
            </CText>
          </div>

          <div
            className="footer-text"
            onClick={() => navigate('/plans')}
          >
            <CText
              color="light"
              level="p2"
            >
              {t('footer.1.labels.1')}
            </CText>
          </div>
        </Grid>

        <Grid size={{ xs: 12, sm: 3 }}>
          <CText className="footer-title" color="light" level="p1">
            <strong>{t('footer.2.title')}</strong>
          </CText>

          <div
            className="footer-text"
            onClick={() => navigate('/settings/language')}
          >
            <CText
              color="light"
              level="p2"
            >
              {t('footer.2.labels.0')}
            </CText>
          </div>

        </Grid>
        
        <Grid size={{ xs: 12, sm: 3 }}>
          <CText className="footer-text" color="light" level="p2">
          </CText>
        </Grid>

        <div className="footer-logo-container">
          <img src={appLogo} alt="Logo" className="footer-logo" />
          <CText
            fontSize={18}
            fontWeight={700}
            color="light"
          >
            Football Connexion
          </CText>
        </div>
      </Grid>
    </div>
  );
};

export default Footer;