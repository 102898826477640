import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { element } from 'prop-types';

import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const COLOR_PRIMARY = '#45d69f';
const LABEL_COLOR = '#f2f2f2';
const ICON_COLOR = '#f2f2f2';
const TEXTFIELD_COLOR = '#242834';
const iconSize = 28;

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    borderRadius: 8,
    backgroundColor: TEXTFIELD_COLOR
  },
  autoComplete: {
    width: '100%'
  },
  root: {
    color: LABEL_COLOR,
    fontSize: 16,
    "&$focusedLabel": {
      color: COLOR_PRIMARY
    },
    "&:hover": {
      cursor: "pointer"
    }
  },
  icon: {
   color: ICON_COLOR,
  },
  icon: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: 6
  },
  selectedElementIcon: {
    fill: LABEL_COLOR
  },
  inputRoot: {
    height: 48,
    color: LABEL_COLOR,
    borderRadius: 8,
    "&&&:before": {
      borderBottom: "none"
    },
    "&:after": {
      borderRadius: 8,
      borderBottom: `2px solid ${COLOR_PRIMARY}`
    },
    "&:hover": {
      cursor: "pointer",
    }
  },
  menuItem: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    }
  },
  focusedLabel: { color: COLOR_PRIMARY }
}));

const CAutocomplete = ({
  data,
  label,
  type,
  value,
  selectCallback,
  className
}) => {

  const autocompleteRef = useRef(null);
  const classes = useStyles();

  let newData = [];
  if(type == "gender") {
    data.map((elmt, index) => (
      newData.push({ "label": elmt.name, "code": elmt.code })
    ))
  }
  else if(type == "team") {
    data.map((elmt, index) => (
      newData.push({ "label": elmt.name, "id": elmt.id, "logo": elmt.logo, "disabled": elmt.disabled })
    ))
  }
  else {
    data.map((elmt, index) => (
      newData.push({ "code": elmt.code ? elmt.code : null , "label": elmt.label, "icon": elmt.icon})
    ))
  }

  const defaultEmptyValue = {code: '', label: '', icon: ''};

  const isValueEmpty = (value) => {
    return value && value.code === defaultEmptyValue.code && value.label === defaultEmptyValue.label;
  }

  return (
    <Autocomplete
      ref={autocompleteRef}
      className={classes.autoComplete}
      id="selector"
      options={newData}
      getOptionLabel={(option) => `${option.label}`}
      value={isValueEmpty(value) ? null : value}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      onChange={(event, newValue) => {
        if (newValue) {
          if (type === "gender") {
            selectCallback(newValue.code);
          } else if (type === "team") {
            selectCallback(newValue.id);
          } else {
            selectCallback({ "code": newValue.code, "label": newValue.label, "icon": newValue.icon });
          }
        } else {
          selectCallback(null);
        }
      }}
      renderOption={(props, option) => (
        <li {...props} className={`${props.className} ${classes.menuItem}`}>
          <span>
            {option?.icon ? <span className={classes.icon}>{option.icon}</span> : null}
            <span>{option.label}</span>
          </span>
        </li>
      )}
      getOptionDisabled={(option) => option.disabled === true}
      popupIcon={<ArrowDropDownRoundedIcon className={classes.icon} fontSize="medium" />}
      clearIcon={<CloseRoundedIcon className={classes.icon} fontSize="small" />}
      ListboxProps={{ style: { maxHeight: 200 } }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="filled"
          size="small"
          className={[classes.input, className].join(' ')}    
          InputLabelProps={{
            classes: {
              root: classes.root,
              focused: classes.focusedLabel
            },
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: value && value.icon ? <span className={`${classes.icon} ${classes.selectedElementIcon}`}>{value.icon}</span> : null,
            classes: {
              root: classes.inputRoot
            },
            inputProps: {
              ...params.inputProps
            }
          }}
        />
      )}      
    />
  );
}

export default CAutocomplete;