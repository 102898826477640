import { makeStyles } from "@material-ui/core/styles";

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const ZONE_BACKGROUND_COLOR_2 = "#242834";
const COLOR_DARK = "#242834";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
  menuItem: {
    backgroundColor: 'transparent',
    color: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transition: 'filter 0.2s ease-in-out',
    '&:hover': {
      filter: 'brightness(90%)',
    },
  },
  menuIcon: {
    width: 18,
    height: 18,
    display: 'flex',
    alignItems: 'center',
    color: COLOR_DARK,
    marginRight: 6
  },
  selectorIcon: {
    width: 14,
    height: 14,
    fill: COLOR_LIGHT,
    marginLeft: 4,
    flexShrink: 0
  },
  label: {
    fontWeight: '700 !important',
    marginBottom: 8
  }
}));