import React from 'react'
import {makeStyles} from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { CText } from "../Text"
import CButton from "../Button"
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = '#242834';
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;

const NoGame = ({redirectBtn, className, type}) => {
  const useStyles = makeStyles((theme) => ({
    cardZone: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      borderRadius: 25,
      listStyleType: 'none',
      boxSizing: 'border-box',
      padding: 30,
      alignItems: "center"
    },
    titleZone: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    titleIconZone: {
      width: 28,
      height: 28,
      padding: 15,
      backgroundColor: COLOR_DARK,
      borderRadius: '50%',
      marginRight: 10
    },
    titleIcon: {
      width: '100%',
      height: '100%',
      fill: COLOR_PRIMARY
    },
    btn: {
      marginTop: 30
    }
  }))

  const { t } = useTranslation()
  const classes = useStyles()
  let navigate = useNavigate()

  let TITLE;

  if(type === "nextGame") {
    TITLE = t('no_next_game_card.title')
  } if(type === "evalGame") {
    TITLE = t('no_eval_game_card.title')
  }

  return (
    <div className={[classes.cardZone,className].join(" ")}>
      <div className={classes.titleZone}>
        <div className={classes.titleIconZone}>
          <CalendarIcon className={classes.titleIcon} />
        </div>
        <CText level="h3" color="primary">{TITLE}</CText>
      </div>
    </div>
  );
}

export default NoGame