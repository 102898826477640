import { Auth, API } from 'aws-amplify'
import * as queries from '../graphql/queries';

const getTeam = async(teamId) => {
  const getTeamResp = await API.graphql({
    query: queries.getTeam,
    variables: { 
      id: teamId
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS'
  });

  return getTeamResp.data.getTeam;
}

export const updateTeamNameInPath = async(path, currentTeamId, navigate) => {
  let currentTeamInfo;
  if(currentTeamId) {
    currentTeamInfo = await getTeam(currentTeamId);
  } else {
    currentTeamInfo = {
      name: "no_team"
    }
  }

  const currentTeamName = currentTeamInfo.name;
  const regex = /\/teams\/([^/]+)(\/.*)?/;
  const match = path.match(regex);

  if (match) {
    const urlTeamName = decodeURIComponent(match[1]);
    const afterTeamName = match[2] || '';
    const encodedTeamName = encodeURIComponent(currentTeamName);

    if (urlTeamName !== currentTeamName) {
      const newPath = `/teams/${encodedTeamName}${afterTeamName}`;
      navigate(newPath);
    }
  }
};