import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { CText } from "../../../components/Text";
import { Chart } from 'react-chartjs-2';
import './stats.styles.css';

const COLOR_PRIMARY = '#45d69f';
const COLOR_PRIMARY_TRANSPARENT = 'rgba(69, 214, 159, 0.4)';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_PINK_TRANSPARENT = 'rgba(233, 33, 99, 0.4)'
const COLOR_BLUE = "#25a5e6";
const COLOR_BLUE_TRANSPARENT = 'rgba(37, 165, 230, 0.4)';
const COLOR_PURPLE = "#9d44fd";
const COLOR_PURPLE_TRANSPARENT = 'rgba(157, 68, 253, 0.4)';
const COLOR_ORANGE = "#f2a624";
const COLOR_DARK = "#242834";
const COLOR_ORANGE_TRANSPARENT = 'rgba(242, 166, 36, 0.4)'
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;
const MENU_WIDTH = 250;
const BORDER_RADIUS = 18;

const PerformanceEvol = ({ matches, teamId }) => {
  const { t } = useTranslation();

  const [monthlyStats, setMonthlyStats] = useState({ matchCountByMonth: [], pointCountByMonth: [], ratioByMonth: [] });

  useEffect(() => {
    const calculatedStats = calculateMonthlyStats(matches, teamId);
    setMonthlyStats(calculatedStats);
  }, [matches, teamId]);

  const calculateMonthlyStats = (matches, teamId) => {
    const matchCountByMonth = Array(12).fill(0);
    const pointCountByMonth = Array(12).fill(0);
    const ratioByMonth = Array(12).fill(null);

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    matches.forEach((match) => {
      const matchDate = new Date(match.date);
      const matchMonth = matchDate.getMonth();
      const matchYear = matchDate.getFullYear();

      const currentSlidingMonth = (currentMonth + 5) % 12;
      const matchSlidingMonth = (matchMonth + 5) % 12;

      if (matchYear > currentYear || (matchYear === currentYear && matchSlidingMonth > currentSlidingMonth)) {
        return;
      }

      let matchPoints = 0;
      if (match.home_team_id === teamId && match.home_team_goals > match.away_team_goals) {
        matchPoints = 3;
      } else if (match.away_team_id === teamId && match.away_team_goals > match.home_team_goals) {
        matchPoints = 3;
      } else if (match.home_team_goals === match.away_team_goals) {
        matchPoints = 1;
      }

      matchCountByMonth[matchSlidingMonth] += 1;
      pointCountByMonth[matchSlidingMonth] += matchPoints;
    });

    // Calculer le ratio de points par match joué pour chaque mois
    for (let i = 0; i < 12; i++) {
      if (matchCountByMonth[i] > 0) {
        ratioByMonth[i] = pointCountByMonth[i] / matchCountByMonth[i];
      } else {
        ratioByMonth[i] = (i <= (currentMonth + 5) % 12) ? 0 : null; // Affiche 0 ou null selon que le mois soit passé ou futur
      }
    }

    return {
      matchCountByMonth,
      pointCountByMonth,
      ratioByMonth
    };
  };

  const data = {
    labels: [
      t('common.months.7'), t('common.months.8'), t('common.months.9'), t('common.months.10'),
      t('common.months.11'), t('common.months.0'), t('common.months.1'), t('common.months.2'),
      t('common.months.3'), t('common.months.4'), t('common.months.5'), t('common.months.6')
    ],
    datasets: [
      {
        type: 'line',
        label: `${t('team_stats.7.labels.0')}`,
        fill: false,
        lineTension: 0.1,
        backgroundColor: COLOR_PURPLE,
        borderColor: COLOR_PURPLE,
        pointBorderColor: COLOR_PURPLE,
        pointBackgroundColor: COLOR_PURPLE,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: COLOR_PURPLE,
        pointHoverBorderColor: COLOR_PURPLE_TRANSPARENT,
        pointHoverBorderWidth: 10,
        pointRadius: 4,
        pointHitRadius: 10,
        data: monthlyStats.ratioByMonth
      },
      {
        type: 'bar',
        label: `${t('team_stats.7.labels.1')}`,
        data: monthlyStats.pointCountByMonth,
        backgroundColor: COLOR_PRIMARY,
        borderColor: COLOR_PRIMARY
      },
      {
        type: 'bar',
        label: `${t('team_stats.7.labels.2')}`,
        data: monthlyStats.matchCountByMonth,
        backgroundColor: COLOR_BLUE,
        borderColor: COLOR_BLUE
      }
    ],
  };

  let options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2,
    scales: {
      y: {
        ticks: {
          stepSize: 1
        }
      }
    }
  };

  return (
    <div className="stats-zone">
      <CText className="title" level="h3" fontWeight="700" color="light">
        {t('team_stats.7.title')}
      </CText>
      <div className="chart-container">
        <div className="chart-wrapper">
          <Chart data={data} options={options} className="stats-elmt-4" />
        </div>
      </div>
    </div>
  );
};

export default PerformanceEvol;