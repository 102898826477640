import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@mui/material/Skeleton';

const COLOR_LIGHT = "#f4f4f4";
const COLOR_DARK = "#242834";
const COLOR_PRIMARY = "#45d69f";
const COLOR_RED = "#fc3c2e";
const COLOR_BLUE = "#25a5e6";
const COLOR_PINK= "#e92163";
const COLOR_PURPLE = "#9d44fd";
const COLOR_ORANGE= "#f2a624";
const FONT_FAMILY='Montserrat';


const LABEL_COLOR = '#101116';
const TEXTFIELD_COLOR = '#242834';
const iconSize = 28;


let fontLevel, fontColor, fontStyle;

const CText = ({
    children,
    level,
    label,
    color,
    fontWeight,
    fontSize,
    className,
    loading
  }) => {
  const useStyles = makeStyles((theme) => ({
    text: {
      fontFamily: FONT_FAMILY
    },
    h1: {
      fontWeight: '600',
      lineHeight: 1.167,
      letterSpacing: '0em',
      [theme.breakpoints.up('xs')]: { fontSize: 20 },
      [theme.breakpoints.up('sm')]: { fontSize: 22 }
    },
    h2: {
      fontWeight: '600',
      lineHeight: 1.167,
      letterSpacing: '0em',
      [theme.breakpoints.up('xs')]: { fontSize: 18 },
      [theme.breakpoints.up('sm')]: { fontSize: 20 }
    },
    h3: {
      fontWeight: '600',
      lineHeight: 1.167,
      letterSpacing: '0em',
      [theme.breakpoints.up('xs')]: { fontSize: 16 },
      [theme.breakpoints.up('sm')]: { fontSize: 18 }
    },
    p1: {
      fontWeight: '500',
      lineHeight: 1.4,
      letterSpacing: '0.007em',
      [theme.breakpoints.up('xs')]: { fontSize: 14 },
      [theme.breakpoints.up('sm')]: { fontSize: 16 }
    },
    p2: {
      fontWeight: '400',
      lineHeight: 1.4,
      letterSpacing: '0.007em',
      [theme.breakpoints.up('xs')]: { fontSize: 13 },
      [theme.breakpoints.up('sm')]: { fontSize: 15 }
    },
    p3: {
      fontWeight: '400',
      lineHeight: 1.4,
      letterSpacing: '0.007em',
      [theme.breakpoints.up('xs')]: { fontSize: 12 },
      [theme.breakpoints.up('sm')]: { fontSize: 14 }
    },
    light: {
      color: COLOR_LIGHT
    },
    dark: {
      color: COLOR_DARK
    },
    primary: {
      color: COLOR_PRIMARY
    },
    red: {
      color: COLOR_RED
    },
    blue: {
      color: COLOR_BLUE
    },
    pink: {
      color: COLOR_PINK
    },
    purple: {
      color: COLOR_PURPLE
    },
    orange: {
      color: COLOR_ORANGE
    }
  }));
  const classes = useStyles();

  return (
    <>
      { (loading === undefined || loading === false) ?
      <Typography
        style={{ fontSize: fontSize, fontWeight: fontWeight }}
        className={[
          classes.text,
          level == "h1" ? classes.h1 : null,
          level == "h2" ? classes.h2 : null,
          level == "h3" ? classes.h3 : null,
          level == "p1" ? classes.p1 : null,
          level == "p2" ? classes.p2 : null,
          level == "p3" ? classes.p3 : null,
          color == "light" ? classes.light : null,
          color == "dark" ? classes.dark : null,
          color == "primary" ? classes.primary : null,
          color == "red" ? classes.red : null,
          color == "blue" ? classes.blue : null,
          color == "pink" ? classes.pink : null,
          color == "purple" ? classes.purple : null,
          color == "orange" ? classes.orange : null,
          className
        ].join(" ")}
      >
        {label} {children}
      </Typography>
      : 
      <Skeleton 
        variant="rounded"
        width={200}
        className={[
          classes.text,
          level == "h1" ? classes.h1 : null,
          level == "h2" ? classes.h2 : null,
          level == "h3" ? classes.h3 : null,
          level == "p1" ? classes.p1 : null,
          level == "p2" ? classes.p2 : null,
          level == "p3" ? classes.p3 : null,
          className
        ].join(" ")}
      />
    }
    </>
  );
};

export { CText }