import React, { useState, useEffect } from 'react'
import TextInput from '../../components/TextInput.js'
import CButton from "../../components/Button.js"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Auth } from 'aws-amplify'
import { CText } from "../../components/Text"
import { useDispatch } from 'react-redux'
import { useStylesRecover } from './recover.styles.js';

const COLOR_PRIMARY = '#45d69f';

const PwdRecoveringInitiate = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const classes = useStylesRecover();
  
  const [email, setEmail] = useState(null);

  // Click Buttons
  const initiatePwdRecovering = async() => {
    if(email) {
      // [EVOL] Set erreur= le champs email est manquant

      // [EVOL] faire un ceck regex du format de l'email => Set erreur= le format de l'email est incorrect
      try {
        await Auth.forgotPassword(email)
        //.then(data => console.log(data))
        .catch(err => console.log(err)); // [EVOL] si reponse 400 l'email renseigné n'est pas bon !
        navigate(`/recover/code`, { state: {email: email}  });
        window.scrollTo(0, 0);
      } catch (error) {
        console.log('Error initiate: ', error);
      }
    }
  }

  // Infos Callbacks
  const emailCb = (data) =>{ setEmail(data) };

  return(
    <div className={classes.container}>
      <div className={classes.formZone}>
        <div className={classes.titleZone}>
          <CText level="h3" fontWeight={700} color="primary" label={`${t('recover.initiate.title')}`} />
        </div>
        <TextInput className={classes.lastInput} label={`${t('recover.initiate.input.0.label')}*`} inputCallback={emailCb} />
        <CButton
          className={classes.formBtn}
          label={t('recover.initiate.button.0.label')}
          height={38}
          type="contained"
          loader={true}
          onClick={async () => await initiatePwdRecovering()}
        />
      </div>
    </div>
  );
}

export default PwdRecoveringInitiate;