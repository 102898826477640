import React, { useState, useEffect } from 'react';
import Arrow from './Arrow';
import Player from './Player';
import { useTheme } from '@material-ui/core/styles'; 
import { useStyles } from './styles';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import { useTranslation } from "react-i18next";
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import CModal from "../Modal";
import TextInput from '../../components/TextInput';

const COLOR_PRIMARY = '#45d69f';
const COLOR_PRIMARY_TRANSPARENT = 'rgba(69, 214, 159, 0.4)';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = '#242834';
const COLOR_RED = "#fc3c2e";
const COLOR_YELLOW = "#EDA247";

const CANVAS_MAX_WIDTH = 750;
const CANVAS_MAX_HEIGHT = 550;

const ObjectModal = ({ modalOpenCallback, objectDetails, onObjectDetailsChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleTitleChange = (data) => {
    const updatedObjectDetails = { ...objectDetails, title: data };
    // Appeler la fonction de rappel pour transmettre l'objet mis à jour
    onObjectDetailsChange(updatedObjectDetails);
  };

  const handleLabelChange = (data) => {
    const updatedObjectDetails = { ...objectDetails, label: data };
    // Appeler la fonction de rappel pour transmettre l'objet mis à jour
    onObjectDetailsChange(updatedObjectDetails);
  };

  return (
    <div className={classes.modalContainer}>
      <TextInput
        className={classes.input}
        label={t('canvas.modals.0.inputs.1.label')}
        defaultValue={objectDetails.title}
        inputCallback={(data) => handleTitleChange(data)}
      />
      <TextInput
        className={classes.input}
        label={t('canvas.modals.0.inputs.0.label')}
        defaultValue={objectDetails.label}
        inputCallback={(data) => handleLabelChange(data)}
      />
    </div>
  );
};


const PlayerIcon = ({ color }) => {
  return(
    <div style={{width: 20, height: 20, backgroundColor: color ? color: COLOR_LIGHT, borderRadius: '50%'}}>
    </div>
  )
}

const ArrowIcon = ({type}) => {
  const adjustedLength = 50;
  const arrowHeight = 17;
  const scale = 0.8;
  const startScaledX = 0;
  const startScaledY = 0;
  const endScaledX = 250;
  const endScaledY = -30;

  return(
    <svg
      style={{
        width: adjustedLength / scale,
        height: arrowHeight * scale,
        transform: `rotate(${Math.atan2(endScaledY - startScaledY, endScaledX - startScaledX) * 180 / Math.PI}deg) scale(${scale})`
      }}
    >
      {type === "arrow-dot" ? (
        <line
          x1={(adjustedLength - (15 * scale)) / scale}
          y1={(arrowHeight / 2) * scale}
          x2="0"
          y2={(arrowHeight / 2) * scale}
          stroke="black"
          strokeWidth={4 / scale}
          strokeDasharray="8, 7"
          strokeLinecap="round"
        />
      ) : (
        <line
          x1={(adjustedLength - (15 * scale)) / scale}
          y1={(arrowHeight / 2) * scale}
          x2="0"
          y2={(arrowHeight / 2) * scale}
          stroke="black"
          strokeWidth={4 / scale}
          strokeLinecap="round"
        />
      )}
      <path d={`M${(adjustedLength - (15 * scale)) / scale},${(arrowHeight / 2 - (7 / scale)) * scale} L${(adjustedLength - (2 * scale)) / scale},${arrowHeight / 2 * scale} L${(adjustedLength - (15 * scale)) / scale},${(arrowHeight / 2 + (7 / scale)) * scale} z`} fill="black" />
    </svg>
  )
}

const Canvas2 = ({ defaultValue, onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [objects, setObjects] = useState(defaultValue ||[]);
  const [selectedObjectId, setSelectedObjectId] = useState(null);
  const [selectedObjectDetails, setSelectedObjectDetails] = useState({});
  const [canvasScale, setCanvasScale] = useState(1);
  const [canvasWidth, setCanvasWidth] = useState(CANVAS_MAX_WIDTH);
  const [canvasHeight, setCanvasHeight] = useState(CANVAS_MAX_HEIGHT);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const canvasContainer = document.getElementById('canvas-container');
      if (canvasContainer) {
        const { clientWidth, clientHeight } = canvasContainer;
        setCanvasWidth(clientWidth);

        const ratio = clientWidth / CANVAS_MAX_WIDTH;
        setCanvasScale(ratio);
        setCanvasHeight((clientWidth / CANVAS_MAX_WIDTH) * CANVAS_MAX_HEIGHT);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseDown = (e) => {
    const isInsideSVG = e.target.closest('svg'); // Vérifie si l'élément cliqué est à l'intérieur d'un élément SVG
    const clickedElementId = e.target.id;
    if (!isInsideSVG && !clickedElementId.startsWith('player-')) {
      setSelectedObjectId(null); // Réinitialiser l'index de l'objet
    }
  };

  const handleCanvasContextMenu = (e) => {
    e.preventDefault();
  };

  const handleAddObjectClick = (type) => {
    if (type === 'player' || type === 'opponent') {
      const color = type === 'player' ? COLOR_BLUE : COLOR_YELLOW;
      const newPlayer = {
        id: Date.now(),
        type: type,
        left: 100,
        top: 100,
        color: color
      };
      setObjects([...objects, newPlayer]);
      onChange([...objects, newPlayer])
    } else if (type === 'arrow' || type === 'arrow-dot') {
      const startX = 100; // Définir la coordonnée x de départ pour la flèche
      const startY = 100; // Définir la coordonnée y de départ pour la flèche
      const endX = 170; // Définir la coordonnée x de fin pour la flèche
      const endY = 170; // Définir la coordonnée y de fin pour la flèche
      const newArrow = {
        id: Date.now(),
        type: type,
        start: { x: startX, y: startY },
        end: { x: endX, y: endY }
      };
      setObjects([...objects, newArrow]);
      onChange([...objects, newArrow]);
    }
  };

  const handleObjectSelect = (id) => {
    setSelectedObjectId(id);
  };

  const handlePlayerMove = (id, newLeft, newTop) => {
    setObjects(objects.map(obj => (obj.id === id ? { ...obj, left: newLeft, top: newTop } : obj)));
    onChange(objects.map(obj => (obj.id === id ? { ...obj, left: newLeft, top: newTop } : obj)));
  };

  const onArrowMove = (updatedArrow) => {
    setObjects(objects.map(obj => {
      if (obj.id === updatedArrow.id) {
        return updatedArrow;
      } else {
        return obj;
      }
    }));

    onChange(objects.map(obj => {
      if (obj.id === updatedArrow.id) {
        return updatedArrow;
      } else {
        return obj;
      }
    }));
  };

  const handleDeleteObject = (event) => {
    // Supprimer l'objet avec l'ID sélectionné
    setObjects(objects.filter(obj => obj.id !== selectedObjectId));
    onChange(objects.filter(obj => obj.id !== selectedObjectId));
    setSelectedObjectId(null);
  };

  useEffect(() => {
    const handleDeleteKeyPress = (event) => {
      if (!modalOpen && (event.key === 'Delete' || event.key === 'Backspace')) {
        handleDeleteObject();
      }
    };

    if (selectedObjectId) {
      const selectedObject = objects.find(obj => obj.id === selectedObjectId);
      setSelectedObjectDetails(selectedObject);
    } else {
      setSelectedObjectDetails(null);
    }
  
    // Ecouteur d'événements pour la touche Supprimer
    window.addEventListener('keydown', handleDeleteKeyPress);
    
    return () => {
      window.removeEventListener('keydown', handleDeleteKeyPress);
    };
  }, [selectedObjectId, objects]);

  const canvasMenu = [
    {
      icon: <PlayerIcon color={COLOR_BLUE} />,
      label: t('canvas.buttons.0.label'),
      type: 'player'
    },
    {
      icon: <PlayerIcon color={COLOR_YELLOW} />,
      label: t('canvas.buttons.1.label'),
      type: 'opponent'
    },
    {
      icon: <ArrowIcon />,
      label: t('canvas.buttons.2.label'),
      type: 'arrow'
    },
    {
      icon: <ArrowIcon type='arrow-dot' />,
      label: t('canvas.buttons.3.label'),
      type: 'arrow-dot'
    }
  ];

  const TAB = [
    {
      label: t('canvas.tabs.0.labels.0')
    },
    {
      label: t('canvas.tabs.0.labels.1')
    }
  ];

  const handleObjectDetailsChange = (updatedObjectDetails) => {
    // Rechercher l'index de l'objet avec l'ID correspondant dans la liste des objets
    const objectIndex = objects.findIndex(obj => obj.id === updatedObjectDetails.id);
    
    // Si l'objet est trouvé, le remplacer par l'objet mis à jour
    if (objectIndex !== -1) {
      const updatedObjects = [...objects];
      updatedObjects[objectIndex] = updatedObjectDetails;
      setObjects(updatedObjects);
      onChange(updatedObjects);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.tacticalSchemaContainer}>
        <div className={classes.lateralMenu}>
          {canvasMenu.map((elmt, index) => (
            <Tooltip key={index} title={`${elmt.label}`} placement="right">
              <div
                className={classes.canvasMenuBtn}
                onClick={() => handleAddObjectClick(elmt.type)}
              >
                {elmt.icon}
              </div>
            </Tooltip>
          ))}
        </div>
        <div
          id="canvas-container"
          style={{ position: 'relative', width: '100%', maxWidth: CANVAS_MAX_WIDTH, height: canvasHeight, overflow: 'hidden', border: '1px solid black' }}
          onMouseDown={handleMouseDown}
          onContextMenu={handleCanvasContextMenu}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: canvasWidth,
              height: canvasHeight,
              transformOrigin: 'top left',
            }}
          >
            {objects.map((object) => (
              object.type === 'player' || object.type === 'opponent' ? (
                <Player
                  key={object.id}
                  player={object}
                  color={object.color}
                  isSelected={object.id === selectedObjectId}
                  onSelect={handleObjectSelect}
                  onPlayerMove={handlePlayerMove}
                  canvasScale={canvasScale}
                  canvasWidth={canvasWidth}
                  canvasHeight={canvasHeight}
                />
              ) : (
                <Arrow
                  key={object.id}
                  arrow={object}
                  type={object.type}
                  isSelected={object.id === selectedObjectId}
                  onSelect={handleObjectSelect}
                  onArrowMove={onArrowMove}
                  canvasScale={canvasScale}
                />
              )
            ))}
          </div>
        </div>
      </div>
      {selectedObjectId && (
        <div className={classes.fabContainer}>
          {(objects.find(obj => obj.id === selectedObjectId)?.type === 'player' || objects.find(obj => obj.id === selectedObjectId)?.type === 'opponent') && (
            <Fab
              className={classes.fabEdit}
              color={"primary"}
              onClick={() => setModalOpen(true)}
            >
              <EditIcon className={classes.fabIcon} />
            </Fab>
          )}
          <Fab
            color={"pink"}
            onClick={() => handleDeleteObject()}
          >
            <TrashIcon className={classes.fabIcon} />
          </Fab>
        </div>
      )}

      <CModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        content={
          <ObjectModal
            objectDetails={selectedObjectDetails}
            onObjectDetailsChange={handleObjectDetailsChange}
          />
        } 
      >
      </CModal>
    </div>
  );
};

export default Canvas2;