import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { CText } from "../../../components/Text";
import { Auth, API } from 'aws-amplify';
import CountUp from 'react-countup';
import { Chart } from 'react-chartjs-2';
import './stats.styles.css';

const COLOR_PRIMARY = '#45d69f';
const COLOR_PRIMARY_TRANSPARENT = 'rgba(69, 214, 159, 0.4)';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_PINK_TRANSPARENT = 'rgba(233, 33, 99, 0.4)'
const COLOR_BLUE = "#25a5e6";
const COLOR_BLUE_TRANSPARENT = 'rgba(37, 165, 230, 0.4)';
const COLOR_PURPLE = "#9d44fd";
const COLOR_PURPLE_TRANSPARENT = 'rgba(157, 68, 253, 0.4)';
const COLOR_ORANGE = "#f2a624";
const COLOR_DARK = "#242834";
const COLOR_ORANGE_TRANSPARENT = 'rgba(242, 166, 36, 0.4)'
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;
const MENU_WIDTH = 250;
const BORDER_RADIUS = 18;

const BestSeries = ({ teamId, season }) => {
  const { t } = useTranslation();

  const [counterOn, setCounterOn] = useState(false);
  const [longestWinningStreak, setLongestWinningStreak] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      getLongestWinningStreak(teamId, season)
    }
  
    fetchData();

    const pageHeight = window.innerHeight
    const y = myRef.current.getBoundingClientRect().top;
    if(pageHeight >= y) {
      setCounterOn(true)
    } else {
      window.addEventListener('scroll', onScroll, { passive: true });
    }

  }, []);

  const getLongestWinningStreak = async (teamId, season) => {
    try {
      let resp = await fetch(`${process.env.REACT_APP_REST_API_BASE_URL}/teams/v1/teams/${teamId}/longest-winning-streak?season=${season}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
      });
      const respBody = await resp.json()

      setLongestWinningStreak(respBody.count)

    } catch (error) {
      console.error("Erreur :", error);
    }
  };

  const myRef = React.useRef();

  const onScroll = () => {
    const pageHeight = window.innerHeight
    const y = myRef.current.getBoundingClientRect().top;
    if(pageHeight >= y) {
      setCounterOn(true)
    }
  };

  return(
    <div className="stats-zone" ref={myRef}>
      <CText className="stats-title" level="h3" fontWeight="700" color="light">
        {t('team_stats.6.title')}
      </CText>
      <div className="stats-elmt-3">
        <CText className="stats-series" level="h1" label={counterOn ? <CountUp end={longestWinningStreak} duration={0.6} delay={0.5} /> : null }/>
      </div>
    </div>
  )
}

export default BestSeries;