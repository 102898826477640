import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/minus.svg';

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_RED = "#fc3c2e"
const COLOR_YELLOW = "#EDA247"
const COLOR_BLUE = "#25a5e6";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const ZONE_BACKGROUND_COLOR_2 = "#242834";
const BUTTON_WIDTH = 100;
const COLOR_DARK = "#242834";
const COLOR_ORANGE= "#f2a624";

const useStyles = makeStyles((theme) => ({
  btn: {
    all: 'unset',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    width: 30,
    borderRadius: '50%',
    cursor: 'pointer',
    "&&:hover": {
      filter: 'brightness(90%)'
    }
  },
  btnDecrement: {
    backgroundColor: COLOR_PINK
  },
  btnDecrementDisabled: {
    backgroundColor: COLOR_RED,
    filter: 'brightness(50%)',
    cursor: 'default',
    "&&:hover": {
      filter: 'brightness(50%)'
    }
  },
  btnIncrement: {
    backgroundColor: COLOR_PRIMARY
  },
  input: {
    [theme.breakpoints.up('xs')]: {
      width: 45,
      height: 35,
    },
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 8,
    backgroundColor: COLOR_DARK,
    fontSize: 15,
    color: COLOR_LIGHT,
    border: 'none',
    outline: 'none',
    textAlign: 'center',
    caretColor: 'transparent',
    'border': `2px solid ${COLOR_DARK}`,
    '&:focus': {
      'border': `2px solid ${COLOR_PRIMARY}`,
      outline: 'none !important'
    },
    '::-moz-selection': {
      background: '#AFAFAF'
    }
  },
  icon: {
    height: '25px',
    width: '25px',
    stroke: COLOR_DARK
  }
}));

const CounterInput = ({ className, onChange, value }) => {
  const classes = useStyles();
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (value) {
      setCount(value);
    }
  }, [value]);

  const handleIncrement = () => {
    setCount(prevCount => {
      const newCount = prevCount + 1;
      onChange(newCount);
      return newCount;
    });
  };

  const handleDecrement = () => {
    setCount(prevCount => {
      const newCount = Math.max(prevCount - 1, 0);
      onChange(newCount);
      return newCount;
    });
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const intValue = parseInt(value, 10);

    if (value === '') {
      setCount(0);
      onChange(0);
    } else if (!isNaN(intValue) && intValue >= 0) {
      setCount(intValue);
      onChange(intValue);
    }
  };

  const handleFocus = (event) => {
    event.target.select();
  };

  const handleBlur = () => {
    if (count === '') {
      setCount(0);
      onChange(0);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <button
        className={[
          classes.btn,
          classes.btnDecrement,
          count <= 0 ? classes.btnDecrementDisabled : ''
        ].join(' ')}
        onClick={handleDecrement}
        disabled={count <= 0}
      >
        <MinusIcon className={classes.icon} />
      </button>
      <input
        className={classes.input}
        type="text"
        value={count.toString()}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus} 
      />
      <button
        className={[classes.btn, classes.btnIncrement].join(' ')}
        onClick={handleIncrement}
      >
        <PlusIcon className={classes.icon} />
      </button>
    </div>
  );
};

export default CounterInput;