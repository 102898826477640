import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { CText } from "../../../components/Text";
import { Line } from 'react-chartjs-2';
import './stats.styles.css';

const COLOR_PRIMARY = '#45d69f';
const COLOR_PRIMARY_TRANSPARENT = 'rgba(69, 214, 159, 0.4)';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_PINK_TRANSPARENT = 'rgba(233, 33, 99, 0.4)'
const COLOR_BLUE = "#25a5e6";
const COLOR_BLUE_TRANSPARENT = 'rgba(37, 165, 230, 0.4)';
const COLOR_PURPLE = "#9d44fd";
const COLOR_PURPLE_TRANSPARENT = 'rgba(157, 68, 253, 0.4)';
const COLOR_ORANGE = "#f2a624";
const COLOR_DARK = "#242834";
const COLOR_ORANGE_TRANSPARENT = 'rgba(242, 166, 36, 0.4)'
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;
const MENU_WIDTH = 250;
const BORDER_RADIUS = 18;

const GoalsEvol = ({ matches, teamId }) => {
  const { t } = useTranslation();

  const [goalsByWeek, setGoalsByWeek] = useState([]);
  const [goalsConcededByWeek, setGoalsConcededByWeek] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const goals = sortGoalsByWeek(matches, teamId);
    const goalsConceded = sortGoalsByWeek(matches, teamId, true);
    setGoalsByWeek(goals);
    setGoalsConcededByWeek(goalsConceded);
    let maxWeek = getMaxWeek(matches)
    maxWeek > 10 ? maxWeek = maxWeek : maxWeek = 10
    const labels = [];
    for (let i = 1; i <= maxWeek; i++) {
      labels.push(`${t('team_stats.0.labels.2')} ${i}`);
    }
    setLabels(labels);
  }, [matches, teamId]);

  const sortGoalsByWeek = (matches, teamId, isGoalsConceded = false) => {
    const goalsByWeek = [];
  
    // Filtrage des matchs par ID de l'équipe
    const filteredMatches = matches.filter((match) => match.home_team_id === teamId || match.away_team_id === teamId);
  
    // Tri des matchs filtrés par ordre croissant des semaines
    const sortedMatches = filteredMatches.sort((a, b) => a.week - b.week);
  
    // Parcours des matchs et ajout des buts marqués ou concédés dans le tableau par semaine
    sortedMatches.forEach((match) => {
      const { week, home_team_goals, away_team_goals } = match;
  
      // Vérification s'il y a des semaines manquantes entre les matchs
      if (goalsByWeek.length < week - 1) {
        const missingWeeks = week - 1 - goalsByWeek.length;
        for (let i = 0; i < missingWeeks; i++) {
          goalsByWeek.push(null);
        }
      }
  
      const goals = isGoalsConceded ? away_team_goals : home_team_goals;
      goalsByWeek.push(goals);
    });
  
    return goalsByWeek;
  };

  const getMaxWeek = (matches) => {
    let maxWeek = 0;
  
    matches.forEach((match) => {
      if (match.week > maxWeek) {
        maxWeek = match.week;
      }
    });
  
    return maxWeek;
  };
  
  
  const data = {
    labels: labels,
    datasets: [
      {
        label: ` ${t('team_stats.0.labels.0')}`,
        fill: false,
        lineTension: 0.1,
        backgroundColor: COLOR_PRIMARY,
        borderColor: COLOR_PRIMARY,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 10.0,
        borderJoinStyle: 'miter',
        pointBorderColor: COLOR_PRIMARY,
        pointBackgroundColor: COLOR_PRIMARY,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: COLOR_PRIMARY,
        pointHoverBorderColor: COLOR_PRIMARY_TRANSPARENT,
        pointHoverBorderWidth: 10,
        pointWidth: 100,
        pointRadius: 4,
        pointHitRadius: 10,
        data: goalsByWeek
      },
      {
        label: ` ${t('team_stats.0.labels.1')}`,
        fill: false,
        lineTension: 0.1,
        backgroundColor: COLOR_PINK,
        borderColor: COLOR_PINK,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 10.0,
        borderJoinStyle: 'miter',
        pointBorderColor: COLOR_PINK,
        pointBackgroundColor: COLOR_PINK,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: COLOR_PINK,
        pointHoverBorderColor: COLOR_PINK_TRANSPARENT,
        pointHoverBorderWidth: 10,
        pointWidth: 100,
        pointRadius: 4,
        pointHitRadius: 10,
        data: goalsConcededByWeek
      }
    ]
  };

  let graphOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2,
    scales: {
      y: {
        ticks: {
          stepSize: 1
        }
      }
    }
  };

  return (
    <div className="stats-zone">
      <CText className="stats-title" level="h3" fontWeight="700" color="light">
        {t('team_stats.0.title')}
      </CText>
      <div className="chart-container">
        <div className="chart-wrapper">
          <Line data={data} options={graphOptions} className="stats-elmt-4" />
        </div>
      </div>
    </div>
  );
}

export default GoalsEvol;