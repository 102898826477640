import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';
import { sessionHandler } from "../utils/auth";

const useUserFromLocalStorage = () => {
  const persistedState = JSON.parse(localStorage.getItem('persist:root'));
  return persistedState ? JSON.parse(persistedState.user) : null;
}

export const PrivateRoute = ({ children }) => {
  const userStored = useUserFromLocalStorage();
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchData = async () => {
      const { isAuthenticated, groups, club } = userStored;

      if (!isAuthenticated) {
        navigate('/');
      } else if (groups.includes("ClubAdmins")) {
        if(!club?.id && window.location.pathname !== '/clubs/new') {
          navigate('/clubs/new');
        } else if(club?.id) {
          const today = new Date().toISOString().split('T')[0];
          const filter = {
            club_id: { eq: club?.id },
            paid: {eq: true},
            or: [
              { end_date: { attributeExists: false } },
              { end_date: { ge: today } }
            ]
          }
          let appSubscriptions = await API.graphql({ query: queries.listAppSubscriptions, variables: { filter: filter }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
          appSubscriptions = appSubscriptions.data.listAppSubscriptions.items;
  
          if (window.location.pathname !== '/subscriptions' && appSubscriptions.length < 1) {
            navigate('/subscriptions');
          }
        }
      }
    }

    fetchData();
    window.scrollTo(0, 0);
    window.history.scrollRestoration = 'manual';
  }, [userStored]);

  return children;
};

export const PublicRoute = ({ children }) => {
  const userStored = useUserFromLocalStorage();

  useEffect(() => {
    if (userStored?.isAuthenticated) {
      // user is authenticated
      sessionHandler();
    }
    window.scrollTo(0, 0);
    window.history.scrollRestoration = 'manual';
  }, [userStored]);

  return children;
};