import React, { useState, useEffect } from 'react'
import TextInput from '../../components/TextInput.js'
import CButton from "../../components/Button.js"
import { makeStyles } from "@material-ui/core/styles"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Auth } from 'aws-amplify'
import { CText } from "../../components/Text"
import { useDispatch } from 'react-redux'
import InputCode from '../../components/Input/InputCode'
import { useStylesRecover } from './recover.styles.js';

const COLOR_PRIMARY = '#45d69f';

const CODE_LENGTH = 6;

const PwdRecoveringCode = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const classes = useStylesRecover();
  
  const [code, setCode] = useState(null);

  // Click Buttons
  const initiatePwdRecovering = async() => {
    if(code) { //[EVOL] set erreur
      try {
        navigate(`/recover/password`, { state: { email: location.state.email, code: code}  });
        window.scrollTo(0, 0);
      } catch (error) {
        console.log('Error initiate: ', error);
      }
    }
  }
  //location.state.email

  // Infos Callbacks
  const codeCb = (data) =>{ setCode(data) };

  return(
    <div className={classes.container}>
      <div className={classes.formZone}>
        <div className={classes.titleZone}>
          <CText level="h3" fontWeight={700} color="primary" label={`${t('recover.code.title')}`} />
        </div>
        <InputCode
          length={CODE_LENGTH}
          onComplete={codeCb}
        />
        <CButton
          className={classes.formBtn}
          label={t('recover.code.button.0.label')}
          height={38}
          type="contained"
          loader={true}
          onClick={async () => await initiatePwdRecovering()}
        />
      </div>
    </div>
  );
}

export default PwdRecoveringCode;