import React from 'react';
import { useTranslation } from "react-i18next";
import { CText } from "../../components/Text";
import './terms-and-conditions.styles.css';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/pt';

const TermsAndConditions = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const currentLang = i18n.language;
  moment.locale(currentLang);

  const localizedDateFormat = moment.localeData().longDateFormat('L');

  const date = moment('2024-10-29').format(localizedDateFormat);

  const sections = t('terms_and_conditions.sections', { returnObjects: true });

  return (
    <div className="terms-and-conditions-container">
      <CText
        className="terms-and-conditions-title"
      >
        {t('terms_and_conditions.title')}
      </CText>

      <CText
        level="p2"
        color="light"
        className="terms-and-conditions-date"
      >
        <span style={{ fontStyle: 'italic', marginRight: 5 }}>
          {t('terms_and_conditions.contents.0.label')}
        </span>
        {date}
      </CText>

      <CText
        className="terms-and-conditions-meta"
        level="p1"
        color="light"
      >
        {t('terms_and_conditions.meta')}
      </CText>
      
      {sections.map((section) => (
        <div key={section.id} className="terms-and-conditions-section">
          <CText
            level="h2"
            color="light"
            className="terms-and-conditions-sectionTitle"
          >
            {section.title}
          </CText>
          {section.content &&
            <CText
              level="p1"
              color="light"
              className="terms-and-conditions-section-content"
            >
              {section.content}
            </CText>
          }
          {section.items && (
            <ul className="terms-and-conditions-items-list">
              {section.items.map((item, index) => {
                const [highlightedText, text] = item.split(/:(.+)/);

                return (
                  <li key={index} className="terms-and-conditions-item">
                    <span className="terms-and-conditions-bullet">•</span>
                    <CText level="p1" color="light" className="terms-and-conditions-section-content" style={{ display: 'inline' }}>
                      <span className="terms-and-conditions-section-content-highlighted" style={{ display: 'inline' }}>
                        {highlightedText}:
                      </span>
                      {text}
                    </CText>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};

export default TermsAndConditions;