import React from 'react';
import ProfilePicture from "../../../components/ProfilePicture/index";
import { CText } from "../../../components/Text";
import './row-player.styles.css';
import { ReactComponent as MedalGoldIcon } from '../../../assets/icons/medal-gold.svg';

export const TopStatsRow = ({ player, value, labelKey, index }) => {
  let medalClass = '';
  if (index === 0) medalClass = 'gold';
  else if (index === 1) medalClass = 'silver';
  else if (index === 2) medalClass = 'bronze';

  return (
    <div className="row-player">
      <div className="row-player-photo-container">
        <ProfilePicture className="row-player-photo" src={player?.photo_path} />
        {index < 3 && (
          <MedalGoldIcon className={`medal ${medalClass}`} />
        )}
      </div>

      <CText className="row-player-name" level="p2" color="light">
        {player?.first_name} {player?.last_name}
      </CText>

      <div className="row-player-stat">
        <div className="row-player-stat-inner">
          <div className={`row-player-stat-value ${index < 3 ? medalClass : 'rating-high'}`}>
            {value}
          </div>
          <div className="row-player-stat-label">{labelKey}</div>
        </div>
      </div>
    </div>
  );
};

export const WarningStatsRow = ({ player, value, labelKey }) => {
  return (
    <div className="row-player">
      <div className="row-player-photo-container">
        <ProfilePicture className="row-player-photo" src={player.photo_path} />
      </div>

      <CText className="row-player-name" level="p2" color="light">
        {player?.first_name} {player?.last_name}
      </CText>

      <div className="row-player-stat">
        <div className="row-player-stat-inner">
          <div className={`row-player-stat-value rating-low`}>
            {value}
          </div>
          <div className="row-player-stat-label">{labelKey}</div>
        </div>
      </div>
    </div>
  );
};