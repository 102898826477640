import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { API } from 'aws-amplify';
import { useSelector } from 'react-redux';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { CText } from "../../components/Text";
import { MatchCard2 } from '../../components/Cards/MatchCard';
import LineUp from '../../components/LineUp/LineUp';
import Box from '@mui/material/Box';
import ProfilePicture from "../../components/ProfilePicture/index";
import { ReactComponent as CardIcon } from '../../assets/icons/card.svg';
import { ReactComponent as BallIcon } from '../../assets/icons/ball.svg';
import { ReactComponent as AssistIcon } from '../../assets/icons/assist.svg';
import Tooltip from '@mui/material/Tooltip';

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_RED = "#fc3c2e"
const COLOR_YELLOW = "#EDA247"
const COLOR_PINK = "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = "#242834";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;
const PLAYER_SUBSITUTES_CARD_HEIGHT = 120;

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    flexGrow: 1,
    [theme.breakpoints.up('xs')]: {
      paddingLeft: 0,
      paddingRight: 0
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 10,
      paddingRight: 10
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 45,
      paddingRight: 45
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 80,
      paddingRight: 80
    }
  },
  yellowCard: {
    fill: COLOR_YELLOW
  },
  redCard: {
    fill: COLOR_RED
  },
  cardIcon: {
    width: 18,
    height: 18
  },
  icon: {
    width: 18,
    height: 18,
    fill: COLOR_LIGHT
  },
  assistIcon: {
    width: 16,
    height: 16,
    fill: COLOR_LIGHT,
    marginLeft: 3
  },
  playerPhoto: {
    width: 25,
    height: 25,
    marginRight: 5,
    borderRadius: 100,
    border: '1px solid'
  },
  eventZone:{
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10
  },
  time: {
    width: 35,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  player: {
    display: 'flex',
    flexDirection: 'row'
  },
  playerAssiter: {
    marginLeft: 15,
    marginTop: 10
  },
  verticalSeparator: {
    borderLeft: `2px solid #363b49`,
    height: '24px',
    marginRight: 8,
    marginLeft: 8,
    alignSelf: 'center',
    borderRadius: 10
  }
}));

const GoalEvent = ({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t('match.contents.0.labels.0')}>
        <BallIcon className={[classes.icon].join(" ")} />
      </Tooltip>
      <CText level="p2" color="light" className={[classes.time].join(" ")}>{data?.time}'</CText>

      <div className={classes.verticalSeparator}></div>

      {data.own_goal ?
        <div>
          <CText className={classes.highlightsInfoTitle} level="p2" color="light">{t('match.contents.0.labels.4')}</CText>
        </div>
        :
        <div>
          <span className={classes.player}>
            <ProfilePicture className={classes.playerPhoto} src={data.user.photo_path} />
            <CText className={classes.highlightsInfoTitle} level="p2" color="light">{data?.user.first_name} {data?.user.last_name}</CText>
          </span>
          {data.assists.items.length > 0 ? (
            <span className={[classes.player, classes.playerAssiter].join(" ")} >
              <ProfilePicture className={classes.playerPhoto} src={data.assists.items[0].user.photo_path} />
              <CText className={classes.highlightsInfoTitle} level="p2" color="light">
                {data.assists.items[0].user.first_name} {data.assists.items[0].user.last_name}
              </CText>
              <Tooltip title={t('match.contents.0.labels.1')}>
                <AssistIcon className={[classes.assistIcon].join(" ")} />
              </Tooltip>
            </span>
          ) : null }
        </div>
      }
    </>
  );
};

const CardEvent = ({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const cardClass = data.type === 'Y' ? classes.yellowCard : classes.redCard;
  const cardLabel = data.type === 'Y' ? t('match.contents.0.labels.2') : t('match.contents.0.labels.3');
  return (
    <>
      <Tooltip title={cardLabel}>
        <CardIcon className={[classes.cardIcon, cardClass].join(" ")} />
      </Tooltip>
      
      <CText level="p2" color="light" className={[classes.time].join(" ")}>{data?.time}'</CText>

      <div className={classes.verticalSeparator}></div>
      
      <ProfilePicture className={classes.playerPhoto} src={data.user.photo_path} />
      <CText className={classes.highlightsInfoTitle} level="p2" color="light">{data?.user.first_name} {data?.user.last_name}</CText>
    </>
  );
};


const GameEvent = ({ data }) => {
  const classes = useStyles();

  // Fonction pour gérer les types d'événements
  const renderEventContent = (eventData) => {
    switch (eventData.event_type) {
      case 'goal':
        return <GoalEvent data={eventData} />;
      case 'card':
        return <CardEvent data={eventData} />;
      default:
        return <p>Événement inconnu</p>;
    }
  };

  return (
    <span className={classes.eventZone}>
      {renderEventContent(data)}
    </span>
  );
};

const Match = (props) => {
  const { t } = useTranslation()
  const userStored = useSelector(state => state?.user);
  const { matchId } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [game, setGame] = useState({});
  const [matchEvents, setMatchEvents] = useState([]);
  
  useEffect(() => {  
    const fetchData = async () => {
      const game = await getGame();
      const currentFormation = await getFormation(game);
      await getLineUp(game, currentFormation);
      setLoaded(true);
    }

    fetchData();
  }, []);


  const getGame = async() => {
    try {
      let getMatchesResp = await API.graphql({
        query: queries.getMatchHighlight,
        variables: {
          id: matchId
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      getMatchesResp = getMatchesResp?.data?.getMatch;
  
      const goals = getMatchesResp?.goals?.items?.map(goal => ({
        ...goal,
        event_type: 'goal'
      }));
      
      const cards = getMatchesResp?.cards?.items?.map(card => ({
        ...card,
        event_type: 'card'
      }));
  
      const matchEvents = [...goals, ...cards];
  
      matchEvents.sort((a, b) => b.time - a.time);
  
      setGame(getMatchesResp);
      setMatchEvents(matchEvents);
  
      return getMatchesResp;
    }
    catch(err) {
      console.log(err);
    }
  };
  
  const getFormation = async (game) => {
    let currentFormation;
    if(game?.home_team?.id) {
      game?.home_team_formation ? currentFormation = game?.home_team_formation : currentFormation =  "4-3-3"
    } else {
      game?.away_team_formation ? currentFormation = game?.away_team_formation : currentFormation =  "4-3-3"
    }

    return currentFormation;
  };

  const getLineUp = async (game, currentFormation) => {
    try {
      const matchPlayerFilter = {
        match_id: { eq: game.id }
      }

      const team = userStored?.club.team
      const userTeamFilter = {
        team_id: { eq: team.id },
        user_type: { eq: "P" },
        end_date: { attributeExists: false },
        invite_status: { eq: 1 }
      }

      let matchPlayers = await API.graphql({
        query: queries.listMatchPlayers,
        variables: { filter: matchPlayerFilter, userTeamFilter: userTeamFilter },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      matchPlayers = matchPlayers.data.listMatchPlayers.items.map(player => {
        const adaptedPlayer = {
          position_number: player.position,
          captain: player.captain,
          player: {
            id: player.user.id,
            first_name: player.user.first_name,
            last_name: player.user.last_name,
            photo_path: player.user.photo_path,
            position: player.user.teams.items[0].position
          }
        };
  
        if (adaptedPlayer.position_number < 12) {
          return { ...adaptedPlayer, line_up: true };
        } else if (adaptedPlayer.position_number === 12) {
          return { ...adaptedPlayer, bench_players: true };
        } else {
          return adaptedPlayer;
        }
      });
  
      const lineUpInitial = [
        { position: 1, player: {} },
        { position: 2, player: {} },
        { position: 3, player: {} },
        { position: 4, player: {} },
        { position: 5, player: {} },
        { position: 6, player: {} },
        { position: 7, player: {} },
        { position: 8, player: {} },
        { position: 9, player: {} },
        { position: 10, player: {} },
        { position: 11, player: {} }
      ];
  
      const gameLineUp = lineUpInitial.map((player) => {
        const matchingPlayer = matchPlayers.find((p) => p.position_number === player.position);
  
        if (matchingPlayer) {
          return {
            ...player,
            player: {
              id: matchingPlayer.player.id,
              first_name: matchingPlayer.player.first_name,
              last_name: matchingPlayer.player.last_name,
              photo_path: matchingPlayer.player.photo_path,
              position: matchingPlayer.player.position
            }
          };
        } else {
          return player;
        }
      });

      const captainPlayer = matchPlayers.find(player => player.captain === true);
      const captainId = captainPlayer ? captainPlayer.player.id : null;

      const benchPlayers = matchPlayers.filter(player => player.bench_players);
      setLineUp({formation: currentFormation, line_up: gameLineUp, bench_players: benchPlayers, captain: captainId });
    } catch (err) {
      console.log(err);
    }
  };

  /********* Handle Lineup *********/
  const [playersList, setPlayersList] = useState([])
  const [lineUp, setLineUp] = useState([]);

  const useStyles = makeStyles((theme) => ({
    container: {
      height: '100%',
      flexGrow: 1
    },
    topButtonZone: {
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'end',
      alignItems: 'end',
      width: '100%',
      marginBottom: 20
    },
    titleZone: {
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'start',
      width: '100%'
    },
    title: {
      marginBottom: 15
    },
    contentZone: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    matchCard: {
      marginBottom: 10
    },
    highlights: {
      width: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      minHeight: 150,
      borderRadius: 25,
      paddingTop: 10,
      paddingBottom: 10,
      [theme.breakpoints.up('xs')]: {
        maxWidth: 500
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 500
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 550,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 600
      }
    },
    leftMatchInfo: {
      justifyContent: 'center'
    }
  }));
  const classes = useStyles();

  const setNewLineUp = async(data) => {
    setLineUp(data)
  }

  const setNewPlayersList = (data) => {
    setPlayersList(data)
  }

  // Content
  let CONTENT;
  if(loaded === true) {
    CONTENT = 
    <div className={classes.contentZone}>
      <Grid container spacing={2}>
        <Grid container item xs={12} md={5} className={classes.leftMatchInfo}>
          <Box 
            display="flex" 
            flexDirection="column"
            alignItems="center"
            width="100%"
          >
            <MatchCard2 data={game} className={classes.matchCard} />

            <div className={classes.highlights}>
              {matchEvents.map((event, index) => (
                <div key={index}>
                  <GameEvent data={event} />
                </div>
              ))}
            </div>
          </Box>
        </Grid>

        <Grid item xs={12} md={7}>
          <LineUp
            gameDate={game.date}
            lineUp={lineUp}
            editable={false}
          />
        </Grid>
      </Grid>
    </div>
  } else {
    CONTENT = <div></div>
  }

  return (
    <div className={classes.container}>
      {CONTENT}
    </div>
  )
}

export default Match;