import React, { useState, useEffect } from 'react'
import {makeStyles} from "@material-ui/core/styles"
import Grid from '@material-ui/core/Grid'
import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux'
import { Auth, API } from 'aws-amplify'
import Tooltip from '@mui/material/Tooltip'
import CountUp from 'react-countup'
import moment from 'moment'
import * as queries from '../../graphql/queries'
import { CText } from "../../components/Text"
import { ClubPhoto } from "../../components/Photos/ClubPhoto"
import MatchCard from '../../components/Cards/MatchCard'
import { ReactComponent as OpponentIcon } from '../../assets/icons/opponent.svg'
import { ReactComponent as EvaluationIcon } from '../../assets/icons/evaluation.svg'
import { getCurrentSeason } from "../../utils/season"

const COLOR_PRIMARY = '#45d69f'
const COLOR_LIGHT = "#f4f4f4"
const COLOR_PINK= "#e92163"
const COLOR_ORANGE= "#f2a624"
const COLOR_BLUE = "#25a5e6"
const COLOR_DARK = '#242834'
const ZONE_BACKGROUND_COLOR = "#1f2126"
const BUTTON_WIDTH = 100

const GameEvaluation = ({ data }) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: 80
    },
    value: {
      width: 60,
      height: 60,
      borderRadius: '50%',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 15
    },
    valueType1: {
      color: COLOR_PRIMARY,
      border: `3px solid ${COLOR_PRIMARY}`
    },
    valueType2: {
      color: COLOR_ORANGE,
      border: `3px solid ${COLOR_ORANGE}`
    },
    valueType3: {
      color: COLOR_PINK,
      border: `3px solid ${COLOR_PINK}`
    }
  }));

  const classes = useStyles();
  const [counterOn, setCounterOn] = useState(false);

  useEffect(() => {
    const pageHeight = window.innerHeight
    const y = myRef.current.getBoundingClientRect().top;
    if(pageHeight >= y) {
      setCounterOn(true)
    } else {
      window.addEventListener('scroll', onScroll, { passive: true });
    }

  }, []);

  const myRef = React.useRef();

  const onScroll = () => {
    const pageHeight = window.innerHeight
    const y = myRef.current.getBoundingClientRect().top;
    if(pageHeight >= y) {
      setCounterOn(true)
    }
  };

  let valueColorClassName;
  if (data.value < 5) {
    valueColorClassName = classes.valueType3;
  } else if (data.value >= 5 && data.value < 7) {
    valueColorClassName = classes.valueType2;
  } else if (data.value >= 7) {
    valueColorClassName = classes.valueType1;
  }

  return(
    <div className={classes.container} ref={myRef}>
      <CText className={[classes.value, valueColorClassName].join(' ')} level="h1">{counterOn ? <CountUp end={data.value} duration={0.6} delay={0.5} /> : null }</CText>
      <CText className={classes.label} level="p1" color="light">{data.label}</CText>
    </div>
  )
}

const PlayerGameSummary = (props) => {
  const { t } = useTranslation();
  const userStored = useSelector(state => state?.user);

  const [loaded, setLoaded] = useState(false)
  const [lastGame, setLastGame] = useState([])
  const [playerMatchInfo, setPlayerMatchInfo] = useState({})

  useEffect(() => {  
    const fetchData = async () => {
      const currentSeason = await getCurrentSeason()
      const lastGame = await getLastGame(currentSeason)
      await getPlayermatchInfo(lastGame?.id)
      setLoaded(true)
    }
  
    fetchData()
  }, [])

  const getLastGame = async(season) => {
    try {
      const matchFilterSort = { field: "date", direction: "desc" }
      const team = userStored?.club.team
      const dateNow = moment().format("YYYY-MM-DD")

      const matchFilter = {
        or: [
          { away_team_id: { eq: team.id }},
          { home_team_id: { eq: team.id }}
        ],
        date: {lte: dateNow},
        season: { eq: season }
      }

      const searchMatchesResp = await API.graphql({ query: queries.searchMatches, variables: { filter: matchFilter, sort: matchFilterSort, limit: 1 }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      const searchMatches = searchMatchesResp.data?.searchMatches?.items

      setLastGame(searchMatches[0])
      return searchMatches[0]
    }
    catch(err) { console.log(err) }
  }

  const getPlayermatchInfo = async(matchId) => {
    try {
      const user = userStored?.user

      const playerMatchFilter = {
        user_id: { eq: user.info.id },
        match_id: { eq: matchId }
      }

      let playerMatchInfo = await API.graphql({ query: queries.listPlayerMatchInfo, variables: { filter: playerMatchFilter, limit: 1 }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      playerMatchInfo = playerMatchInfo.data?.listMatchPlayers?.items
      setPlayerMatchInfo(playerMatchInfo)
    }
    catch(err) { console.log(err) }
  }

  const useStyles = makeStyles((theme) => ({
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    titleZone: {
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'start',
      width: '100%'
    },
    title: {
      marginBottom: 15
    },
    info: {
      fill: COLOR_PRIMARY
    },
    infoIcon: {
      marginRight: 10,
      [theme.breakpoints.up('xs')]: {
        width: 35,
        height: 35
      },
      [theme.breakpoints.up('sm')]: {
        width: 40,
        height: 40
      },
    },
    subTitleZone: {
      display: 'inline-block',
      width: '100%',
      display: 'flex',
      flexDirection:'row',
      alignItems: 'center',
      marginBottom: 30
    },
    teamName: {
      marginLeft: 5,
      marginRight: 5
    },
    contentZone: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    teams: {
      marginLeft: 10,
      display: 'flex',
      alignItems: 'center'
    },
    teamsSeparator: {
      marginLeft: 8,
      marginRight: 8
    },
    awayTeamIcon: {
      height: '100%',
      width: 40,
      fill: COLOR_LIGHT,
      marginLeft: 10,
      display: 'flex',
      alignItems: 'center'
    },
    homeTeamIcon: {
      height: '100%',
      width: 40,
      fill: COLOR_LIGHT,
      marginRight: 10,
      display: 'flex',
      alignItems: 'center'
    },
    card: {
      display: 'block',
      overflowX: 'hidden',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      borderRadius: 25,
      listStyleType: 'none',
      width: '100%',
      boxSizing: 'border-box',
      [theme.breakpoints.up('xs')]: {
        padding: 20
      },
      [theme.breakpoints.up('sm')]: {
        padding: 20,
        maxWidth: 500
      },
      [theme.breakpoints.up('md')]: {
        padding: 20,
        maxWidth: 550
      },
      [theme.breakpoints.up('lg')]: {
        padding: 35,
        maxWidth: 600
      }
    },    
    cardContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    cardTitle: {
      marginBottom: 30
    },
    matchCard: {
      marginBottom: 25
    }
  }));
  const classes = useStyles();

  let CONTENT;
  if(loaded === true) {
    if(lastGame) {
      CONTENT = 
      <div className={classes.container}>
        <div className={classes.contentZone}>
          <div className={classes.titleZone}>
            <CText className={classes.title} level="h1" color="light" label={t('player_game_summary.title')} />
          </div>
          <MatchCard data={lastGame} className={classes.matchCard}/>
          {playerMatchInfo.length > 0 ?
            <>
            {playerMatchInfo[0].offensive_rating && playerMatchInfo[0].defensive_rating && playerMatchInfo[0].collective_rating ?
              <div className={classes.card}>
                <CText className={classes.cardTitle} level="h3" fontWeight="700" color="light">{t('player_game_summary.labels.0')}</CText>
                <div className={classes.cardContent}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <GameEvaluation data={{value: playerMatchInfo[0].offensive_rating, label: t('player_game_summary.labels.1') }}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <GameEvaluation data={{value: playerMatchInfo[0].defensive_rating, label: t('player_game_summary.labels.2') }}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <GameEvaluation data={{value: playerMatchInfo[0].collective_rating, label: t('player_game_summary.labels.3') }}/>
                    </Grid>
                  </Grid>
                </div>
              </div>
              :
              <div className={classes.card}>
                <div className={classes.cardContent}>
                  <CText className={classes.info} level="h2" color="primary">
                    <EvaluationIcon className={classes.infoIcon} />{t('player_game_summary.labels.5')}
                  </CText>
                </div>
              </div>
            }
            </>
            :
            <div className={classes.card}>
              <div className={classes.cardContent}>
                <CText className={classes.info} level="h2" color="primary">
                  <EvaluationIcon className={classes.infoIcon} />{t('player_game_summary.labels.4')}
                </CText>
              </div>
            </div>
          }

        </div>
      </div>
    } else {
      CONTENT = <div>

      </div>
    }
  } else {
    CONTENT = <div></div>
  }

  return(CONTENT)
}
  
export default PlayerGameSummary;