import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';


const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = '#242834';
const BUTTON_WIDTH = 100;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (props) => (props.selected ? `${COLOR_PRIMARY} !important` : `${COLOR_DARK} !important`),
    color: (props) => (props.selected ? `${COLOR_DARK}` : `${COLOR_LIGHT}`),
    fontSize: '14px',
    height: '25px',
    padding: '0 8px',
    borderRadius: '16px',
    transition: 'background-color 0.2s ease-in-out',
    filter: (props) => (!props.selected ? 'brightness(1.3)' : 'none'),
    '&:hover': {
      filter: 'brightness(90%)',
    },
  },
  label: {
    fontWeight: 'bold'
  },
}));

const SelectableChip = ({ label, selected, onClick }) => {
  const classes = useStyles({ selected });

  return (
    <Chip
      label={label}
      size="small"
      onClick={onClick}
      className={classes.root}
      classes={{ label: classes.label }}
    />
  );
};

export default SelectableChip;