import React, { useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import i18next from 'i18next'
import { useTranslation } from "react-i18next"
import moment from 'moment';
import { ReactComponent as Calendar } from '../assets/icons/colored/calendar.svg'
import { ReactComponent as Clock } from '../assets/icons/clock.svg'
import { formatTime, getTimeFormat} from '../utils/dates';
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

const COLOR_LIGHT = "#f4f4f4";
const COLOR_PRIMARY = '#45d69f';
const LABEL_COLOR = '#101116';
const TEXTFIELD_COLOR = '#242834';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    borderRadius: 8,
    backgroundColor: TEXTFIELD_COLOR
  },
  root: {
    color: COLOR_LIGHT,
    fontSize: 16,
    "&$focusedLabel": {
      color: COLOR_PRIMARY
    },
    "&$erroredLabel": {
      color: "rgb(208,48,63)"
    }
  },
  inputRoot: {
    color: COLOR_LIGHT,
    borderRadius: 8,
    "&&&:before": {
      borderBottom: "none"
    },
    "&:after": {
      borderRadius: 8,
      borderBottom: `2px solid #45d69f`
    }
  },
  focusedLabel: { color: "#45d69f" },
  pickerRoot: {
    color: COLOR_LIGHT
  }
}));

const theme = createTheme({
  palette: {
    primary: {
      main: COLOR_PRIMARY
    }
  }
});
    

const calendarIcon = React.forwardRef((props, ref) => <Calendar {...props} ref={ref} /> );
const clockIcon = React.forwardRef((props, ref) => <Clock {...props} ref={ref} /> );

const CDatePicker = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [value, setValue] = useState(props.defaultValue || '');

  const handleChange = (newValue) => {
    setValue(newValue);
    if (props.inputCallback) {
      props.inputCallback(newValue);
    }
  };

  return(
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <DatePicker
          label={props.label}
          inputFormat={ i18next.language === "fr" ? "dd/MM/yyyy" : "MM/dd/yyyy" }
          value={value}
          onChange={handleChange}
          onInputChange={(e) => setValue(e.target.value)}
          sx={{
            mx: 0.5,
            fontSize: 30,
          }}
          InputProps={{
            className: classes.pickerRoot
          }}
          components={{
            OpenPickerIcon: calendarIcon
          }}
          PaperProps={{
            sx: {
              "& .MuiPickersDay-root": {
                "&.Mui-selected": {
                  opacity: 1,
                  color: COLOR_LIGHT
                }
              },
              "& .MuiPickersDay-today":
              !value ? {
                backgroundColor: COLOR_PRIMARY,
                opacity: 0.6,
                border: 2
              } : {
                border: 0,
                backgroundColor: 'none'
              },
              "& .PrivatePickersYear-yearButton": {
                "&.Mui-selected": {
                  backgroundColor: 'inherit ! important',
                  opacity: 1,
                  color: 'rgba(0, 0, 0, 0.87)'
                },
                "&:focus": {
                  backgroundColor: `${COLOR_PRIMARY} ! important`,
                  opacity: 1,
                  color: COLOR_LIGHT
                },
              }
            }
          }}
          //onError={(err) => setError(true)}
          renderInput={(params) => 
            <TextField
              {...params}
              label={props.label}
              variant="filled"
              size="small"
              error={false}
              className={[classes.input, props.className].join(' ')}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.root,
                  focused: classes.focusedLabel,
                  error: classes.erroredLabel
                }
              }}
              inputProps={{
                ...params.inputProps,
                placeholder: `${t('date.format.label')}`
              }}
              InputProps={{
                ...params.InputProps,

                classes: {
                  root: classes.inputRoot
                }
              }}
            />
          }
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
}

const CTimePicker = (props) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const timeFormat = getTimeFormat(i18n.language);

  const is12HourFormat = timeFormat.includes('A') || timeFormat.includes('a');

  const [inputValue, setInputValue] = useState(
    props.defaultValue ? formatTime(new Date(props.defaultValue), timeFormat) : ''
  );
  const [previousValue, setPreviousValue] = useState(inputValue);

  const formatTimeInput = (value) => {
    if (!value) return '';

    const numbersOnly = value.replace(/[^0-9]/g, '');
    let formattedTime = '';

    if (is12HourFormat) {
      if (numbersOnly.length >= 2) {
        formattedTime = `${numbersOnly.slice(0, 2)}:${numbersOnly.slice(2, 4)}`;

        if (value.length === 2 && previousValue.length !== 1) {
          formattedTime = `${numbersOnly.slice(0, 2)}${numbersOnly.slice(2, 4)}`;
        }
        
        if (formattedTime.length === 5 && previousValue.length !== 8) {
          if (value[5] === 'A' || value[4] === 'a') {
            formattedTime += ' AM';
          } else {
            formattedTime += ' PM';
          }
        }

      } else {
        formattedTime = numbersOnly;
      }
    } else {
      if (numbersOnly.length >= 2) {
        formattedTime = `${numbersOnly.slice(0, 2)}:${numbersOnly.slice(2, 4)}`;
      } else {
        formattedTime = numbersOnly;
      }
    }

    return formattedTime;
  };

  const handleInputChange = (event) => {
    const { value } = event.target;

    //const formattedValue = value.length >= inputValue.length ? formatTimeInput(value) : value;
    const formattedValue = formatTimeInput(value);

    setInputValue(formattedValue);
    setPreviousValue(formattedValue);

    // Appeler inputCallback uniquement si l'entrée n'est pas vide
    const momentTime = moment(formattedValue, timeFormat, true);
    props.inputCallback(momentTime.isValid() ? momentTime : null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <TextField
          label={props.label}
          value={inputValue}
          onChange={handleInputChange}
          variant="filled"
          size="small"
          className={[classes.input, props.className].join(' ')}
          InputLabelProps={{
            shrink: true,
            classes: {
              root: classes.root,
              focused: classes.focusedLabel,
              error: classes.erroredLabel
            }
          }}
          InputProps={{
            classes: {
              root: classes.inputRoot
            }
          }}
          placeholder={timeFormat}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export { CDatePicker, CTimePicker };