import React, { useState } from 'react'
import { CText } from '../Text'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { ReactComponent as CupIcon } from '../../assets/icons/cup.svg'
import { ReactComponent as ChampionshipIcon } from '../../assets/icons/championship.svg'
import { ReactComponent as CaretIcon } from '../../assets/icons/caret.svg'
import { useStylesSelector } from './selector.styles.js'

const CompetitionsSelector = ({ competitions, selectedCompetitionIndex, onCompetitionSelect }) => {
  const classes = useStylesSelector();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isDisabled = competitions.length <= 1;

  const combinedClass = `${classes.selectorBtn} ${isDisabled ? classes.disabledSelectorBtn : ''}`;

  const handleButtonClick = (event) => {
    if (!isDisabled) {
      handleMenuOpen(event);
    }
  };

  return (
    <>
      <div className={combinedClass} onClick={handleButtonClick}>
        <ChampionshipIcon className={classes.selectorCompetitionIcon} />
        <CText level="p2" color="light" className={classes.label}>{competitions[selectedCompetitionIndex]?.name}</CText>
        {!isDisabled && <CaretIcon className={classes.selectorIcon} />}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            minWidth: '20ch',
            maxHeight: 160
          }
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {competitions.map((competition, index) => (
          index !== selectedCompetitionIndex &&
          <MenuItem key={index} onClick={() => { onCompetitionSelect(index); handleMenuClose(); }}>
            {competition.type === "CHP" ? <ChampionshipIcon className={classes.listIcon} /> : <CupIcon className={classes.listIcon} />}
            {competition.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CompetitionsSelector;