import { makeStyles } from "@material-ui/core/styles";

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const ZONE_BACKGROUND_COLOR_2 = "#242834";
const COLOR_DARK = "#242834";

export const useStyles = makeStyles((theme) => ({
  container1: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
  container2: {
    maxWidth: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
  menuItem: {
    backgroundColor: 'transparent',
    color: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transition: 'filter 0.2s ease-in-out',
    '&:hover': {
      filter: 'brightness(90%)',
    },
  },
  menuIcon: {
    width: 18,
    height: 18,
    display: 'flex',
    alignItems: 'center',
    color: COLOR_DARK,
    marginRight: 6
  },
  selectorBtn: {
    color: COLOR_LIGHT,
    fill: COLOR_LIGHT,
    border: `2px solid ${COLOR_PRIMARY}`,
    backgroundColor: ZONE_BACKGROUND_COLOR_2,
    height: 28,
    padding: 6,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
    "&:hover": {
      filter: 'brightness(90%)'
    }
  },
  selectorSeasonBtn: {
    paddingBottom: '20px !important',
    paddingTop: '20px !important'
  },
  selectedName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexGrow: 1
  },
  disabledSelectorBtn: {
    cursor: 'default',
    "&:hover": {
      filter: 'none'
    }
  },
  icon: {
    width: 16,
    height: 16,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  selectorIcon: {
    width: 14,
    height: 14,
    fill: COLOR_LIGHT,
    marginLeft: 'auto',
    flexShrink: 0
  },
  label: {
    fontWeight: '700 !important'
  },
  selectorBtnContent: {
    display: 'flex',
    alignItems: 'center'
  },
  selectedOption: {
    display: 'flex',
    alignItems: 'center'
  }
}));