import React, { useState, useEffect, Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from "react-i18next"
import { Auth, API } from 'aws-amplify'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import * as queries from '../../graphql/queries.js'
import * as mutations from '../../graphql/mutations.js'

import CButton from "../../components/Button.js"
import CModal from "../../components/Modal.js"
import { CText } from "../../components/Text.js"
import CSelect from "../../components/Select.js"
import TextInput from '../../components/TextInput.js'
import { getSession } from "../../utils/auth.js"
import { BadRequestSnackBar } from "../../components/SnackBar/index.js"
import NoTeamsCard from "../../components/Cards/NoTeamsCard.js"
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { getTeamIdFromUrl } from '../../utils/team.js'

import { ReactComponent as DotsVerticalIcon } from '../../assets/icons/dots-vertical.svg'
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg'
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg'

import { useStylesTactics } from './tactics.styles.js';
import { useStylesTeam, useStylesTeams, useStylesModal } from './teams.styles.js';
import { getCurrentSeason } from "../../utils/season.js"

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = '#242834';
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;

const TacticCard = ({ data, index, deleteCallback, errorCallback }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStylesTactics();

  const [anchorEl, setAnchorEl] = useState(null)
  const [deletedCompetitionIndex, setDeletedCompetitionIndex] = useState(null)
  const [competitionTeamId, setCompetitionTeamId] = useState(null)  
  const [coach, setCoach] = useState(null)
  const [loaded, setLoaded] = useState(false)

  //Menu
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event?.stopPropagation();
    setAnchorEl(null);
    setDeletedCompetitionIndex(null)
    setCompetitionTeamId(null)
  };

  const handleDelete = async(event) => {
    event.stopPropagation();
    const deleteInput = {
      id: data.id
    };

    await API.graphql({
      query: mutations.deleteTactic,
      variables: { input: deleteInput },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });

    handleClose();
    deleteCallback(index);
  };

  useEffect(() => {
    const coaches = data?.users?.items
    if(coaches) {
      for(let i = 0; i < coaches.length; i++) {
        if(coaches[i].invite_status === 1) {
          data.coach = {
            id: coaches[i].user.id,
            firstName: coaches[i].user.first_name,
            lastName: coaches[i].user.last_name,
            inviteStatus: 1
          }
        } else if(coaches[i].invite_status === 0) {
          data.coach = {
            id: coaches[i].user.id,
            firstName: coaches[i].user.first_name,
            lastName: coaches[i].user.last_name,
            inviteStatus: 0
          }
        } else {
          data.coach = null
        }
      }
    }

    setLoaded(true);
  }, []);

  const onClickTactic = (id) => {
    navigate(`?id=${id}`);
  };

  return (
    <li className={classes.dataZone} onClick={() => onClickTactic(data.id)}>
      {loaded === true ?
        <Grid container direction="column">
          <div className={classes.name}>
            <CText level="p1" color="light" fontWeight={700}>
              {data.title}
            </CText>
            <div className={classes.moreBtn} onClick={(e) => handleClick(e)}>
              <DotsVerticalIcon />
            </div>
          </div>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={(e) => handleClose(e)}
            PaperProps={{
              style: {
                minWidth: '10ch'
              },
            }}
            autoFocus={false}
          >
            <MenuItem onClick={(e) => handleDelete(e)}>
              <TrashIcon className={classes.listIcon} />
              {t('tactics.labels.0')}
            </MenuItem>
          </Menu>
        </Grid>
        : null
      }
    </li>
  );
}

const AddTacticModal = (props) => {
  const { t } = useTranslation();
  const classes = useStylesModal();
  const userStored = useSelector((state) => state?.user)

  const genders = [
    { "label": t('genders.0.longLabel'), "code": "M" },
    { "label": t('genders.1.longLabel'), "code": "F" }
  ]

  /********* Handle Title *********/
  const [title, setTitle] = useState("");
  const titleCb = (data) => setTitle(data);

  /********* Create Team *********/
  const createTactic = async () => {
    try {
      if (title) {
        const teamId = props.teamId;
        const tactics = [...props.tactics];
  
        const data = {
          "title": title,
          "team_id": teamId,
          "teamTacticsId": teamId
        };
        await getSession();
        const resp = await API.graphql({
          query: mutations.createTactic,
          variables: { input: data },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        
        const newTactic = {
          id: resp.data.createTactic.id,
          title: resp.data.createTactic.title
        };
  
        const newTactics = [...tactics];
        let added = false;
        let indexToAdd = 0;
  
        if (newTactics.length > 0) {
          for (let i = 0; i < newTactics.length; i++) {
            const numberNewTactics = parseInt(newTactic.title.replace(/\D/g, ''), 10) || 0;
            const numberExistingTactics = parseInt(newTactics[i].title.replace(/\D/g, ''), 10) || 0;
        
            if (!added && numberNewTactics < numberExistingTactics) {
              indexToAdd = i;
              added = true;
              break;
            }
          }
        }

        if (added) {
          newTactics.splice(indexToAdd, 0, newTactic);
        } else {
          newTactics.push(newTactic);
        }

        await props.updateTeams(newTactics);
        props.modalOpenCallback(false);
      } else {
        let errorElmts = [];
        if (!title) {
          errorElmts.push(t('snack_bars.0.elements.0.label'));
        }

        props.errorCallback(errorElmts);
      }
    } catch (err) {
      console.log('error fetching:', err);
    }
  };
  return (
    <div className={classes.modalContainer}>
      <div className={classes.form}>
        <CText className={classes.formTitle} level="h2" fontWeight={700} color="primary" label={`${t('tactics.modals.0.title')}`} />
        <TextInput className={classes.input} label={`${t('tactics.modals.0.inputs.0.label')}`} inputCallback={titleCb} value={title} />
        
        <div className={classes.formBtnZone}>
          <CButton
            className={classes.formBtn}
            label={t('teams.modals.0.buttons.0.label')}
            height={38}
            type="contained"
            loader={true}
            onClick={async () => await createTactic()}
          />
        </div>
      </div>
    </div>
  );
}

const TacticsList = (props) => {
  const { t } = useTranslation();
  const classes = useStylesTactics();
  const userStored = useSelector((state) => state?.user)

  const [modalOpen, setModalOpen] = useState(false);
  const [tactics, setTactics] = useState([]);
  const [teams, setTeams] = useState([]);
  const [teamIndex, setTeamIndex] = useState(0);
  const [teamId, setTeamId] = useState(null);
  const [loaded, setLoaded] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const teams = await getTeams();
      await getTactics(teams);

      if (teams.length > 0) {
        const teamId = getTeamIdFromUrl(window.location.pathname, teams);
    
        const foundIndex = teams.findIndex(team => team.id === teamId);
        if (foundIndex !== -1) {
          setTeamIndex(foundIndex);
        }
    
        setTeamId(teamId);
        getTactics(teams);
      }

      setLoaded(true)
    }
    
    fetchData();
  }, []);

  const getTeams = async() => {
    try {
      const { groups, club } = userStored;
      let teams = [];
    
      if (groups.includes("ClubAdmins")) {
        const clubId = club?.id;
  
        const teamsFilter = {
          club_id: { eq: clubId },
          deleted: { eq: false }
        }
  
        let teamsList = await API.graphql({ query: queries.listClubTeams, variables: { filter: teamsFilter }, authMode: 'AMAZON_COGNITO_USER_POOLS'})
        teamsList = teamsList.data.listTeams.items;
  
        const teamsListOrdered = teamsList.sort((a, b) => {
          const numberA = parseInt(a.name.replace(/\D/g, ''), 10) || 0;
          const numberB = parseInt(b.name.replace(/\D/g, ''), 10) || 0;
          return numberA - numberB;
        });
  
        teams = teamsListOrdered;
      } else {
        const team = club?.team
        teams.push(team)
      }
  
      setTeams(teams)
  
      return teams;
    }
    catch(err) { console.log(err) }
  }
  
  const getTactics = async(teams) => {
    try {
      const teamId = getTeamIdFromUrl(window.location.pathname, teams);

      const filter = {
        team_id: { eq: teamId }
      }

      let tacticsList = await API.graphql({
        query: queries.listTactics,
        variables: {
          filter: filter
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      tacticsList = tacticsList.data.listTactics.items;

      const tacticsListOrdered = tacticsList.sort((a, b) => {
        const numberA = parseInt(a.title.replace(/\D/g, ''), 10) || 0;
        const numberB = parseInt(b.title.replace(/\D/g, ''), 10) || 0;
        return numberA - numberB;
      });

      setTactics(tacticsListOrdered)
    }
    catch(err) { console.log(err) }
  }
  
  
  const onClickModalAdd = () => {
    setModalOpen(true);
  }

  const deleteCB = (index) => {
    const temp = [...tactics]
    temp.splice(index, 1)
    setTactics(temp)
  }

  /********* Handle Error *********/
  const [openSnack, setOpenSnack] = useState(false)
  const [snackBarErrorElmts, setSnackBarErrorElmts] = useState("")

  const errorCB = (errorElmts) => {
    setOpenSnack(true)
    setSnackBarErrorElmts(errorElmts)
  }

  const closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  }

  const updateTeams = async(newTeams) => {
    setTactics(newTeams);
  };

  // Content Modal
  let contentModal

  if(loaded) {
    contentModal = <AddTacticModal
      tactics={tactics}
      teamId={teamId}
      updateTeams={updateTeams}
      modalOpenCallback={(data) => setModalOpen(data)}
      errorCallback={errorCB}
    />
  }

  return(
    <div>
    {loaded ?
      <div className={classes.container}>
        <CText level="h1" color="light">{`${t('tactics.title')}`}</CText>
        <div className={classes.topButtonsContainer}>
          <CButton
            className={classes.addButton}
            label={t('tactics.buttons.0.label')}
            type="contained"
            color={COLOR_PRIMARY}
            size={14}
            minWidth={BUTTON_WIDTH}
            maxWidth={BUTTON_WIDTH}
            onClick={async () => await onClickModalAdd()}
          />
        </div>

        {loaded === true && tactics.length < 1 ?
          <div className={classes.noTeamsInfo}>

          </div>
          :
          <div className={classes.list}>
            <Fragment>
              <Grid container spacing={1}>
                {tactics?.map((tactic, idx) => (
                  <Grid container item xs={12} sm={6} md={6} lg={4} xl={4} key={idx} justifyContent="center" alignItems="stretch">
                    <TacticCard
                      data={tactic}
                      index={idx}
                      deleteCallback={deleteCB}
                      errorCallback={errorCB}
                    />
                  </Grid>
                ))}
              </Grid>
            </Fragment>
          </div>
        }

        <BadRequestSnackBar
          open={openSnack}
          onClose={closeSnackBar}
          autoHideDuration={5000}
          errorElmts={snackBarErrorElmts}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        />

        <CModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          content={contentModal}
        >
        </CModal>
      </div>
      :null
    }
    </div>
  )
}
  
export default TacticsList;