import React, { useState, useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import Grid from '@material-ui/core/Grid'
import { useTranslation } from "react-i18next"
import i18next from 'i18next'
import { API, graphqlOperation, Storage } from 'aws-amplify'
import { getSession } from "../../utils/auth"
import { useNavigate } from "react-router-dom"
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import moment from 'moment'
import CButton, { PickMediaBtn } from "../../components/Button"
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import CModal from "../../components/Modal"
import CSelect from "../../components/Select"
import TextInput from '../../components/TextInput'
import { CDatePicker, CTimePicker } from '../../components/DatePicker'
import { CText } from "../../components/Text"
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { setPhotoPath } from '../../store/reducers/user'
import { ClubPhoto } from "../../components/Photos/ClubPhoto"
import getLocalizedLabel from '../../utils/localizationUtils'
import ProfilePicture from "../../components/ProfilePicture/index"
import { ReactComponent as BandageIcon } from '../../assets/icons/bandage.svg'
import { ReactComponent as MedicalKitIcon } from '../../assets/icons/medical-kit.svg'
import { ReactComponent as WeightIcon } from '../../assets/icons/weight.svg'
import { ReactComponent as FatMassIcon } from '../../assets/icons/fat-mass.svg'
import { ReactComponent as DotsVerticalIcon } from '../../assets/icons/dots-vertical.svg'
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg'
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg'
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg'
import { getCurrentSeason } from "../../utils/season"

import { Chart } from 'react-chartjs-2'

const COLOR_PRIMARY = '#45d69f';
const COLOR_PRIMARY_TRANSPARENT = 'rgba(69, 214, 159, 0.4)';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_PINK_TRANSPARENT = 'rgba(233, 33, 99, 0.4)'
const COLOR_BLUE = "#25a5e6";
const COLOR_BLUE_TRANSPARENT = 'rgba(37, 165, 230, 0.4)';
const COLOR_PURPLE = "#9d44fd";
const COLOR_PURPLE_TRANSPARENT = 'rgba(157, 68, 253, 0.4)';
const COLOR_ORANGE = "#f2a624";
const COLOR_DARK = "#242834";
const COLOR_ORANGE_TRANSPARENT = 'rgba(242, 166, 36, 0.4)'
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;
const MENU_WIDTH = 250;

const formatDate = (date, language) => {
  let formattedDate;
  switch (true) {
    case language === "fr":
      formattedDate = moment(date).format("DD-MM-YYYY");
      break;
    default:
      formattedDate = moment(date).format("MM-DD-YYYY");
      break;
  }
  return formattedDate;
};

const Injuries = ({userId, season, t}) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      height: '100%'
    },
    title: {
      marginBottom: 30
    },
    titleIcon: {
      fill: COLOR_LIGHT,
      width: 23,
      height: 23,
      marginRight: 10
    },
    subTitle: {
      marginBottom: 15
    },
    card: {
      width: '100%',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      borderRadius: 18,
      listStyleType: 'none',
      padding: 20
    },
    info: {
      backgroundColor: COLOR_DARK,
      borderRadius: 18,
      listStyleType: 'none',
      padding: 20,
      flex: 1
    },
    timeLineLabel: {
      flexShrink: 0
    },
    timeLineLabelLeft: {
      marginRight: 8
    },
    timeLineLabelRight: {
      marginLeft: 8
    },
    timeLine: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      alignItems: 'center'
    },
    timeLineCircle: {
      width: 12,
      height: 12,
      borderRadius: '50%',
      border: '2px solid',
      borderColor: COLOR_PRIMARY,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 5,
      marginRight: 5,
      flexShrink: 0
    },
    timeLineDot: {
      width: 5,
      height: 5,
      borderRadius: '50%',
      backgroundColor: COLOR_PRIMARY,
    },
    timeLineLine: {
      maxWidth: 150,
      width: '100%',
      height: 3,
      borderRadius: 3,
      backgroundColor: COLOR_PRIMARY
    },
    headBtn:{
      display: 'flex',
      justifyContent: 'end',
      flex: 1
    },
    moreBtn: {
      width: 20,
      height: 20,
      padding: 3,
      marginLeft: 5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      fill: COLOR_LIGHT,
      borderRadius: '50%',
      "&&:hover": {
        backgroundColor: '#242526',
        boxShadow: 'none'
      }
    },
    listIcon: {
      width: 17,
      height: 17,
      fill: COLOR_DARK
    },
    listIconZone: {
      marginRight: 8,
      display: 'flex',
      justifyContent: 'center'
    },
    modalContainer: {
      [theme.breakpoints.up('xs')]: {
        padding: 20
      },
      [theme.breakpoints.up('sm')]: {
        padding: 20
      },
      [theme.breakpoints.up('md')]: {
        padding: 30
      },
      [theme.breakpoints.up('lg')]: {
        padding: 30
      }
    },
    modalButton: {
      marginTop: 30,
      height: 160
    },
    inputInline: {
      position: 'relative',
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center'
    },
    input: {
      marginBottom: "15px !important"
    },
    inputRight: {
      marginLeft: 10
    }
  }));

  const classes = useStyles();

  const [injuries, setInjuries] = useState({});
  const [playerInjuries, setPlayerInjuries] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {  
    const fetchData = async () => {
      listInjuries()
      await listUserInjuries()
      setIsLoaded(true)
    }
  
    fetchData()
  }, [])

  const calculateEndDate = (startDate, duration) => {
    const startDateMoment = moment(startDate);
    const endDateMoment = startDateMoment.add(duration, 'days');
    const endDate = endDateMoment.format('YYYY-MM-DD');
    return endDate;
  };

  const listInjuries = async () => {
    try {
      let injuries = await API.graphql({
        query: queries.listInjuries,
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      injuries = injuries?.data?.listInjuries;
  
      const formattedInjuries = injuries.items.map((item) => ({
        label: getLocalizedLabel(item, i18next.language),
        code: item.id
      }));

      formattedInjuries.sort((a, b) => a.label.localeCompare(b.label));
  
      setInjuries(formattedInjuries);
    } catch (err) {
      console.log(err);
    }
  };
  
  const listUserInjuries = async () => {
    try {
      const filter = {
        user_id: { eq: userId },
        season: { eq: season },
      };
      let injuries = await API.graphql({
        query: queries.listPlayerInjuries,
        variables: { filter: filter },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      injuries = injuries?.data?.listPlayerInjuries.items;
  
      injuries.sort((a, b) => new Date(b.start_date) - new Date(a.start_date))

      injuries.forEach((item) => {
        const endDate = calculateEndDate(item.start_date, item.duration);
        item.end_date = endDate;
      });
  
      const formattedInjuries = injuries.map((item) => ({
        ...item,
        start_date: formatDate(item.start_date, i18next.language),
        end_date: formatDate(item.end_date, i18next.language),
        label: getLocalizedLabel(item.injury, i18next.language)
      }));
      
      setPlayerInjuries(formattedInjuries);
    } catch (err) {
      console.log(err);
    }
  };
  
  //Menu
  const [anchorEl, setAnchorEl] = useState(null)
  const [addBtn, setAddBtn] = useState(false)
  const [deleteBtn, setDeleteBtn] = useState(false)
  const [playerInjuryData, setPlayerInjuryData] = useState()

  const open = Boolean(anchorEl);
  const moreBtnClick = (event, addBtn, deleteBtn, data) => {
    setPlayerInjuryData()
    setAnchorEl(event.currentTarget)
    setAddBtn(addBtn)
    setDeleteBtn(deleteBtn)
    setPlayerInjuryData(data)
  };

  const handleClose = () => {
    setAnchorEl(null)
  };

  const clickAdd = async() => {
    setModalOpen(true)
    handleClose()
  };

  const onClickDelete = async () => {
    handleClose();
    if (playerInjuryData) {
      const deleteInput = {
        id: playerInjuryData.id
      };
  
      await API.graphql({
        query: mutations.deletePlayerInjury,
        variables: { input: deleteInput },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      const updatedInjuries = playerInjuries.filter((item) => item.id !== playerInjuryData.id);
      setPlayerInjuries(updatedInjuries);
    }
  };

  /********* Handle Injury *********/
  const [injury, setInjury] = useState();
  const selectInjury = (data) => setInjury(data);

  /********* Date *********/
  const [startDate, setStartDate] = useState("");
  const inputStartDate = (data) => setStartDate(data);

  /*********  *********/
  const [duration, setDuration] = useState();
  const inputDuration = (data) => setDuration(data)

  const addWeight = async () => {
    const formatedStartDate = moment(startDate).format("YYYY-MM-DD");
  
    const playerInjuryInput = {
      season: season,
      start_date: formatedStartDate,
      duration: duration,
      injury_id: injury.code,
      injuryUsersId: injury.code,
      user_id: userId,
      userInjuriesId: userId
    };

    const createdPlayerInjury = {
      ...playerInjuryInput,
      id: 'temp-id',
      start_date: formatDate(startDate, i18next.language),
      duration: duration,
      end_date: formatDate(calculateEndDate(startDate, duration), i18next.language) ,
      label: injury.label
    };
  
    setPlayerInjuries((prevPlayerInjuries) => {
      const updatedInjuries = [createdPlayerInjury, ...prevPlayerInjuries];
      return updatedInjuries;
    });
  
    // Créer l'objet playerInjury sur le serveur
    const createPlayerInjuryResp = await API.graphql({
      query: mutations.createPlayerInjury,
      variables: { input: playerInjuryInput },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
  
    // Mettre à jour l'ID temporaire avec l'ID du joueur blessé créé sur le serveur
    const { id } = createPlayerInjuryResp.data.createPlayerInjury;
    createdPlayerInjury.id = id;
  
    // Mettre à jour l'état injuries avec l'objet mis à jour
    setInjuries((prevInjuries) =>
      prevInjuries.map((item) => (item.id === 'temp-id' ? createdPlayerInjury : item))
    );
  
    setModalOpen(false);
  };

  const CONTENT_MODAL = 
    <div className={classes.modalContainer}>
      <CSelect
        label={`${t('team_user_profile.modals.0.inputs.0.label')} *`}
        data={injuries}
        selectCallback={selectInjury}
        className={classes.input}
        value={injury}
      />
      <div className={[classes.input, classes.inputInline].join(" ")}>
        <CDatePicker
          label={`${t('team_user_profile.modals.0.inputs.1.label')} *`}
          inputCallback={inputStartDate}
        />
        <TextInput
          className={classes.inputRight}
          label={`${t('team_user_profile.modals.0.inputs.2.label')} *`}
          inputCallback={inputDuration}
          value={duration}
        />
      </div>
      
      <CButton
        className={classes.modalButton}
        label={`${t('team_user_profile.modals.0.buttons.0.label')}`}
        type="contained"
        color={COLOR_PRIMARY}
        height={40}
        size={14}
        onClick={() => addWeight()}
      />
    </div>

  return(
    <div className={classes.card}>
      <CText className={classes.title} level="h3" fontWeight="700" color="light">
        <BandageIcon className={classes.titleIcon}/>
        {t('team_user_profile.contents.0.sub_contents.0.title')}
        <span className={classes.headBtn}>
          <DotsVerticalIcon className={classes.moreBtn} onClick={(e) => moreBtnClick(e, true, false)}/>
        </span>
      </CText>

      <Grid container spacing={1} alignItems="flex-start">
        {isLoaded === true && playerInjuries?.map((playerInjury, index) => (
          <Grid container item xs={12} key={`injury-${index}`}>
            <div className={classes.info}>
              <CText className={classes.subTitle} level="h3" fontWeight="700" color="light">
                {playerInjury.label}
                <span className={classes.headBtn}>
                  <DotsVerticalIcon className={classes.moreBtn} onClick={(e) => moreBtnClick(e, false, true, playerInjury)}/>
                </span>
              </CText>
              <div className={classes.timeLine}>
                <CText className={[classes.timeLineLabel, classes.timeLineLabelLeft].join(' ')} level="p2" fontWeight="700" color="light">{playerInjury.start_date}</CText>
                <div className={classes.timeLineCircle}>
                  <div className={classes.timeLineDot} />
                </div>
                <div className={classes.timeLineLine} />
                <div className={classes.timeLineCircle}>
                  <div className={classes.timeLineDot} />
                </div>
                <CText className={[classes.timeLineLabel, classes.timeLineLabelRight].join(' ')} level="p2" fontWeight="700" color="light">{playerInjury.end_date}</CText>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '10ch',
          },
        }}
        autoFocus={false}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {addBtn ?
          <MenuItem onClick={() => clickAdd()}>
            <span className={classes.listIconZone}>
              <AddIcon className={classes.listIcon} />
            </span>
            {t('team_user_profile.contents.0.buttons.0.label')}
          </MenuItem> : null
        }
        {deleteBtn ?
          <MenuItem onClick={() => onClickDelete()}>
            <span className={classes.listIconZone}>
              <TrashIcon className={classes.listIcon} />
            </span>
            {t('team_user_profile.contents.0.buttons.1.label')}
          </MenuItem>: null
        }
      </Menu>

      <CModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        content={CONTENT_MODAL}
      >
      </CModal>
    </div>
  )
}

const WeightEvolution = ({userId, season, t}) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      height: '100%'
    },
    title: {
      marginBottom: 30
    },
    titleIcon: {
      fill: COLOR_LIGHT,
      width: 23,
      height: 23,
      marginRight: 10
    },
    subTitle: {
      marginBottom: 15
    },
    card: {
      display: 'block',
      width: '100%',
      overflowX: 'hidden',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      borderRadius: 18,
      listStyleType: 'none',
      padding: 20
    },
    info: {
      backgroundColor: COLOR_DARK,
      borderRadius: 18,
      listStyleType: 'none',
      padding: 20,
      flex: 1
    },
    headBtn:{
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      flex: 1
    },
    chart: {
      maxHeight: 250
    },
    moreBtn: {
      width: 20,
      height: 20,
      padding: 3,
      marginLeft: 5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      fill: COLOR_LIGHT,
      borderRadius: '50%',
      "&&:hover": {
        backgroundColor: '#242526',
        boxShadow: 'none'
      }
    },
    crossBtnZone: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      flex: 1,
      marginTop: -15,
      marginRight: -5
    },
    crossBtn: {
      position: 'absolute',
      width: 10,
      height: 10,
      padding: 6,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      stroke: COLOR_LIGHT,
      borderRadius: '50%',
      backgroundColor: COLOR_PINK,
      cursor: 'pointer',
      "&&:hover": {
        filter: 'brightness(90%)'
      }
    },
    listIcon: {
      width: 20,
      height: 20,
      fill: COLOR_DARK,
      marginRight: 8
    },
    listIconZone: {
      marginRight: 8,
      display: 'flex',
      justifyContent: 'center'
    },
    modal: {
      maxHeight: 400
    },
    modalContainer: {
      [theme.breakpoints.up('xs')]: {
        padding: 20
      },
      [theme.breakpoints.up('sm')]: {
        padding: 20
      },
      [theme.breakpoints.up('md')]: {
        padding: 30
      },
      [theme.breakpoints.up('lg')]: {
        padding: 30
      }
    },
    modalButton: {
      marginTop: 30,
      height: 160
    },
    inputInline: {
      position: 'relative',
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center'
    },
    input: {
      marginBottom: "15px !important"
    },
    inputRight: {
      marginLeft: 10
    }
  }));

  const classes = useStyles();

  const [playerWeights, setPlayerWeights] = useState([]);
  const [weightData, setWeightData] = useState({dates: [], values: []});
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState({type: "", value: false});

  useEffect(() => {  
    const fetchData = async () => {
      await listPlayerWeights()
      setIsLoaded(true)
    }
  
    fetchData()
  }, [])

  const listPlayerWeights = async () => {
    try {
      const filter = {
        user_id: { eq: userId },
        season: { eq: season },
      };
  
      let playerWeights = await API.graphql({
        query: queries.listWeights,
        variables: { filter: filter },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      playerWeights = playerWeights?.data?.listWeights.items;
  
      playerWeights.sort((a, b) => {
        const dateComparison = new Date(a.date) - new Date(b.date);
        if (dateComparison !== 0) {
          return dateComparison;
        }
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
  
      const formattedPlayerWeights = playerWeights.map((item) => ({
        ...item,
        date: formatDate(item.date, i18next.language)
      }));
  
      setPlayerWeights(formattedPlayerWeights);
  
      const weightData = formattedPlayerWeights.reduce((acc, item) => {
        acc.values.push(item.weight);
        acc.dates.push(item.date);
        return acc;
      }, { values: [], dates: [] });
  
      setWeightData(weightData);
    } catch (err) {
      console.log(err);
    }
  };
  
  //Menu
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl);
  const moreBtnClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null)
  };

  const clickAdd = async() => {
    handleClose()
    setModalOpen({type: "add", value: true})
  };

  const clickDelete = () => {
    handleClose()
    setModalOpen({type: "delete", value: true})
  };

  /********* Weight *********/
  const [weight, setWeight] = useState();
  const inputWeight = (data) => {
    const formattedData = data.replace(',', '.');
    const weight = parseFloat(formattedData);
  
    if (!isNaN(weight)) {
      setWeight(weight);
    } else {
      //console.error("Invalid input: not a valid number");
    }
  };

  const addPlayerWeight = async () => {
    const formatedDate = moment().format("YYYY-MM-DD");
  
    const playerWeightInput = {
      season: season,
      date: formatedDate,
      weight: weight,
      user_id: userId,
      userWeightsId: userId
    };

    const createdPlayerWeight = {
      ...playerWeightInput,
      id: 'temp-id',
      date: formatDate(formatedDate, i18next.language)
    };
  
    setPlayerWeights((prevPlayerWeights) => {
      const updatedWeights = [...prevPlayerWeights, createdPlayerWeight];
      return updatedWeights;
    });

    setWeightData((prevWeightData) => ({
      dates: [...prevWeightData.dates, formatDate(formatedDate, i18next.language)],
      values: [...prevWeightData.values, weight]
    }));
  
    // Créer l'objet playerInjury sur le serveur
    const createPlayerWeightResp = await API.graphql({
      query: mutations.createWeight,
      variables: { input: playerWeightInput },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
  
    // Mettre à jour l'ID temporaire avec l'ID du joueur blessé créé sur le serveur
    const { id } = createPlayerWeightResp.data.createWeight;
    createdPlayerWeight.id = id;
  
    // Mettre à jour l'état injuries avec l'objet mis à jour
    setPlayerWeights((prevWeights) =>
      prevWeights.map((item) => (item.id === 'temp-id' ? createdPlayerWeight : item))
    );
  
    setModalOpen({type: "", value: false});
  }

  const onClickDelete = async(id) => {
    const deleteInput = {
      id: id
    };

    await API.graphql({
      query: mutations.deleteWeight,
      variables: { input: deleteInput },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })

    const updatedPlayerWeights = playerWeights.filter((item) => item.id !== id);

    setPlayerWeights(updatedPlayerWeights);

    const weightData = updatedPlayerWeights.reduce((acc, item) => {
      acc.values.push(item.weight)
      acc.dates.push(item.date)
      return acc
    }, { values: [], dates: [] })

    setWeightData(weightData)
  }

  let CONTENT_MODAL
  if(modalOpen.type == "add") {
    CONTENT_MODAL =
    <div className={classes.modalContainer}>
      <TextInput
        className={classes.input}
        label={`${t('team_user_profile.modals.1.inputs.0.label')} *`}
        inputCallback={inputWeight}
        value={weight}
      />

      <CButton
        className={classes.modalButton}
        label={`${t('team_user_profile.modals.1.buttons.0.label')}`}
        type="contained"
        color={COLOR_PRIMARY}
        height={40}
        size={14}
        onClick={() => addPlayerWeight()}
      />
    </div>
  } else if(modalOpen.type == "delete") {
    CONTENT_MODAL =
    <div className={classes.modalContainer}>
      <Grid container spacing={1} alignItems="flex-start">
        {playerWeights?.slice()?.reverse()?.map((playerWeight, index) => (
          <Grid container item xs={12} key={`weight-${index}`}>
            <div className={classes.info}>
              <CText className={classes.subTitle} level="p2" fontWeight="700" color="light">
               {playerWeight.date}:
                <span className={classes.crossBtnZone}>
                  <span className={classes.crossBtn} onClick={() => onClickDelete(playerWeight.id)}>
                    <CrossIcon />
                  </span>
                </span>
              </CText>
              <CText level="p2" fontWeight="700" color="light">
                {playerWeight.weight} kg
              </CText>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  }

  const data = {
    labels: weightData.dates,
    datasets: [
      {
        type: 'line',
        label: ` ${t('team_user_profile.contents.0.sub_contents.1.labels.0')} (kg)`,
        fill: false,
        lineTension: 0.1,
        backgroundColor: COLOR_PURPLE,
        borderColor: COLOR_PURPLE,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 10.0,
        borderJoinStyle: 'miter',
        pointBorderColor: COLOR_PURPLE,
        pointBackgroundColor: COLOR_PURPLE,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: COLOR_PURPLE,
        pointHoverBorderColor: COLOR_PURPLE_TRANSPARENT,
        pointHoverBorderWidth: 10,
        pointWidth: 100,
        pointRadius: 4,
        pointHitRadius: 10,
        data: weightData.values
      }
    ]
  };

  let minValue = Math.floor(Math.min(...weightData.values)) - 10;
  if (minValue < 0) {
    minValue = 0
  }

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1,
    scales: {
      y: {
        ticks: {
          stepSize: 10
        },
        min: minValue
      }
    }
  };

  return(
    <div className={classes.card}>
      <CText className={classes.title} level="h3" fontWeight="700" color="light">
        <WeightIcon className={classes.titleIcon}/>
        {t('team_user_profile.contents.0.sub_contents.1.title')}
        <span className={classes.headBtn}>
          <DotsVerticalIcon className={classes.moreBtn} onClick={moreBtnClick} />
        </span>
      </CText>
      {isLoaded ?
        <Chart data={data} options={options} className={classes.chart} /> : null
      }

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '10ch'
          },
        }}
        autoFocus={false}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <MenuItem onClick={() => clickAdd()}>
          <span className={classes.listIconZone}>
            <AddIcon className={classes.listIcon} />
          </span>
          {t('team_user_profile.contents.0.buttons.0.label')}
        </MenuItem>
        <MenuItem onClick={() => clickDelete()}>
          <span className={classes.listIconZone}>
            <TrashIcon className={classes.listIcon} />
          </span>
          {t('team_user_profile.contents.0.buttons.1.label')}
        </MenuItem>
      </Menu>
      <CModal
        className={classes.modal}
        open={modalOpen.value}
        onClose={() => setModalOpen({type:"", value: false})}
        content={CONTENT_MODAL}
      >
      </CModal>
    </div>
  )
}

const FatMassEvolution = ({userId, season, t}) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      height: '100%'
    },
    title: {
      marginBottom: 30
    },
    titleIcon: {
      fill: COLOR_LIGHT,
      width: 23,
      height: 23,
      marginRight: 10
    },
    subTitle: {
      marginBottom: 15
    },
    card: {
      display: 'block',
      width: '100%',
      overflowX: 'hidden',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      borderRadius: 18,
      listStyleType: 'none',
      padding: 20
    },
    info: {
      backgroundColor: COLOR_DARK,
      borderRadius: 18,
      listStyleType: 'none',
      padding: 20,
      flex: 1
    },
    headBtn:{
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      flex: 1
    },
    chart: {
      maxHeight: 250
    },
    moreBtn: {
      width: 20,
      height: 20,
      padding: 3,
      marginLeft: 5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      fill: COLOR_LIGHT,
      borderRadius: '50%',
      "&&:hover": {
        backgroundColor: '#242526',
        boxShadow: 'none'
      }
    },
    crossBtnZone: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      flex: 1,
      marginTop: -15,
      marginRight: -5
    },
    crossBtn: {
      position: 'absolute',
      width: 10,
      height: 10,
      padding: 6,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      stroke: COLOR_LIGHT,
      borderRadius: '50%',
      backgroundColor: COLOR_PINK,
      cursor: 'pointer',
      "&&:hover": {
        filter: 'brightness(90%)'
      }
    },
    listIcon: {
      width: 20,
      height: 20,
      fill: COLOR_DARK,
      marginRight: 10
    },
    listIconZone: {
      marginRight: 8,
      display: 'flex',
      justifyContent: 'center'
    },
    modal: {
      maxHeight: 400
    },
    modalContainer: {
      [theme.breakpoints.up('xs')]: {
        padding: 20
      },
      [theme.breakpoints.up('sm')]: {
        padding: 20
      },
      [theme.breakpoints.up('md')]: {
        padding: 30
      },
      [theme.breakpoints.up('lg')]: {
        padding: 30
      }
    },
    modalButton: {
      marginTop: 30,
      height: 160
    },
    inputInline: {
      position: 'relative',
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center'
    },
    input: {
      marginBottom: "15px !important"
    },
    inputRight: {
      marginLeft: 10
    }
  }));

  const classes = useStyles();

  const [playerBodyFats, setPlayerBodyFats] = useState([]);
  const [bodyFatData, setBodyFatData] = useState({dates: [], values: []});
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState({type: "", value: false});

  useEffect(() => {  
    const fetchData = async () => {
      await listPlayerBodyFats()
      setIsLoaded(true)
    }
  
    fetchData()
  }, [])

  const listPlayerBodyFats = async () => {
    try {
      const filter = {
        user_id: { eq: userId },
        season: { eq: season }
      };
  
      let playerBodyFats = await API.graphql({
        query: queries.listBodyFats,
        variables: { filter: filter },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      playerBodyFats = playerBodyFats?.data?.listBodyFats.items;
  
      playerBodyFats.sort((a, b) => {
        const dateComparison = new Date(a.date) - new Date(b.date);
        if (dateComparison !== 0) {
          return dateComparison;
        }
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
  
      const formattedPlayerBodyFats = playerBodyFats.map((item) => ({
        ...item,
        date: formatDate(item.date, i18next.language)
      }));
  
      setPlayerBodyFats(formattedPlayerBodyFats);
  
      const bodyFatData = formattedPlayerBodyFats.reduce((acc, item) => {
        acc.values.push(item.body_fat_percentage);
        acc.dates.push(item.date);
        return acc;
      }, { values: [], dates: [] });
  
      setBodyFatData(bodyFatData);
  
    } catch (err) {
      console.log(err);
    }
  };

  //Menu
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl);
  const moreBtnClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null)
  };

  const clickAdd = async() => {
    handleClose()
    setModalOpen({type: "add", value: true})
  };

  const clickDelete = () => {
    handleClose()
    setModalOpen({type: "delete", value: true})
  };

  /********* BodyFat *********/
  const [bodyFat, setBodyFat] = useState();
  const inputBodyFat = (data) => {
    const formattedData = data.replace(',', '.');
    const bodyFatValue = parseFloat(formattedData);
  
    if (!isNaN(bodyFatValue)) {
      setBodyFat(bodyFatValue);
    } else {
      //console.error("Invalid input: not a valid number");
    }
  };

  const addPlayerBodyFat = async () => {
    const formatedDate = moment().format("YYYY-MM-DD");
  
    const playerBodyFatInput = {
      body_fat_percentage: bodyFat,
      date: formatedDate,
      season: season,
      user_id: userId,
      userBody_fatsId: userId
    }

    const createdPlayerBodyFat = {
      ...playerBodyFatInput,
      id: 'temp-id',
      date: formatDate(formatedDate, i18next.language)
    };
  
    setPlayerBodyFats((prevPlayerBodyFat) => {
      const updatedPlayerBodyFat = [...prevPlayerBodyFat, createdPlayerBodyFat];
      return updatedPlayerBodyFat;
    });

    setBodyFatData((prevPlayerBodyFat) => ({
      dates: [...prevPlayerBodyFat.dates, formatDate(formatedDate, i18next.language)],
      values: [...prevPlayerBodyFat.values, bodyFat]
    }));
  
    // Créer l'objet playerInjury sur le serveur
    const createPlayerBodyFatResp = await API.graphql({
      query: mutations.createBodyFat,
      variables: { input: playerBodyFatInput },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
  
    // Mettre à jour l'ID temporaire avec l'ID du joueur blessé créé sur le serveur
    const { id } = createPlayerBodyFatResp.data.createBodyFat;
    createdPlayerBodyFat.id = id;
  
    // Mettre à jour l'état injuries avec l'objet mis à jour
    setPlayerBodyFats((prevPlayerBodyFats) =>
      prevPlayerBodyFats.map((item) => (item.id === 'temp-id' ? createdPlayerBodyFat : item))
    );
  
    setModalOpen({type: "", value: false});
  }

  const onClickDelete = async(id) => {
    const deleteInput = {
      id: id
    };

    await API.graphql({
      query: mutations.deleteBodyFat,
      variables: { input: deleteInput },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })

    const updatedPlayerBodyFats = playerBodyFats.filter((item) => item.id !== id);

    setPlayerBodyFats(updatedPlayerBodyFats);

    const bodyFatData = updatedPlayerBodyFats.reduce((acc, item) => {
      acc.values.push(item.body_fat_percentage)
      acc.dates.push(item.date)
      return acc
    }, { values: [], dates: [] })

    setBodyFatData(bodyFatData)
  }

  let CONTENT_MODAL
  if(modalOpen.type == "add") {
    CONTENT_MODAL =
    <div className={classes.modalContainer}>
      <TextInput
        className={classes.input}
        label={`${t('team_user_profile.modals.2.inputs.0.label')} *`}
        inputCallback={inputBodyFat}
        value={bodyFat}
      />

      <CButton
        className={classes.modalButton}
        label={`${t('team_user_profile.modals.2.buttons.0.label')}`}
        type="contained"
        color={COLOR_PRIMARY}
        height={40}
        size={14}
        onClick={() => addPlayerBodyFat()}
      />
    </div>
  } else if(modalOpen.type == "delete") {
    CONTENT_MODAL =
    <div className={classes.modalContainer}>
      <Grid container spacing={1} alignItems="flex-start">
        {playerBodyFats?.slice()?.reverse()?.map((playerBodyFat, index) => (
          <Grid container item xs={12} key={`body-fat-${index}`}>
            <div className={classes.info}>
              <CText className={classes.subTitle} level="p2" fontWeight="700" color="light">
               {playerBodyFat.date}:
                <span className={classes.crossBtnZone}>
                  <span className={classes.crossBtn} onClick={() => onClickDelete(playerBodyFat.id)}>
                    <CrossIcon />
                  </span>
                </span>
              </CText>
              <CText level="p2" fontWeight="700" color="light">
                {playerBodyFat.body_fat_percentage} %
              </CText>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  }

  const data = {
    labels: bodyFatData.dates,
    datasets: [
      {
        type: 'line',
        label: ` ${t('team_user_profile.contents.0.sub_contents.2.labels.0')}`,
        fill: false,
        lineTension: 0.1,
        backgroundColor: COLOR_BLUE,
        borderColor: COLOR_BLUE,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 10.0,
        borderJoinStyle: 'miter',
        pointBorderColor: COLOR_BLUE,
        pointBackgroundColor: COLOR_BLUE,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: COLOR_BLUE,
        pointHoverBorderColor: COLOR_BLUE_TRANSPARENT,
        pointHoverBorderWidth: 10,
        pointWidth: 100,
        pointRadius: 4,
        pointHitRadius: 10,
        data: bodyFatData.values
      }
    ],
  };

  let minValue = Math.floor(Math.min(...bodyFatData.values)) - 10;
  if (minValue < 0) {
    minValue = 0
  }

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1,
    scales: {
      y: {
        ticks: {
          stepSize: 10
        },
        min: minValue
      }
    }
  };

  return(
    <div className={classes.card}>
      <CText className={classes.title} level="h3" fontWeight="700" color="light">
        <FatMassIcon className={classes.titleIcon}/>
        {t('team_user_profile.contents.0.sub_contents.2.title')}
        <span className={classes.headBtn}>
          <DotsVerticalIcon className={classes.moreBtn} onClick={moreBtnClick}/>
        </span>
      </CText>
      {isLoaded ?
        <Chart data={data} options={options} className={classes.chart} /> : null
      }

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '10ch'
          },
        }}
        autoFocus={false}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={() => clickAdd()}>
          <span className={classes.listIconZone}>
            <AddIcon className={classes.listIcon} />
          </span>
          {t('team_user_profile.contents.0.buttons.0.label')}
        </MenuItem>
        <MenuItem onClick={() => clickDelete()}>
          <span className={classes.listIconZone}>
            <TrashIcon className={classes.listIcon} />
          </span>
          {t('team_user_profile.contents.0.buttons.1.label')}
        </MenuItem>
      </Menu>
      <CModal
        className={classes.modal}
        open={modalOpen.value}
        onClose={() => setModalOpen({type:"", value: false})}
        content={CONTENT_MODAL}
      >
      </CModal>
    </div>
  )
}

const TeamPlayer = (props) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      height: '100%',
      [theme.breakpoints.up('md')]: {
        marginLeft: MENU_WIDTH
      }
    },
    userInfo: {
      marginTop: 0,
      marginRight: 0,
      marginLeft: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 30
    },
    seasonSummary: {
      display: "flex",
      flexDirection: 'column',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 60
    },
    seasonSummaryTitle: {
      marginBottom: 15
    },
    infoTitle: {
      marginBottom: 30
    },
    seasonSummaryDataZone: {
      display: "flex",
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    },
    separator: {
      width: 2,
      borderRadius: 10,
      height: 22,
      backgroundColor: COLOR_LIGHT,
      marginLeft: 15,
      marginRight: 15
    },
    profilePicture: {
      border: '3px solid',
      marginRight: 20,
      [theme.breakpoints.up('xs')]: {
        width: 130,
        height: 130
      },
      [theme.breakpoints.up('sm')]: {
        width: 150,
        height: 150
      },
      [theme.breakpoints.up('md')]: {
        width: 180,
        height: 180
      },
      [theme.breakpoints.up('lg')]: {
        width: 200,
        height: 200
      }
    },
    name: {
      marginBottom: 10
    },
    team:{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    teamLogoZone: {
      height: 30,
      width: 30,
      textAlign: 'center',
      marginRight: 5
    },
    teamLogo: {
      height: '100%',
      width: 'auto',
    },
    position: {
      marginRight: 15
    },
    btnContainer: {
      width: 180,
      marginTop: 20
    },
    subTitle:{
      fill: COLOR_LIGHT,
      marginBottom: 15
    },
    subTitleIcon: {
      width: 25,
      height: 25,
      marginRight: 10
    },
    card: {
      backgroundColor: ZONE_BACKGROUND_COLOR
    }
  }));

  const classes = useStyles()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const [userId, setUserId] = useState({});
  const [user, setUser] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [season, setSeason] = useState(null)

  // Get User
  const userStored = useSelector((state) => state?.user)
  const userPhotoStored = userStored.photo_path

  useEffect(() => {
    const fetchData = async () => {
      const userId = await getUserId();
      await getUserInfo(userId);
      setUserId(userId);
      const currentSeason = await getCurrentSeason()
      setSeason(currentSeason)
      setIsLoaded(true)
    }
  
    fetchData();
  }, [userStored])

  const getUserId = async () => {
    try {
      const url = window.location.pathname;
      const parts = url.split('/');
      const playerId = parts[parts.length - 1];
  
      return playerId;
    } catch (err) {
      console.log(err);
    }
  };

  const getUserInfo = async (userId) => {
    try {
      let user = await API.graphql({
        query: queries.getUser,
        variables: { id: userId },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
      user = user?.data?.getUser
      setUser(user)
    } catch (err) {
      console.log(err);
    }
  };

  // Handle Add profile photo
  const [photoModalOpen, setPhotoModalOpen] = useState(false);

  const MENU = [
    {
      icon: <MedicalKitIcon className={classes.subTitleIcon}/>,
      label: t('team_user_profile.contents.0.title')
    }
  ]

  return (
    <div className={classes.container}>
      {isLoaded ?
        <div>
          <div className={classes.userInfo}>
            <ProfilePicture
              className={classes.profilePicture}
              src={user?.photo_path}
            />
            <div>
              <CText level="h2" color="light" className={classes.name}>
                {user.first_name} {user.last_name}
              </CText>
            </div>
          </div>
          
          <CText
            level="h1"
            color="light"
            fontWeight={700}
            className={classes.subTitle}
          >
            {MENU[0].icon} {MENU[0].label} 
          </CText>
          <Grid container spacing={1} alignItems="flex-start">
            <Grid container item xs={12} alignItems="center">
              <Injuries userId={userId} season={season} t={t} />
            </Grid>
            <Grid container item xs={12} alignItems="center">
              <WeightEvolution userId={userId} season={season} t={t} />
            </Grid>
            <Grid container item xs={12} alignItems="center">
              <FatMassEvolution userId={userId} season={season} t={t} />
            </Grid>
          </Grid>
        </div>
        : null
      }
    </div>
  )
}

export default TeamPlayer;