import React, { useState, useEffect, useMemo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@mui/material/Tooltip';
import { ClubPhoto } from "../Photos/ClubPhoto";
import { CText } from "../../components/Text";
import { ReactComponent as HistoricalIcon } from '../../assets/icons/historical.svg';

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = '#242834';
const COLOR_RED = "#fc3c2e";
const COLOR_YELLOW = "#EDA247";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 16,
    height: 16,
    fill: COLOR_LIGHT
  },
  playerPhoto: {
    width: 35,
    height: 35,
    borderRadius: '50%',
    border: '1px solid',
    marginRight: 10
  },
  teamLogo: {
    maxWidth: 22,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  teamLabel: {
    marginLeft: 8
  },
  noDataIconZone: {
    width: 28,
    height: 28,
    padding: 15,
    backgroundColor: COLOR_DARK,
    borderRadius: '50%',
    marginRight: 10
  },
  noDataIcon: {
    width: '100%',
    height: '100%',
    fill: COLOR_PRIMARY
  }
}));

const createData = (column1, column2, column3, column4, column5, column6, column7, column8, column9) => {
  return {
    column1,
    column2,
    column3,
    column4,
    column5,
    column6,
    column7,
    column8,
    column9
  };
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const EnhancedTableHead = (props) => {
  const classes = useStyles();
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells = []
  } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const renderSkeletons = () => (
    Array.from(new Array(headCells.length)).map((_, index) => (
      <TableCell
        key={index}
        style={{ color: COLOR_LIGHT, borderBottom: 'none' }}
        align="center"
      >
        <Skeleton variant="rounded" height={22} />
      </TableCell>
    ))
  );

  return (
    <TableHead>
      <TableRow>
        {Array.isArray(headCells) && headCells.length > 0 ? (
          headCells.map((headCell, index) => (
            <TableCell
              style={{
                color: COLOR_LIGHT,
                borderBottom: 'none'
              }}
              key={headCell.id}
              align={headCell.align}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  fontSize: 14,
                  fontWeight: 700,
                  cursor: 'pointer',
                  '&.Mui-selected': {
                    '& > *': {
                      color: COLOR_LIGHT,
                    },
                  },
                  '&.Mui-active': {
                    color: COLOR_LIGHT,
                    '& .MuiSvgIcon-root:not(.Mui-active)': {
                      fill: COLOR_LIGHT
                    }
                  },
                  '&:hover': {
                    color: COLOR_LIGHT,
                    filter: 'brightness(90%)',
                    '& > *': {
                      filter: 'brightness(90%)'
                    }
                  }
                }}
              >
                {headCell.icon ?
                  <Tooltip title={`${headCell.label}`}>
                    {headCell.icon}
                  </Tooltip>
                  : headCell.label
                }
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))
        ) : (
          renderSkeletons()
        )}
      </TableRow>
    </TableHead>
  );
};



const CTable = ({ headCells, data, loading }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("column1");
  const [selected, setSelected] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      const newRows = data.map((item) => {
        return createData(...item);
      });
      setRows(newRows);
    }
  }, [data]);

  const visibleRows = useMemo(() => {
    return stableSort(rows, getComparator(order, orderBy));
  }, [rows, order, orderBy]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = rows.map(n => n.player);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, player) => {
    const selectedIndex = selected.indexOf(player);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, player);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <TableContainer>
      <Table aria-labelledby="tableTitle">
        <EnhancedTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
          translation={t}
          headCells={headCells}
        />
          <TableBody>
            {loading ? (
              Array.from({ length: 5 }).map((_, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  sx={{ backgroundColor: rowIndex % 2 === 0 ? '#24262a' : '#1d2027' }}
                >
                  {Array.isArray(headCells) && headCells.length > 0 ? (
                    headCells.map((headCell, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        align={headCell.align}
                        style={{ borderBottom: 'none', color: COLOR_LIGHT }}
                      >
                        <Skeleton variant="rounded" animation="wave" width="100%" height={22} />
                      </TableCell>
                    ))
                  ) : (
                    Array.from({ length: 5 }).map((_, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        align="center"
                        style={{ borderBottom: 'none', color: COLOR_LIGHT }}
                      >
                        <Skeleton variant="rounded" animation="wave" width="100%" height={22} />
                      </TableCell>
                    ))
                  )}
                </TableRow>
              ))
            ) : (
              rows.length > 0 ? visibleRows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const rowColor = index % 2 === 0 ? '#24262a' : '#1d2027';

                return (
                  <TableRow
                    onClick={event => handleClick(event, row.column1)}
                    tabIndex={-1}
                    key={index}
                    sx={{
                      cursor: "pointer",
                      backgroundColor: rowColor,
                      '&:hover': {
                        filter: 'brightness(90%)'
                      },
                      borderBottom: 'none',
                      color: COLOR_LIGHT
                    }}
                  >
                    <TableCell
                      align="left"
                      component="th"
                      id={labelId}
                      scope="row"
                      style={{ borderBottom: 'none', color: COLOR_LIGHT, width: 90 }}
                    >
                      {row.column1}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ borderBottom: 'none', color: COLOR_LIGHT, width: 180, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    >
                      <ClubPhoto
                        className={classes.teamLogo}
                        src={row.column2}
                      />
                      <span className={classes.teamLabel}>
                        {row.column3}
                      </span>
                    </TableCell>
                    <TableCell align="left" style={{ borderBottom: 'none', color: COLOR_LIGHT, width: 120 }}>{row.column4}</TableCell>
                    <TableCell align="center" style={{ borderBottom: 'none', color: COLOR_LIGHT, paddingRight: '40px', width: 40 }}>{row.column5}</TableCell>
                    <TableCell align="center" style={{ borderBottom: 'none', color: COLOR_LIGHT, paddingRight: '40px', width: 40 }}>{row.column6}</TableCell>
                    <TableCell align="center" style={{ borderBottom: 'none', color: COLOR_LIGHT, paddingRight: '40px', width: 40 }}>{row.column7}</TableCell>
                    <TableCell align="center" style={{ borderBottom: 'none', color: COLOR_LIGHT, paddingRight: '40px', width: 40 }}>{row.column8}</TableCell>
                    {headCells.length >= 8 ? <TableCell align="center" style={{ borderBottom: 'none', color: COLOR_LIGHT, paddingRight: '40px', width: 40 }}>{row.column9}</TableCell> : null}
                  </TableRow>
                );
              }) : (
                <TableRow>
                  <TableCell colSpan={headCells.length} align="center" style={{ borderBottom: 'none', color: COLOR_LIGHT }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                      <div className={classes.noDataIconZone}>
                        <HistoricalIcon className={classes.noDataIcon} />
                      </div>
                      <CText level="p1" fontWeight={700} color="primary">{t('no_historical.title')}</CText>
                    </div>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CTable;