import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { CText } from "../Text";

const useStyles = makeStyles((theme) => ({
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginRight: theme.spacing(0.1),
  },
}));

const CustomSwitch = ({ label, checked, onChange, className }) => {
  const classes = useStyles();

  const handleSwitchChange = (event) => {
    event.stopPropagation(); // Empêcher la propagation du clic
    onChange(event);
  };

  const handleLabelClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div 
      className={`${classes.switchContainer} ${className}`}
      onClick={handleLabelClick}
    >
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={handleSwitchChange}
          />
        }
        label={<CText level="p1" color="light">{label}</CText>}
        labelPlacement="start"
        classes={{
          label: classes.label,
        }}
      />
    </div>
  );
};

export default CustomSwitch;
