export const getTeamIdFromUrl = (url, teams) => {
  const regex = /\/teams\/([^/]+)/;
  const match = url.match(regex);
  if (match) {
      const encodedName = match[1];
      const decodedName = decodeURIComponent(encodedName);

      for (let i = 0; i < teams.length; i++) {
          if (teams[i].name === decodedName) {
              return teams[i].id;
          }
      }
  }
  return null;
}

export const getTeamIdFromUrlParams = async(url, teams) => {
    const params = new URLSearchParams(window.location.search);

    if (params.has('team')) {
        const teamName = decodeURIComponent(params.get('team'));
        
        for (let i = 0; i < teams.length; i++) {
            if (teams[i].name === teamName) {
                return teams[i].id;
            }
        }
    }
    return null;
}