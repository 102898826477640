import React from 'react';
import { useLocation } from 'react-router-dom';
import TacticsList from './TacticsList.js';
import Tactic from "./Tactic.js";

const Tactics = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tacticId = searchParams.get('id');

  return (
    <>
      {tacticId ? <Tactic /> : <TacticsList />}
    </>
  );
}

export default Tactics;