import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import { CText } from "../Text.js"
import { ReactComponent as Warning } from '../../assets/icons/warning.svg'

const LABEL_COLOR = '#101116';
const TEXTFIELD_COLOR = '#242834';
const COLOR_LIGHT = "#f4f4f4";
const iconSize = 28;
const COLOR_SNACK_ERROR = "#3b0108";

const useStyles = makeStyles((theme) => ({
  snackBarError: {
    "& .MuiSnackbarContent-root": {
      backgroundColor: COLOR_SNACK_ERROR,
      opacity: 0.3,
      borderRadius: 25,
      maxWidth: 300
    }
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5
  },
  icon: {
    width: 18,
    height: 18,
    fill: COLOR_LIGHT,
    marginRight: 5
  },
  list: {
    marginLeft: 12
  }
}));

export const SnackBarError = ({ open, onClose, autoHideDuration, message, anchorOrigin }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return(
    <Snackbar
      className={classes.snackBarError}
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      anchorOrigin={anchorOrigin}
      message={
        <div>
          <CText level="p3" color="light" label={`${t('snack_bar.error.title')}`} fontWeight={700} className={classes.title} />
          {message}
        </div>
      }
    />
  );
}

export const BadRequestSnackBar = ({ open, onClose, autoHideDuration, errorElmts, anchorOrigin, key, title, message }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return(
    <Snackbar
      className={classes.snackBarError}
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      anchorOrigin={anchorOrigin}
      message={
        <div>
          <div className={classes.titleContainer}>
            <Warning className={classes.icon}/>
            <CText level="p3" color="light" label={title ? title : `${t('snack_bars.0.title')}`} fontWeight={700} className={classes.title} />
          </div>
          <div>
            <CText
              level="p3"
              fontWeight="700"
              color="ligth"
              label={message}
            />
            {errorElmts.length > 0 ?
              <ul className={classes.list}>
                {errorElmts.map((errorElmt, index) => (
                  <li key={index}>
                    <CText
                      level="p3"
                      fontWeight="700"
                      color="ligth"
                      label={`• ${errorElmt}`}
                    />
                  </li>
                ))}
              </ul> : null
            }
          </div>
        </div>
      }
    />
  );
}