import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as TeamFlagIcon } from '../../assets/icons/team-flag.svg'
import { Storage } from 'aws-amplify'
import { useSelector } from 'react-redux'
import Skeleton from '@mui/material/Skeleton';

const COLOR_LIGHT = "#f4f4f4"

const useStyles = makeStyles((theme) => ({
  teamIcon: {
    fill: COLOR_LIGHT,
    width: 30,
    height: 30
  }
}));

export const ClubPhoto = ({ className, onClick, src, onLoad, owner, loading, size }) => {  
  const classes = useStyles();

  const [loaded, setLoaded] = useState(false);
  const [photo, setPhoto] = useState("");

  // Get User stored
  const userStored = useSelector((state) => state?.user);
  const userPhotoStored = userStored?.photo_path;

  const getPhoto = async (abortSignal) => {
    let userPhoto;
    if(src) {
      userPhoto = await Storage.get(src, { level: 'public' });
      if (!abortSignal.aborted) setPhoto(userPhoto);
    } else if(userPhotoStored && owner) {
      userPhoto = await Storage.get(userPhotoStored, { level: 'public' });
      if (!abortSignal.aborted) setPhoto(userPhoto);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      await getPhoto(abortController.signal);
      if (!abortController.signal.aborted) setLoaded(true);
    };

    fetchData();

    return () => {
      abortController.abort();
    };
  }, [userStored, src, owner]);

  let content;
  if(photo) {
    content = <img
      className={[className].join(' ')}
      src={photo}
      onLoad={onLoad}
    />;
  } else {
    content = <TeamFlagIcon className={[className, classes.teamIcon].join(' ')}
    style={{width: size ? size : null }}
    />;
  }

  return(
    <>
    { (loading === undefined || loading === false) ?
      <span className={[className].join(' ')} onClick={onClick}>
        {content}
      </span>
      :
      <div className={[classes.photoZoneSkeleton, className].join(' ')} onClick={onClick}>
        <Skeleton variant="circular" width='100%' height='100%' />
      </div>
    }
    </>
  );
}

