import { makeStyles } from "@material-ui/core/styles"

const TOP_BAR_HEIGHT = 56
const COLOR_LIGHT = "#f4f4f4"
const COLOR_PRIMARY = "#45d69f"
const COLOR_PINK= "#e92163"
const HORIZONTAL_PADDING = 30
const VERTICAL_CONTENT_PADDING = 25
const MENU_WIDTH = 250

export const useStylesTopBar = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#1A1919',
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '3%',
      paddingRight: '3%'
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 30,
      paddingRight: 30
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 45,
      paddingRight: 45
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 50,
      paddingRight: 50
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 80,
      paddingRight: 80
    },
    paddingTop: 'env(safe-area-inset-top)'
  },
  toolbar: {
    minHeight: TOP_BAR_HEIGHT,
    padding: 0
  },
  ligthContentZone: {
    backgroundColor: COLOR_LIGHT
  },
  contentWrapper: {
    marginLeft: MENU_WIDTH
  },
  logo: {
    width: 40,
    height: 40,
    marginRight: 10
  },
  team: {
    borderRadius: 10,
    border: `1px solid ${COLOR_PRIMARY}`,
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: 5,
    paddingLeft: 5,
    marginRight: 5,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    "&:hover": {
      filter: 'brightness(90%)'
    }
  },
  teamLogoZone: {
    height: 30,
    width: 30,
    textAlign: 'center',
    marginRight: 3
  },
  teamLogo: {
    height: '100%',
    width: 'auto'
  },
  content: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '3%',
      paddingRight: '3%'
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 30,
      paddingRight: 30
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 45,
      paddingRight: 45
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 50,
      paddingRight: 50
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 80,
      paddingRight: 80
    }
  },
  navBarLeftItems:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  navBarRightItems:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dropdown: {
    position: "absolute",
    top: 58,
    border: "var(--border)",
    borderRadius: "var(--border-radius)",
    padding: "1rem",
    overflow: "hidden",
    transition: "height var(--speed) ease",
    backgroundColor: "var(--bg)",
    [theme.breakpoints.down('xs')]: {
      transform: "translateX(-0%)",
      right: 0,
      left: 0
    },
    [theme.breakpoints.up('sm')]: {
      transform: "translateX(-45%)",
      width: 320
    },
  },
  playerPhoto: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    border: '1px solid'
  },
  iconBtnFocused: {
    backgroundColor: '#232e2f',
    '& svg': {
      fill: '#45d69f',
      stroke: '#45d69f'
    }
  }
}));