import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getClubId, getSession } from "../../utils/auth";
import { getCurrentSeason } from "../../utils/season";
import * as queries from '../../graphql/queries';
import { API } from 'aws-amplify';

export const signIn = createAsyncThunk('user/signIn', async (_, thunkAPI) => {
  const session = await getSession();

  const groups = session.idToken.payload['cognito:groups'];
  const userId = session.idToken.payload.sub;
  let [clubId, listInvitations, clubNames] = ["", null, []];
  let teams = [];
  let res = {};
  if (groups.includes("Coaches") || groups.includes("Players")) {
    const currentSeason = await getCurrentSeason();
    const filter = {
      season: { eq: currentSeason },
      user_id: { eq: userId },
      invite_status: { eq: 1 }
    };
    teams = await API.graphql({
      query: queries.listInvitations,
      variables: { filter: filter },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });

    teams = teams.data.listInvitations.items;
    clubId = teams[0]?.team?.club?.id;

    if (teams) {
      teams[0]?.team?.club?.names.forEach(name => {
        clubNames.push(JSON.parse(name));
      });
    }

    res.teamId = teams ? teams[0]?.team?.id : null;
    res.teamName = teams ? teams[0]?.team?.name : null;
  } else if (groups.includes("ClubAdmins")) {
    clubId = await getClubId();

    const teamsFilter = {
      club_id: { eq: clubId },
      deleted: { eq: false }
    };

    let teamsList = await API.graphql({
      query: queries.listClubTeams,
      variables: { filter: teamsFilter },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });

    teamsList = teamsList.data.listTeams.items;

    const teamsListOrdered = teamsList.sort((a, b) => {
      const numberA = parseInt(a.name.replace(/\D/g, ''), 10) || 0;
      const numberB = parseInt(b.name.replace(/\D/g, ''), 10) || 0;
      return numberA - numberB;
    });

    teams = teamsListOrdered;
    res.teamId = teams ? teams[0]?.id : null;
    res.teamName = teams ? teams[0]?.name : null;
  }

  res.userId = userId;
  res.groups = groups;
  res.clubId = clubId;
  res.clubNames = clubNames;
  res.clubLogoPath = teams ? teams[0]?.team?.club?.logo : null;

  return res;
});

export const addTeam = createAsyncThunk('user/addTeam', async (data, { getState }) => {
  const state = getState();
  const { user } = state;

  const result = {
    clubId: data.clubId,
    teamId: data.teamId,
    season: data.season
  };

  const persistRoot = localStorage.getItem('persist:root');
  if (persistRoot) {
    const parsedPersistRoot = JSON.parse(persistRoot);
    
    if (parsedPersistRoot.user) {
      const user = JSON.parse(parsedPersistRoot.user);

      user.club = {
        ...user.club,
        id: result.clubId,
        team: {
          ...user.club.team,
          id: result.teamId
        }
      };

      localStorage.setItem('persist:root', JSON.stringify({
        ...parsedPersistRoot,
        user: JSON.stringify(user)
      }));
    }
  }

  return result;
});


export const getTeam = createAsyncThunk('user/getTeam', async () => {
  // Récupérer la valeur du localStorage
  const persistRoot = localStorage.getItem('persist:root');
  if (persistRoot) {
    // Parser la valeur récupérée
    const parsedPersistRoot = JSON.parse(persistRoot);
    if (parsedPersistRoot.user) {
      // Récupérer l'objet utilisateur
      const user = JSON.parse(parsedPersistRoot.user);

      // Vérifier si l'utilisateur a une équipe
      if (user.club && user.club.team) {
        return {
          teamId: user.club.team.id || null,
          teamName: user.club.team.name || null,
          clubId: user.info.club.id || null,
          clubNames: user.club.names || [],
          clubLogoPath: user.club.logo_path || null
        };
      }
    }
  }

  return {
    teamId: null,
    teamName: null,
    clubId: null,
    clubNames: [],
    clubLogoPath: null
  };
});
export const updateTeam = createAsyncThunk('user/updateTeam', async (data) => {
  return {
    id: data.id,
    name: data.name,
    season: data?.season,
    club: { id: data.club.id, name: data.club.name, }
  };
});

export const setPhotoPath = createAsyncThunk('user/setPhotoPath', async (data) => {
  return { photoPath: data.photoPath }
});

export const setClubLogoPath = createAsyncThunk('user/setClubLogoPath', async (data) => {
  return { photoPath: data.photoPath }
});

export const user = createSlice({
  name: 'user',
  initialState: {
    info: {
      id: ""
    },
    first_name: "",
    last_name: "",
    groups: [],
    club: {},
    isAuthenticated: false
  },
  reducers: {
    signOut: (state) => {
      state.isAuthenticated = false
      state.info = {}
      state.groups = []
      state.club = {}
      state.first_name = ""
      state.last_name = ""
      state.photo_path = ""
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.fulfilled, (state, { payload }) => {
        state.isAuthenticated = true;
        state.info.id = payload.userId;
        state.groups = payload.groups;
        state.club = {
          id: payload.clubId,
          names: payload.clubNames,
          logo_path: payload.clubLogoPath,
          team: {
            id: payload.teamId,
            name: payload.teamName
          }
        };
      })
      .addCase(addTeam.fulfilled, (state, { payload }) => {
        state.club.season = payload.season;
        state.club.id = payload.clubId;
        state.club.team = {
          id: payload.teamId
        };
      })
      .addCase(setPhotoPath.fulfilled, (state, { payload }) => {
        if (payload.photoPath !== "") {
          state.photo_path = payload.photoPath;
        }
      })
      .addCase(updateTeam.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateTeam.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        if (!state.club) {
          state.club = {};
        }
        state.club.team = {
          id: payload.id,
          name: payload.name
        };
        state.club.season = payload?.season;
        state.club.id = payload.club.id;
      })
      .addCase(updateTeam.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error = error.message;
      })
      .addCase(getTeam.fulfilled, (state, { payload }) => {
        state.club.id = payload.clubId;
        state.club.team = {
          id: payload.teamId
        };
      });
  }  
})

export const { signOut } = user.actions;
export const getIsAuthenticated = (state) => state?.user.isAuthenticated;
export default user.reducer;