import {makeStyles} from "@material-ui/core/styles"

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_RED = "#fc3c2e"
const COLOR_YELLOW = "#EDA247"
const COLOR_BLUE = "#25a5e6";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const ZONE_BACKGROUND_COLOR_2 = "#242834";
const BUTTON_WIDTH = 100;
const COLOR_DARK = "#242834";
const MENU_WIDTH = 250;

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexGrow: 1,
    paddingTop: 0,
    paddingBottom: 0,
    paddingTop: 15,
    paddingBottom: 15,
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '3%',
      paddingRight: '3%'
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 30,
      paddingRight: 30
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 45,
      paddingRight: 45
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 60,
      paddingRight: 60
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 80,
      paddingRight: 80
    }
  },
  content: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center'
  },
  formBtn: {
    width: '100%',
    marginTop: 35
  },
  input: {
    marginBottom: '15px !important'
  },
  inputDeleteBtn: {
    marginLeft: 8,
    minWidth: '40px !important',
    width: '47.99px !important',
    height: '47.99px !important',
    paddingRight: '0px !important',
    paddingLeft: '0px !important',
    paddingTop: '0px !important',
    paddingBottom: '0px !important'
  },
  inputDeleteBtnIcon: {
    width: 22,
    height: 22
  },
  inputAddBtnWrapper: {
    width: '100%'
  },
  inputAddBtn: {
    marginTop: -8,
    maxWidth: 100,
    height: '30px !important',
    float: 'left'
  },
  multiInput: {
    width: '100%',
    marginBottom: 20
  },
  radioInput: {
    backgroundColor: ZONE_BACKGROUND_COLOR_2,
    width: 'calc(100% - 20px)',
    paddingTop: 6,
    paddingBottom: 12,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 8
  },
  competitionsInput: {
    backgroundColor: ZONE_BACKGROUND_COLOR_2,
    width: 'calc(100% - 20px)',
    paddingTop: 6,
    paddingBottom: 12,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 8
  },
  competitionsInputLabel: {
    fontSize: "14px !important",
    marginBottom: 5
  },
  competitionLabel: {
    fontSize: "16px !important",
    fontWeight: 500,
    display: 'flex',
    maxWidth: 270,
    width: '100%',
    alignItems: 'center',
    "&:not(:last-child)": {
      marginBottom: "5px",
    }
  },
  competitionAddBtn: {
    height: 20,
    marginLeft: 'auto'
  },
  competitionIcon: {
    fill: COLOR_LIGHT,
    width: 22,
    height: 22,
    marginRight: 5
  },
  lastInput: {
    marginBottom: 0
  },
  inputInline: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  inputRight: {
    marginLeft: 15
  },
  textZone: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20
  },
  title: {
    marginBottom: 20
  },
  form: {
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flex: 1
    },
    [theme.breakpoints.up('lg')]: {
      flex: 1
    }
  },
  image: {
    height: '100%'
  },
  formZone: {
    backgroundColor: '#1A1919',
    borderRadius: 25,
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 550,
    padding: 30
  },
  labelBtn: {
    all: 'unset',
    cursor: 'pointer',
    marginLeft: 8
  },
  labelBtnLabel: {
		"&:active, &:focus": {
      filter: 'brightness(90%)'
		}
  },
  labelBtnWrapper: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'right',
    width: '100%'
  },
  photo: {
    border: '3px solid',
    marginRight: 20,
    borderRadius: '50%',
    [theme.breakpoints.up('xs')]: {
      width: '100%',
      maxWidth: 140,
      height: 140
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      maxWidth: 150,
      height: 150
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      maxWidth: 165,
      height: 165
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      maxWidth: 165,
      height: 165
    },
    cursor: 'pointer',
    "&:hover": {
      filter: 'brightness(95%)'
    }
  },
  topButtonsContainer: {
    display: 'inline-block',
    width: '100%',
    marginBottom: 20
  },
  topButton: {
    float: 'right',
    minWidth: 100
  },
  teamLogo: {
    textAlign: 'center',
    marginBottom: 15,
    [theme.breakpoints.up('xs')]: {
      width: '100%',
      maxWidth: 140
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      maxWidth: 150
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      maxWidth: 165
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      maxWidth: 165
    },
    cursor: 'pointer',
    "&:hover": {
      opacity: '0.8'
    }
  },
  moreBtn: {
    width: 20,
    height: 20,
    padding: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fill: COLOR_LIGHT,
    borderRadius: '50%',
    marginLeft: 'auto',
    "&&:hover": {
      backgroundColor: '#242526',
      boxShadow: 'none'
    }
  },
  listIconZone: {
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center'
  },
  listIcon: {
    width: 17,
    height: 17,
    fill: COLOR_DARK,
    stroke: COLOR_DARK
  }
}));

export const useStylesModal = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
      padding: 20
    },
    [theme.breakpoints.up('sm')]: {
      padding: 20
    },
    [theme.breakpoints.up('md')]: {
      padding: 30
    },
    [theme.breakpoints.up('lg')]: {
      padding: 30
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  inputInline: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center'
  },
  input: {
    width: '100%',
    marginBottom: 15
  },
  halfInput: {
    width: 'calc(50% - 7.5px)',
    marginBottom: 15
  },
  inputLeft: {
    marginRight: 15/2,
    width: 'calc(100%)'
  },
  inputRight: {
    marginLeft: 15/2,
    width: 'calc(100%)'
  },
  formTitle: {
    marginBottom: 30,
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  formBtnZone: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30
  },
  formBtn: {
    marginTop: 'auto',
    width: 200
  },
  formTitle: {
    marginBottom: 30,
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  crossBtnZone: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    flex: 1,
    marginTop: -15,
    marginRight: -5
  },
  crossBtn: {
    position: 'absolute',
    width: 10,
    height: 10,
    padding: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    stroke: COLOR_LIGHT,
    borderRadius: '50%',
    backgroundColor: COLOR_PINK,
    cursor: 'pointer',
    "&&:hover": {
      filter: 'brightness(90%)'
    }
  },
  info: {
    backgroundColor: COLOR_DARK,
    borderRadius: 18,
    listStyleType: 'none',
    padding: 20,
    flex: 1
  }
}));