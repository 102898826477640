import { makeStyles } from "@material-ui/core/styles";

const COLOR_LIGHT = "#f4f4f4";
const MENU_BACKGROUND_COLOR = "rgba(28, 30, 33, 1)";
const MENU_LABEL_COLOR = "rgba(242, 242, 242, 1)";
const MENU_LABEL_ACTIVE_COLOR = "rgba(69, 214, 159, 1)";
const MENU_LABEL_ACTIVE_BACKGROUND_COLOR = "rgba(69, 214, 159, 0.07)";
const MENU_WIDTH = 250;
const MENU_LABEL_MARGIN = 10;
const MENU_LABEL_PADDING_HORIZONTAL = 15;
const MENU_LABEL_PADDING_VERTICAL = 5;
const COLOR_PRIMARY = "#45d69f";
const COLOR_PINK = "#e92163";

export const useStylesMenu = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  drawer: {
    [theme.breakpoints.up('xs')]: {
      width: 0
    },
    [theme.breakpoints.up('md')]: {
      width: MENU_WIDTH
    }
  },
  drawerZone: {
    flexShrink: 0,
    backgroundColor: MENU_BACKGROUND_COLOR,
    [theme.breakpoints.up('xs')]: {
      width: 0
    },
    [theme.breakpoints.up('md')]: {
      width: MENU_WIDTH
    }
  },
  titleZone: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: 10,
    marginLeft: 10,
    paddingRight: 15+5,
    paddingLeft: 15+5,
    textDecoration: 'none',
    color: MENU_LABEL_COLOR,
    padding: MENU_LABEL_PADDING_VERTICAL,
    borderRadius: 10,
    cursor: 'pointer',
    marginBottom: 2,
    "-moz-user-select": "none",
    "-khtml-user-select": "none",
    "-webkit-user-select": "none",
    "&:hover, &:visited": {
      backgroundColor: MENU_LABEL_ACTIVE_BACKGROUND_COLOR
    }
  },
  titleZoneFocus: {
    color: MENU_LABEL_ACTIVE_COLOR,
    backgroundColor: MENU_LABEL_ACTIVE_BACKGROUND_COLOR
  },
  subTitleZone: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingRight: 20,
    paddingLeft: 30,
    textDecoration: 'none',
    color: MENU_LABEL_COLOR,
    padding: MENU_LABEL_PADDING_VERTICAL,
    borderRadius: 10,
    cursor: 'pointer',
    marginBottom: 2,
    cursor: 'pointer',
    "-moz-user-select": "none",
    "-khtml-user-select": "none",
    "-webkit-user-select": "none",
    "&:hover, &:visited": {
      backgroundColor: MENU_LABEL_ACTIVE_BACKGROUND_COLOR
    }
  },
  subTitleZoneFocus: {
    color: MENU_LABEL_ACTIVE_COLOR,
    backgroundColor: MENU_LABEL_ACTIVE_BACKGROUND_COLOR
  },
  drawerContainer: {
    overflow: 'auto',
  },
  divider: {
    marginTop: 5,
    marginBottom: 5
  },
  rightIcon: {
    width: 14,
    height: 14,
    fill: COLOR_LIGHT
  },
  menuIcon: {
    marginRight: 8,
    width: 20,
    height: 20,
    fill: COLOR_LIGHT,
    stroke: COLOR_LIGHT
  },
  menuIconFocus: {
    marginRight: 8,
    width: 20,
    height: 20,
    fill: COLOR_PRIMARY,
    stroke: COLOR_PRIMARY
  },
  badge: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    minWidth: 18,
    height: 10,
    borderRadius: 20,
    backgroundColor: COLOR_PINK,
    textAlign: 'center',
    color: 'white',
    fontSize: 13,
    fontWeight: '700'
  },
  badgeZone: {
    position: 'absolute',
    right: 22
  },
  badgeContent: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  plus: {
    position: 'absolute',
    fontSize: 12,
    top: '-3px',
    right: '-7px'
  }
}));

export const useStylesSideMenu = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0
  },
  drawerPaper: {
    width: MENU_WIDTH
  },
  titleZone: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    height: 40,
    paddingRight: 15+5,
    paddingLeft: 15+5,
    textDecoration: 'none',
    color: MENU_LABEL_COLOR,
    borderRadius: 10,
    cursor: 'pointer',
    marginBottom: 2,
    "-moz-user-select": "none",
    "-khtml-user-select": "none",
    "-webkit-user-select": "none",
    "&:hover, &:visited": {
      backgroundColor: MENU_LABEL_ACTIVE_BACKGROUND_COLOR
    }
  },
  titleZoneFocus: {
    color: MENU_LABEL_ACTIVE_COLOR,
    backgroundColor: MENU_LABEL_ACTIVE_BACKGROUND_COLOR
  },
  subTitleZone: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    height: 40,
    paddingRight: 20,
    paddingLeft: 45,
    textDecoration: 'none',
    color: MENU_LABEL_COLOR,
    borderRadius: 10,
    cursor: 'pointer',
    marginBottom: 2,
    "-moz-user-select": "none",
    "-khtml-user-select": "none",
    "-webkit-user-select": "none",
    "&:hover, &:visited": {
      backgroundColor: MENU_LABEL_ACTIVE_BACKGROUND_COLOR
    }
  },
  subTitleZoneFocus: {
    color: MENU_LABEL_ACTIVE_COLOR,
    backgroundColor: MENU_LABEL_ACTIVE_BACKGROUND_COLOR
  },
  drawerContainer: {
    overflow: 'auto',
  },
  divider: {
    marginTop: 5,
    marginBottom: 5
  },
  rightIcon: {
    width: 16,
    height: 16,
    fill: COLOR_LIGHT
  },
  menuIcon: {
    marginRight: 8,
    width: 23,
    height: 23,
    fill: COLOR_LIGHT,
    stroke: COLOR_LIGHT
  },
  menuIconFocus: {
    marginRight: 8,
    width: 23,
    height: 23,
    fill: COLOR_PRIMARY,
    stroke: COLOR_PRIMARY
  },
  badge: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    minWidth: 18,
    height: 10,
    borderRadius: 20,
    backgroundColor: COLOR_PINK,
    textAlign: 'center',
    color: 'white',
    fontSize: 13,
    fontWeight: '700'
  },
  badgeZone: {
    position: 'absolute',
    right: 22
  }
}));