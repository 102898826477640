import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from "@material-ui/core/styles";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

import { CText } from "../Text";

const ZONE_BACKGROUND_COLOR = "#1f2126";
const PADDING_HORIZONTAL = 10;
const PADDING_VERTICAL = 5;
const LIGHT_COLOR = "#f4f4f4";
const PRIMARY_COLOR = "#45d69f";

const COLOR_PRIMARY = "#45d69f";
const SUBMENU_BACKGROUND_COLOR = "rgba(69, 214, 159, 0.07)";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  display: 'inline-flex',
  overflowX: 'auto',
  padding: 0,
  minHeight: 35,
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 2,
    borderRadius: 5,
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 50,
    width: '100%',
    backgroundColor: `${COLOR_PRIMARY}`,
    borderRadius: 25,
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    minHeight: 35,
    padding: '0 0px',
    margin: '0 0px',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: COLOR_PRIMARY,
      "&:hover": {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }),
);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ height: '100%', width: '100%', flexGrow: 1, overflow: 'hidden' }}
      {...other}
    >
      {value === index && (
        <div style={{ height: '100%', width: '100%', flexGrow: 1 }}>
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const CTab = ({ items, onClickCB }) => {
  const useStyles = makeStyles((theme) => ({
    tabContainer: {
      backgroundColor: ZONE_BACKGROUND_COLOR,
      padding: 15,
      marginBottom: 30,
      borderRadius: 10,
      display: 'flex',
      overflow: 'auto',
    },
    labelContainer: {
      paddingLeft: PADDING_HORIZONTAL,
      paddingRight: PADDING_HORIZONTAL,
      paddingTop: PADDING_VERTICAL,
      paddingBottom: PADDING_VERTICAL,
      borderRadius: 8,
      margin: 0,
      "&:hover": {
        backgroundColor: SUBMENU_BACKGROUND_COLOR,
      },
      display: 'flex',
      flexDirection: 'row',
      alignItems: "center"
    },
    labelContainerActive: {
      paddingLeft: PADDING_HORIZONTAL,
      paddingRight: PADDING_HORIZONTAL,
      paddingTop: PADDING_VERTICAL,
      paddingBottom: PADDING_VERTICAL,
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'row',
      alignItems: "center"
    },
    icon: {
      fill: LIGHT_COLOR,
      marginRight: 6,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    },
    iconActive: {
      fill: COLOR_PRIMARY
    }
  }));
  const classes = useStyles();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onClickCB(newValue);
  };

  return (
    <div className={classes.tabContainer}>
      <StyledTabs
        value={value}
        onChange={handleChange}
      >
        {items.map((item, index) => (
          <StyledTab
            key={`menu-${index}`}
            label={
              <div className={value === index ? classes.labelContainerActive : classes.labelContainer}>
                {item.icon ?
                  <span className={[classes.icon, value === index ? classes.iconActive : null ].join(" ")}>
                    {item.icon}
                  </span>
                  : null
                }
                <CText
                  level="h3"
                  fontWeight={500}
                  color={value === index ? "primary" : "light"}
                >
                  {item.label}
                </CText>
              </div>
            }
          />
        ))}
      </StyledTabs>
    </div>
  );
}

export { CTab, TabPanel };