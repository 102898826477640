import React, { useEffect, useState } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { CText } from "../../components/Text";
import { CRadio } from '../../components/Radio/Radio';
import { ReactComponent as LeftArrowIcon } from '../../assets/icons/left-arrow.svg';
import { ClubPhoto } from "../../components/Photos/ClubPhoto";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { API } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import { updateTeam } from '../../store/reducers/user';
import { getCurrentSeason } from "../../utils/season";
import CButton from "../../components/Button";
import { getSession } from "../../utils/auth";

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = '#242834';
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;

const TEAM_CARD_WIDTH = 500;

const Team = ({data, selectedValue, handleChangeCB}) => {
  const useStyles = makeStyles((theme) => ({
    dataZone: {
      display: 'flex',
      boxSizing: 'border-box',
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      border: `1px solid ${ZONE_BACKGROUND_COLOR}`,
      boxShadow: '0px 4px 9px rgba(0, 0, 0, 0.3)',
      borderRadius: 15,
      listStyleType: 'none',
      cursor: 'pointer',
      [theme.breakpoints.up('xs')]: {
        padding: 15,
        maxWidth: TEAM_CARD_WIDTH
      },
      [theme.breakpoints.up('md')]: {
        padding: 15,
        maxWidth: TEAM_CARD_WIDTH,
      },
      "&&:hover": {
        filter: 'brightness(95%)',
      },
    },
    dataZoneSelected: {
      border: `1px solid ${COLOR_PRIMARY}`,
    },
    logoZone: {
      height: 30,
      width: 30,
      textAlign: 'center',
      marginRight: 5
    },
    logo: {
      height: '100%',
      width: 'auto'
    },
    label: {
      textAlign: 'center',
      marginRight: 'auto'
    },
    flag: {
      marginRight: 8,
      fontSize: 26
    },
    btn: {
      marginLeft: '10px'
    }
  }));

  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();

  const [userGroups, setUserGroups] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (data) {
        const session = await getSession();
        const groups = session.idToken.payload['cognito:groups'];
        setUserGroups(groups);
      }
    }

    fetchData();
  }, [data]);

  const handleChange = (value) => {
    handleChangeCB(value);
  };

  const modifyTeamInfo = () => {
    navigate(`/teams/${data.id}/edit`);
  };

  return (
    <li
      className={[
        classes.dataZone, 
        selectedValue === data.id ? classes.dataZoneSelected : ''
      ].join(' ')}
      onClick={() => handleChange(data.id)}
    >
      <div className={classes.logoZone}>
        <ClubPhoto
          className={classes.logo}
          src={data.club.logo}
        />
      </div>
      <CText className={classes.label} level="p2" fontWeight={700} color="light">
        {JSON.parse(data.club.names[0]).name} • {data.name}
      </CText>

      {(userGroups?.includes("Coaches") || userGroups?.includes("ClubAdmins")) ?
        <CButton
          className={classes.btn}
          label={t('user_teams.buttons.0.label')}
          type="contained"
          color={COLOR_PRIMARY}
          size={14}
          onClick={(event) => modifyTeamInfo(event)}
        />
        : null
      }
      <CRadio
        value={data.id}
        checked={selectedValue === data.id}
      />
    </li>
  );
};

const UserTeams = (props) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 20,
      width: '100%',
      [theme.breakpoints.up('xs')]: {
        maxWidth: TEAM_CARD_WIDTH
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: TEAM_CARD_WIDTH + 15,
      },
    },
    titleIcon: {
      stroke: COLOR_LIGHT,
      fill: COLOR_LIGHT,
      width: 22,
      height: 22,
      backgroundColor: ZONE_BACKGROUND_COLOR,
      padding: 7,
      borderRadius: '50%',
      cursor: 'pointer',
      "&:hover": {
        opacity: '0.8',
      },
      marginRight: 15,
    },
    teamContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    list: {
      width: '100%',
      padding: 0,
      margin: 0,
    }
  }));

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();

  // Get User
  const userStored = useSelector((state) => state?.user);

  const [teams, setTeams] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [selectedTeamId, setSelectedTeamId] = useState(userStored?.club?.team?.id);
  const [currentSeason, setCurrentSeason] = useState("");
  
  const getTeams = async () => {
    try {
      let teamsList = [];
      let teamsListOrdered = [];
  
      if (userStored.groups.includes("ClubAdmins")) {
        const clubId = userStored.club.id;
  
        const filter = {
          club_id: { eq: clubId },
          deleted: { eq: false }
        };
  
        teamsList = await API.graphql({ 
          query: queries.listTeamsWithClub, 
          variables: { filter: filter }, 
          authMode: 'AMAZON_COGNITO_USER_POOLS' 
        });
        teamsList = teamsList.data.listTeams.items;
  
        teamsListOrdered = teamsList.sort((a, b) => {
          const numberA = parseInt(a.name.replace(/\D/g, ''), 10) || 0;
          const numberB = parseInt(b.name.replace(/\D/g, ''), 10) || 0;
          return numberA - numberB;
        });
      } else if (userStored.groups.includes("Players") || userStored.groups.includes("Coaches")) {
        const currentSeason = await getCurrentSeason();
        setCurrentSeason(currentSeason);

        const userId = userStored.info.id;
      
        const teamFilter = {
          season: { eq: currentSeason },
          invite_status: { eq: 1 }
        };
      
        let getUserTeamsWithConversation = await API.graphql({
          query: queries.getUserTeamsWithConversation,
          variables: {
            id: userId,
            teamFilter: teamFilter
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        getUserTeamsWithConversation = getUserTeamsWithConversation?.data?.getUser?.teams.items;

        teamsList = getUserTeamsWithConversation?.map((team) => ({
          id: team.team.id,
          name: team.team.name,
          club: {
            id: team.team.club.id,
            names: team.team.club.names,
            logo: team.team.club.logo,
          }
        })) || [];
      
        teamsListOrdered = teamsList.sort((a, b) => {
          const clubNameA = JSON.parse(a.club.names[0]).name.toLowerCase();
          const clubNameB = JSON.parse(b.club.names[0]).name.toLowerCase();
        
          // Compare club names
          if (clubNameA < clubNameB) return -1;
          if (clubNameA > clubNameB) return 1;
        
          // If club names are the same, sort by team names
          const teamNameA = a.name.toLowerCase();
          const teamNameB = b.name.toLowerCase();
        
          // Extract numbers from team names
          const teamNumberA = parseInt(teamNameA.replace(/\D/g, ''), 10) || 0;
          const teamNumberB = parseInt(teamNameB.replace(/\D/g, ''), 10) || 0;
        
          // Compare the numbers extracted from the team names
          if (teamNumberA < teamNumberB) return -1;
          if (teamNumberA > teamNumberB) return 1;
        
          // If team numbers are the same or absent, sort by team name alphabetically
          if (teamNameA < teamNameB) return -1;
          if (teamNameA > teamNameB) return 1;
        
          return 0;
        });
      }
      
      setTeams(teamsListOrdered);
    } catch (err) {
      console.log(err);
    }
  };  
  
  useEffect(() => {
    const fetchData = async () => {
      await getTeams();
      setisLoading(false);
    }
  
    fetchData();
  }, [userStored]);

  const saveSelection = (value) => {
    setSelectedTeamId(value);
    const selectedTeam = teams.find(team => team.id === value);
  
    if (selectedTeam) {
      dispatch(updateTeam({
        id: selectedTeam.id,
        season: currentSeason,
        name: selectedTeam.name,
        club: { id: selectedTeam.club.id, name: selectedTeam.club.name, } 
      }));
    }
  };

  const handlePrevious = () => {
    const path = location.state?.prevUrl || location.pathname;
    const queryParams = new URLSearchParams(location.search);

    const encodedTeamName = encodeURIComponent(userStored?.club?.team?.name);

    const regex = /(.*)\/teams\/([^/]+)(\/.*)?/;
    const match = path.match(regex);

    if (match) {
      const beforeTeams = match[1] || '';
      const afterTeamName = match[3] || '';

      const newPath = `${beforeTeams}/teams/${encodedTeamName}${afterTeamName}`;

      navigate(newPath + location.search);
    } 
    else if (queryParams.has('team')) {
      queryParams.set('team', encodedTeamName);
      const newPathWithQuery = `${path.split('?')[0]}?${queryParams.toString()}`;
      navigate(newPathWithQuery);
    }
    else {
      navigate(-1);
    }

    window.scrollTo(0, 0);
  };


  return(
    <div className={classes.container}>
      {isLoading ?
        <>
        </>
        :
        <>
          <div className={classes.title}>
            <LeftArrowIcon className={classes.titleIcon} onClick={() => handlePrevious()} /> 
            <CText  level="h1" color="light" label={`${t('user_teams.title')}`} />
          </div>

          <ul className={classes.list}>
            <Grid container spacing={1}>
              {teams.map((team, index) => (
                <Grid item xs={12} key={index} className={classes.teamContainer}>
                  <Team data={team} selectedValue={selectedTeamId} handleChangeCB={saveSelection} />
                </Grid>
              ))}
            </Grid>
          </ul>
        </>
      } 
    </div>
  )
}
  
export default UserTeams;