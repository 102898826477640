import React from 'react';
import { CText } from "../../components/Text";
import './custom-calendar.css';
import { ReactComponent as ChevronIcon } from '../../assets/icons/left-chevron.svg';

const CustomToolbar = (toolbar) => {
  const goToBack = () => {
    toolbar.onNavigate('PREV');
  };

  const goToNext = () => {
    toolbar.onNavigate('NEXT');
  };

  const goToCurrent = () => {
    toolbar.onNavigate('TODAY');
  };

  const handleViewChange = (view) => {
    toolbar.onView(view);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
      {/* Left-aligned controls */}
      <div>
      </div>

      {/* Centered current date */}
      <div style={{display: 'flex'}}>
        <ChevronIcon className="arrow" onClick={goToBack} />
        <CText level="h3" color="light">{toolbar.label.charAt(0).toUpperCase() + toolbar.label.slice(1)}</CText>
        <ChevronIcon className="arrow right-arrow" onClick={goToNext} />
      </div>

      {/* Right-aligned view options */}
      <div></div>
      {/*<div>

        <button onClick={goToCurrent}>Today</button>

      </div>*/}
    </div>
  );
};

export default CustomToolbar;