import React, { useState, useEffect } from 'react';
import TextInput from '../../components/TextInput.js';
import CButton from "../../components/Button.js";
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import { sessionHandler } from '../../utils/auth';
import { useTranslation } from "react-i18next";
import { Auth } from 'aws-amplify';
import { CText } from "../../components/Text";
import { Helmet } from 'react-helmet';
import Grid from '@mui/material/Grid2';
import CSelect from "../../components/Select";
import { useDispatch } from 'react-redux';
import { BadRequestSnackBar } from "../../components/SnackBar";
import authentImg from '../../assets/images/authent.svg';
import './auth.css';

const COLOR_PRIMARY = '#45d69f';

const SignUp = (props) => {
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [openSnack, setOpenSnack] = useState(false);
  const [snackBarErrorMessage, setSnackBarErrorMessage] = useState("");
  const [snackBarErrorElmts, setSnackBarErrorElmts] = useState([]);
  
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState(null);
  const [passwordError, setPasswordError] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [type, setType] = useState(null);
  const [searchParams] = useSearchParams();

  const invitationId = searchParams.get("invitation");
  const invitationType = searchParams.get("type");

  // Click Buttons
  const signUpClick = async() => {
    let errorMessage;

    if(firstName && lastName && email && password && type?.code) {
      // Call backend top pass data form
      try {
        const user = await Auth.signUp({
          username: email,
          password: password,
          attributes: {
            email: email,
            'custom:first_name': firstName,
            'custom:last_name': lastName,
            'custom:user_type': type?.code,
            'custom:locale': i18n.language
          }
        });
        if(user.userConfirmed === false) {
          //console.log("Verification Code.");
          navigate(`/auth/verification_code`, {
            state: {
              email: email,
              password: password
            },
            search: createSearchParams({
              invitation: invitationId
            }).toString()
          });
        } else {
          if(user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            //console.log("Change password.");
            props.formTypeCallback("new_pwd");
            props.userAuthentCallback(user);
          } else {
            //console.log("User authenticated.")
            await dispatch(signIn())
            sessionHandler()
          }
        }
      } catch (error) {
        //console.log('error signing in', error)
        setSnackBarErrorMessage("")
        setSnackBarErrorElmts([])
        setOpenSnack(true)
        setSnackBarErrorMessage(t('sign_up.errors.1.message'))
      }
    } else {
      let errorElmts = []
      setSnackBarErrorMessage("")
      setSnackBarErrorElmts([])
      setSnackBarErrorMessage(t('sign_up.errors.0.message'))
      if(!firstName) {
        errorElmts.push(t('sign_up.inputs.0.label'))
      }
      if(!lastName) {
        errorElmts.push(t('sign_up.inputs.1.label'))
      }
      if(!email) {
        errorElmts.push(t('sign_up.inputs.2.label'))
      }
      if(!password) {
        errorElmts.push(t('sign_up.inputs.3.label'))
      }
      if(!type) {
        errorElmts.push(t('sign_up.inputs.4.label'))
      }

      setOpenSnack(true)
      setSnackBarErrorMessage(t('sign_up.errors.0.message'))
      setSnackBarErrorElmts(errorElmts)
    }
  }

  const closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
    setSnackBarErrorMessage("");
  }

  const signIn = () => {
    navigate(`/auth/sign_in`);
    window.scrollTo(0, 0);
  }

  // Infos Callbacks
  const firstNameCb = (data) =>{ setFirstName(data) }
  const lastNameCb = (data) =>{ setLastName(data) }
  const emailCb = (data) =>{ setEmail(data) }
  const passwordCb = (data) =>{ setPassword(data) }
  const typeCB = (data) => {
    setType(data)
  }

  const userType = [
    { "label": t('user_types.0.label'), "code": "C" },
    { "label": t('user_types.1.label'), "code": "P" },
    { "label": t('user_types.2.label'), "code": "CA" }
  ]

  useEffect(() => {
    const fetchData = async () => {
      //Get the invitation and set type
      if(invitationType) {
        setType({ code: invitationType });
      }
    }
  
    fetchData()
  }, [])

  return(
    <div className="auth-container">
      <Helmet>
        <title>{t('sign_up.head.title')}</title>
        <meta name="description" content={t('sign_up.head.meta')} />
      </Helmet>
      <Grid container spacing={2} className="auth-container-grid">
        <Grid size={{xs: 12, sm: 12, md: 6 }} className="auth-form">
          <div className="auth-sign-form-zone">
            <div className="auth-input-inline">
              <TextInput
                className="auth-input"
                label={`${t('sign_up.inputs.0.label')} *`}
                inputCallback={firstNameCb}
              />
              <TextInput
                className="auth-input auth-input-right"
                label={`${t('sign_up.inputs.1.label')} *`}
                inputCallback={lastNameCb}
              />
            </div>
            <TextInput
              className="auth-input"
              label={`${t('sign_up.inputs.2.label')} *`}
              inputCallback={emailCb}
            />
            <TextInput
              className="auth-input"
              label={`${t('sign_up.inputs.3.label')} *`}
              type="password"
              inputCallback={passwordCb}
            />
            {!invitationId ?
              <CSelect
                label={`${t('sign_up.inputs.4.label')} *`}
                data={userType}
                selectCallback={typeCB}
                className="auth-last-input"
              />
              : null
            }
            <CButton
              className="auth-form-btn"
              label={t('sign_up.buttons.0.label')}
              height={38}
              type="contained"
              loader={true}
              onClick={async () => await signUpClick()}
            />
          </div>

          <div className="auth-sign-form-zone-2">
            <CText level="p2" color="light" className="auth-form-zone2-label">
              {t('sign_up.messages.0.label')}
              <button className="auth-label-btn" onClick={() => signIn()}>
                <CText
                  level="p2"
                  fontWeight="700"
                  color="primary"
                  className="auth-label-btn-label"
                  label={t('sign_up.buttons.1.label')}
                />
              </button>
            </CText>
          </div>
        </Grid>
        
        <Grid size={{ xs: 0, sm: 0, md: 6 }}>
          <img src={authentImg} className="auth-image" />
        </Grid>
      </Grid>
      <BadRequestSnackBar
        open={openSnack}
        message={snackBarErrorMessage}
        onClose={closeSnackBar}
        autoHideDuration={5000}
        errorElmts={snackBarErrorElmts}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </div>
  );
}

export default SignUp;