export const onCreateConversation = `subscription onCreateConversation($team_id: ID!, $season: String!) {
  onCreateConversation(team_id: $team_id, season: $season) {
    id
    team_id
    season
  }
}`;

export const onCreateMessage = `subscription onCreateMessage($conversation_id: ID!) {
  onCreateMessage(conversation_id: $conversation_id) {
    id
    conversation_id
    sender {
      id
      first_name
      last_name
      photo_path
    }
    type
    content
    createdAt
    read_by
  }
}`;

export const onCreateTeamUser = `subscription onCreateTeamUser($user_id: ID!) {
  onCreateTeamUser(user_id: $user_id) {
    id
    user_id
  }
}`;

export const onCreateTeam = `subscription onCreateTeam($filter: ModelSubscriptionTeamFilterInput) {
  onCreateTeam(filter: $filter) {
    id
    club_id
    name
  }
}`;

export const onUpdateTeam = `subscription onUpdateTeam($filter: ModelSubscriptionTeamFilterInput) {
  onUpdateTeam(filter: $filter) {
    id
  }
}`;