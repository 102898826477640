import React, { useState, useEffect, Dimensions, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { API } from 'aws-amplify';
import { useSelector } from 'react-redux'
import pitch from '../../assets/images/pitch.svg';
import { ReactComponent as Cross } from '../../assets/icons/cross.svg';
import { ReactComponent as Bench } from '../../assets/icons/bench.svg';
import { ReactComponent as CaptainBand } from '../../assets/icons/colored/captain-band.svg';
import { ReactComponent as CaptainBandDark } from '../../assets/icons/colored/captain-band-dark.svg';
import * as queries from '../../graphql/queries';
import ProfilePicture from "../../components/ProfilePicture/index";
import CButton from "../Button";
import CModal from "../Modal";
import { CText } from "../Text";
import CSelect from "../Select";
import { sortASC } from "../../utils/sort";
import formations from '../../assets/referentials/formations.json';

const COLOR_PRIMARY = '#45d69f';
const COLOR_DARK = "#242834";
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_BLUE = "#25a5e6";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;
const PLAYER_SUBSITUTES_CARD_HEIGHT = 120;

// Add old selected player to the list
const removeSelectedPlayer = async (goalkeepers, defenders, midfielders, strikers, positionInfo) => {
  let players;
  if (positionInfo.player.id) {
    switch (positionInfo.player.position) {
      case 'GK':
        players = [...goalkeepers];
        players.forEach((player) => {
          if (player.user.id === positionInfo.player.id) {
            player.selected = false;
          }
        });
        break;
      case 'DF':
        players = [...defenders];
        players.forEach((player) => {
          if (player.user.id === positionInfo.player.id) {
            player.selected = false;
          }
        });
        break;
      case 'MF':
        players = [...midfielders];
        players.forEach((player) => {
          if (player.user.id === positionInfo.player.id) {
            player.selected = false;
          }
        });
        break;
      case 'ST':
        players = [...strikers];
        players.forEach((player) => {
          if (player.user.id === positionInfo.player.id) {
            player.selected = false;
          }
        });
        break;
    }
  }

  return [...goalkeepers, ...defenders, ...midfielders, ...strikers]
};


const Player = ({ data, index, selectedPlayerCallback }) => {
  const useStyles = makeStyles((theme) => ({
    dataZone: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      borderRadius: 25,
      listStyleType: 'none',
      cursor: 'pointer',
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 8,
      paddingTop: 8,
      width: '100%',
      "&&:hover": {
        filter: 'brightness(95%)',
        boxShadow: 'none'
      }
    },
    playerPhoto: {
      width: 40,
      height: 40,
      borderRadius: 100,
      border: '1px solid'
    },
    playerName: {
      marginLeft: 10
    }
  }));

  const classes = useStyles();
  const { t } = useTranslation();

  const POSITION = {
    GK: t('position.GK.singular'),
    DF: t('position.DF.singular'),
    MF: t('position.MF.singular'),
    ST: t('position.ST.singular')
  }[data.position];

  const selecteUser = (data) => {
    const selectedPlayer = {
      info: data,
      index: index
    };
    selectedPlayerCallback(selectedPlayer);
  };

  return (
    <li className={classes.dataZone} onClick={() => selecteUser(data)}>
      <Grid container direction="row" alignItems="center">
        <Grid container item xs={12} direction="row" alignItems="center">
          <ProfilePicture className={classes.playerPhoto} src={data.user.photo_path} />
          <CText className={classes.playerName} level="p2" color="light">{data.user.first_name} {data.user.last_name} • {POSITION} </CText>
        </Grid>
      </Grid>
    </li>
  );
};

const AddModal = ({goalkeepers, onChangeGoalkeepers, defenders, onChangeDefenders, midfielders, onChangeMidfielders, strikers,
  onChangeStrikers, positionInfo, modalOpenCallback, selectedPlayerCallback, playersListCallback, selectedPosition }) => {

  const useStyles = makeStyles((theme) => ({
    modalContainer: {
      height:'100%',
      maxHeight: 350,
      display:'block',
      paddingTop: 20,
      paddingBottom: 20
    },
    playerList: {
      paddingLeft: 20,
      paddingRight: 20
    },
    modalButton: {
      marginTop: 30
    },
    modalAddButton: {
      marginBottom: 20
    },
    inputInline: {
      position: 'relative',
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center'
    },
    input: {
      marginBottom: 15
    },
    modalDeleteButton: {
      marginLeft: 10
    }
  }));
  const classes = useStyles();

  const selectedPlayerCB = async(data) => {
    let goalkeepersArray = [...goalkeepers]
    let defendersArray = [...defenders]
    let midfieldersArray = [...midfielders]
    let strikersArray = [...strikers]

    let allPlayers = [...goalkeepersArray, ...defendersArray, ...midfieldersArray, ...strikersArray];

    const oldSelectedPlayer = JSON.parse(JSON.stringify(selectedPosition)); // Copie indépendante de selectedPosition
    const newSelectedPlayerId = data.info.user.id

    if (oldSelectedPlayer?.player?.id) {
      const newPlayersList = await removeSelectedPlayer(goalkeepersArray, defendersArray, midfieldersArray, strikersArray, oldSelectedPlayer)
      allPlayers = newPlayersList
    }

    allPlayers = allPlayers.map((player) => {
      if (player.user.id === newSelectedPlayerId) {
        return { ...player, selected: true };
      }
  
      return player;
    });

    const newGoalkeepersArray = allPlayers.filter(
      (player) => player.position === 'GK'
    );
    const newDefendersArray = allPlayers.filter(
      (player) => player.position === 'DF'
    );
    const newMidfieldersArray = allPlayers.filter(
      (player) => player.position === 'MF'
    );
    const newStrikersArray = allPlayers.filter(
      (player) => player.position === 'ST'
    );
  
    onChangeGoalkeepers(newGoalkeepersArray)
    onChangeDefenders(newDefendersArray)
    onChangeMidfielders(newMidfieldersArray)
    onChangeStrikers(newStrikersArray)

    playersListCallback(allPlayers)
    modalOpenCallback(false)
    selectedPlayerCallback(data)
  }

  return (
    <div className={classes.modalContainer}>
      <ul className={classes.playerList}>
        <Grid container spacing={1}>
          {goalkeepers.map((player, playerIdx) => (
            player.selected === false ?
              <Grid container item key={playerIdx} alignItems="center" justifyContent="center">
                <Player data={player} index={playerIdx} selectedPlayerCallback={selectedPlayerCB} />
              </Grid>
              : null
          ))}
          {defenders.map((player, playerIdx) => (
            player.selected === false ?
              <Grid container item key={playerIdx} alignItems="center" justifyContent="center">
                <Player data={player} index={playerIdx} selectedPlayerCallback={selectedPlayerCB} />
              </Grid>
              : null
          ))}
          {midfielders.map((player, playerIdx) => (
            player.selected === false ?
            <Grid container item key={playerIdx} alignItems="center" justifyContent="center">
              <Player data={player} index={playerIdx} selectedPlayerCallback={selectedPlayerCB} />
            </Grid>
            : null
          ))}
          {strikers.map((player, playerIdx) => (
            player.selected === false ?
            <Grid container item key={playerIdx} alignItems="center" justifyContent="center">
              <Player data={player} index={playerIdx} selectedPlayerCallback={selectedPlayerCB} />
            </Grid>
            : null
          ))}
        </Grid>
      </ul>
    </div>
  );
}

const LineUp = ({currentSeason, onChange, lineUp, onChangePlayersList, onChangeCaptain, editable, gameDate, type}) => {
  const [modalPlayerListOpen, setModalPlayerListOpen] = useState(false);
  const [formation, setFormation] = useState(lineUp?.formation ? {"label": lineUp.formation, "code": lineUp.formation} : {"label": "4-3-3", "code": "4-3-3"});
  const [formationIndex, setFormationIndex] = useState(0);
  const [captainId, setCaptainId] = useState("");

  const [selectedPosition, setSelectedPosition] = useState({});
  const [gameLineUp, setGameLineUp] = useState([
    { position: 1, player: {} },
    { position: 2, player: {} },
    { position: 3, player: {} },
    { position: 4, player: {} },
    { position: 5, player: {} },
    { position: 6, player: {} },
    { position: 7, player: {} },
    { position: 8, player: {} },
    { position: 9, player: {} },
    { position: 10, player: {} },
    { position: 11, player: {} }
  ]);
  const [gameBench, setGameBench] = useState([]);
  const [newGoalkeepers, setNewGoalkeepers] = useState([]);
  const [newDefenders, setNewDefenders] = useState([]);
  const [newMidfielders, setNewMidfielders] = useState([]);
  const [newStrikers, setNewStrikers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  
  const pitchRef = useRef(null);
  const { t } = useTranslation();

  const [dimensions, setDimensions] = useState({
    pitchHeight: 0,
    pitchWidth: 0,
    playerZone: 0,
    lateralPadding: 0,
    playerZoneWrapper: 0,
    selectFormationLeftMargin: 0
  });
  
  const [width, setWidth] = useState(0);
  
  const handleResize = async() => {
    const width = window.innerWidth;
    setWidth(width);
  
    let pitchHeight;
    let pitchWidth;
    let playerZone;
    let lateralPadding;
    let playerZoneWrapper;
    let selectFormationLeftMargin;

    if (pitchRef.current) {
      pitchWidth = pitchRef.current.offsetWidth;
      pitchHeight = pitchRef.current.offsetHeight;
    }
  
    if (width < 500) {
      playerZone = 35;
      lateralPadding = 30;
      playerZoneWrapper = 80;
      selectFormationLeftMargin = 100;
    } else if (width < 600) {
      playerZone = 40;
      lateralPadding = 0;
      playerZoneWrapper = 80;
      selectFormationLeftMargin = 280;
    } else {
      selectFormationLeftMargin = 220;
      playerZone = 45;
      lateralPadding = 0;
      playerZoneWrapper = 80;
      selectFormationLeftMargin = 310;
    }
  
    setDimensions({
      pitchHeight,
      pitchWidth,
      playerZone,
      lateralPadding,
      playerZoneWrapper,
      selectFormationLeftMargin
    });
  };

  const lineupsRef = [
    {
      "formation": "4-3-3",
      "players": [
        {
          "number": 1,
          "position": {
            "top": dimensions.pitchHeight - 5 - dimensions.playerZone,
            "left": dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2
          }
        },
        {
          "number": 2,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone - 30,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + 200 - dimensions.lateralPadding
          }
        },
        {
          "number": 3,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone - 30,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - (200) + dimensions.lateralPadding
          }
        },
        {
          "number": 4,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - 100
          }
        },
        {
          "number": 5,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + 100
          }
        },
        {
          "number": 6,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) + (dimensions.playerZone / 2),
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2)
          }
        },
        {
          "number": 7,
          "position": {
            "top": 0 + dimensions.playerZone - 2 * 5,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + 180 - dimensions.lateralPadding
          }
        },
        {
          "number": 8,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) - (dimensions.playerZone / 2) - 15,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + (2 * dimensions.playerZone)
          }
        },
        {
          "number": 9,
          "position": {
            "top": 0 - 3,
            "left": dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2
          }
        },
        {
          "number": 10,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) - (dimensions.playerZone / 2) - 15,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - (2 * dimensions.playerZone)
          }
        },
        {
          "number": 11,
          "position": {
            "top": 0 + dimensions.playerZone - 2 * 5,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - 180 + dimensions.lateralPadding
          }
        }
      ]
    },
    {
      "formation": "3-5-2",
      "players": [
        {
          "number": 1,
          "position": {
            "top": dimensions.pitchHeight - 5 - dimensions.playerZone,
            "left": dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2
          }
        },
        {
          "number": 2,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone -20,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + 100
          }
        },
        {
          "number": 3,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) - (dimensions.playerZone / 2) - 40,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - 190 + dimensions.lateralPadding
          }
        },
        {
          "number": 4,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone -20,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - 100
          }
        },
        {
          "number": 5,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone -20,
            "left": dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2
          }
        },
        {
          "number": 6,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) - (dimensions.playerZone / 2) + 10,
            "left": dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2
          }
        },
        {
          "number": 7,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) - (dimensions.playerZone / 2) - 40,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + 190 - dimensions.lateralPadding
          }
        },
        {
          "number": 8,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) - (dimensions.playerZone / 2) - 40,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + (2 * dimensions.playerZone) - 15
          }
        },
        {
          "number": 9,
          "position": {
            "top": 0 - 3,
            "left": dimensions.pitchWidth / 2
          }
        },
        {
          "number": 10,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) - (dimensions.playerZone / 2) - 40,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - (2 * dimensions.playerZone) + 15
          }
        },
        {
          "number": 11,
          "position": {
            "top": 0 - 3,
            "left": dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper
          }
        }
      ]
    },
    {
      "formation": "4-2-3-1",
      "players": [
        {
          "number": 1,
          "position": {
            "top": dimensions.pitchHeight - 5 - dimensions.playerZone,
            "left": dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2
          }
        },
        {
          "number": 2,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone - 30,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + 200 - dimensions.lateralPadding
          }
        },
        {
          "number": 3,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone - 30,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - (200) + dimensions.lateralPadding
          }
        },
        {
          "number": 4,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - 100
          }
        },
        {
          "number": 5,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + 100
          }
        },
        {
          "number": 6,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) - (dimensions.playerZone / 2) + 15,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - (2 * dimensions.playerZone)
          }
        },
        {
          "number": 7,
          "position": {
            "top": 0 + (dimensions.playerZone - 2 * 5) + 30,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + 180 - dimensions.lateralPadding
          }
        },
        {
          "number": 8,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) - (dimensions.playerZone / 2) + 15,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + (2 * dimensions.playerZone)
          }
        },
        {
          "number": 9,
          "position": {
            "top": 0 - 3,
            "left": dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2
          }
        },
        {
          "number": 10,
          "position": {
            "top": 0 + (dimensions.playerZone - 2 * 5) + 30,
            "left": dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2
          }
        },
        {
          "number": 11,
          "position": {
            "top": 0 + (dimensions.playerZone - 2 * 5) + 30,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - 180 + dimensions.lateralPadding
          }
        }
      ]
    },
    {
      "formation": "4-4-2",
      "players": [
        {
          "number": 1,
          "position": {
            "top": dimensions.pitchHeight - 5 - dimensions.playerZone,
            "left": dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2
          }
        },
        {
          "number": 2,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone - 30,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + 200 - dimensions.lateralPadding
          }
        },
        {
          "number": 3,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone - 30,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - (200) + dimensions.lateralPadding
          }
        },
        {
          "number": 4,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - 100
          }
        },
        {
          "number": 5,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + 100
          }
        },
        {
          "number": 6,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) - (dimensions.playerZone / 2),
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - (2 * dimensions.playerZone)
          }
        },
        {
          "number": 7,
          "position": {
            "top": 0 + (1.5 * dimensions.playerZone) - (2 * 5) - 15,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + 180 - dimensions.lateralPadding
          }
        },
        {
          "number": 8,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) - (dimensions.playerZone / 2),
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + (2 * dimensions.playerZone)
          }
        },
        {
          "number": 9,
          "position": {
            "top": 0 - 3,
            "left": dimensions.pitchWidth / 2
          }
        },
        {
          "number": 10,
          "position": {
            "top": 0 - 3,
            "left": dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper
          }
        },
        {
          "number": 11,
          "position": {
            "top": 0 + (1.5 * dimensions.playerZone) - (2 * 5) - 15,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - 180 + dimensions.lateralPadding
          }
        }
      ]
    },
    {
      "formation": "4-5-1",
      "players": [
        {
          "number": 1,
          "position": {
            "top": dimensions.pitchHeight - 5 - dimensions.playerZone,
            "left": dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2
          }
        },
        {
          "number": 2,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone - 30,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + 200 - dimensions.lateralPadding
          }
        },
        {
          "number": 3,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone - 30,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - (200) + dimensions.lateralPadding
          }
        },
        {
          "number": 4,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - 100
          }
        },
        {
          "number": 5,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + 100
          }
        },
        {
          "number": 6,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) - (dimensions.playerZone / 2) + 15,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - (2 * dimensions.playerZone)
          }
        },
        {
          "number": 7,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) - (dimensions.playerZone / 2) - 45,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + 180 - dimensions.lateralPadding
          }
        },
        {
          "number": 8,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) - (dimensions.playerZone / 2) + 15,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + (2 * dimensions.playerZone)
          }
        },
        {
          "number": 9,
          "position": {
            "top": 0 - 3,
            "left": dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2
          }
        },
        {
          "number": 10,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) - (dimensions.playerZone / 2) - 45,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2)
          }
        },
        {
          "number": 11,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) - (dimensions.playerZone / 2) - 45,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - 180 + dimensions.lateralPadding
          }
        }
      ]
    },
    {
      "formation": "5-3-2",
      "players": [
        {
          "number": 1,
          "position": {
            "top": dimensions.pitchHeight - 5 - dimensions.playerZone,
            "left": dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2
          }
        },
        {
          "number": 2,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone - 20,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + 100
          }
        },
        {
          "number": 3,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone - 60,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - (200) + dimensions.lateralPadding
          }
        },
        {
          "number": 4,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone - 20,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - 100
          }
        },
        {
          "number": 5,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone - 20,
            "left": dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2
          }
        },
        {
          "number": 6,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) - (dimensions.playerZone / 2) - 5,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - (2 * dimensions.playerZone)
          }
        },
        {
          "number": 7,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone - 60,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + 200 - dimensions.lateralPadding
          }
        },
        {
          "number": 8,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) - (dimensions.playerZone / 2) - 5,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + (2 * dimensions.playerZone)
          }
        },
        {
          "number": 9,
          "position": {
            "top": 0 - 3,
            "left": dimensions.pitchWidth / 2
          }
        },
        {
          "number": 10,
          "position": {
            "top": 0 + (1.5 * dimensions.playerZone) - 5,
            "left": dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2
          }
        },
        {
          "number": 11,
          "position": {
            "top": 0 - 3,
            "left": dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper
          }
        }
      ]
    }
  ]

  const userStored = useSelector(state => state?.user);
  
  useEffect(() => {
    const fetchData = async () => {
      await getTeamPlayers(currentSeason, lineUp?.line_up, lineUp?.bench_players)
      if(lineUp?.formation) {
        setFormation({"label": lineUp.formation, "code": lineUp.formation})
        const formationIndex = lineupsRef.findIndex((lineupRef) => lineupRef.formation === lineUp.formation)
        setFormationIndex(formationIndex)
      }

      if(lineUp?.line_up) {
        setGameLineUp(lineUp.line_up);
      }

      if(lineUp?.bench_players) {
        setGameBench(lineUp.bench_players);
      }

      if(lineUp?.captain) {
        setCaptainId(lineUp.captain);
      }

      await handleResize();

      setLoaded(true)
    }
  
    fetchData();
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getTeamPlayers = async (currentSeason, lineUp, benchPlayers) => {
    try {
      const team = userStored?.club?.team;

      const userFilter = {
        invite_status: { eq: 1 },
        team_id: { eq: team.id },
        user_type: { eq: "P" },
        season: { eq: currentSeason },
        or: [
          {end_date: { gt: gameDate }},
          {end_date: {attributeExists: false}}
        ]
      };
  
      let teamPlayers = await API.graphql({
        query: queries.getTeamPlayers,
        variables: { id: team.id, userFilter: userFilter },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      teamPlayers = teamPlayers.data.getTeam.users.items;
  
      const lineUpIds = lineUp && lineUp?.length > 0 ? lineUp.map((player) => player?.player?.id) : [];
      const benchPlayersIds = benchPlayers && benchPlayers?.length > 0 ? benchPlayers?.map((player) => player?.player?.id) : [];

      teamPlayers.forEach((player) => {
        player.selected = lineUpIds.includes(player.user.id) || benchPlayersIds.includes(player.user.id);
      });

      const teamPlayersOrdered = teamPlayers.sort(sortASC("first_name"));
  
      let [goalkeepersArray, defendersArray, midfieldersArray, strikersArray] = [[], [], [], []]

      teamPlayersOrdered.forEach((player, index) => {
        switch (player.position) {
          case 'GK':
            goalkeepersArray.push(player);
            break;
          case 'DF':
            defendersArray.push(player);
            break;
          case 'MF':
            midfieldersArray.push(player);
            break;
          case 'ST':
            strikersArray.push(player);
            break;
        }
      });
  
      setNewGoalkeepers(goalkeepersArray);
      setNewDefenders(defendersArray);
      setNewMidfielders(midfieldersArray);
      setNewStrikers(strikersArray);
    } catch (err) {
      console.log(err);
    }
  };

  const useStyles = makeStyles((theme) => ({
    benchIcon: {
      fill: COLOR_PRIMARY,
      stroke: COLOR_PRIMARY,
      height: 60,
      width: 60
    },
    benchTitleZone: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 30
    },
    benchZone: {
      width: `calc(100% - 40px)`,
      borderRadius: 25,
      backgroundColor: ZONE_BACKGROUND_COLOR,
      marginTop: 8,
      padding: 20
    },
    container: {
      height: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    crossBtn: {
      position: 'absolute',
      width: 15,
      height: 15,
      padding: 8,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 5,
      right: 5,
      cursor: 'pointer',
      stroke: COLOR_LIGHT,
      borderRadius: '50%',
      "&&:hover": {
        backgroundColor: '#242526',
        boxShadow: 'none'
      }
    },
    lineupZone: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
      [theme.breakpoints.up('xs')]: {
        maxWidth: 500
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 500
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 550,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 600
      }
    },
    lineupFormationZone: {
      marginLeft: dimensions.selectFormationLeftMargin
    },
    pitchZone: {
      position: 'relative',
      marginTop: 20,
      userSelect: 'none'
    },
    pitch: {
      pointerEvents: 'none',
      userSelect: 'none',
      width: '100%'
    },
    pitchPlayerZoneWrapper: {
      width: dimensions.playerZoneWrapper,
      position: "absolute",
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    pitchPlayerZone: {
      width: dimensions.playerZone,
      height: dimensions.playerZone,
      borderRadius: 100,
      backgroundColor: COLOR_LIGHT,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `2px solid ${COLOR_PRIMARY}`
    },
    pitchPlayerZoneEditable: {
      cursor: 'pointer',
      "&&:hover": {
        filter: 'brightness(90%)'
      }
    },
    playerNumber: {
      fontSize: 20,
      fontWeight: '700'
    },
    playerName: {
      fontSize: 14,
      fontWeight: '700',
      textAlign: 'center'
    },
    listPlayersZone: {
      float: 'right',
      height: '100%',
      flexGrow: 1,
      background: '#1f2126',
      borderRadius: 25,
      display: 'flex',
      flexDirection: 'column',
      padding: 20
    },
    playerSubstitutesBtn: {
      height: PLAYER_SUBSITUTES_CARD_HEIGHT - (3 * 2),
      border: `3px solid ${COLOR_PRIMARY}`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 30,
      borderRadius: 25,
      transform: 'translate(0, 0)',
      "&&:hover": {
        cursor: 'pointer',
        filter: 'brightness(90%)'
      }
    },
    playerSubstitutesCard: {
      background: '#242834',
      height: PLAYER_SUBSITUTES_CARD_HEIGHT,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 20,
      borderRadius: 25,
      transform: 'translate(0, 0)'
    },
    playerPhoto: {
      width: 60,
      height: 60,
      borderRadius: 100,
      backgroundColor: COLOR_LIGHT
    },
    playerSubstitutesCardInfo: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 10,
      textAlign: 'center'
    },
    selectFormation: {
      width: 150,
      backgroundColor: ZONE_BACKGROUND_COLOR
    },
    formation: {
      width: 150,
      backgroundColor: ZONE_BACKGROUND_COLOR,
      display: 'flex',
      justifyContent: 'center',
      padding: 8,
      borderRadius: 8
    },
    substitutesZone: {
      background: '#1f2126',
      borderRadius: 25,
      padding: 20,
      display: 'flex',
      flexDirection: 'row'
    },
    crossBtnPlayer: {
      position: 'absolute',
      width: 9,
      height: 9,
      padding: 5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: -3,
      right: 12,
      cursor: 'pointer',
      stroke: COLOR_LIGHT,
      borderRadius: '50%',
      backgroundColor: COLOR_PINK,
      "&&:hover": {
        filter: 'brightness(90%)'
      }
    },
    captain: {
      position: 'absolute',
      width: 20,
      height: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: -5,
      left: 6,
    },
    captainBtn: {
      cursor: 'pointer',
      "&&:hover": {
        filter: 'brightness(90%)'
      }
    }
  }));
  const classes = useStyles();

  const selectFormation = (data) => {
    lineupsRef.map((lineUp, index) => {
      if (data.label === lineUp.formation) {
        setFormation(data);
        setFormationIndex(index);
        onChange({formation: data.label, line_up: gameLineUp, bench_players: gameBench});
      } else {
       //console.log("Formation Selected does not exist !");
      }
    });
  }

  const openPlayerSelection = (position, player) => {
    setSelectedPosition({position: position, player: player})
    setModalPlayerListOpen(true)
  }

  // Selected Player
  const selectedPlayerCB = (data) => {
    let player = {
      id: data.info.user.id,
      first_name: data.info.user.first_name,
      last_name: data.info.user.last_name,
      photo_path: data.info.user.photo_path,
      position: data.info.position,
      captain: data.info.captain
    }

    let newGameLineUp = [...gameLineUp]
    let newGameBench = gameBench

    if(selectedPosition.position !== 12) {
      newGameLineUp[selectedPosition.position-1].player = player
      setGameLineUp(newGameLineUp)
    } else {
      const newGameBenchPlayer = {
        position: 12,
        player: player
      }
      newGameBench.push(newGameBenchPlayer)
    }
    onChange({formation: formation.code, line_up: newGameLineUp, bench_players: newGameBench})
  }

  // Remove Player
  const removePlayer = async(position) => {
    const newPlayersList = await removeSelectedPlayer(newGoalkeepers, newDefenders, newMidfielders, newStrikers, gameLineUp[position-1])
    onChangePlayersList(newPlayersList)
    let newGameLineUp = [...gameLineUp]
    newGameLineUp[position-1].player = {}
    setGameLineUp(newGameLineUp)
    onChange({formation: formation.code, line_up: newGameLineUp, bench_players: gameBench})
  }

  const removeBenchPlayer = async(index) => {
    const newPlayersList = await removeSelectedPlayer(newGoalkeepers, newDefenders, newMidfielders, newStrikers, gameBench[index])
    onChangePlayersList(newPlayersList)
    const updatedGameBench = [...gameBench];
    updatedGameBench.splice(index, 1);
    setGameBench(updatedGameBench);
    onChange({formation: formation.code, line_up: gameLineUp, bench_players: updatedGameBench})
  };

  // Add/Change captain
  const selectCaptain = async(playerId) => {
    if(playerId !== captainId) {
      setCaptainId(playerId);
    } else {
      setCaptainId("");
    }

    onChangeCaptain(playerId);
  }

  const playersCB = (data) => {
    onChangePlayersList(data);
  }

  // Content Modal
  let contentModal;
  contentModal = 
    <AddModal
      goalkeepers={newGoalkeepers}
      onChangeGoalkeepers={(data) => setNewGoalkeepers(data)}
      defenders={newDefenders}
      onChangeDefenders={(data) => setNewDefenders(data)}
      midfielders={newMidfielders}
      onChangeMidfielders={(data) => setNewMidfielders(data)}
      strikers={newStrikers}
      onChangeStrikers={(data) => setNewStrikers(data)}
      positionInfo={gameLineUp[selectedPosition-1]}
      selectedPosition={selectedPosition}
      modalOpenCallback={(data) => setModalPlayerListOpen(data)}
      selectedPlayerCallback={(data) => selectedPlayerCB(data)}
      playersListCallback={playersCB}
      gameDate={gameDate}
    />

  return (
    <>
      <div className={classes.container}>
        <div className={classes.lineupZone}>
          <div className={classes.lineupFormationZone}>
            {editable ?
              <CSelect
                label={t('common.formation')}
                data={formations}
                selectCallback={(data) => selectFormation(data)}
                value={formation}
                className={classes.selectFormation}
              />
              :
              <CText className={classes.formation} level="p1" color="light">{formation.label}</CText>
            }
          </div>

          <div className={classes.pitchZone}>
            {loaded && lineupsRef[formationIndex].players.map((playerRef, index) => (
              <div
                key={index}
                className={classes.pitchPlayerZoneWrapper}
                style={{ top: playerRef.position.top, left: playerRef.position.left }}
              >
                {!gameLineUp[index]?.player?.id ? (
                  <div
                    className={[classes.pitchPlayerZone, editable ? classes.pitchPlayerZoneEditable : null].join(' ')}
                    onClick={editable ? () => openPlayerSelection(playerRef.number, gameLineUp[index]?.player) : null}>
                    <CText className={classes.playerNumber} color="dark">{playerRef.number}</CText>
                  </div>
                ) : (
                  <>
                    <ProfilePicture
                      className={[classes.pitchPlayerZone, editable ? classes.pitchPlayerZoneEditable : null].join(' ')}
                      src={gameLineUp[index].player.photo_path}
                      onClick={editable ? () => openPlayerSelection(playerRef.number, gameLineUp[index]?.player) : null }
                    />
                    {editable ?
                      <div className={classes.crossBtnPlayer} onClick={() => removePlayer(playerRef.number)}>
                        <Cross />
                      </div> : null
                    }

                    {captainId === gameLineUp[index]?.player?.id ? (
                      <div 
                        className={`${classes.captain} ${editable ? classes.captainBtn : ''}`} 
                        onClick={editable ? () => selectCaptain(gameLineUp[index].player.id) : null}
                      >
                        <CaptainBand />
                      </div>
                    ) : editable ? (
                      <div className={`${classes.captain} ${classes.captainBtn}`} onClick={() => selectCaptain(gameLineUp[index].player.id)}>
                        <CaptainBandDark />
                      </div>
                    ) : null}

                    <CText className={classes.playerName} color="light">
                      {gameLineUp[index].player.first_name.charAt(0)}.{gameLineUp[index].player.last_name}
                    </CText>
                  </>
                )}
              </div>
            ))}
            <img src={pitch} className={classes.pitch} ref={pitchRef} />
          </div>
        </div>

        <div className={classes.lineupZone}>
          <div className={classes.benchZone}>
            <div className={classes.benchTitleZone}>
              <Bench className={classes.benchIcon}/>
              {editable ?
                <div style={{position: 'absolute', right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <CButton
                    label={t('pre_game.buttons.1')}
                    height={26}
                    size={14}
                    type="contained"
                    onClick={() => openPlayerSelection(12)}
                  />
                </div> : null
              }
            </div>
            <Grid container spacing={2}>
              {gameBench.map((player, index) => (
                <Grid item xs={6} md={4} key={index} >
                  <Grid className={classes.playerSubstitutesCard}>
                    {editable ?
                      <Grid className={classes.crossBtn} onClick={() => removeBenchPlayer(index)}>
                        <Cross />
                      </Grid> : null
                    }
                    <ProfilePicture className={classes.playerPhoto} src={player?.player?.photo_path} />
                    <Grid className={classes.playerSubstitutesCardInfo}>
                      <CText level="p2" color="light" fontWeight={700}>{player?.player?.first_name?.charAt(0)}.{player?.player?.last_name}</CText>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>

      <CModal
        open={modalPlayerListOpen}
        onClose={() => setModalPlayerListOpen(false)}
        content={contentModal}
      >
      </CModal>
    </>
  )
}

export default LineUp;