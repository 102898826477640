export const calculateGoalkeeperRating = (data) => {
  // Define the weights
  const weightSaves = 0.1;
  const weightCleanSheet = 2.0;
  const weightNoCleanSheet = -1.5;
  const weightGoalsConceded = -0.5;
  const weightErrors = -1.0;
  const weightPenaltiesStopped = 1.0;
  const weightPenaltiesConceded = -1.5;
  const weightDecisiveSaves = 0.5;
  const weightYellowCard = -1;
  const weightRedCard = -2.5;

  // Base rating, assuming the average goalkeeper starts with a 5 out of 10 rating
  let baseRating = 5;

  // Extract values from data object, providing default values for missing data
  const {
    saves = 0,
    clean_sheet = false,
    goals_conceded = 0,
    errors_leading_to_goal = 0,
    penalties_stopped = 0,
    penalties_conceded = 0,
    decisive_saves = 0,
    yellow_card = 0,
    red_card = 0
  } = data;


  // Calculate the preliminary rating based on the statistics and their weights
  let rating = baseRating +
               (saves * weightSaves) +
               (clean_sheet ? weightCleanSheet : weightNoCleanSheet) +
               (goals_conceded * weightGoalsConceded) +
               (errors_leading_to_goal * weightErrors) +
               (penalties_stopped * weightPenaltiesStopped) +
               (penalties_conceded * weightPenaltiesConceded) +
               (decisive_saves * weightDecisiveSaves) +
               (yellow_card * weightYellowCard) +
               (red_card * weightRedCard);

  // Ensure the rating is between 0 and 10
  rating = Math.max(Math.min(rating, 10), 0);

  return rating;
}