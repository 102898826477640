import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import offsideFlag from '../assets/images/offside_flag.png';

const HORIZONTAL_PADDING = '6%';
const LIGHT_COLOR = "#f4f4f4";
const PRIMARY_COLOR = "#45d69f";

const NotFound = (props) => {
	const useStyles = makeStyles((theme) => ({
		container: {
			height: '100vh'
		},
		content: {
			height: '100%',
			paddingLeft: HORIZONTAL_PADDING,
			paddingRight: HORIZONTAL_PADDING,
			display: "flex",
			alignItems: "center"
		},
		title: {
			fontSize: 100,
			fontWeight: '900',
			marginBottom: 10,
			textShadow: `4px 4px 0 ${PRIMARY_COLOR}`,
			color: LIGHT_COLOR
		},
		message: {
		fontSize: 30,
		fontWeight: '600',
		color: PRIMARY_COLOR,
		marginBottom: 30
	},
		photo: {
			width: 350
		}
	}));
	const classes = useStyles();

	return(
		<div className={classes.container}>
			<div className={classes.content}>
					<Grid container alignItems="center" spacing={0}>
						<Grid container item xs={12} justifyContent="center" alignItems="center" >

							<div style={{textAlign: 'center'}}>
							<img src={offsideFlag} className={classes.photo}/>
								<Typography className={classes.title}>
									Off-side !!
								</Typography>
								<Typography className={classes.message}>
									Page Not Foud.
								</Typography>
							</div>
						</Grid>
					</Grid>
			</div>
		</div>
	)
}
  
export default NotFound;