import React from 'react';
import { useTranslation } from "react-i18next";
import { CText } from "../../components/Text";


import UserInfo from './UserInfo';
import ClubInfo from './ClubInfo';

import { useStyles } from './styles';
import { useSelector } from 'react-redux';

const AccountEdition = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const userStored = useSelector((state) => state?.user);

  let CONTENT;
  if(userStored.groups.includes("Coaches") || userStored.groups.includes("Players")) {
    CONTENT = <UserInfo />
  } else if(userStored.groups.includes("ClubAdmins")) {
    CONTENT = <ClubInfo />
  }

  return(
    <div className={classes.container}>
      <CText className={classes.title} level="h1" color="light" label={`${t('edit_account.title')}`} />
      {CONTENT}
    </div>
  );
}

export default AccountEdition;