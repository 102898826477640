import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { makeStyles } from "@material-ui/core/styles";

const COLOR_LIGHT = "#f4f4f4";
const COLOR_PRIMARY = "#45d69f";
const COLOR_RED = "#fc3c2e";
const COLOR_BLUE = "#25a5e6";
const COLOR_PINK= "#e92163";
const COLOR_PURPLE = "#9d44fd";
const COLOR_YELLOW= "#f2a624";
const COLOR_ORANGE = "#EDA247";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const COLOR_DARK = "#242834";

const useStyles = makeStyles((theme) => ({
  textEditor: {
    '& .ql-editor': {
      color: COLOR_LIGHT,
      border: 'none !important'
    },
    '& .ql-toolbar': {
      borderRadius: 8,
      backgroundColor: ZONE_BACKGROUND_COLOR,
      border: 'none !important',
      marginBottom: 10,
      color: `${COLOR_LIGHT} !important`,
      fill: `${COLOR_LIGHT} !important`,
      stroke: `${COLOR_LIGHT} !important`,
      '& .ql-picker-label, .ql-picker-options, .ql-fill, .ql-snow ': {
        color: `${COLOR_LIGHT}`,
        fill: `${COLOR_LIGHT}`,
        stroke: `${COLOR_LIGHT}`
      }
    },
    '& .ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected': {
      color: `${COLOR_PRIMARY} !important`,
      stroke: `${COLOR_PRIMARY} !important`
    },
    '& .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke': {
      color: `${COLOR_PRIMARY} !important`,
      stroke: `${COLOR_PRIMARY} !important`
    },
    '& .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label': {
      border: 'none',
      color: `${COLOR_PRIMARY} !important`,
      fill: `${COLOR_PRIMARY} !important`,
      stroke: `${COLOR_PRIMARY} !important`
    },
    ' & button:hover .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill': {
      color: `${COLOR_PRIMARY} !important`,
      fill: `${COLOR_PRIMARY} !important`,
      stroke: `${COLOR_PRIMARY} !important`
    },
    '& .ql-snow .ql-stroke': {
      color: `${COLOR_LIGHT}`,
      stroke: `${COLOR_LIGHT}`
    },
    '& .ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter': {
      color: `${COLOR_PRIMARY} !important`,
      stroke: `${COLOR_PRIMARY} !important`
    },
    '& .ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke': {
      color: `${COLOR_PRIMARY} !important`,
      stroke: `${COLOR_PRIMARY} !important`
    },
    '& .ql-container': {
      border: 'none !important',
      borderRadius: 8,
      filter: 'brightness(115%)',
      backgroundColor: ZONE_BACKGROUND_COLOR
    },
    '& .ql-editor': {
      minHeight: 200,
      color: COLOR_LIGHT
    },
    '& .ql-snow .ql-picker-options': {
      backgroundColor: ZONE_BACKGROUND_COLOR,
      border: 'none'
    },
    '&. ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options': {
      backgroundColor: ZONE_BACKGROUND_COLOR,
      border: 'none'
    }
  },
}));

const TextEditor = ({ onChange, defaultValue }) => {
  const [value, setValue] = useState(defaultValue || '');
  const classes = useStyles();

  const handleEditorChange = (content) => {
    setValue(content);
    if (onChange) {
      onChange(content);
    }
  };

  const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      [{ 'color': [COLOR_LIGHT, COLOR_PRIMARY, COLOR_BLUE, COLOR_PURPLE, COLOR_YELLOW, COLOR_ORANGE, COLOR_PINK, COLOR_RED] }], // Définir la couleur de texte par défaut
      ['clean']
    ],
  };

  return (
    <div className={classes.textEditorContainer}>
      <ReactQuill
        value={value}
        onChange={handleEditorChange}
        modules={modules}
        className={classes.textEditor}
      />
    </div>
  );
};

export default TextEditor;