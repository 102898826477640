import {makeStyles} from "@material-ui/core/styles"

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_RED = "#fc3c2e"
const COLOR_YELLOW = "#EDA247"
const COLOR_BLUE = "#25a5e6";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const ZONE_BACKGROUND_COLOR_2 = "#242834";
const BUTTON_WIDTH = 100;
const COLOR_DARK = "#242834";

export const useStylesTeam = makeStyles((theme) => ({
  dataZone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: ZONE_BACKGROUND_COLOR,
    borderRadius: 25,
    listStyleType: 'none',
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    paddingTop: 20,
    width: '100%'
  },
  teamName: {
    marginBottom: 15,
    textAlign: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translate(0, 0)'
  },
  infoIcon: {
    width: 20,
    height: 20,
    fill: COLOR_LIGHT,
    marginRight: 10
  },
  infoList: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 12,
    paddingRight: 12,
    height: 100,
    marginBottom: 8
  },
  infoListElmt: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  infoListElmtMargin: {
    marginBottom: 8
  },
  infoTitle: {
    marginRight: 10,
    whiteSpace: "nowrap"
  },
  infoZone: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8
  },
  coachInfo: {
    marginTop: 20,
    marginBottom: 0,
    display: 'flex',
    alignItems: 'start'
  },
  coachInfo2: {
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center'
  },
  moreBtn: {
    position: 'absolute',
    top: -12,
    right: -12,
    width: 20,
    height: 20,
    padding: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fill: COLOR_LIGHT,
    borderRadius: '50%',
    "&&:hover": {
      backgroundColor: '#242526',
      boxShadow: 'none'
    }
  },
  moreBtnChampionship: {
    width: 20,
    height: 20,
    padding: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fill: COLOR_LIGHT,
    borderRadius: '50%',
    marginLeft: 'auto', // Ajoutez cette ligne
    "&&:hover": {
      backgroundColor: '#242526',
      boxShadow: 'none'
    }
  },    
  listIcon: {
    width: 18,
    height: 18,
    fill: COLOR_DARK,
    marginRight: 8
  },
  coachZone: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: "wrap"
  },
  coachName: {
    marginRight: 10
  },
  labelBtn: {
    all: 'unset',
    cursor: 'pointer'
  },
  labelBtnLabel: {
    "&:active, &:focus": {
      filter: 'brightness(90%)'
    }
  }
}));

export const useStylesTeams = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%'
  },
  topButtonsContainer: {
    display: 'inline-block',
    width: '100%',
    marginBottom: 30
  },
  topButtonsContainerRight: {
    float: 'right',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  addButton: {

  },
  info: {
    marginRight: 8,
    fill: COLOR_LIGHT
  },
  leftButton: {
    [theme.breakpoints.up('xs')]: {
      marginRight: 5
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 10
    },
    [theme.breakpoints.up('xl')]: {
      marginRight: 12
    }
  },
  listTitle: {
    textAlign: 'center',
    marginBottom: 20
  },
  list: {
    marginBottom: 50
  },
  noTeamsInfo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}));

export const useStylesModal = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
      padding: 20
    },
    [theme.breakpoints.up('sm')]: {
      padding: 20
    },
    [theme.breakpoints.up('md')]: {
      padding: 30
    },
    [theme.breakpoints.up('lg')]: {
      padding: 30
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  inputInline: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center'
  },
  input: {
    width: '100%',
    marginBottom: 15
  },
  halfInput: {
    width: 'calc(50% - 7.5px)',
    marginBottom: 15
  },
  inputLeft: {
    marginRight: 15/2,
    width: 'calc(100%)'
  },
  inputRight: {
    marginLeft: 15/2,
    width: 'calc(100%)'
  },
  formTitle: {
    marginBottom: 30,
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  formBtnZone: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30
  },
  formBtn: {
    marginTop: 'auto',
    width: 200
  },
  formTitle: {
    marginBottom: 30,
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  crossBtnZone: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    flex: 1,
    marginTop: -15,
    marginRight: -5
  },
  crossBtn: {
    position: 'absolute',
    width: 10,
    height: 10,
    padding: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    stroke: COLOR_LIGHT,
    borderRadius: '50%',
    backgroundColor: COLOR_PINK,
    cursor: 'pointer',
    "&&:hover": {
      filter: 'brightness(90%)'
    }
  },
  info: {
    backgroundColor: COLOR_DARK,
    borderRadius: 18,
    listStyleType: 'none',
    padding: 20,
    flex: 1
  }
}));