import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from "react-i18next"
import { API, Storage } from 'aws-amplify'
import { getSession } from "../../utils/auth"
import * as mutations from '../../graphql/mutations'

import CButton, { PickMediaBtn } from "../../components/Button"
import { CText } from "../../components/Text"

import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

import { setPhotoPath } from '../../store/reducers/user'
import { ClubPhoto } from "../../components/Photos/ClubPhoto"
import { useStyles } from './styles';

import ProfilePicture from "../../components/ProfilePicture/index"

const COLOR_PRIMARY = '#45d69f';
const COLOR_PINK = "#e92163";

const ProfilePhotoModal = ({ modalOpenCallback }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const userStored = useSelector(state => state?.user)

  const [previewFile, setPreviewFile] = useState();
  const [fileData, setFileData] = useState();
  const [fileStatus, setFileStatus] = useState(false);

  const pickFile = (data) => {
    setPreviewFile(URL.createObjectURL(data))
    setFileData(data)
  }

  const removeFile = (data) => {
    setPreviewFile()
    setFileData()
  }

  const uploadFile = async () => {
    // [EVOL] Le nom du fichier ainsi que la création/update de l'url en bdd doit etre fait dans une lambda
    try {
      const userId = userStored?.info?.id;

      const fileType = fileData.type
      let fileExtension

      switch (fileType) {
        case 'image/png':
          fileExtension = "png"
          break;
        case 'image/jpeg':
          fileExtension = "jpeg"
          break;
        case 'image/svg+xml':
          fileExtension = "svg"
          break;
        case 'image/webp':
          fileExtension = "webp"
          break;
        default:
          throw new Error("Invalid file format.");
      }

      const filePath = `users/${userId}/${Date.now()}.${fileExtension}`

      await getSession()

      await Storage.put(filePath, fileData, {
        contentType: fileData.type,
      });

      const data = {
        id: userId,
        photo_path: `${filePath}`
      };

      await API.graphql({
        query: mutations.updateUser,
        variables: {input: data},
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      dispatch(setPhotoPath({ "photoPath": filePath }))
      modalOpenCallback(false)
    } catch(err) {
      console.log(err)
    }
  }

  let CONTENT;
  if(previewFile) {
    CONTENT =
    <div className={classes.selectPhoto}>
      <ProfilePicture className={classes.profilePicture} src={previewFile} updatePhoto={true} />
      <div className={classes.btnZone}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <CButton
              label={t('profile.modal_picture.buttons.1.label')}
              className={classes.inlineBtn}
              onClick={() => removeFile()}
              height={38} color={COLOR_PINK}
              type="contained"
            />
          </Grid>
          <Grid item xs={6}>
            <CButton
              label={t('profile.modal_picture.buttons.2.label')}
              className={classes.inlineBtn}
              onClick={() => uploadFile()}
              height={38}
              color={COLOR_PRIMARY}
              type="contained"
            />
          </Grid>
        </Grid>
      </div>
    </div>
  } else {
    CONTENT =
    <div className={classes.selectPhoto}>
      <div className={classes.btnZone}>
        <PickMediaBtn label={t('profile.modal_picture.buttons.0.label')} fileCb={(data) => pickFile(data)} height={38} className={classes.btn} color={COLOR_PRIMARY} type="contained" />
      </div>
    </div>
  }

  return (
    <div className={classes.container}>
      <CText level="h2" fontWeight={700} className={classes.title} color="primary" label={`${t('profile.modal_picture.title')}`} />
      {CONTENT}
    </div>
  )
}

export default ProfilePhotoModal;