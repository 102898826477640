import React, { useState, useEffect } from 'react'
import {makeStyles} from "@material-ui/core/styles"
import Grid from '@material-ui/core/Grid'
import { useTranslation } from "react-i18next"
import CButton from "../../components/Button"
import CModal from "../../components/Modal"
import { CText } from "../../components/Text"
import CounterInput from "../../components/Input/CounterInput"
import ProfilePicture from "../../components/ProfilePicture/index"
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useStylesEvaluation } from './evaluation.styles'

import { ReactComponent as DotsVerticalIcon } from '../../assets/icons/dots-vertical.svg'
import { ReactComponent as ChangesIcon } from '../../assets/icons/changes.svg'
import { calculateGoalkeeperRating } from "../../utils/ratingPlayerCalculator"

const COLOR_PRIMARY = '#45d69f'

const RadioBtnGroup = ({ props, data, onChange, value, test }) => {
  const useStyles = makeStyles((theme) => ({
    radioBtnGrp: {
      display: 'flex',
      flexDirection: 'row'
    },
    radioBtn: {
      all: 'unset',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 3,
      paddingBottom: 3,
      paddingLeft: 10,
      paddingRight: 10,
      minWidth: 15,
      border: `2px solid ${COLOR_PRIMARY}`,
      marginRight: 10,
      textAlign: 'center',
      borderRadius: 8,
      cursor: 'pointer',
      "&&:hover": {
        filter: 'brightness(90%)'
      }
    },
    radioBtnActivated: {
      display: 'flex',
      justifyContent: 'center',
      all: 'unset',
      paddingTop: 3,
      paddingBottom: 3,
      paddingLeft: 10,
      paddingRight: 10,
      minWidth: 15,
      border: `2px solid ${COLOR_PRIMARY}`,
      marginRight: 10,
      textAlign: 'center',
      borderRadius: 8,
      backgroundColor: COLOR_PRIMARY
    }
  }));

  const classes = useStyles();

  const newValue = (data) => {
    onChange(data.value);
  }

  return (
    <div className={classes.radioBtnGrp}>
      {data.map((data, index) => (
        <button
          key={index}
          className={value === data.value ? classes.radioBtnActivated : classes.radioBtn}
          onClick={() => { newValue(data) }}
        >
          <CText level="p2" fontWeight={700} color={value === data.value ? "dark" : "light"}>{data.label}</CText>
        </button>
      ))}
    </div>
  );
}

const EvalModal = ({evalSaveCB, evaluationValue, data}) => {
  useEffect(() => {  
    const fetchData = async () => {
      if(evaluationValue) {
        setEvaluation(evaluationValue)
      } else {
        setEvaluation({
          saves: 0,
          decisive_saves: 0,
          penalties_stopped: 0,
          penalties_conceded: 0,
          errors_leading_to_goal: 0
        });
      }
    }
  
    fetchData()
  }, [])

  const { t } = useTranslation();
  const classes = useStylesEvaluation();

  const [evaluation, setEvaluation] = useState({});

  const noteValues = [
    { "label": "1", "value": 1 },
    { "label": "2", "value": 2 },
    { "label": "3", "value": 3 },
    { "label": "4", "value": 4 },
    { "label": "5", "value": 5 },
    { "label": "6", "value": 6 },
    { "label": "7", "value": 7 },
    { "label": "8", "value": 8 },
    { "label": "9", "value": 9 },
    { "label": "10", "value": 10 }
  ]

  const genericEvalValues = [
    { "label": t('after_game.3.modals.0.labels.16'), "value": 0 },
    { "label": t('after_game.3.modals.0.labels.17'), "value": 1 },
    { "label": t('after_game.3.modals.0.labels.18'), "value": 2 }
  ]

    const savesValues = [
    { "label": "0-5", "value": 5 },
    { "label": "6-10", "value": 10 },
    { "label": "11-15", "value": 15 },
    { "label": "16+", "value": 20 },
  ]

  const save = () => {
    evalSaveCB(evaluation)
  }

  const CONTENT = data.position === 1 ? (
    <>
      <div className={classes.ratingZone}>
        <CText
          className={classes.evalTitle}
          level="p2"
          fontWeight={700}
          color="primary"
        >
          {t('after_game.3.modals.0.labels.11')}
        </CText>
        <RadioBtnGroup
          data={savesValues}
          onChange={(data) => setEvaluation({...evaluation, saves: data})}
          value={evaluation?.saves}
        />
      </div>
      <div className={classes.ratingZone}>
        <CText
          className={classes.evalTitle}
          level="p2"
          fontWeight={700}
          color="primary"
        >
          {t('after_game.3.modals.0.labels.12')}
        </CText>
        <CounterInput
          onChange={(data) => setEvaluation({...evaluation, decisive_saves: data})}
          value={evaluation?.decisive_saves}
        />
      </div>
      <div className={classes.ratingZone}>
        <CText
          className={classes.evalTitle}
          level="p2"
          fontWeight={700}
          color="primary"
        >
          {t('after_game.3.modals.0.labels.14')}
        </CText>
        <CounterInput
          onChange={(data) => setEvaluation({...evaluation, penalties_stopped: data})}
          value={evaluation?.penalties_stopped}
        />
      </div>
      <div className={classes.ratingZone}>
        <CText
          className={classes.evalTitle}
          level="p2"
          fontWeight={700}
          color="primary"
        >
          {t('after_game.3.modals.0.labels.15')}
        </CText>
        <CounterInput
          onChange={(data) => setEvaluation({...evaluation, penalties_conceded: data})}
          value={evaluation?.penalties_conceded}
        />
      </div>
      <div className={classes.ratingZone}>
        <CText
          className={classes.evalTitle}
          level="p2"
          fontWeight={700}
          color="primary"
        >
          {t('after_game.3.modals.0.labels.13')}
        </CText>
        <CounterInput
          onChange={(data) => setEvaluation({...evaluation, errors_leading_to_goal: data})}
          value={evaluation?.errors_leading_to_goal}
        />
      </div>
    </>
  ) : (
    <>
      <div className={classes.ratingZone}>
        <CText className={classes.evalTitle} level="p2" fontWeight={700} color="primary">{t('after_game.3.modals.0.labels.0')}</CText>
        <RadioBtnGroup data={noteValues} onChange={(data) => setEvaluation({...evaluation, offensive_rating: data})} value={evaluation?.offensive_rating}/>
      </div>
      <div className={classes.ratingZone}>
        <CText className={classes.evalTitle} level="p2" fontWeight={700} color="primary">{t('after_game.3.modals.0.labels.1')}</CText>
        <RadioBtnGroup data={noteValues} onChange={(data) => setEvaluation({...evaluation, defensive_rating: data})} value={evaluation?.defensive_rating}/>
      </div>
      <div className={classes.ratingZone}>
        <CText className={classes.evalTitle} level="p2" fontWeight={700} color="primary">{t('after_game.3.modals.0.labels.2')}</CText>
        <RadioBtnGroup data={noteValues} onChange={(data) => setEvaluation({...evaluation, collective_rating: data})} value={evaluation?.collective_rating}/>
      </div>

      <div className={classes.ratingZone}>
        <CText className={classes.evalTitle} level="p2" fontWeight={700} color="primary">{t('after_game.3.modals.0.labels.3')}</CText>
        <RadioBtnGroup data={genericEvalValues} onChange={(data) => setEvaluation({...evaluation, attempted_passes: data})} value={evaluation?.attempted_passes}/>
      </div>
      <div className={classes.ratingZone}>
        <CText className={classes.evalTitle} level="p2" fontWeight={700} color="primary" label={`${t('after_game.3.modals.0.labels.4')}`} />
        <RadioBtnGroup data={genericEvalValues} onChange={(data) => setEvaluation({...evaluation, successful_passes: data})} value={evaluation?.successful_passes}/>
      </div>

      <div className={classes.ratingZone}>
        <CText className={classes.evalTitle} level="p2" fontWeight={700} color="primary" label={`${t('after_game.3.modals.0.labels.5')}`} />
        <RadioBtnGroup data={genericEvalValues} onChange={(data) => setEvaluation({...evaluation, attempted_dribbling: data})} value={evaluation?.attempted_dribbling}/>
      </div>
      <div className={classes.ratingZone}>
        <CText className={classes.evalTitle} level="p2" fontWeight={700} color="primary" label={`${t('after_game.3.modals.0.labels.6')}`} />
        <RadioBtnGroup data={genericEvalValues} onChange={(data) => setEvaluation({...evaluation, successful_dribbling: data})} value={evaluation?.successful_dribbling}/>
      </div>

      <div className={classes.ratingZone}>
        <CText className={classes.evalTitle} level="p2" fontWeight={700} color="primary" label={`${t('after_game.3.modals.0.labels.7')}`} />
        <RadioBtnGroup data={genericEvalValues} onChange={(data) => setEvaluation({...evaluation, attempted_shots: data})} value={evaluation?.attempted_shots}/>
      </div>
      <div className={classes.ratingZone}>
        <CText className={classes.evalTitle} level="p2" fontWeight={700} color="primary" label={`${t('after_game.3.modals.0.labels.8')}`} />
        <RadioBtnGroup data={genericEvalValues} onChange={(data) => setEvaluation({...evaluation, successful_shots: data})} value={evaluation?.successful_shots}/>
      </div>

      <div className={classes.ratingZone}>
        <CText className={classes.evalTitle} level="p2" fontWeight={700} color="primary" label={`${t('after_game.3.modals.0.labels.9')}`} />
        <RadioBtnGroup data={genericEvalValues} onChange={(data) => setEvaluation({...evaluation, attempted_tackles: data})} value={evaluation?.attempted_tackles}/>
      </div>
      <div className={classes.ratingZone}>
        <CText className={classes.evalTitle} level="p2" fontWeight={700} color="primary" label={`${t('after_game.3.modals.0.labels.10')}`} />
        <RadioBtnGroup data={genericEvalValues} onChange={(data) => setEvaluation({...evaluation, successful_tackles: data})} value={evaluation?.successful_tackles}/>
      </div>
    </>
  );

  return (
    <div className={classes.modalContent}>
      {CONTENT}

      <div className={classes.modalBtnContainer}>
        <CButton
          className={classes.modalBtn}
          label={t('after_game.3.modals.0.buttons.0.label')}
          type="contained"
          onClick={() => save()}
        />
      </div>
    </div>
  );
}

const Player = ({data, onChangeEvaluation, score, cards, goals}) => {
  const { t } = useTranslation();
  const classes = useStylesEvaluation();

  const [modalOpen, setModalOpen] = useState(false);
  const [evaluation, setEvaluation] = useState({});

  const handleEvalSaveCB = (evaluations) => {
    console.log(evaluations)
    let rating;
    let updatedEvaluation = { ...evaluations };
    // gérer card yellow & red

    if (data.position === 1) {
      updatedEvaluation.clean_sheet = score?.opponent === 0;
      rating = calculateGoalkeeperRating(updatedEvaluation);
    } else {
      rating = (
        (
          (updatedEvaluation.offensive_rating || 0) +
          (updatedEvaluation.defensive_rating || 0) +
          (updatedEvaluation.collective_rating || 0)
        ) / 3
      ).toFixed(1);
    }
  
    updatedEvaluation.rating = rating;
  
    setModalOpen(false);
    setEvaluation(updatedEvaluation);
    onChangeEvaluation(updatedEvaluation);
  };

  const content = <EvalModal evalSaveCB={handleEvalSaveCB} evaluationValue={data?.evaluation} data={data} />

  //Menu
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl);
  const handleClick = (event) => { setAnchorEl(event.currentTarget) };
  const handleClose = () => { setAnchorEl(null) };

  const handleUpdate = async() => {
    setModalOpen(true)
    handleClose()
  };

  // Handle average note
  let averageRating;
  if(data?.evaluation?.rating !== null) {
    averageRating = data?.evaluation?.rating
  } else if (evaluation?.rating) {
    averageRating = evaluation?.rating
  }

  let averageRatingClassName;
  if (averageRating < 5) {
    averageRatingClassName = classes.evalLabel3;
  } else if (averageRating >= 5 && averageRating < 7) {
    averageRatingClassName = classes.evalLabel2;
  } else if (averageRating >= 7) {
    averageRatingClassName = classes.evalLabel1;
  }

  return (
    <li className={classes.dataZone}>
      <Grid container  direction="row" alignItems="center" >
        <Grid container item xs={9}  direction="row" alignItems="center" >
          <ProfilePicture className={classes.playerPhoto} src={data.player.photo_path} />
          <CText className={classes.playerName} level="p2" color="light">{data.player.first_name} {data.player.last_name}</CText>
        </Grid>
        <Grid item xs={3} className={classes.evalZone}>
          {averageRating ?
            <>
              <CText className={averageRatingClassName} level="p2" fontWeight={700}>
                {averageRating}
              </CText>
              <DotsVerticalIcon className={classes.moreBtn} onClick={handleClick}/>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    minWidth: '10ch'
                  },
                }}
                autoFocus={false}
              >
                <MenuItem onClick={handleUpdate}>
                  <span className={classes.listIconZone}>
                    <ChangesIcon className={classes.listIcon} />
                  </span>
                  {t('after_game.3.labels.0.label')}
                </MenuItem>
              </Menu>
            </>
            :
            <CButton label={t('after_game.3.buttons.0.label')} height={26} size={14} type="contained" onClick={() => setModalOpen(true)} />
          }
        </Grid>
      </Grid>

      <CModal
        className={classes.modalContainer}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        content={content}
      >
      </CModal>
    </li>
  );
}

const Evaluation = ({lineUp, onChangeLineUp, goals, cards, score}) => {
  const classes = useStylesEvaluation();

  const setEvaluation = (data, type, index) => {
    if(type === "bench") {
      lineUp.bench_players[index].evaluation = data
    } else {
      lineUp.line_up[index].evaluation = data
    }

    onChangeLineUp(lineUp)
  }

  return(
    <Grid container spacing={2} className={classes.container}>
      <div className={classes.list}>
        <ul>
          {lineUp.line_up.length > 0 || lineUp.bench_players.length > 0 ?
            <Grid container spacing={1}>
              {lineUp?.line_up.map((player, playerIdx) => (
                player.player.id ?
                  <Grid container item key={playerIdx} alignItems="center" justifyContent="center">
                    <Player
                      data={player}
                      score={score}
                      cards={cards}
                      goals={goals}
                      onChangeEvaluation={(data) => setEvaluation(data, "lineUp", playerIdx)}
                    />
                  </Grid>
                  : null
              ))}

              {lineUp?.bench_players.map((player, playerIdx) => (
                player.player.id ?
                  <Grid container item key={playerIdx} alignItems="center" justifyContent="center">
                    <Player
                      data={player}
                      score={score}
                      onChangeEvaluation={(data) => setEvaluation(data, "bench", playerIdx)} />
                  </Grid>
                  : null
              ))}
            </Grid>
            : null
          }
        </ul>
      </div>
    </Grid>
  )
}
  
export default Evaluation;