import React, { useState, useRef, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

const COLOR_PRIMARY = '#45d69f';
const COLOR_DARK = '#242834';

const useStyles = makeStyles((theme) => ({
  codeInputs: {
    display: 'flex',
    flexDirection: 'row',
  },
  codeInput: {
    [theme.breakpoints.up('xs')]: {
      width: 36,
      height: 44
    },
    [theme.breakpoints.up('sm')]: {
      width: 45,
      height: 55,
    },
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 8,
    backgroundColor: COLOR_DARK,
    fontSize: 18,
    border: 'none',
    outline: 'none',
    textAlign: 'center',
    caretColor: 'transparent',
    cursor: 'pointer',
    'border': `2px solid ${COLOR_DARK}`,
    '&:focus': {
      'border': `2px solid ${COLOR_PRIMARY}`,
      outline: 'none !important'
    },
    '::-moz-selection': {
      background: '#AFAFAF'
    }
  },
  codeInputSeparation: {
    marginRight: 30
  }
}));
//[EVOL] Si j'ai les 6 code et j'en retire 1 il garde l'ancien code, doit retouner la valeur en temps reel et pas au bout de 6 cara
const InputCode = ({ length, onComplete, className, initialValue }) => {
  const classes = useStyles();
  const [code, setCode] = useState(
    initialValue ? initialValue.split("").slice(0, length) : Array(length).fill("")
  );
  const inputs = useRef([]);

  const processInput = (e, slot) => {
    const num = e.target.value;
    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);
    if (slot !== length - 1) {
      inputs.current[slot + 1].focus();
    }
    if (newCode.every((num) => num !== "")) {
      onComplete(newCode.join(""));
    }
  };

  const onKeyDown = (e, slot) => {
    if (
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105)
    ) {
      const newCode = [...code];
      newCode[slot] = "";
      setCode(newCode);
    }
  };

  useEffect(() => {
    if (initialValue) {
      const initialCode = initialValue.split("").slice(0, length);
      setCode(initialCode);
      if (inputs.current[0]) {
        inputs.current[0].focus();
      }
    }
  }, [initialValue, length]);

  return (
    <div className={classes.codeInputs}>
      {code.map((num, idx) => (
        <input
          className={[
            className ? className : classes.codeInput,
            idx === Math.floor(length / 2) - 1 && length % 2 === 0
              ? classes.codeInputSeparation
              : null,
          ].join(" ")}
          key={idx}
          type="text"
          maxLength={1}
          value={num}
          required
          autoFocus={!code[0].length && idx === 0}
          onChange={(e) => processInput(e, idx)}
          onKeyDown={(e) => onKeyDown(e, idx)}
          ref={(ref) => (inputs.current[idx] = ref)}
        />
      ))}
    </div>
  );
};

export default InputCode;