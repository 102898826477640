import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { CText } from "../../../components/Text";
import './stats.styles.css';

const LastGames = ({ teamId, matches, numberMatches }) => {
  const { t } = useTranslation();
  const [lastGamesResults, setLastGamesResults] = useState([]);

  useEffect(() => {
    getLastGameResults();
  }, []);

  const getLastGameResults = async () => {
    try {
      const numMatches = numberMatches;
  
      if (!Array.isArray(matches) || matches.length === 0) {
        const emptyResults = Array(numMatches).fill(null);
        setLastGamesResults(emptyResults);
        return;
      }
  
      const sortedMatches = matches.sort((a, b) => new Date(b.date) - new Date(a.date));
  
      const recentMatches = sortedMatches.slice(0, numMatches);
  
      let matchResults = recentMatches.map((match) => {
        let result = "D";
  
        if (
          typeof match.home_team_goals === 'number' &&
          typeof match.away_team_goals === 'number'
        ) {
          if (match.home_team_id === teamId) {
            if (match.home_team_goals > match.away_team_goals) {
              result = "W";
            } else if (match.home_team_goals < match.away_team_goals) {
              result = "L";
            }
          } else if (match.away_team_id === teamId) {
            if (match.away_team_goals > match.home_team_goals) {
              result = "W";
            } else if (match.away_team_goals < match.home_team_goals) {
              result = "L";
            }
          }
  
          if (match.home_team_goals === match.away_team_goals) {
            result = "D";
          }
        } else {
          result = "N/A";
        }
  
        return result;
      });
  
      const emptyResultsCount = numMatches - matchResults.length;
      const emptyResults = Array(emptyResultsCount).fill(null);
      matchResults = [...matchResults, ...emptyResults];
  
      setLastGamesResults(matchResults);
    } catch (err) {
      console.log("Error in getLastGameResults: ", err);
    }
  };
  
  
  let Result = (gameResult, index) => {
    let res;

    switch (gameResult) {
      case 'W':
        res = <div key={index} className="stats-result stats-result-win" />
        break;
      case 'D':
        res = <div key={index} className="stats-result stats-result-draw" />
        break;
      case 'L':
        res = <div key={index} className="stats-result stats-result-loss" />
        break;
      default:
        res = <div key={index} className="stats-result stats-result-empty" />
        break;
    }
    return res;
  }

  return(
    <div className="stats-zone">
      <CText className="stats-title" level="h3" fontWeight="700" color="light">
        {t('team_stats.5.title')}
      </CText>
      <div className="stats-elmt-2">
        {lastGamesResults.map((gameResult, index) => (
          Result(gameResult, index)
        ))}
      </div>
    </div>
  )
}

export default LastGames;