import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { API } from 'aws-amplify';
import { useSelector } from 'react-redux';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import CButton from "../../components/Button";
import { CText } from "../../components/Text";
import MatchCard from '../../components/Cards/MatchCard';
import LineUp from '../../components/LineUp/LineUp';
import NoGame from "../../components/Cards/NoGame";
import { getCurrentSeason } from "../../utils/season";
import { useParams } from 'react-router-dom';

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_BLUE = "#25a5e6";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;
const PLAYER_SUBSITUTES_CARD_HEIGHT = 120;

const PreGame = (props) => {
  const { t } = useTranslation()
  const userStored = useSelector(state => state?.user);

  const [editable, setEditable] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [game, setGame] = useState([]);
  const [currentSeason, setCurrentSeason] = useState("");

  const { matchId } = useParams();

  useEffect(() => {  
    const fetchData = async () => {
      await getUserType();
      const currentSeason = await getCurrentSeason();
      setCurrentSeason(currentSeason);
      const game = await getGame();
      const currentFormation = await getFormation(game);
      await getLineUp(game, currentFormation, currentSeason);
      setLoaded(true);
    }
  
    fetchData();
  }, [])

  const getUserType = async() => {
    try {
      const groups = userStored?.groups;
      if(groups.includes("Coaches")) {
        setEditable(true);
      }
    }
    catch(err) { console.log(err) }
  }

  const getGame = async() => {
    try {
      let getMatchResp = await API.graphql({
        query: queries.getMatch,
        variables: { id: matchId },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      getMatchResp = getMatchResp.data.getMatch;

      setGame(getMatchResp);

      return getMatchResp;
    }
    catch(err) {
      //console.log(err)
    }
  }

  const getFormation = async (game) => {
    let currentFormation;
    if(game?.home_team?.id) {
      game?.home_team_formation ? currentFormation = game?.home_team_formation : currentFormation =  "4-3-3"
    } else {
      game?.away_team_formation ? currentFormation = game?.away_team_formation : currentFormation =  "4-3-3"
    }

    return currentFormation
  }

  const getLineUp = async (game, currentFormation, currentSeason) => {
    try {
      const matchPlayerFilter = {
        match_id: { eq: game.id }
      }
  
      const team = userStored?.club.team;
      const userTeamFilter = {
        season: { eq: currentSeason },
        team_id: { eq: team.id },
        user_type: { eq: "P" },
        end_date: { attributeExists: false },
        invite_status: { eq: 1 }
      }
  
      let matchPlayers = await API.graphql({
        query: queries.listMatchPlayers,
        variables: { filter: matchPlayerFilter, userTeamFilter: userTeamFilter },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      const captainPlayer = matchPlayers.data.listMatchPlayers.items.find(player => player.captain === true);
      const captainId = captainPlayer ? captainPlayer.user.id : null;

      if (captainPlayer) {
        setCaptain(captainPlayer.user.id);
      } else {
        setCaptain(null);
      }
  
      matchPlayers = matchPlayers.data.listMatchPlayers.items.map(player => {
        const adaptedPlayer = {
          position_number: player.position,
          captain: player.captain,
          player: {
            id: player.user.id,
            first_name: player.user.first_name,
            last_name: player.user.last_name,
            photo_path: player.user.photo_path,
            position: player.user.teams.items[0].position
          }
        };
  
        if (adaptedPlayer.position_number < 12) {
          return { ...adaptedPlayer, line_up: true };
        } else if (adaptedPlayer.position_number === 12) {
          return { ...adaptedPlayer, bench_players: true };
        } else {
          return adaptedPlayer;
        }
      });
  
      const lineUpInitial = [
        { position: 1, player: {} },
        { position: 2, player: {} },
        { position: 3, player: {} },
        { position: 4, player: {} },
        { position: 5, player: {} },
        { position: 6, player: {} },
        { position: 7, player: {} },
        { position: 8, player: {} },
        { position: 9, player: {} },
        { position: 10, player: {} },
        { position: 11, player: {} }
      ];
  
      const gameLineUp = lineUpInitial.map((player) => {
        const matchingPlayer = matchPlayers.find((p) => p.position_number === player.position);
  
        if (matchingPlayer) {
          return {
            ...player,
            player: {
              id: matchingPlayer.player.id,
              first_name: matchingPlayer.player.first_name,
              last_name: matchingPlayer.player.last_name,
              photo_path: matchingPlayer.player.photo_path,
              position: matchingPlayer.player.position
            }
          };
        } else {
          return player;
        }
      });
  
      const benchPlayers = matchPlayers.filter(player => player.bench_players);
      setLineUp({ formation: currentFormation, line_up: gameLineUp, bench_players: benchPlayers, captain: captainId });
    } catch (err) {
      console.log(err);
    }
  };
  
  
  /********* Handle Lineup *********/
  const [playersList, setPlayersList] = useState([])
  const [lineUp, setLineUp] = useState({});
  const [captain, setCaptain] = useState("");

  const useStyles = makeStyles((theme) => ({
    container: {
      height: '100%',
      flexGrow: 1,
      [theme.breakpoints.up('xs')]: {
        paddingLeft: 0,
        paddingRight: 0
      },
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 10,
        paddingRight: 10
      },
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 30,
        paddingRight: 30
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: 45,
        paddingRight: 45
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 80,
        paddingRight: 80
      }
    },
    topButtonZone: {
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'end',
      alignItems: 'end',
      width: '100%',
      boxSizing: 'border-box',
      marginBottom: 20
    },
    titleZone: {
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'start',
      boxSizing: 'border-box',
      width: '100%'
    },
    title: {
      marginBottom: 15
    },
    contentZone: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    matchCard: {
      marginBottom: 25
    },
    noTeamsInfo: {
      width: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center'
    }
  }));
  const classes = useStyles();

  const setNewLineUp = async(data) => { setLineUp(data) };
  const setNewPlayersList = (data) => { setPlayersList(data) };
  const setNewCaptain = (data) => { setCaptain(data) };

  const updatePlayers = async (filteredPlayers, currentSeason, captainId) => {
    const playersToUpdate = [];
    let currentCaptain = null;

    for (const player of filteredPlayers) {
      const matchPlayerFilter = {
        user_id: { eq: player.user_id },
        match_id: { eq: player.match_id }
      };

      const matchPlayers = await API.graphql({
        query: queries.listMatchPlayers,
        variables: { filter: matchPlayerFilter },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      if (matchPlayers.data.listMatchPlayers.items.length > 0) {
        const matchPlayer = matchPlayers.data.listMatchPlayers.items[0];
        let hasDifference = false;

        if (player.captain !== matchPlayer.captain || player.position !== matchPlayer.position) {
          hasDifference = true;
        }

        if (hasDifference) {
          player.id = matchPlayer.id;
          playersToUpdate.push(player);
        }
      } else {
        playersToUpdate.push(player);
      }
    }

    if (!captainId && currentCaptain) {
      currentCaptain.captain = false;

      await API.graphql({
        query: mutations.updateMatchPlayer,
        variables: {
          input: {
            id: currentCaptain.id,
            captain: false
          }
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    }

    for (const player of playersToUpdate) {
      if (player.user_id === captainId) {
        player.captain = true;
      } else {
        player.captain = false;
      }

      if (player.id) {
        // Mise à jour du joueur existant
        await API.graphql({
          query: mutations.updateMatchPlayer,
          variables: { input: player },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
      } else {
        // Création d'un nouveau joueur
        player.season = currentSeason;
        await API.graphql({
          query: mutations.createMatchPlayer,
          variables: { input: player },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
      }
    }
  };

  const updateFormation = async (lineUp, game) => {
    let updateFormation = false;
    let matchInput = {
      id: game.id
    };

    if (game?.home_team?.id) {
      if (game?.home_team_formation !== lineUp.formation) {
        matchInput.home_team_formation = lineUp.formation;
        updateFormation = true;
      }
    } else {
      if (game?.away_team_formation !== lineUp.formation) {
        matchInput.away_team_formation = lineUp.formation;
        updateFormation = true;
      }
    }

    if (updateFormation) {
      await API.graphql({
        query: mutations.updateMatch,
        variables: { input: matchInput },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    }
  };

  const removePlayers = async (playersList, game) => {
    const filteredPlayersList = playersList.filter((player) => !player.selected);
    const playersToRemove = [];

    const matchPlayerFilter = {
      match_id: { eq: game.id }
    };

    let matchPlayers = await API.graphql({
      query: queries.listMatchPlayers,
      variables: { filter: matchPlayerFilter },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
    
    matchPlayers = matchPlayers.data.listMatchPlayers.items;

    const initialMatchPlayers = matchPlayers;
    filteredPlayersList.forEach((filteredPlayer) => {
      const playerId = filteredPlayer.user.id;
      initialMatchPlayers.forEach((initialPlayer) => {
        const initialPlayerId = initialPlayer.user.id;
        if (playerId === initialPlayerId) {
          playersToRemove.push(filteredPlayer.user);
        }
      });
    });

    for (const player of playersToRemove) {
      const matchPlayerFilter = {
        user_id: { eq: player.id },
        match_id: { eq: game.id }
      };

      const matchPlayers = await API.graphql({
        query: queries.listMatchPlayers,
        variables: { filter: matchPlayerFilter },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      const matchPlayer = matchPlayers.data.listMatchPlayers.items[0];
      const deleteInput = { id: matchPlayer.id };

      await API.graphql({
        query: mutations.deleteMatchPlayer,
        variables: { input: deleteInput },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    }
  };

  const onClickSave = async () => {
    try {
      const filteredPlayers = [...lineUp.line_up, ...lineUp.bench_players]
        .filter(player => player.player && player.player.id)
        .map(player => ({
          matchPlayersId: game.id,
          match_id: game.id,
          position: player.position,
          userMatchesId: player.player.id,
          user_id: player.player.id,
          captain: player.captain
        }));

      await Promise.all([
        updatePlayers(filteredPlayers, currentSeason, captain),
        updateFormation(lineUp, game),
        removePlayers(playersList, game)
      ]);

    } catch (error) {
      console.error('Error during save:', error);
    }
  };

  // Content
  let CONTENT;
  if(loaded === true) {
    if(game) {
      CONTENT =
      <div className={classes.contentZone}>
        <div className={classes.titleZone}>
          <CText className={classes.title} level="h1" color="light">{t('pre_game.title')}</CText>
        </div>
        {editable ?
          <div className={classes.topButtonZone}>
            <CButton
              label={t('pre_game.buttons.0')}
              type="contained"
              color={COLOR_PRIMARY}
              size={14}
              minWidth={BUTTON_WIDTH}
              onClick={() => onClickSave()}
              loader={true}
            />
          </div>
          : null
        }
        <MatchCard data={game} className={classes.matchCard} />
        <LineUp
          currentSeason={currentSeason}
          gameDate={game.date}
          onChange={setNewLineUp}
          lineUp={lineUp}
          onChangePlayersList={setNewPlayersList}
          onChangeCaptain={setNewCaptain}
          editable={editable}
        />
      </div>
    } else {
      CONTENT =
      <div className={classes.noTeamsInfo}>
        <NoGame type="game" />
      </div>
    }
  } else {
    CONTENT = <div></div>
  }

  return (
    <div className={classes.container}>
      {CONTENT}
    </div>
  )
}

export default PreGame;