import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { makeStyles } from "@material-ui/core/styles";
import appLogoMobile from "../../assets/images/app_logo_mobile.png";
import { CText } from "../../components/Text";
import { ReactComponent as IosShareIcon } from '../../assets/icons/ios-share.svg';
import { ReactComponent as IosAddIcon } from '../../assets/icons/ios-add.svg';
import { useTranslation } from "react-i18next";

const LIGHT_COLOR = "#f4f4f4";
const PRIMARY_COLOR = "#45d69f";
const COLOR_PURPLE = "#9d44fd";
const COLOR_DARK = "#242834";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    height: '100vh',
  },
  content: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%) translateY(100%)',
    backgroundColor: '#1A1919',
    borderRadius: 18,
    outline: 'none',
    overflow: 'scroll',
    transition: 'transform 0.5s ease-out, opacity 0.5s ease-out',
    opacity: 0,
    marginBottom: 20,
    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 6%)',
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      maxWidth: 500,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 550,
    }
  },
  contentOpen: {
    transform: 'translateX(-50%) translateY(0)',
    opacity: 1,
  },
  contentClosing: {
    transform: 'translateX(-50%) translateY(100%)',
    opacity: 0,
  },
  titleLogo: {
    width: 40,
    height: 40,
    marginRight: 10
  },
  title: {
    padding: 20,
    display: 'flex',
    flexDirection: 'row'
  },
  icon: {
    width: 22,
    height: 22,
    fill: PRIMARY_COLOR
  },
  highlight: {
    padding: 4,
    backgroundColor: COLOR_DARK,
    borderRadius: 5,
    marginLeft: 10
  },
  modalContent: {
    padding: 20
  },
  modalContentLabel: {
    marginBottom: 15,
    displayf: 'flex',
    alignItems: 'center'
  }
}));

const ModalApp = ({ open, onClose, title, children, className }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  const [isAnimating, setIsAnimating] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [internalOpen, setInternalOpen] = useState(open);

  useEffect(() => {
    let timer;
    if (open) {
      setInternalOpen(true);
      timer = setTimeout(() => {
        setIsAnimating(true);
      }, 100);
    } else if (isAnimating) {
      setIsClosing(true);

      timer = setTimeout(() => {
        setIsAnimating(false);
        setIsClosing(false);
        setInternalOpen(false);
        onClose();
      }, 500);
    }

    return () => clearTimeout(timer);
  }, [open, isAnimating, onClose]);

  return (
    <Modal
      open={internalOpen}
      onClose={onClose}
      className={classes.container}
      closeAfterTransition
      aria-labelledby="modal-app-download"
      aria-describedby=""
    >
      <div
        className={[
          classes.content, 
          className, 
          isAnimating && !isClosing ? classes.contentOpen : '',
          isClosing ? classes.contentClosing : ''
        ].join(' ')}
      >
        <div className={classes.title}>
          <img src={appLogoMobile} className={classes.titleLogo} alt="App Logo" />
          <CText
            className={[classes.titleText].join(" ")}
            level="h1"
            color="light"
          >
            {t('home.modals.0.title')}
          </CText>
        </div>
        <div className={classes.modalContent}>
          <CText
            className={[classes.modalContentLabel].join(" ")}
            level="p1"
            color="light"
          >
            {t('home.modals.0.labels.0')}
          </CText>
          <CText level="p1" color="light" className={[classes.modalContentLabel].join(" ")}>
            1. {t('home.modals.0.labels.1')} <IosShareIcon className={[classes.icon, classes.highlight].join(" ")} />
          </CText>
          <CText level="p1" color="light" className={[classes.modalContentLabel].join(" ")}>
            2. {t('home.modals.0.labels.2')} <span className={classes.highlight}>{t('home.modals.0.labels.3')} </span> <IosAddIcon className={[classes.icon, classes.highlight].join(" ")} />
          </CText>
        </div>
      </div>
    </Modal>
  );
};

export default ModalApp;