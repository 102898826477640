import { ReactComponent as GoalKeeperIcon } from '../../assets/icons/goalkeeper.svg';
import { ReactComponent as DefenderIcon } from '../../assets/icons/defender.svg';
import { ReactComponent as MidfielderIcon } from '../../assets/icons/midfielder.svg';
import { ReactComponent as StrickerIcon } from '../../assets/icons/stricker.svg';

export const positions = (t) => {
  const list = [
    {
      "code": "GK",
      "label": t('positions.0.label'),
      "short_label": t('positions.0.shortLabel'),
      "icon": <GoalKeeperIcon />
    },
    {
      "code": "DF",
      "label": t('positions.1.label'),
      "short_label": t('positions.1.shortLabel'),
      "icon": <DefenderIcon />
    },
    {
      "code": "MF",
      "label": t('positions.2.label'),
      "short_label": t('positions.2.shortLabel'),
      "icon": <MidfielderIcon />
    },
    {
      "code": "ST",
      "label": t('positions.3.label'),
      "short_label": t('positions.3.shortLabel'),
      "icon": <StrickerIcon />
    }
  ]

  return list;
}

export const getPositionInfo = (t, code) => {
  return positions(t).find(position => position.code === code);
}