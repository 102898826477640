import React, { useState, useEffect } from 'react';

import { makeStyles, styled } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as VisibilityIcon }  from '../assets/icons/visibility.svg';
import { ReactComponent as VisibilityOffIcon }  from '../assets/icons/visibility-off.svg';
import { ReactComponent as SearchIcon }  from '../assets/icons/search.svg';

const LABEL_COLOR = '#f4f4f4';
const COLOR_PRIMARY = '#45d69f';
const TEXTFIELD_COLOR = '#242834';
const FONT_FAMILY='Montserrat';
const iconSize = 28;

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    borderRadius: 8,
    fontSize: "16px !important",
    backgroundColor: TEXTFIELD_COLOR,
    fontFamily: FONT_FAMILY
  },
  root: {
    color: LABEL_COLOR,
    userSelect: 'none',
    "&$focusedLabel": {
      color: COLOR_PRIMARY
    },
    '&.Mui-disabled': {
      color: '#adadad'
    },
    fontFamily: FONT_FAMILY
  },
  inputRoot: {
    color: LABEL_COLOR,
    borderRadius: 8,
    background: 'transparent',
    "&&&:before": {
      borderBottom: "none"
    },
    "&:after": {
      borderRadius: 8,
      borderBottom: `2px solid #45d69f`
    },
    '& :-webkit-autofill': {
      fontSize: "16px !important",
      borderRadius: 8,
      "transition-delay": "99999999s"
    },
    '& :-webkit-autofill::first-line': {
      fontSize: "16px !important",
      borderRadius: 8
    },
    '&.Mui-disabled': {
      color: '#adadad'
    },
    fontFamily: FONT_FAMILY
  },
  focusedLabel: { 
    color: COLOR_PRIMARY, 
    fontFamily: FONT_FAMILY
  },
  passwordRightIcon: {
    width: 22,
    height: 22,
    fill: LABEL_COLOR,
    stroke: LABEL_COLOR,
    cursor: "pointer",
    marginTop: 10,
    marginLeft: 8,
    padding: 8,
    borderRadius: 100,
    "&:hover": {
      backgroundColor:'rgba(26, 25, 25, 0.5)'
    },
    fontFamily: FONT_FAMILY // Ajout de la propriété fontFamily
  },
  searchInputRoot: {
    color: '#f2f2f2',
    opacity: 'none',
    borderRadius: 8,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
    fontFamily: FONT_FAMILY // Ajout de la propriété fontFamily
  },
  searchIcon: {
    width: 18,
    height: 18,
    fill: '#f2f2f2',
    stroke: '#f2f2f2'
  },
}));

const TextInput = (props) => {
  const [value, setValue] = useState(props.defaultValue || "");
  const [showPassword, setShowPassword] = useState(false);
  //const [errorNewPasword, setErrorNewPasword] = useState(false);
  //const [errorConfirmPasword, setErrorConfirmPasword] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    setValue(props.defaultValue || "");
  }, [props.defaultValue]);

  const onChange = (event) =>{
    setValue(event.target.value);
    props.inputCallback(event.target.value);
  }

  return(
    <TextField
      //error={errorConfirmPasword}
      label={props.label}
      variant="filled"
      size="small"
      className={[classes.input, props.className].join(' ')}
      type={props.type !== "password" || showPassword ? 'text' : 'password'}
      //defaultValue={props.defaultValue}
      disabled={props.disabled}
      value={value}
      onChange={onChange}
      InputLabelProps={{
        classes: {
          root: classes.root,
          focused: classes.focusedLabel
        }
      }}
      InputProps={{
        classes: {
          root: classes.inputRoot,
        },
        endAdornment: props.type === "password" ?
          <InputAdornment position="end">
            { showPassword === true ?
              <VisibilityIcon className={classes.passwordRightIcon} onClick={() => setShowPassword(!showPassword)}/>
              : <VisibilityOffIcon className={classes.passwordRightIcon} onClick={() => setShowPassword(!showPassword)}/>
            }
          </InputAdornment>
          : null
      }} 
    />
  );
}

const CSearchTextField= styled(TextField)({
  '& label.Mui-focused': {
    color: COLOR_PRIMARY
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: COLOR_PRIMARY,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent'
    },
    '&:hover fieldset': {
      borderColor: 'transparent'
    },
    '&.Mui-focused fieldset': {
      borderColor: COLOR_PRIMARY,
    },
  },
});


export const SearchBar = (props) => {
  const classes = useStyles();

  const [value, setValue] = useState("");
  const [showClearIcon, setShowClearIcon] = useState("none");

  const handleChange = (event) => {
    setShowClearIcon(event.target.value === "" ? "none" : "flex");
    setValue(event.target.value);
    props.inputCallback(event.target.value);
  };

  return (
    <CSearchTextField
      className={[classes.input, classes.searchInput, props.className].join(' ')}
      variant="outlined"
      size="small"
      onChange={handleChange}
      placeholder={props.placeholder}
      value={props.value || props.value == "" ? props.value : value }
      InputLabelProps={{
        classes: {
          root: classes.searchRoot,
          focused: classes.focusedLabel
        }
      }}
      InputProps={{
        classes: {
          root: classes.searchInputRoot,
        },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon className={classes.searchIcon} />
          </InputAdornment>
        )
      }}
    />
  );
};

export default TextInput;