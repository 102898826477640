import {makeStyles} from "@material-ui/core/styles"

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_RED = "#fc3c2e"
const COLOR_YELLOW = "#EDA247"
const COLOR_BLUE = "#25a5e6";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const ZONE_BACKGROUND_COLOR_2 = "#242834";
const BUTTON_WIDTH = 100;

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    marginBottom: 30
  },
  selectPhoto: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  btnZone: {
    marginTop: 'auto',
    alignItems: 'baseline',
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  inlineBtn: {
    width: '100%'
  },
  btn:{
    width: '50%'
  },
  profilePicture: {
    border: '3px solid',
    marginBottom: 30,
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    overflow: 'hidden',
    textAlign: 'center',
    [theme.breakpoints.up('xs')]: {
      width: 150,
      height: 150
    },
    [theme.breakpoints.up('sm')]: {
      width: 180,
      height: 180
    },
    [theme.breakpoints.up('md')]: {
      width: 200,
      height: 200
    },
    [theme.breakpoints.up('lg')]: {
      width: 220,
      height: 220
    }
  },
  clubPicture: {
    marginBottom: 30,
    width: 'auto',
    [theme.breakpoints.up('xs')]: {
      maxWidth: 150,
      height: 150
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 180,
      height: 180
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 200,
      height: 200
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 220,
      height: 220
    }
  }
}));