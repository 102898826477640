import React, { useState, useEffect } from 'react';
import { CText } from '../Text';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ReactComponent as CaretIcon } from '../../assets/icons/caret.svg';
import { useDispatch } from 'react-redux';
import { useStyles } from './Dorpdown.styles.js';

const COLOR_PRIMARY = '#45d69f';

const DropdownFilter = ({ options, onSelect, className, type, selectedIndex }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(selectedIndex);
  const [selectedOption, setSelectedOption] = useState(options[selectedIndex] || '');

  const isDisabled = options.length <= 1;

  useEffect(() => {
    setCurrentIndex(selectedIndex);
    setSelectedOption(options[selectedIndex] || '');
  }, [selectedIndex, options]);

  const openSelection = (e) => {
    if (!isDisabled) {
      setAnchorEl(e.currentTarget);
      setOpen(true);
    }
  };

  const closeSelection = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleSelect = (index) => {
    closeSelection();
    setCurrentIndex(index);
    setSelectedOption(options[index]);
    if (onSelect) onSelect(index);
  };

  const combinedClass = `${classes.selectorBtn} ${isDisabled ? classes.disabledSelectorBtn : ''}`;

  return (
    <div className={type === 'icon' ? classes.container1 : classes.container2}>
      <div className={combinedClass} onClick={openSelection}>
        {!isDisabled && (
          <div className={classes.selectorBtnContent}>
            <span className={classes.selectedOption}>
              {type === 'icon' ?
                <span className={classes.icon}>{selectedOption.icon}</span> 
                :
                <CText level="p2" color="light" className={classes.label}>
                  {selectedOption.name}
                </CText>
              }
            </span>
            <CaretIcon className={classes.selectorIcon} />
          </div>
        )}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeSelection}
        PaperProps={{
          style: {
            minWidth: '20ch',
            maxHeight: 160,
          },
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        autoFocus={false}
      >
      {options
        .filter((option, index) => index !== currentIndex)
        .map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => handleSelect(options.indexOf(option))}
            className={`${classes.menuItem}}`}
          >
            {option?.icon ? <span className={classes.menuIcon}>{option?.icon}</span> : null} {option?.name}
          </MenuItem>
      ))}
      </Menu>
    </div>
  );
};

export default DropdownFilter;