import { getCurrentSeason } from "../../../utils/season";
import { API } from 'aws-amplify';
import * as queries from '../../../graphql/queries';

export const matchTimes = Array.from({length: 90}, (_, i) => { return { "label": `${i+1}'`, "code": i+1 } } );

export const searchPlayers = async (listPlayers, input) => {
  if (input.length < 1) {
    return listPlayers.slice(0, 6);
  } else {
    const searchTerms = input.toLowerCase().split(' ').filter(term => term.length > 0);

    return listPlayers.filter(user => {
      const userNames = [user.user.first_name.toLowerCase(), user.user.last_name.toLowerCase()];

      return searchTerms.every(term => {
        const startsWithTerm = userNames.some(name => name.startsWith(term));

        return startsWithTerm || (term.length >= 3 && userNames.some(name => name.includes(term)));
      });
    });
  }
};

export const getTeamPlayers = async(userStored) => {
  try {
    const currentSeason = await getCurrentSeason();

    const teamId = userStored?.club?.team?.id;

    const userFilter = {
      invite_status: { eq: 1 },
      user_type: { eq: "P" },
      season: { eq: currentSeason }
    };

    let teamPlayers = await API.graphql({
      query: queries.getTeamPlayers,
      variables: {
        id: teamId,
        userFilter: userFilter
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
    teamPlayers = teamPlayers.data.getTeam.users.items

    return teamPlayers;
  } catch(err) {
    //console.log(err)
  }
}