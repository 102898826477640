import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { CText } from "../../../components/Text";
import { Bar } from 'react-chartjs-2';
import './stats.styles.css';

const COLOR_PRIMARY = '#45d69f';
const COLOR_PRIMARY_TRANSPARENT = 'rgba(69, 214, 159, 0.4)';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_PINK_TRANSPARENT = 'rgba(233, 33, 99, 0.4)'
const COLOR_BLUE = "#25a5e6";
const COLOR_BLUE_TRANSPARENT = 'rgba(37, 165, 230, 0.4)';
const COLOR_PURPLE = "#9d44fd";
const COLOR_PURPLE_TRANSPARENT = 'rgba(157, 68, 253, 0.4)';
const COLOR_ORANGE = "#f2a624";
const COLOR_DARK = "#242834";
const COLOR_ORANGE_TRANSPARENT = 'rgba(242, 166, 36, 0.4)'
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;
const MENU_WIDTH = 250;
const BORDER_RADIUS = 18;

const GoalsPerGame = ({ matches, teamId }) => {
  const { t } = useTranslation();

  const [averageGoalsScored, setAverageGoalsScored] = useState(0);
  const [averageGoalsConceded, setAverageGoalsConceded] = useState(0);

  useEffect(() => {
    const { averageGoalsScored, averageGoalsConceded } = calculateGoalsStats(matches, teamId);
    setAverageGoalsScored(averageGoalsScored);
    setAverageGoalsConceded(averageGoalsConceded);
  }, [matches, teamId]);

  const calculateGoalsStats = (matches, teamId) => {
    let goalsScored = 0;
    let goalsConceded = 0;
  
    matches.forEach((match) => {
      if (match.home_team_id === teamId) {
        goalsScored += match.home_team_goals;
        goalsConceded += match.away_team_goals;
      } else if (match.away_team_id === teamId) {
        goalsScored += match.away_team_goals;
        goalsConceded += match.home_team_goals;
      }
    });
  
    const averageGoalsScored = goalsScored / matches.length;
    const averageGoalsConceded = goalsConceded / matches.length;
  
    return { averageGoalsScored, averageGoalsConceded };
  }; 

  const data = {
    labels: [ "" ],
    datasets: [
      {
        label: `${t('team_stats.4.labels.0')}`,
        data: [averageGoalsScored],
        backgroundColor: COLOR_PRIMARY,
        borderColor: COLOR_PRIMARY
      },
      {
        label: `${t('team_stats.4.labels.1')}`,
        data: [averageGoalsConceded],
        backgroundColor: COLOR_PINK,
        borderColor: COLOR_PINK
      }
    ],
  };

  let options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1
  };

  return(
    <div className="stats-zone">
      <CText className="stats-title" level="h3" fontWeight="700" color="light">
        {t('team_stats.4.title')}
      </CText>
      <Bar data={data} options={options} className="stats-elmt-1" />
    </div>
  )
}

export default GoalsPerGame;