import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const conversationsSlice = createSlice({
  name: 'conversations',
  initialState: {
    conversations: []
  },
  reducers: {
    incrementConversationCount: (state, action) => {
      const { id } = action.payload;
      const existingConversation = state.conversations.find(convo => convo.id === id);

      if (existingConversation) {
        existingConversation.count += 1;
      } else {
        state.conversations.push({ id, count: 1 });
      }
    },
    updateConversationCount: (state, action) => {
      const { id, count } = action.payload;
      const existingConversation = state.conversations.find(convo => convo.id === id);

      if (existingConversation) {
        existingConversation.count = count;  // Update the count to the specified value
      } else {
        state.conversations.push({ id, count });  // Add the conversation with the specified count if it doesn't exist
      }
    },
    resetConversationCount: (state, action) => {
      const { id } = action.payload;
      const existingConversation = state.conversations.find(convo => convo.id === id);
      if (existingConversation) {
        existingConversation.count = 0;  // Reset the count to 0
      } else {
        state.conversations.push({ id, count: 0 });  // Initialize the count at 0 if it doesn't exist
      }
    }
  }
});

export const { 
  incrementConversationCount, 
  resetConversationCount,
  updateConversationCount
} = conversationsSlice.actions;

export default conversationsSlice.reducer;