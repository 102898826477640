import {makeStyles} from "@material-ui/core/styles"

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_RED = "#fc3c2e"
const COLOR_YELLOW = "#EDA247"
const COLOR_BLUE = "#25a5e6";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const ZONE_BACKGROUND_COLOR_2 = "#242834";
const BUTTON_WIDTH = 100;
const COLOR_DARK = "#242834";

export const useStylesSelector = makeStyles((theme) => ({
  selectorBtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    "&:hover": {
      filter: 'brightness(90%)'
    }
  },
  disabledSelectorBtn: {
    cursor: 'default',
    "&:hover": {
      filter: 'none'
    }
  },
  selectorIcon: {
    width: 14,
    height: 14,
    fill: COLOR_LIGHT,
    marginLeft: 6
  },
  selectorCompetitionIcon: {
    width: 18,
    height: 18,
    fill: COLOR_LIGHT,
    marginRight: 6
  },
  listIcon: {
    width: 18,
    height: 18,
    fill: COLOR_DARK,
    marginRight: 6
  },
  label: {
    fontWeight: '700 !important'
  }
}));