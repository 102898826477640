import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { API } from 'aws-amplify';
import { useSelector } from 'react-redux'
import pitch from '../../assets/images/pitch.svg';
import { ReactComponent as Cross } from '../../assets/icons/cross.svg';
import { ReactComponent as Bench } from '../../assets/icons/bench.svg';
import { ReactComponent as CaptainBand } from '../../assets/icons/colored/captain-band.svg';
import { ReactComponent as CaptainBandDark } from '../../assets/icons/colored/captain-band-dark.svg';
import * as queries from '../../graphql/queries';
import ProfilePicture from "../../components/ProfilePicture/index";
import { CText } from "../Text";
import { sortASC } from "../../utils/sort";

const COLOR_PRIMARY = '#45d69f';
const COLOR_DARK = "#242834";
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_BLUE = "#25a5e6";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;
const PLAYER_SUBSITUTES_CARD_HEIGHT = 120;

const SubstitutionLineUp = ({ player, onChangePosition }) => {
  const [selectedPosition, setSelectedPosition] = useState({});
  const [gameLineUp, setGameLineUp] = useState([
    { position: 1, player: {} },
    { position: 2, player: {} },
    { position: 3, player: {} },
    { position: 4, player: {} },
    { position: 5, player: {} },
    { position: 6, player: {} },
    { position: 7, player: {} },
    { position: 8, player: {} },
    { position: 9, player: {} },
    { position: 10, player: {} },
    { position: 11, player: {} }
  ]);

  const [loaded, setLoaded] = useState(false);
  
  const pitchRef = useRef(null);
  const { t } = useTranslation();

  const [dimensions, setDimensions] = useState({
    pitchHeight: 0,
    pitchWidth: 0,
    playerZone: 0,
    lateralPadding: 0,
    playerZoneWrapper: 0,
    selectFormationLeftMargin: 0
  });
  
  const [width, setWidth] = useState(0);
  
  const handleResize = async() => {
    const width = window.innerWidth;
    setWidth(width);
  
    let pitchHeight;
    let pitchWidth;
    let playerZone;
    let lateralPadding;
    let playerZoneWrapper;
    let selectFormationLeftMargin;

    if (pitchRef.current) {
      pitchWidth = pitchRef.current.offsetWidth;
      pitchHeight = pitchRef.current.offsetHeight;
    }
  
    if (width < 500) {
      playerZone = 35;
      lateralPadding = 30;
      playerZoneWrapper = 80;
      selectFormationLeftMargin = 100;
    } else if (width < 600) {
      playerZone = 40;
      lateralPadding = 0;
      playerZoneWrapper = 80;
      selectFormationLeftMargin = 280;
    } else {
      selectFormationLeftMargin = 220;
      playerZone = 45;
      lateralPadding = 0;
      playerZoneWrapper = 80;
      selectFormationLeftMargin = 310;
    }
  
    setDimensions({
      pitchHeight,
      pitchWidth,
      playerZone,
      lateralPadding,
      playerZoneWrapper,
      selectFormationLeftMargin
    });
  };

  const lineupsRef = [
    {
      "formation": "4-3-3",
      "players": [
        {
          "number": 1,
          "position": {
            "top": dimensions.pitchHeight - 5 - dimensions.playerZone,
            "left": dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2
          }
        },
        {
          "number": 2,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone - 30,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + 200 - dimensions.lateralPadding
          }
        },
        {
          "number": 3,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone - 30,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - (200) + dimensions.lateralPadding
          }
        },
        {
          "number": 4,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - 100
          }
        },
        {
          "number": 5,
          "position": {
            "top": (dimensions.pitchHeight - 10 - dimensions.playerZone) - dimensions.playerZone,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + 100
          }
        },
        {
          "number": 6,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) + (dimensions.playerZone / 2),
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2)
          }
        },
        {
          "number": 7,
          "position": {
            "top": 0 + dimensions.playerZone - 2 * 5,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + 180 - dimensions.lateralPadding
          }
        },
        {
          "number": 8,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) - (dimensions.playerZone / 2) - 15,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) + (2 * dimensions.playerZone)
          }
        },
        {
          "number": 9,
          "position": {
            "top": 0 - 3,
            "left": dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2
          }
        },
        {
          "number": 10,
          "position": {
            "top": ((dimensions.pitchHeight / 2) - (dimensions.playerZone / 2)) - (dimensions.playerZone / 2) - 15,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - (2 * dimensions.playerZone)
          }
        },
        {
          "number": 11,
          "position": {
            "top": 0 + dimensions.playerZone - 2 * 5,
            "left": (dimensions.pitchWidth / 2 - dimensions.playerZoneWrapper / 2) - 180 + dimensions.lateralPadding
          }
        }
      ]
    }
  ];
  
  useEffect(() => {
    const fetchData = async () => {
      await handleResize();

      setLoaded(true)
    }
  
    fetchData();
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const useStyles = makeStyles((theme) => ({
    benchIcon: {
      fill: COLOR_PRIMARY,
      stroke: COLOR_PRIMARY,
      height: 60,
      width: 60
    },
    benchTitleZone: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 30
    },
    benchZone: {
      width: `calc(100% - 40px)`,
      borderRadius: 25,
      backgroundColor: ZONE_BACKGROUND_COLOR,
      marginTop: 8,
      padding: 20
    },
    container: {
      height: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    crossBtn: {
      position: 'absolute',
      width: 15,
      height: 15,
      padding: 8,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 5,
      right: 5,
      cursor: 'pointer',
      stroke: COLOR_LIGHT,
      borderRadius: '50%',
      "&&:hover": {
        backgroundColor: '#242526',
        boxShadow: 'none'
      }
    },
    lineupZone: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
      [theme.breakpoints.up('xs')]: {
        maxWidth: 500
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 500
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 550,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 600
      }
    },
    lineupFormationZone: {
      marginLeft: dimensions.selectFormationLeftMargin
    },
    pitchZone: {
      position: 'relative',
      marginTop: 20,
      userSelect: 'none'
    },
    pitch: {
      pointerEvents: 'none',
      userSelect: 'none',
      width: '100%'
    },
    pitchPlayerZoneWrapper: {
      width: dimensions.playerZoneWrapper,
      position: "absolute",
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    pitchPlayerZone: {
      width: dimensions.playerZone,
      height: dimensions.playerZone,
      borderRadius: 100,
      backgroundColor: COLOR_LIGHT,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `2px solid ${COLOR_PRIMARY}`
    },
    pitchPlayerZoneEditable: {
      cursor: 'pointer',
      "&&:hover": {
        filter: 'brightness(90%)'
      }
    },
    playerNumber: {
      fontSize: 20,
      fontWeight: '700'
    },
    playerName: {
      fontSize: 14,
      fontWeight: '700',
      textAlign: 'center'
    },
    listPlayersZone: {
      float: 'right',
      height: '100%',
      flexGrow: 1,
      background: '#1f2126',
      borderRadius: 25,
      display: 'flex',
      flexDirection: 'column',
      padding: 20
    },
    playerSubstitutesBtn: {
      height: PLAYER_SUBSITUTES_CARD_HEIGHT - (3 * 2),
      border: `3px solid ${COLOR_PRIMARY}`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 30,
      borderRadius: 25,
      transform: 'translate(0, 0)',
      "&&:hover": {
        cursor: 'pointer',
        filter: 'brightness(90%)'
      }
    },
    playerSubstitutesCard: {
      background: '#242834',
      height: PLAYER_SUBSITUTES_CARD_HEIGHT,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 20,
      borderRadius: 25,
      transform: 'translate(0, 0)'
    },
    playerPhoto: {
      width: 60,
      height: 60,
      borderRadius: 100,
      backgroundColor: COLOR_LIGHT
    },
    playerSubstitutesCardInfo: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 10,
      textAlign: 'center'
    },
    selectFormation: {
      width: 150,
      backgroundColor: ZONE_BACKGROUND_COLOR
    },
    formation: {
      width: 150,
      backgroundColor: ZONE_BACKGROUND_COLOR,
      display: 'flex',
      justifyContent: 'center',
      padding: 8,
      borderRadius: 8
    },
    substitutesZone: {
      background: '#1f2126',
      borderRadius: 25,
      padding: 20,
      display: 'flex',
      flexDirection: 'row'
    },
    crossBtnPlayer: {
      position: 'absolute',
      width: 9,
      height: 9,
      padding: 5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: -3,
      right: 12,
      cursor: 'pointer',
      stroke: COLOR_LIGHT,
      borderRadius: '50%',
      backgroundColor: COLOR_PINK,
      "&&:hover": {
        filter: 'brightness(90%)'
      }
    },
    captain: {
      position: 'absolute',
      width: 20,
      height: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: -5,
      left: 6,
    },
    captainBtn: {
      cursor: 'pointer',
      "&&:hover": {
        filter: 'brightness(90%)'
      }
    }
  }));

  const classes = useStyles();

  const handlePlayerPositionClick = (index) => {
    const existingPosition = gameLineUp.findIndex(p => p.player?.id === player.id);

    let newLineUp = [...gameLineUp];

    if (existingPosition !== -1) {
      newLineUp[existingPosition].player = {};
    }

    newLineUp[index] = { ...newLineUp[index], player };

    setGameLineUp(newLineUp);
    onChangePosition(index + 1);
  };

  return (
    <>
      <div className={classes.pitchZone}>
        {lineupsRef[0].players.map((playerRef, index) => (
          <div
            key={index}
            className={classes.pitchPlayerZoneWrapper}
            style={{ top: playerRef.position.top, left: playerRef.position.left }}
          >
            {!gameLineUp[index]?.player?.id ? (
              <div
                className={`${classes.pitchPlayerZone} ${classes.pitchPlayerZoneEditable}`}
                onClick={() => handlePlayerPositionClick(index)}
              >
                <CText className={classes.playerNumber} color="dark">{playerRef.number}</CText>
              </div>
            ) : (
              <>
                <ProfilePicture
                  className={`${classes.pitchPlayerZone} ${classes.pitchPlayerZoneEditable}`}
                  src={gameLineUp[index].player.photo_path}
                />
                <CText className={classes.playerName} color="light">
                  {player?.first_name?.charAt(0)}.{player?.last_name}
                </CText>
              </>
            )}
          </div>
        ))}
        <img src={pitch} className={classes.pitch} ref={pitchRef} />
      </div>
    </>
  );
}

export default SubstitutionLineUp;