import React, { useState, useEffect } from 'react';

const COLOR_PRIMARY = '#45d69f';
const COLOR_PRIMARY_TRANSPARENT = 'rgba(69, 214, 159, 0.4)';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = '#242834';
const COLOR_RED = "#fc3c2e";
const COLOR_YELLOW = "#EDA247";

const Arrow = ({ arrow, index, onArrowMove, onSelect, isSelected, type, canvasScale }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [isDraggingCircle, setIsDraggingCircle] = useState(false);
  const [circleOffset, setCircleOffset] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (isDragging || isDraggingCircle) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, isDraggingCircle]);

  const CIRCLE_SIZE = 4;
  const TRANSPARENT_CIRCLE_SIZE = CIRCLE_SIZE * 2;

  const startScaledX = arrow.start.x * canvasScale;
  const startScaledY = arrow.start.y * canvasScale;
  const endScaledX = arrow.end.x * canvasScale;
  const endScaledY = arrow.end.y * canvasScale;

  const adjustedLength = Math.sqrt((endScaledY - startScaledY) ** 2 + (endScaledX - startScaledX) ** 2);
  const arrowHeight = 15 / canvasScale;
  const arrowminWidth = 90 * canvasScale;

  const handleCircleMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingCircle(true);
    const xDiff = e.clientX - endScaledX;
    const yDiff = e.clientY - endScaledY;
    setCircleOffset({ x: xDiff, y: yDiff });
  };

  const handleMouseDown = (e) => {
    onSelect(arrow.id);
    setIsDragging(true);
    const offsetX = e.clientX - startScaledX;
    const offsetY = e.clientY - startScaledY;
    setDragOffset({ x: offsetX, y: offsetY });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      // Déplacer le joueur en fonction de la position de la souris et de l'offset calculé
      let newStartX = e.clientX - dragOffset.x;
      let newStartY = e.clientY - dragOffset.y;
      let newEndX = arrow.end.x + (newStartX - startScaledX) / canvasScale;
      let newEndY = arrow.end.y + (newStartY - startScaledY) / canvasScale;
  
      // Limiter la position maximale en left et top
      newStartX = Math.min(Math.max(0, newStartX));
      newStartY = Math.min(Math.max(0, newStartY));
      newEndX = Math.min(Math.max(0, newEndX));
      newEndY = Math.min(Math.max(0, newEndY));
  
      const updatedArrow = { ...arrow, start: { x: newStartX / canvasScale, y: newStartY / canvasScale }, end: { x: newEndX, y: newEndY } };
      onArrowMove(updatedArrow);
    } else if (isDraggingCircle) {
      const newEndX = e.clientX - circleOffset.x;
      const newEndY = e.clientY - circleOffset.y;
      const dx = newEndX - startScaledX;
      const dy = newEndY - startScaledY;
      let length = Math.sqrt(dx * dx + dy * dy);

      // Vérifie que la longueur de la flèche ne devienne pas inférieure à arrowminWidth
      if (length < arrowminWidth) {
        length = arrowminWidth;
      }

      const angle = Math.atan2(dy, dx);
      const adjustedEndX = startScaledX + length * Math.cos(angle);
      const adjustedEndY = startScaledY + length * Math.sin(angle);
      const updatedArrow = { ...arrow, end: { x: adjustedEndX / canvasScale, y: adjustedEndY / canvasScale } };
      onArrowMove(updatedArrow);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setIsDraggingCircle(false);
  };

  const centerY = startScaledY + (endScaledY - startScaledY) / 2;

  return (
    <svg
      key={index}
      style={{
        position: 'absolute',
        left: startScaledX,
        top: startScaledY,
        width: adjustedLength / canvasScale , // Modifier la largeur en fonction du canvasScale
        height: arrowHeight * canvasScale, // Modifier la hauteur en fonction du canvasScale
        transform: `rotate(${Math.atan2(endScaledY - startScaledY, endScaledX - startScaledX) * 180 / Math.PI}deg) scale(${canvasScale})`, // Appliquer le canvasScale à la transformation
        transformOrigin: 'left center'
      }}
      onMouseDown={handleMouseDown}
    >
      {type === "arrow-dot" ? (
        <line
          x1={(adjustedLength - ((15 + TRANSPARENT_CIRCLE_SIZE ) * canvasScale)) / canvasScale} // Ajuster x1 en fonction du canvasScale
          y1={(arrowHeight / 2) * canvasScale} // Ajuster y1 en fonction du canvasScale
          x2="0"
          y2={(arrowHeight / 2) * canvasScale} // Ajuster y2 en fonction du canvasScale
          stroke="black"
          strokeWidth={4 / canvasScale} // Ajuster la largeur de la ligne en fonction du canvasScale
          strokeDasharray="10, 5"
          strokeLinecap="round"
        />
      ) : (
        <line
          x1={(adjustedLength - ((15 + TRANSPARENT_CIRCLE_SIZE ) * canvasScale)) / canvasScale} // Ajuster x1 en fonction du canvasScale
          y1={(arrowHeight / 2) * canvasScale} // Ajuster y1 en fonction du canvasScale
          x2="0"
          y2={(arrowHeight / 2) * canvasScale} // Ajuster y2 en fonction du canvasScale
          stroke="black"
          strokeWidth={4 / canvasScale} // Ajuster la largeur de la ligne en fonction du canvasScale
          strokeLinecap="round"
        />
      )}
      <path d={`M${(adjustedLength - ((15 + TRANSPARENT_CIRCLE_SIZE ) * canvasScale)) / canvasScale},${(arrowHeight / 2 - (7 / canvasScale)) * canvasScale} L${(adjustedLength - ((2 + TRANSPARENT_CIRCLE_SIZE ) * canvasScale)) / canvasScale},${arrowHeight / 2 * canvasScale} L${(adjustedLength - ((15 + TRANSPARENT_CIRCLE_SIZE ) * canvasScale)) / canvasScale},${(arrowHeight / 2 + (7 / canvasScale)) * canvasScale} z`} fill="black" />
      {isSelected &&
        <g onMouseDown={handleCircleMouseDown}>
          {/* Cercle transparent pour le padding */}
          <circle
            cx={(adjustedLength - TRANSPARENT_CIRCLE_SIZE * canvasScale) / canvasScale} // Ajuster cx en fonction du canvasScale
            cy={(arrowHeight / 2) * canvasScale} // Ajuster cy en fonction du canvasScale
            r={TRANSPARENT_CIRCLE_SIZE}
            fill="transparent"
          />
          <circle
            cx={(adjustedLength - TRANSPARENT_CIRCLE_SIZE * canvasScale) / canvasScale} // Ajuster cx en fonction du canvasScale
            cy={(arrowHeight / 2) * canvasScale} // Ajuster cy en fonction du canvasScale
            r={CIRCLE_SIZE}
            fill={COLOR_PINK}
          />
        </g>
      }
    </svg>
  );
};

export default Arrow;