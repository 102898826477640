import React, { useState } from 'react';
import { useStyles } from './ChipFilter.styles.js';
import { CText } from "../Text.js";
import SelectableChip from "./SelectableChip.js";
import Box from '@mui/material/Box';

const ChipFilter = ({ label, data, onChange }) => {
  const classes = useStyles();
  const [selectedChips, setSelectedChips] = useState(data);

  const handleChipClick = (chipCode) => {
    setSelectedChips((prevSelectedChips) => {
      const updatedChips = prevSelectedChips.map((chip) => {
        if (chip.code === chipCode) {
          return { ...chip, selected: !chip.selected };
        }
        return chip;
      });

      onChange(updatedChips);
      return updatedChips;
    });
  };

  return (
    <div className={classes.container}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
        {selectedChips.map((chip) => (
          <SelectableChip
            key={chip.code}
            label={chip.label}
            selected={chip.selected}  // Use selected from the chip object
            onClick={() => handleChipClick(chip.code)}
          />
        ))}
      </Box>
    </div>
  );
};

export default ChipFilter;