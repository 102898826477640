import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderTop: '1px solid #ccc',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: '#fff',
    borderRadius: '30px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    position: 'relative',
    zIndex: 1,
    overflow: 'hidden',
  },
  textField: {
    flex: 1,
    marginRight: theme.spacing(1),
    '& .MuiOutlinedInput-root': {
      padding: '0',
      display: 'flex',
      alignItems: 'center',
      '& fieldset': {
        border: 'none',
      },
    },
    '& .MuiInputBase-input': {
      overflowY: 'auto',
      boxSizing: 'border-box',
      lineHeight: 1.3,
      resize: 'none',
      padding: '6px 12px',
      fontSize: '0.95rem'
    },
  },
  sendButton: {
    borderRadius: '50%',
    padding: theme.spacing(1),
  },
}));