import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Auth } from 'aws-amplify';
import { CText } from "../../../components/Text";
import { TopStatsRow } from './RowPlayer';
import './stats.styles.css';

const MVPRanking = ({teamId, season}) => {
  const { t } = useTranslation();
  const [players, setPlayers] = useState([]);

  useEffect(() => {  
    const fetchData = async () => {
      getMVPRank(teamId, season);
    }
  
    fetchData()
  }, [])

  const getMVPRank = async (teamId, season) => {
    try {
      let resp = await fetch(`${process.env.REACT_APP_REST_API_BASE_URL}/teams/v1/teams/${teamId}/players-mvp?season=${season}&limit=3`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
      });
      const respBody = await resp.json();

      setPlayers(respBody);
    } catch (error) {
      console.error("Erreur :", error);
    }
  };

  return(
    <div className="stats-zone">
      <CText className="stats-title" level="h3" fontWeight="700" color="light">
        {t('team_stats.2.title')}
      </CText>
      
      <div className="stats-content">
        <div className="stats-content-inner">
          {players.length > 0 ?
            players.map((player, index) => (
              <div key={index}>
                <TopStatsRow
                  player={player.player}
                  value={player?.mvp_count}
                  index={index}
                  labelKey={t('common.mvp')}
                />
                <div className="stats-divider" />
              </div>
            ))
          : null}
        </div>
      </div>
    </div>
  )
}

export default MVPRanking;