import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const LABEL_COLOR = '#f2f2f2';
const COLOR_DARK = "#242834";
const ICON_COLOR = '#f2f2f2';
const TEXTFIELD_COLOR = '#242834';
const iconSize = 28;
const FONT_FAMILY='Montserrat';

const useStyles = makeStyles((theme) => ({
  selectZone: {
    backgroundColor: TEXTFIELD_COLOR,
    borderRadius: 8,
    width: '100%',
    fontFamily: FONT_FAMILY,
    "& .MuiInputBase-root": {
      height: 48  
    },
    "& .MuiSelect-select": {
      paddingTop: 25,
      color: `${LABEL_COLOR} !important`,
      fill: `${LABEL_COLOR} !important`,
      fontFamily: FONT_FAMILY
    },
    "& .MuiFormControl-root": {
      backgroundColor: TEXTFIELD_COLOR
    }
  },
  select: {
    fontFamily: FONT_FAMILY,
    "& .MuiInput-underline:before": {
      display: 'none'
    },
    "&:hover:not(.Mui-disabled):before": {
      display: 'none'
    },
    "&&&:before": {
      borderBottom: "none"
    }
  },
  label: {
    color: `${LABEL_COLOR} !important`,
    marginTop: -4,
    fontFamily: `${FONT_FAMILY} !important`,
    "&.Mui-focused": {
      color: `${COLOR_PRIMARY} !important`,
      marginTop: -6
    },
    "&.MuiFormLabel-filled": {
      marginTop: -6
    },
    fontSize: "16px !important"
  },
  icon: {
    marginRight: 8,
    width: 17,
    height: 17,
    display: 'flex',
    alignItems: 'center'
  },
  filledInputRoot: {
    "&.MuiFilledInput-root": {
      backgroundColor: 'transparent',
    },
    fontFamily: FONT_FAMILY
  },
  menuItemFocusVisible: {
    "&.Mui-focusVisible": {
      backgroundColor: "transparent"
    }
  },
  menuItem: {
    "&.MuiMenuItem-root": {
      fontFamily: FONT_FAMILY,
      "&.Mui-selected": {
        color: `${COLOR_LIGHT} !important`,  // Applique COLOR_LIGHT pour l'élément sélectionné
        backgroundColor: `${COLOR_PRIMARY} !important`  // Optionnel : changer le fond pour l'élément sélectionné
      },
      "&.Mui-focusVisible": {
        backgroundColor: "transparent",
      },
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      }
    }
  },
  menuList: {
    transform: 'translateY(1px) !important',
    fontFamily: FONT_FAMILY
  },
  menuItemSpan: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fill: COLOR_DARK,
    color: COLOR_DARK,
    fontFamily: FONT_FAMILY,
    "&.Mui-selected": {
      fill: COLOR_LIGHT,
      color: `${COLOR_LIGHT} !important`,
      fontWeight: 300,
    }
  }
}));


const CSelect = ({
  data,
  label,
  type,
  selectCallback,
  className,
  autoComplete = false,
  value
}) => {

  const classes = useStyles();
  const [currentValue, setCurrentValue] = useState(value?.code ? value.code : "");

  const handleChange = (event) => {
    const selectedCode = event.target.value;
    const selectedItem = data.find(item => item.code === selectedCode);
  
    if (selectedItem) {
      setCurrentValue(selectedCode);
      selectCallback(selectedItem);
    }
  };

  return (
    <FormControl
      variant="filled"
      className={`${className} ${classes.selectZone}`}
    >
      <InputLabel
        className={classes.label}
      >
        {label}
      </InputLabel>
      <Select
        value={currentValue}
        onChange={handleChange}
        className={`${classes.select} ${classes.filledInputRoot}`}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200
            }
          },
          className: classes.menuList
        }}
      >
        {data.map((item) => (
          <MenuItem
            key={item.code}
            value={item.code}
            className={classes.menuItem}
          >
            <span className={`${classes.menuItemSpan} ${currentValue === item.code ? "Mui-selected" : ""}`}>
              {item?.icon ? <span className={classes.icon}>{item.icon}</span> : null}
              <span>{item.label}</span>
            </span>
          </MenuItem>
        ))}
      </Select>

    </FormControl>
  );
}

export default CSelect;
