import React, { useState } from 'react';
import TextInput from '../../components/TextInput.js';
import CButton from "../../components/Button.js";
import { sessionHandler } from '../../utils/auth';
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { signIn } from '../../store/reducers/user';
import { SnackBarError } from "../../components/SnackBar";
import './auth.css';

const NewPassword = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [openSnack, setOpenSnack] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  
  const [newPwd, setNewPwd] = useState(null);

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const password = searchParams.get("password");

  // Click Buttons
  const submitClick = async() => {
    try {
      const user = await Auth.signIn(email, password);
      const userAuthenticated = await Auth.completeNewPassword(user, newPwd);
      console.log("Password changed.");
      console.log("User authenticated.");
      sessionHandler();
      dispatch(signIn());
    } catch (error) {
      console.log('error signing in', error);
    }
  }

  const closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };



  // Infos Callbacks
  const newPwdCb = (data) =>{ setNewPwd(data) };

  return(
    <div className="auth-container">
      <div className="auth-form-zone">
        <TextInput
          className="auth-input"
          label={t('common.new_password')}
          type="password"
          inputCallback={newPwdCb}
        />
        <CButton
          className="auth-form-btn"
          label={t('button.confirm')}
          height={38}
          type="contained"
          loader={true}
          onClick={async () => await submitClick()}
        />
      </div>

      <SnackBarError
        open={openSnack}
        onClose={closeSnackBar}
        autoHideDuration={5000}
        message={snackBarMessage}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </div>
  );
}

export default NewPassword;