import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import CButton from "../../../components/Button";
import { CText } from "../../../components/Text";
import CSelect from "../../../components/Select";
import { useStyles } from './Highlights.styles.js';
import { matchTimes } from './utils.js';

const OwnGoalDescriptionModal = ({goalDescription, goalNumber}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const userStored = useSelector(state => state?.user);

  const [selectedGameTime, setSelectedGameTime] = useState(null);

  const selectedTimeCb = (data) => {
    setSelectedGameTime(data)
  }

  const submit = () => {
    const goalInfo = {
      scorer: {},
      assister: {},
      own_goal: true,
      time: selectedGameTime.code
    };

    goalDescription(goalInfo);
  }

  let CONTENT;
  CONTENT =
  <div className={classes.modalSelectedPlayer}>
    <CText level="h2" fontWeight={700} color="primary">{t('after_game.2.modals.2.title')}{goalNumber}</CText>
    <div className={classes.modalInfo}>
      <CSelect
        className={classes.selector}
        label={t('common.game_time')}
        selectCallback={selectedTimeCb}
        data={matchTimes}
        value={selectedGameTime}
      />
    </div>

    <CButton
      className={classes.modalValidationBtn}
      label={t('button.confirm')}
      height={38}
      type="contained"
      onClick={() => submit()}
    />
  </div>

  return (
    <div className={classes.modalContainer}>
      {CONTENT}
    </div>
  );
}

export default OwnGoalDescriptionModal;