import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import CButton from "../../../components/Button";
import { CText } from "../../../components/Text";
import { SearchBar } from '../../../components/TextInput';
import ProfilePicture from "../../../components/ProfilePicture/index";
import CSelect from "../../../components/Select";
import SubstitutionLineUp from '../../../components/LineUp/SubstitutionLineUp'
import { useStyles } from './Highlights.styles.js';
import { matchTimes, searchPlayers, getTeamPlayers } from './utils.js';

const COLOR_PRIMARY = '#45d69f';
const COLOR_PINK= "#e92163";

const SubstitutionModal = ({description}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const userStored = useSelector(state => state?.user);

  const [selectedPlayerIn, setPlayerIn] = useState(null);
  const [selectedPlayerOut, setPlayerOut] = useState(null);
  const [selectedGameTime, setSelectedGameTime] = useState(null);
  const [allPlayers, setAllPlayers] = useState([]);
  const [listPlayers, setListPlayers] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [playerInPosition, setPlayerInPosition] = useState('');

  useEffect(() => {  
    const fetchData = async () => {
      const players = await getTeamPlayers(userStored);
      setAllPlayers(players)
      setListPlayers(players.slice(0, 6))
    }
  
    fetchData()
  }, [])

  const selectedTimeCb = (data) => {
    setSelectedGameTime(data)
  }

  const onChangeSearch = async(data) => {
    setSearchValue(data);
    const players = await searchPlayers(allPlayers, data);
    setListPlayers([]); // Don't remove
    setListPlayers(players);
  }

  const selectPlayerIn = (player) => {
    setPlayerIn(player);
    setSearchValue('');
    setListPlayers(allPlayers.slice(0, 6))
  };

  const selectPlayerOut = (player) => {
    setPlayerOut(player);
    setSearchValue('');
    setListPlayers(allPlayers.slice(0, 6))
  };

  const submit = () => {
    const data = {
      player_in: {
        id: selectedPlayerIn.user.id,
        first_name: selectedPlayerIn.user.first_name,
        last_name: selectedPlayerIn.user.last_name
      },
      player_out: {
        id: selectedPlayerOut.user.id,
        first_name: selectedPlayerOut.user.first_name,
        last_name: selectedPlayerOut.user.last_name,
        position: playerInPosition
      },
      time: selectedGameTime.code
    };

    description(data);
  }
  
  let CONTENT;
  if(selectedPlayerIn === null) {
    CONTENT =
    <div className={classes.modalSearch}>
      <div className={classes.modalSearchBarWrapper}>
        <SearchBar
          placeholder={`${t('after_game.2.modals.4.inputs.0.label')}`}
          value={searchValue}
          inputCallback={onChangeSearch}
        />
      </div>
      <ul className={classes.modalSearchResults}>
        {listPlayers?.map((player, index) => (
          <li key={index}>
            <div className={classes.modalSearchResult} onClick={() => selectPlayerIn(player)}>
              <ProfilePicture className={classes.modalPlayerPhoto} src={player.user.photo_path} />
              <CText level="p2" fontWeight={500} color="light" label={`${player.user.first_name} ${player.user.last_name}`} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  } else if(selectedPlayerOut === null) {
    CONTENT =
    <div className={classes.modalSearch}>
      <div className={classes.modalSearchBarWrapper}>
        <SearchBar
          placeholder={`${t('after_game.2.modals.4.inputs.1.label')}`}
          value={searchValue}
          inputCallback={onChangeSearch}
        />
      </div>
      <ul className={classes.modalSearchResults}>
        {listPlayers?.map((player, index) => (
          <li key={index}>
            <div className={classes.modalSearchResult} onClick={() => selectPlayerOut(player)}>
              <ProfilePicture className={classes.modalPlayerPhoto} src={player.user.photo_path} />
              <CText level="p2" fontWeight={500} color="light" label={`${player.user.first_name} ${player.user.last_name}`} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  } else {
    CONTENT =
    <>
      <div className={classes.modalTitle}>
        <CText level="h2" fontWeight={700} color="primary">{t('after_game.2.modals.4.title')}</CText>
      </div>
      <div className={classes.modalInfo}>
        <div className={classes.modalSubstitutionZone}>
          <CText level="p1" color="light">
            <span style={{marginRight: 5, color: COLOR_PRIMARY}}>{t('after_game.2.labels.5.label')}:</span>
            {selectedPlayerIn.user.first_name} {selectedPlayerIn.user.last_name}
          </CText>

          <CText level="p1" color="light">
            <span style={{marginRight: 5, color: COLOR_PINK}}>{t('after_game.2.labels.6.label')}:</span>
            {selectedPlayerOut.user.first_name} {selectedPlayerOut.user.last_name}
          </CText>
        </div>
        <div className={classes.selector}>
          <CSelect
            label={t('common.game_time')}
            selectCallback={selectedTimeCb}
            data={matchTimes}
            value={selectedGameTime}
          />
        </div>

      <div className={classes.substitutionLineUp}>
        <SubstitutionLineUp
          onChangePosition={setPlayerInPosition}
          player={selectedPlayerIn.user}
        />
      </div>

      </div>

      <div style={{paddingBottom: 20}}>
        <CButton
          className={classes.modalValidationBtn}
          label={t('button.confirm')}
          height={38}
          type="contained"
          onClick={() => submit()}
        />
      </div>

    </>
  }

  return (
    <div className={classes.modalContainer}>
      {CONTENT}
    </div>
  );
}

export default SubstitutionModal;