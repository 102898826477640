import React, { useState, useEffect } from 'react';
import { CText } from "../../components/Text";
import { useStyles } from './styles';

const COLOR_PRIMARY = '#45d69f';
const COLOR_PRIMARY_TRANSPARENT = 'rgba(69, 214, 159, 0.4)';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = '#242834';
const COLOR_RED = "#fc3c2e";
const COLOR_YELLOW = "#EDA247";
const FONT_FAMILY='Montserrat'

const PLAYER_SIZE = 50;


const Player = ({
  player,
  onPlayerMove,
  onSelect,
  isSelected,
  index,
  color,
  canvasScale,
  canvasWidth,
  canvasHeight
}) => {
  const classes = useStyles();

  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const TITLE_WIDTH = 100 * canvasScale;

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    onSelect(player.id);
    // Calculer la différence entre la position de la souris et la position du joueur au moment du clic
    setDragOffset({
      x: e.clientX / canvasScale - player.left,
      y: e.clientY / canvasScale - player.top
    });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      // Calculer les positions maximales autorisées
      const maxLeft = canvasWidth / canvasScale - (PLAYER_SIZE + 3*2); // La largeur du canvas moins la largeur + border du joueur
      const maxTop = canvasHeight / canvasScale - (PLAYER_SIZE + 3*2); // La hauteur du canvas moins la hauteur + border du joueur
  
      // Déplacer le joueur en fonction de la position de la souris et de l'offset calculé
      let newLeft = e.clientX / canvasScale - dragOffset.x;
      let newTop = e.clientY / canvasScale - dragOffset.y;
  
      // Limiter la position maximale en left et top
      newLeft = Math.min(Math.max(0, newLeft), maxLeft);
      newTop = Math.min(Math.max(0, newTop), maxTop);
  
      onPlayerMove(player.id, newLeft, newTop); // Utiliser la fonction onPlayerMove
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        id={`player-${index}`}
        className={classes.player}
        style={{
          cursor: isDragging ? 'grabbing' : 'grab',
          left: player.left * canvasScale,
          top: player.top * canvasScale,
          width: PLAYER_SIZE * canvasScale,
          height: PLAYER_SIZE * canvasScale,
          backgroundColor: color,
          border: `3px solid ${isSelected ? COLOR_PINK : color}`
        }}
        onMouseDown={handleMouseDown}
      >
        {player.label}
      </div>
      <div
        className={classes.playerTitleZone}
        style={{
          position: 'absolute',
          width: TITLE_WIDTH,
          top: (player.top + PLAYER_SIZE + 12) * canvasScale,
          left: (player.left + 3 + (PLAYER_SIZE / 2) - ((TITLE_WIDTH) / (2 * canvasScale))) * canvasScale,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <CText
          level="p2"
          color="light"
          fontWeight='700'
        >
          {player.title}
        </CText>
      </div>
    </div>
  );
};

export default Player;
